<div class="d-flex justify-content-between border-bottom" title="Expand sub-tasks" (click)="selectTaskTemplate()"  [ngClass]="{selected:isSelected}"

        wackadooResourceDragStart
        [resource]="taskTemplate"

        wackadooResourceDrop
        [resourceType]="taskTemplate.getType()"
        [resourceDropSubject]="handleResourceDropSubject"
>
    <div class="marginBottom3 d-flex">
        <div class="no-wrap">
            <div class="btn-group">
                <button type="button" title="View/Edit TaskTemplate Details" class="btn btn-secondary ultralightgray-bg smallish bold paddingTop2 paddingBottom2 paddingLeftQuarterEm paddingRightQuarterEm marginRight0 " (click)="openTaskTemplateModal($event)"><span class="fas fa-window-restore"></span></button>
            </div>
            <div class="btn-group ">
                <button type="button" title="Duplicate TaskTemplate (and sub-TaskTemplates)" class="btn btn-secondary ultralightgray-bg smallish bold paddingTop2 paddingBottom2 paddingLeftQuarterEm paddingRightQuarterEm marginLeft0 marginRight0 " (click)="duplicateTaskTemplate($event)">2x</button>
            </div>
            <div class="btn-group ">
                <button type="button" title="Copy TaskTemplate (and sub-TaskTemplates) To Different TaskTemplateGroup" class="btn btn-secondary ultralightgray-bg smallish bold paddingTop2 paddingBottom2 paddingLeftQuarterEm paddingRightQuarterEm marginLeft0 marginRight0 " (click)="copyToDifferentTaskTemplateGroup($event)"><span class="fas fa-external-link-alt"></span></button>
            </div>
            <div class="btn-group ">
                <button type="button" title="Copy TaskTemplate (and sub-TaskTemplates) To Different Contract Type" class="btn btn-secondary ultralightgray-bg smallish bold paddingTop2 paddingBottom2 paddingLeftQuarterEm paddingRightQuarterEm marginLeft0 marginRight0 " (click)="copyToDifferentContractType($event)"><span class="fas fa-external-link-alt"></span></button>
            </div>
<!--
            <div class="btn-group marginTop2 ">
                <button type="button" title="Add Reminder/Follow-Up" class="btn btn-secondary ultralightgray-bg smallish bold paddingTop2 paddingBottom2 paddingLeftQuarterEm paddingRightQuarterEm marginLeft0 marginRight0 " (click)="addReminder($event)"><span class="fas fa-bell"></span></button>
            </div>

-->            
            <div class="btn-group ">
                <button type="button" title="Delete Task (and sub-Tasks)" class="btn btn-secondary ultralightgray-bg smallish bold paddingTop2 paddingBottom2 paddingLeftQuarterEm paddingRightQuarterEm marginLeft0 " (click)="deleteTaskTemplate($event)"><span class="fas fa-trash-alt" aria-hidden="true"></span></button>
            </div>
        </div>
        <div class="d-flex marginLeftHalfEm middle">
            <div class="{{ iconClassNames }} marginTop6"></div>
            <div class="d-flex rollover marginLeftHalfEm">
                <div>
                    {{ taskTemplate.sequence.value }}
                </div>
                <div class="marginLeftHalfEm">
                    {{ taskTemplate.taskName.value }}
                </div>
            </div>
        </div>
    </div>
    <div class="marginBottom3 center rollover marginLeftHalfEm width-2-em">
        <span class="badge" [ngClass]="{selected:isSelected}">{{ taskTemplate.totalTasks.value }}<span *ngIf="isSelected"> &gt;&gt;</span></span>
    </div>
</div>
