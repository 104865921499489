import { Component, OnInit } from '@angular/core';
import { MergeDuplicatePeopleComponent } from './merge-duplicate-people.component';

@Component({
  selector: 'wackadoo-duplicate-attorneys',
  templateUrl: '../../../../../../src/app/client-core/ui/duplicate-merger/duplicate-merger.component.html',
})
export class DuplicateAttorneysComponent extends MergeDuplicatePeopleComponent implements OnInit {

  ngOnInit(): void {
    this.eventHandler = 'Attorneys';

    this.firstNameField = 'attorneyFirstName';
    this.lastNameField = 'attorneyLastName';
    this.companyField = 'attorneyCompany';

    this.leftGridTitle = 'Duplicate Attorneys?';
    this.leftGridFields = ['attorneyCompany', 'attorneyFirstName', 'attorneyLastName'];
    this.leftGridQueryFilter = 'attorneyNote:dupe OR attorneyNote:duplicate';

    this.rightGridTitle = 'Similar sounding Attorneys...';
    this.rightGridFields = ['attorneyCompany', 'attorneyFirstName', 'attorneyLastName'];

    this.childEventHandlersToMergeForeignKeyValues = ['Contracts'];

    super.ngOnInit();
  }

}
