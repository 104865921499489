import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { WResource } from 'src/app/client-core/data/resource.model';
import { Subject, Subscription } from 'rxjs';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { WEvent } from 'src/app/client-core/data/event.model';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { Globals } from 'src/app/client-core/services/global.service';
import { User } from 'src/app/client-core/data/user.model';
import { UserAuthService } from 'src/app/client-core/services/user-auth.service';
import { ClosingProService } from '../../../closing-pro.service';
import { ScreenType } from 'src/app/client-core/services/screen-type.enum';

@Component({
  selector: 'wackadoo-task-list-panel-item',
  templateUrl: './task-list-panel-item.component.html',
})
export class TaskListPanelItemComponent implements OnInit, OnDestroy {

  @Input() task: WResource = null;
  @Input() sequence: number = null;
  @Input() isSelected = false;

  @Input() selectTaskSubject: Subject<WResource> = null;

  @Input() notifyChildrenSubject: Subject<WResource> = null;
  @Input() notifyParentSubject: Subject<WResource> = null;

  private _childListenerSubscription: Subscription = null;
  @Input() childListenerSubject: Subject<WResource> = null;

  @Input() dragNDropMode: string = null;

  handleResourceDropSubject: Subject<WResource> = null;
  handleResourceDropSubscription: Subscription = null;

  @Input() refreshPanelSubject: Subject<void> = null;

  public user: User = null;

  onHotList = false;

  constructor(
    public closingProService: ClosingProService,
    public eventServerService: EventServerService,
    public userInterfaceService: UserInterfaceService,
    public modalDialogService: ModalDialogService,
    public userAuthService: UserAuthService,
  ) {
  }

  ngOnInit(): void {

    this.user = this.userAuthService.currentUser.getValue();

    this.onHotList = ((this.task.taskPriority.value === 'high') || (this.task.taskPriority.value === 'medium'));

    // First, if necessary, automatically re-sequence the task in the panel

    if (this.task && this.sequence && (this.task.sequence.value !== this.sequence)) {
      // console.log('adjusting sequence:', this.task.sequence.value, this.sequence);
      // set the sequence locally
      this.task.sequence.value = this.sequence;
      // save the sequence to the server
      const parms = this.task.keyField.asParm;
      parms.sequence = this.sequence;

      // to fire-and-forget, we have to subscribe() because HttpClient.post() returns a "cold" Observable<>
      this.eventServerService.fireEvent('Tasks', 'modify', parms).subscribe();
    }

    // Second, if necessary, set up to listen for CHILD tasks to be completed...

    if (this.childListenerSubject) {
      this._childListenerSubscription = this.childListenerSubject.subscribe(
        (task: WResource) => {
          if (this.isSelected) {
            if (this.task && task) {
              // notifications may be fired on this subject for other tasks...
              // we only care about "our" child tasks...
              if (this.task.taskID.value === task.parentTaskID.value) {

                // console.log('TaskListPanelItem._childListenerSubscription(' + this.task.taskName.value + ')');

                this.eventServerService.fireEvent('Tasks', 'listWithChildCounts', this.task.keyField.asParm).subscribe(
                  (event: WEvent) => {
                    try {
                      if (event.status === 'OK') {

                        const oldTaskCompletion = this.task.taskComplete.value;

                        this.task = event.resources[0];

                        // console.log(
                        //    'TaskListPanelItem._childListenerSubscription(' + this.task.taskName.value + ')',
                        //    'counts: ' + this.task.completedTasks.value + ' of ' + this.task.totalTasks.value,
                        // );

                        let newTaskCompletion = oldTaskCompletion;
                        if (!oldTaskCompletion) {
                          newTaskCompletion = (this.task.completedTasks.value === this.task.totalTasks.value);
                        }

                        // If we're changing completion status,
                        // we wait to notify everybody until after we have actually changed it...

                        // console.log(
                        //    'TaskListPanelItem._childListenerSubscription(' + this.task.taskName.value + ')',
                        //    'counts: ' + this.task.completedTasks.value + ' of ' + this.task.totalTasks.value,
                        //    'oldTaskCompletion: ' + oldTaskCompletion, 'newTaskCompletion: ' + newTaskCompletion
                        // );

                        if (oldTaskCompletion !== newTaskCompletion) {

                          const parms: any = this.task.keyField.asParm;
                          parms.taskComplete = newTaskCompletion;

                          this.eventServerService.fireEvent('Tasks', 'modify', parms).subscribe(
                            (event2: WEvent) => {
                              try {
                                if (event2.status === 'OK') {

                                  // console.log('TaskListPanelItem._childListenerSubscription(' + this.task.taskName.value + ') - setting checkbox: ' + newTaskCompletion);

                                  this.task.taskComplete.value = newTaskCompletion; // same as... event2.getParameter('taskComplete');

                                  if (this.notifyParentSubject) {
                                    this.notifyParentSubject.next(this.task);
                                  }

                                } else {
                                  throw new Error(event2.message);
                                }
                              } catch (ex) {
                                this.userInterfaceService.alertUserToException(ex, 'Failed to update Task completion status!');
                              }
                            }
                          );
                        }

                      } else {
                        throw new Error(event.message);
                      }
                    } catch (ex) {
                      this.userInterfaceService.alertUserToException(ex, 'Failed to get Task child counts!');
                    }
                  }
                );
              }
            }
          }
        }
      );
    }

    // Third, set up to listen for another task to be dropped on this one...

    this.handleResourceDropSubject = new Subject<WResource>();

    this.handleResourceDropSubscription = this.handleResourceDropSubject.subscribe(
      (payloadTask: WResource) => {

        if (this.dragNDropMode === 'relative') {

          // console.log('Make the timing of task "' + payloadTask.taskName.value + '" relative to "' + this.task.taskName.value + '"');

          this.closingProService.relateTasks(payloadTask, this.task).subscribe(
            (responseEvent: WEvent) => {
              try {
                if (responseEvent) {
                  // console.log('Made the timing of task "' + payloadTask.taskName.value + '" relative to "' + this.task.taskName.value + '"');
                  const subsequentTaskLeadTime = responseEvent.getParameter('subsequentTaskLeadTime');

                  if ((subsequentTaskLeadTime < 0) && (!this.task.taskStartDate || !this.task.taskStartDate.value)) {
                    let msg = '\n';
                    msg += 'With a negative time (' + subsequentTaskLeadTime + '), you are trying to schedule the "' + payloadTask.taskName.value + '" task BEFORE the start date of the "' + this.task.taskName.value + '" task.';
                    msg += '\n';
                    msg += '\n';
                    msg += 'However, the "' + this.task.taskName.value + '" task has no defined start date.';
                    msg += '\n';
                    msg += '\n';
                    msg += 'You MUST now schedule the "' + this.task.taskName.value + '" task, or this relative timing that you just set up will not be properly triggered.';

                    this.modalDialogService.showAlert(msg, 'Please Note!');
                  }

                }

                // Q: Do we need to do this?
                this.userInterfaceService.reloadCurrentPage();

              } catch (ex) {
                const msg2 = 'TaskList.handleGoodDrop.relateTasks.callback()\n';
                this.userInterfaceService.alertUserToException(ex, msg2);
              }
            }
          );

        } else if (this.dragNDropMode === 'move') {

          // Dropping a Task with children on the tertiary panel is not allowed!
          if (!this.notifyChildrenSubject && (payloadTask.totalTasks && (payloadTask.totalTasks.value > 0))) {

            this.modalDialogService.showAlert('You may NOT drop a Task with sub-tasks here...');

          // Dropping a Task on itself is a NOP!!!
          } else if (payloadTask.taskID.value !== this.task.taskID.value) {

            const parms = payloadTask.keyField.asParm;
            // primary panel has no parentTaskID...
            parms.parentTaskID = (this.task.parentTaskID.value ? this.task.parentTaskID.value : Globals.MAGIC_NULL_FKEY_VALUE);
            // drop the payload in BEFORE the target...
            if (this.task.parentTaskID.value === payloadTask.parentTaskID.value) {
              parms.sequence = this.task.sequence.value - (payloadTask.sequence.value > this.task.sequence.value ? 1 : 0);
            } else {
              parms.sequence = this.task.sequence.value - 1;
            }

            this.eventServerService.fireEvent('Tasks', 'modify', parms).subscribe(
              (event: WEvent) => {
                if (event.status === 'OK') {
                  if (this.notifyParentSubject) {
                    this.notifyParentSubject.next(this.task);
                  }
                  this.userInterfaceService.reloadCurrentPage();
                } else {
                  this.modalDialogService.showAlert(event.message, 'Failed to insert task!');
                }
              }
            );
          }
        }
      }
    );

  }

  ngOnDestroy(): void {
    if (this._childListenerSubscription) {
      this._childListenerSubscription.unsubscribe();
    }

    if (this.handleResourceDropSubscription) {
      this.handleResourceDropSubscription.unsubscribe();
    }

  }

  get iconClassNames(): string {
    return this.closingProService.getTaskIconClassNames(this.task.taskType.value, this.task.taskComplete.value);
  }

  get screenType(): ScreenType {
    return this.userInterfaceService.screenType.getValue();
  }

  selectTask(): void {
    // console.log('selectTask', this.task);
    // guests do not select tasks if there are no children...
    if (this.selectTaskSubject && (this.user.isMemberOrAbove || (this.task.totalTasks.value > 0))) {
      this.selectTaskSubject.next(this.task);
    }
  }

  clickTaskCheckbox(e: any): void {
    e.stopPropagation();
    e.preventDefault();

    // console.log('TaskListPanelItem.clickTaskCheckbox() - ' + this.task.taskName.value);

    this.modalDialogService.showPleaseWait('Setting task complete for this task <span class="italic">and all sub-tasks</span>...');

    const parms = this.task.keyField.asParm;
    parms.taskComplete = !this.task.taskComplete.value;

    if (this.task.taskComplete.value && ((this.task.completedTasks ? this.task.completedTasks.value : 0) !== this.task.totalTasks.value)) {
      // we DO NOT propagate the click downwards if the task is marked as complete,
      // but there are IN-complete child tasks below it...
    } else {
      parms.propagate = true;
    }

    this.eventServerService.fireEvent('Tasks', 'modify', parms).subscribe(
      (event: WEvent) => {
        try {
          this.modalDialogService.showPleaseWait(false);

          if (event.status === 'OK') {

            this.eventServerService.fireEvent('Tasks', 'listWithChildCounts', this.task.keyField.asParm).subscribe(
              (event2: WEvent) => {
                try {
                  if (event2.status === 'OK') {
                    this.task = event2.resources[0];
                  } else {
                    throw new Error(event2.message);
                  }
                } catch (ex) {
                  this.userInterfaceService.alertUserToException(ex, 'Failed to get Task child counts!');
                }
              }
            );

            if (this.notifyChildrenSubject) {
              this.notifyChildrenSubject.next(this.task);
            }

            if (this.notifyParentSubject) {
              this.notifyParentSubject.next(this.task);
            }

          } else {
            throw new Error(event.message);
          }
        } catch (ex) {
          this.userInterfaceService.alertUserToException(ex, 'Failed to update Task completion status!');
        }
      }
    );
  }

  openTaskModal(e: any): void {
    e.stopPropagation();
    e.preventDefault();

    this.modalDialogService.modifyResourceModal('TaskModalComponent', 'Tasks', this.task).subscribe(
      (event: WEvent) => {
        // if we cancel or properly saved the page...
        if (event && (event.status !== 'OK')) {
          // console.log('TaskListPanelItemComponent.openTaskModal.closed()', event);
          this.modalDialogService.showAlert('Unable to modify task...\n' + event.message, 'WARNING!').subscribe(
            () => {
              this.refreshPanelSubject.next();
            }
          );
        } else {
          this.refreshPanelSubject.next();
        }
      }
    );
  }

  duplicateTask(e: any): void {
    e.stopPropagation();
    e.preventDefault();

    try {
      // console.log('TaskList.duplicateTask() - Got here!\n\n' + JSON.stringify(resource.simplifyResource()));

      const confirmMsg = 'This is going to <strong>DUPLICATE</strong> the "'
                          +  this.task.taskName.value
                          + '" Task, and EVERY "sub-task" beneath it!'
                          + '\n\nAre you SURE you want to do this?';

      this.modalDialogService.showConfirm(confirmMsg, 'WARNING!').subscribe(
        (flag: boolean) => {
          if (flag) {
            const msg = 'Duplicating task: ' + this.task.taskName.value + ' (and sub-tasks...)';
            this.modalDialogService.showPleaseWait(msg, true);
            this.closingProService.logThisNote(this.task.contractID.value, msg, true);
            this.eventServerService.fireEvent('Tasks', 'duplicateHierarchyBranch', this.task.keyField.asParm).subscribe(
              (event: WEvent) => {
                this.modalDialogService.showPleaseWait(false);
                if (event.status !== 'OK') {
                  this.modalDialogService.showAlert(event.message, 'Error Duplicating Task (and/or sub-tasks)');
                }
                // refresh the current panel...
                this.refreshPanelSubject.next();
                // update the parent taskComplete checkbox, if applicable...
                if (this.notifyParentSubject) {
                  this.notifyParentSubject.next(this.task);
                }
              }
            );
          }
        }
      );

    } catch (ex) {
      const msg = 'TaskLists.duplicateTask()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  copyToDifferentContract(e: any): void {
    e.stopPropagation();
    e.preventDefault();

    try {
      const temp = this.eventServerService.newResource('Tasks');
      temp.setFieldsFromParms(this.task.asParms);
      temp.contractID.value = Globals.MAGIC_NULL_FKEY_VALUE;
      temp.contractID.foreignKeyFilterParms = {contractStatus: 'active'};

      const oldContractID = this.task.contractID.value;

      // console.log('TaskList.copyToDifferentContract() - Got here!', temp, temp.asParms);

      this.modalDialogService.showSelectValue('SelectContractIDModalComponent', temp, 'contractID').subscribe(
        (newContractID: number) => {
            try {
              // console.log('TaskList.copyToDifferentContract() - Got there! newContractID: ' + newContractID + ' (oldContractID: ' + oldContractID + ')');

              if (newContractID) {

                if (newContractID !== oldContractID) {

                  const parms = this.task.keyField.asParm;
                  parms.fieldName = 'contractID';
                  parms.newValue = newContractID;
                  // console.log('TaskList.copyToDifferentContract.click()  - Got there!',parms);

                  const userMessage = 'Duplicating task (and sub-tasks) in a different contract: ' + this.task.taskName.value;
                  this.modalDialogService.showPleaseWait(userMessage);

                  this.eventServerService.fireEvent('Tasks', 'duplicateHierarchyBranch', parms).subscribe(
                    () => {
                      this.modalDialogService.showPleaseWait(false);

                      this.closingProService.logThisNote(oldContractID, userMessage, true);

                      const parms2: any = {};
                      parms2.contractID = newContractID;
                      this.eventServerService.loadResourceFromServer('ActiveContracts', parms2).subscribe(
                        (r: WResource) => {
                          if (r) {
                            this.userInterfaceService.selectResource('ActiveContracts', r);
                            this.userInterfaceService.reloadCurrentPage();
                          }
                        }
                      );
                    }
                  );

                } else {
                  this.modalDialogService.showAlert('Not copying this task to the same contract.\n(Use the "Duplicate Task" button if that is what you really want to do.)');
                }
              }

            } catch (ex) {
              const msg = 'TaskList.copyToDifferentContract.click()\n';
              this.userInterfaceService.alertUserToException(ex, msg);
            }
        }
      );

    } catch (ex) {
      const msg = 'TaskLists.copyToDifferentContract()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  deleteTask(e: any): void {
    e.stopPropagation();
    e.preventDefault();

    try {
      const confirmMsg = 'This is going to <strong>DELETE</strong> the "'
              +  this.task.taskName.value
              + '" Task for this Contract, and EVERY "sub-task" beneath it!'
              + '\n\nAre you SURE you want to do this?';

      this.modalDialogService.showConfirm(confirmMsg, 'WARNING!').subscribe(
        (flag: boolean) => {
          if (flag) {
            this.closingProService.logThisNote(this.task.contractID.value, 'Deleting task: ' + this.task.taskName.value + ' (and sub-tasks...)', true);
            this.eventServerService.fireEvent('Tasks', 'delete', this.task.taskID.asParm).subscribe(
              (event: WEvent) => {
                if (event.status !== 'OK') {
                  throw new Error(event.message);
                }
                try {
                  // unselect the resource
                  this.selectTaskSubject.next(null);
                  // refresh the current panel...
                  this.refreshPanelSubject.next();
                  // update the parent taskComplete checkbox, if applicable...
                  if (this.notifyParentSubject) {
                    this.notifyParentSubject.next(this.task);
                  }
                } catch (ex) {
                  const msg2 = 'TaskLists.deleteTask()\n';
                  this.userInterfaceService.alertUserToException(ex, msg2);
                }
              }
            );
          }
        }
      );

    } catch (ex) {
      const msg = 'TaskLists.deleteTask()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  openEMailer(e: any): void {
    e.stopPropagation();
    e.preventDefault();

    const parms = this.task.keyField.asParm;
    parms.contractID = this.task.contractID.value;
    parms.contractType = this.task.contractType.value;
    parms.templateName = this.task.taskEMailTemplate.value;

    // Here, we determine what kind of resource can be in the resourceBlock in the template...
    const matches = this.task.taskName.value.match(/.*inspection.*|.*estimate.*|.*repair.*|.*survey.*|.*vendor.*/i);
    parms.resourceType = (matches === null ? 'Client' : 'Vendor');

    // EMailer is a modal dialog...
    this.modalDialogService.showEMailer('EMailer', parms);
  }

  scheduleTaskNow(e: any): void {
    e.stopPropagation();
    e.preventDefault();

    // if it's NOT on the hot list...

    if (!this.onHotList) {

      const d = new Date();

      const parms = this.task.keyField.asParm;
      parms.taskStartDate = d.toJSON();

      this.eventServerService.fireEvent('Tasks', 'modify', parms).subscribe(
        (event: WEvent) => {
          if (event) {
            if (event.status !== 'OK') {
              throw new Error(event.message);
            }
            this.refreshPanelSubject.next();
          }
        }
      );
    }
  }

  scheduleTask(e: any): void {
    e.stopPropagation();
    e.preventDefault();

    this.closingProService.scheduleTask(this.task).subscribe(
      (event: WEvent) => {
        if (event) {
          if (event.status !== 'OK') {
            throw new Error(event.message);
          }
          this.refreshPanelSubject.next();
        }
      }
    );
  }

  addReminder(e: any): void {
    e.stopPropagation();
    e.preventDefault();

    this.closingProService.addReminder(this.task).subscribe(
      (event: WEvent) => {
        if (event && this.notifyParentSubject) {
          this.notifyParentSubject.next(this.task);
        }
      }
    );
  }

}
