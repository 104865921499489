<div class="card">
    <div class="card-header">
        Client
    </div>
    <div class="card-body">
        <div [ngClass]="{'d-flex justify-content-between':(screenType !== 'phone')}">
            <div>
                <div [ngClass]="{'d-flex':(screenType !== 'phone')}">
                    <div>
                        <wackadoo-field [f]="resource.clientFirstName" [mode]="mode"></wackadoo-field>
                    </div>
                    <div [ngClass]="{marginLeftHalfEm:(screenType !== 'phone')}">
                        <wackadoo-field [f]="resource.clientLastName" [mode]="mode"></wackadoo-field>
                    </div>
                </div>
                <div><wackadoo-field [f]="resource.clientEmail" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.clientPhone" [mode]="mode"></wackadoo-field></div>
            </div>
            <div [ngClass]="{marginLeftHalfEm:(screenType !== 'phone')}">
                <div class="small underline italic">Address</div>
                <div><wackadoo-field [f]="resource.clientCompany" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.clientAddress" [mode]="mode"></wackadoo-field></div>
                <div class="d-flex">
                    <div>
                        <wackadoo-field [f]="resource.clientCity" [mode]="mode" [size]="10"></wackadoo-field>
                    </div>
                    <div class="marginLeftHalfEm">
                        <wackadoo-field [f]="resource.clientState" [mode]="mode" [size]="2"></wackadoo-field>
                    </div>
                    <div class="marginLeftHalfEm">
                        <wackadoo-field [f]="resource.clientPostalCode" [mode]="mode" [size]="5"></wackadoo-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="marginTopWholeEm">
            <div class="small underline italic">
                Note
            </div>
            <div>
                <wackadoo-field [f]="resource.clientNote" [mode]="mode"></wackadoo-field>
            </div>
        </div>
    </div>
</div>
