import { HttpParameterCodec } from '@angular/common/http';

// This is our "do not encode the + sign encoder" for sending Event parms over to the server...

export class EventServerHttpUrlEncodingCodec implements HttpParameterCodec {
    encodeKey(k: string): string { return standardEncoding(k); }
    encodeValue(v: string): string { return standardEncoding(v); }
    decodeKey(k: string): string { return decodeURIComponent(k); }
    decodeValue(v: string): string { return decodeURIComponent(v); }
}

function standardEncoding(v: string): string {
    return encodeURIComponent(v);
}
