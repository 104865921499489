import { Directive, Input, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { UserInterfaceService } from '../services/user-interface.service';

@Directive({
  selector: '[wackadooPageLoad]'
})
export class PageLoadDirective implements OnInit {

  @Input() pageName: string = null;
  @Input() eventHandler: string = null;
  @Input() action: string = null;
  @Input() parms: any = {};
  @Input() debug = false;

  constructor(
    public elementRef: ElementRef,
    public renderer: Renderer2,
    public userInterfaceService: UserInterfaceService,
  ) {}

  ngOnInit(): void {

    // tell the element to listen for on-click...

    this.renderer.listen(
      this.elementRef.nativeElement,
      'click',
      (e: any) => {

        if (this.debug) {
          console.log('PageLoadDirective.click()', (this.pageName ? this.pageName :  this.eventHandler), this.action, 'parms: ' + JSON.stringify(this.parms), this.parms);
        }
        this.userInterfaceService.loadPage((this.pageName ? this.pageName :  this.eventHandler), this.action, this.parms);

        e.stopPropagation();
        e.preventDefault();

        return false;
      }
    );

  }
}
