<div class="row">
    <div class="col-sm-4">

        <div class="btn-group marginTopHalfEm">
            <button type="button" class="btn btn-secondary" (click)="togglePieCharts()" ><span class="fas" [ngClass]="{'fa-chart-pie':!showPieCharts, 'fa-table':showPieCharts }"></span> Show {{ (showPieCharts ? 'Data Tables' : 'Pie Charts') }} </button>
        </div>

        <div class="card" [ngClass]="{small:(screenType === 'phone')}"  *ngIf="!showPieCharts">
            <div class="card-header">
                {{thisYear}} Contract Counts
            </div>
            <div class="card-body">
                <table class="table marginBottomQuarterEm small">
                    <tbody>
                        <tr class="bold nowrap">
                            <td>
                                agency
                            </td>
                            <td class="right">
                                active
                            </td>
                            <td class="right">
                                closed
                            </td>
                            <td class="right">
                                listed
                            </td>
                            <td class="right">
                                term
                            </td>
                            <td class="right">
                                wthdr
                            </td>
                            <td class="right">
                                other
                            </td>
                            <td class="right">
                                total
                            </td>
                            <td class="right">
                                %
                            </td>
                        </tr>
                        <tr class="nowrap" *wackadooResourceBlock="let r of []; let idx = index; eventHandler: 'Reports'; action: 'list'; parms: { reportName: 'Contract Count and Pct (for a year, by agency)', year: thisYear }; defaultText: ''; debug: false;">
                            <td>
                                <wackadoo-field [f]="r.agency" mode="read"></wackadoo-field>
                            </td>
                            <td class="right">
                                <wackadoo-field [f]="r.active" mode="read"></wackadoo-field>
                            </td>
                            <td class="right">
                                <wackadoo-field [f]="r.closed" mode="read"></wackadoo-field>
                            </td>
                            <td class="right">
                                <wackadoo-field [f]="r.listed" mode="read"></wackadoo-field>
                            </td>
                            <td class="right">
                                <wackadoo-field [f]="r.terminated" mode="read"></wackadoo-field>
                            </td>
                            <td class="right">
                                <wackadoo-field [f]="r.withdrawn" mode="read"></wackadoo-field>
                            </td>
                            <td class="right">
                                <wackadoo-field [f]="r.other" mode="read"></wackadoo-field>
                            </td>
                            <td class="right">
                                <wackadoo-field [f]="r.total" mode="read"></wackadoo-field>
                            </td>
                            <td class="right">
                                <wackadoo-field [f]="r.totalPct" mode="read"></wackadoo-field>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        
        <div class="card" [ngClass]="{small:(screenType === 'phone')}"  *ngIf="!showPieCharts">
            <div class="card-header">
                {{lastYear}} Contract Counts
            </div>
            <div class="card-body">
                <table class="table marginBottomQuarterEm small">
                    <tbody>
                        <tr class="bold nowrap">
                            <td>
                                agency
                            </td>
                            <td class="right">
                                active
                            </td>
                            <td class="right">
                                closed
                            </td>
                            <td class="right">
                                listed
                            </td>
                            <td class="right">
                                term
                            </td>
                            <td class="right">
                                wthdr
                            </td>
                            <td class="right">
                                other
                            </td>
                            <td class="right">
                                total
                            </td>
                            <td class="right">
                                %
                            </td>
                        </tr>
                        <tr class="nowrap" *wackadooResourceBlock="let r of []; let idx = index; eventHandler: 'Reports'; action: 'list'; parms: { reportName: 'Contract Count and Pct (for a year, by agency)', year: lastYear }; defaultText: ''; debug: false;">
                            <td>
                                <wackadoo-field [f]="r.agency" mode="read"></wackadoo-field>
                            </td>
                            <td class="right">
                                <wackadoo-field [f]="r.active" mode="read"></wackadoo-field>
                            </td>
                            <td class="right">
                                <wackadoo-field [f]="r.closed" mode="read"></wackadoo-field>
                            </td>
                            <td class="right">
                                <wackadoo-field [f]="r.listed" mode="read"></wackadoo-field>
                            </td>
                            <td class="right">
                                <wackadoo-field [f]="r.terminated" mode="read"></wackadoo-field>
                            </td>
                            <td class="right">
                                <wackadoo-field [f]="r.withdrawn" mode="read"></wackadoo-field>
                            </td>
                            <td class="right">
                                <wackadoo-field [f]="r.other" mode="read"></wackadoo-field>
                            </td>
                            <td class="right">
                                <wackadoo-field [f]="r.total" mode="read"></wackadoo-field>
                            </td>
                            <td class="right">
                                <wackadoo-field [f]="r.totalPct" mode="read"></wackadoo-field>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div *ngIf="showPieCharts">
            <wackadoo-chart-block
                mainTitle="{{thisYear}} Closings And Listings"
                subTitle=""
                chartType="pie"
                [showPercentage]="true"

                eventHandler="Reports"
                action="list"
                [parms]="{reportName:'Closings and Listings by Agency (for a year)', year: thisYear}"

                [applySelectedKeys]="false"
                defaultTextContent="No data found for this year..."
                [debug]="false"

                xAxisLabelField="agency"

                [relativeHeight]="22"

            ></wackadoo-chart-block>

            <wackadoo-chart-block
                mainTitle="{{lastYear}} Closings And Listings"
                subTitle=""
                chartType="pie"
                [showPercentage]="true"

                eventHandler="Reports"
                action="list"
                [parms]="{reportName:'Closings and Listings by Agency (for a year)', year: lastYear}"

                [applySelectedKeys]="false"
                defaultTextContent="No data found for last year..."
                [debug]="false"

                xAxisLabelField="agency"

                [relativeHeight]="22"

            ></wackadoo-chart-block>
        </div>

    </div>
    <div class="col-sm-8">

        <div class="btn-group marginTopHalfEm">
            <button type="button" class="btn btn-secondary" (click)="toggleBarCharts()" ><span class="fas" [ngClass]="{'fa-chart-bar':!showBarCharts, 'fa-table':showBarCharts }"></span> Show {{ (showBarCharts ? 'Data Tables' : 'Bar Charts') }} </button>
        </div>

        <div *ngIf="showBarCharts">

            <wackadoo-chart-block
                mainTitle="Contracts Closed (incl. Forecast)"
                subTitle=""
                chartType="bar"

                eventHandler="Reports"
                action="list"
                [parms]="{reportName:'Contracts Closed by Month by Year (incl. Forecast)'}"
                
                [applySelectedKeys]="false"
                defaultTextContent="No data found for Contracts Closed by Month by Year (incl. Forecast)"
                [debug]="false"
                
                yAxisLabel="Contracts Closed"
                xAxisLabelField="month"
                [dataSetLabels]="{thisYear:thisYear, lastYear:lastYear, twoYearsAgo:twoYearsAgo, threeYearsAgo:threeYearsAgo}"

                [hideDataLabels]="true"

                [relativeHeight]="22"

            ></wackadoo-chart-block>

            <wackadoo-chart-block
                mainTitle="Contracts Signed"
                subTitle=""
                chartType="bar"

                eventHandler="Reports"
                action="list"
                [parms]="{reportName:'Contracts Signed by Month by Year'}"
                
                [applySelectedKeys]="false"
                defaultTextContent="No data found for Contracts Signed by Month by Year..."
                [debug]="false"
                
                yAxisLabel="Contracts Signed"
                xAxisLabelField="month"
                [dataSetLabels]="{thisYear:thisYear, lastYear:lastYear, twoYearsAgo:twoYearsAgo, threeYearsAgo:threeYearsAgo}"

                [hideDataLabels]="true"

                [relativeHeight]="22"

            ></wackadoo-chart-block>

            <wackadoo-chart-block
                mainTitle="Contracts Listed (incl. Forecast)"
                subTitle=""
                chartType="bar"

                eventHandler="Reports"
                action="list"
                [parms]="{reportName:'Contracts Listed by Month by Year (incl. Forecast)'}"
                
                [applySelectedKeys]="false"
                defaultTextContent="No data found for Contracts Listed by Month by Year (incl. Forecast)"
                [debug]="false"
                
                yAxisLabel="Contracts Closed"
                xAxisLabelField="month"
                [dataSetLabels]="{thisYear:thisYear, lastYear:lastYear, twoYearsAgo:twoYearsAgo, threeYearsAgo:threeYearsAgo}"

                [hideDataLabels]="true"

                [relativeHeight]="22"

            ></wackadoo-chart-block>
        </div>

        <div  *ngIf="!showBarCharts">

            <div class="card">
                <div class="card-header">
                    Contracts Closed (incl. Forecast)
                </div>
                <div class="card-body">
                    <table class="table marginBottomQuarterEm small">
                        <tbody>
                            <tr class="bold nowrap">
                                <td>
                                    month
                                </td>
                                <td class="right">
                                    {{thisYear}}
                                </td>
                                <td class="right">
                                    {{lastYear}}
                                </td>
                                <td class="right">
                                    {{twoYearsAgo}}
                                </td>
                                <td class="right">
                                    {{threeYearsAgo}}
                                </td>
                            </tr>
                            <tr class="nowrap" *wackadooResourceBlock="let r of []; let idx = index; eventHandler: 'Reports'; action: 'list'; parms: { reportName: 'Contracts Closed by Month by Year (incl. Forecast)' }; defaultText: ''; debug: false;">
                                <td>
                                    <wackadoo-field [f]="r.month" mode="read"></wackadoo-field>
                                </td>
                                <td class="right">
                                    <wackadoo-field [f]="r.thisYear" mode="read"></wackadoo-field>
                                </td>
                                <td class="right">
                                    <wackadoo-field [f]="r.lastYear" mode="read"></wackadoo-field>
                                </td>
                                <td class="right">
                                    <wackadoo-field [f]="r.twoYearsAgo" mode="read"></wackadoo-field>
                                </td>
                                <td class="right">
                                    <wackadoo-field [f]="r.threeYearsAgo" mode="read"></wackadoo-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
            <div class="card">
                <div class="card-header">
                    Contracts Signed
                </div>
                <div class="card-body">
                    <table class="table marginBottomQuarterEm small">
                        <tbody>
                            <tr class="bold nowrap">
                                <td>
                                    month
                                </td>
                                <td class="right">
                                    {{thisYear}}
                                </td>
                                <td class="right">
                                    {{lastYear}}
                                </td>
                                <td class="right">
                                    {{twoYearsAgo}}
                                </td>
                                <td class="right">
                                    {{threeYearsAgo}}
                                </td>
                            </tr>
                            <tr class="nowrap" *wackadooResourceBlock="let r of []; let idx = index; eventHandler: 'Reports'; action: 'list'; parms: { reportName: 'Contracts Signed by Month by Year' }; defaultText: ''; debug: false;">
                                <td>
                                    <wackadoo-field [f]="r.month" mode="read"></wackadoo-field>
                                </td>
                                <td class="right">
                                    <wackadoo-field [f]="r.thisYear" mode="read"></wackadoo-field>
                                </td>
                                <td class="right">
                                    <wackadoo-field [f]="r.lastYear" mode="read"></wackadoo-field>
                                </td>
                                <td class="right">
                                    <wackadoo-field [f]="r.twoYearsAgo" mode="read"></wackadoo-field>
                                </td>
                                <td class="right">
                                    <wackadoo-field [f]="r.threeYearsAgo" mode="read"></wackadoo-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
            <div class="card">
                <div class="card-header">
                    Contracts Listed (incl. Forecast)
                </div>
                <div class="card-body">
                    <table class="table marginBottomQuarterEm small">
                        <tbody>
                            <tr class="bold nowrap">
                                <td>
                                    month
                                </td>
                                <td class="right">
                                    {{thisYear}}
                                </td>
                                <td class="right">
                                    {{lastYear}}
                                </td>
                                <td class="right">
                                    {{twoYearsAgo}}
                                </td>
                                <td class="right">
                                    {{threeYearsAgo}}
                                </td>
                            </tr>
                            <tr class="nowrap" *wackadooResourceBlock="let r of []; let idx = index; eventHandler: 'Reports'; action: 'list'; parms: { reportName: 'Contracts Listed by Month by Year (incl. Forecast)' }; defaultText: ''; debug: false;">
                                <td>
                                    <wackadoo-field [f]="r.month" mode="read"></wackadoo-field>
                                </td>
                                <td class="right">
                                    <wackadoo-field [f]="r.thisYear" mode="read"></wackadoo-field>
                                </td>
                                <td class="right">
                                    <wackadoo-field [f]="r.lastYear" mode="read"></wackadoo-field>
                                </td>
                                <td class="right">
                                    <wackadoo-field [f]="r.twoYearsAgo" mode="read"></wackadoo-field>
                                </td>
                                <td class="right">
                                    <wackadoo-field [f]="r.threeYearsAgo" mode="read"></wackadoo-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        
        </div>
    </div>
</div>
