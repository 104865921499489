import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageComponent } from 'src/app/client-core/pages/page/page.component';
import { WResource } from 'src/app/client-core/data/resource.model';
import { Subscription, Subject } from 'rxjs';
import { UserAuthService } from 'src/app/client-core/services/user-auth.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { ClosingProService } from '../../closing-pro.service';
import { WEvent } from 'src/app/client-core/data/event.model';
import { WForeignKey, WSelect, WField } from 'src/app/client-core/data/field.model';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';

@Component({
  selector: 'wackadoo-task-templates',
  templateUrl: './task-templates.component.html',
})
export class TaskTemplatesComponent extends PageComponent implements OnInit, OnDestroy {

  taskTemplateGroupID: WForeignKey = null;
  contractType: WSelect = null;

  onTaskTemplateGroupChange: Subject<WField> = null;
  onTaskTemplateGroupChangeSubscription: Subscription = null;

  onContractTypeChange: Subject<WField> = null;
  onContractTypeChangeSubscription: Subscription = null;

  dragNDropMode: WField = null;

  // current selected tasks

  primaryPanelSelectedTaskTemplate: WResource = null;
  secondaryPanelSelectedTaskTemplate: WResource = null;
  tertiaryPanelSelectedTaskTemplate: WResource = null;

  // task selection subjects/subscriptions

  primaryTaskTemplateSelectionSubscription: Subscription = null;
  primaryTaskTemplateSelectionSubject: Subject<WResource> = null;

  secondaryTaskTemplateSelectionSubscription: Subscription = null;
  secondaryTaskTemplateSelectionSubject: Subject<WResource> = null;

  tertiaryTaskTemplateSelectionSubscription: Subscription = null;
  tertiaryTaskTemplateSelectionSubject: Subject<WResource> = null;

  // "up" task template add subjects/subscriptions

  taskTemplateAddedSecondaryToPrimarySubject: Subject<WResource> = null;
  taskTemplateAddedTertiaryToSecondarySubject: Subject<WResource> = null;

  // used to control which panel is currently displayed when on a phone...
  activePhonePanel = 1;

  constructor(
    userAuthService: UserAuthService,
    userInterfaceService: UserInterfaceService,
    public eventServerService: EventServerService,
    public closingProService: ClosingProService,
    public modalDialogService: ModalDialogService,
  ) {
    super(userAuthService, userInterfaceService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.dragNDropMode = new WSelect('dragNDropMode', 'move');
    this.dragNDropMode.select = 'move|relative';
    this.dragNDropMode.selectLabels = 'Change Order|Relate Timing';
    this.dragNDropMode.displayComponent = 'radio';

    // console.log('TaskList.ngOnInit()');

    // first, define the input fields...
    const temp = this.eventServerService.newResource('TaskTemplates');
    this.taskTemplateGroupID = temp.taskTemplateGroupID;
    this.contractType = temp.contractType;

    this.onTaskTemplateGroupChange = new Subject<WField>();
    this.onTaskTemplateGroupChangeSubscription = this.onTaskTemplateGroupChange.subscribe(
      (f: WField) => {
        // console.log('onTaskTemplateGroupChange', f);
        // note: at this point, this.taskTemplateGroupID.value has already been set because it IS f.value
        this.userInterfaceService.setPageState('TaskTemplates', 'taskTemplateGroupID', f.value);
        this.reloadPageState();
      }
    );

    this.onContractTypeChange = new Subject<WField>();
    this.onContractTypeChangeSubscription = this.onContractTypeChange.subscribe(
      (f: WField) => {
        // note: at this point, this.contractType.value has already been set because it IS f.value
        this.userInterfaceService.setPageState('TaskTemplates', this.taskTemplateGroupID.value + '-contractType', f.value);
        this.reloadPageState();
      }
    );

    // controls for the panels...

    this.primaryTaskTemplateSelectionSubject = new Subject<WResource>();
    this.secondaryTaskTemplateSelectionSubject = new Subject<WResource>();
    this.tertiaryTaskTemplateSelectionSubject = new Subject<WResource>();

    this.taskTemplateAddedSecondaryToPrimarySubject = new Subject<WResource>();
    this.taskTemplateAddedTertiaryToSecondarySubject = new Subject<WResource>();

    // set up the subscriptions for selected tasks...

    this.primaryTaskTemplateSelectionSubscription = this.primaryTaskTemplateSelectionSubject.subscribe(
      (taskTemplate: WResource) => {
        // console.log('primaryTaskSelectionSubscription()', (this.primaryPanelSelectedTask ? this.primaryPanelSelectedTask.taskName.value : null), task.taskName.value);
        this.userInterfaceService.setPageState('TaskTemplates', this.taskTemplateGroupID.value + '-' + this.contractType.value + '-primaryTaskTemplateID', (taskTemplate ? taskTemplate.taskTemplateID.value : null));
        this.primaryPanelSelectedTaskTemplate = taskTemplate;
        this.secondaryTaskTemplateSelectionSubject.next(null);
        if (taskTemplate && (this.screenType === 'phone')) {
          this.activePhonePanel = 2;
        }
      }
    );

    this.secondaryTaskTemplateSelectionSubscription = this.secondaryTaskTemplateSelectionSubject.subscribe(
      (taskTemplate: WResource) => {
        // console.log('secondaryTaskSelectionSubscription()', task.taskName.value);
        this.userInterfaceService.setPageState('TaskTemplates', this.taskTemplateGroupID.value + '-' + this.contractType.value + '-secondaryTaskTemplateID', (taskTemplate ? taskTemplate.taskTemplateID.value : null));
        this.secondaryPanelSelectedTaskTemplate = taskTemplate;
        this.tertiaryTaskTemplateSelectionSubject.next(null);
        if (taskTemplate && (this.screenType === 'phone')) {
          this.activePhonePanel = 3;
        }
      }
    );

    this.tertiaryTaskTemplateSelectionSubscription = this.tertiaryTaskTemplateSelectionSubject.subscribe(
      (taskTemplate: WResource) => {
        // console.log('tertiaryTaskSelectionSubscription()', task.taskName.value);
        this.userInterfaceService.setPageState('TaskTemplates', this.taskTemplateGroupID.value + '-' + this.contractType.value + '-tertiaryTaskTemplateID', (taskTemplate ? taskTemplate.taskTemplateID.value : null));
        this.tertiaryPanelSelectedTaskTemplate = taskTemplate;
        if (taskTemplate && (this.screenType === 'phone')) {
          this.activePhonePanel = 3;
        }
      }
    );

    // Now, reload page state - cascade them so they fire in the proper order...

    const oldTaskTemplateGroupID = this.userInterfaceService.getPageState('TaskTemplates', 'taskTemplateGroupID');

    const parms: any = {};
    if (oldTaskTemplateGroupID) {
      parms.taskTemplateGroupID = oldTaskTemplateGroupID;
    } else {
      parms.pageSize = -1;
    }

    this.eventServerService.fireEvent('TaskTemplateGroups', 'list', parms).subscribe(
      (event: WEvent) => {
        try {
          if (event) {
            if (event.status === 'OK') {
              // console.log('TaskTemplateGroups.list', (event.resources ? event.resources.length : 'null'));
              if (event.resources && (event.resources.length > 0)) {
                // remember, we are populating the fkey field from the TaskTemplate, but the VALUE from the TaskTemplateGroup
                this.taskTemplateGroupID.value = event.resources[0].taskTemplateGroupID.value;
                this.taskTemplateGroupID.displayValue = event.resources[0].taskTemplateGroupID.displayValue;
                this.reloadPageState();
              } else {
                this.initialLoad();
              }
            } else {
              throw new Error(event.message);
            }
          }
        } catch (ex) {
          console.log(event);
          this.userInterfaceService.alertUserToException(ex, 'Unable to find ANY TaskTemplateGroups on TaskTemplates page!', true);
        }
      }
    );

  }

  reloadPageState(): void {

    if (this.taskTemplateGroupID.value) {
      // this.userInterfaceService.dumpPageState();

      // clear ALL the current selections prior to the reload... (w/o triggering new page state...)
      this.primaryPanelSelectedTaskTemplate = null;
      this.secondaryPanelSelectedTaskTemplate = null;
      this.tertiaryPanelSelectedTaskTemplate = null;

      // get the contract type...
      const oldContractType = this.userInterfaceService.getPageState('TaskTemplates', this.taskTemplateGroupID.value + '-contractType');
      if (oldContractType) {
        this.contractType.value = String(oldContractType);
      } else {
        this.contractType.value = 'Buyer';
        this.userInterfaceService.setPageState('TaskTemplates', this.taskTemplateGroupID.value + '-contractType', this.contractType.value);
      }

      // get the proper selected taskTemplates...
      const oldPrimaryTaskTemplateID   = this.userInterfaceService.getPageState('TaskTemplates', this.taskTemplateGroupID.value + '-' + this.contractType.value + '-primaryTaskTemplateID');
      const oldSecondaryTaskTemplateID = this.userInterfaceService.getPageState('TaskTemplates', this.taskTemplateGroupID.value + '-' + this.contractType.value + '-secondaryTaskTemplateID');
      const oldTertiaryTaskTemplateID  = this.userInterfaceService.getPageState('TaskTemplates', this.taskTemplateGroupID.value + '-' + this.contractType.value + '-tertiaryTaskTemplateID');

      // console.log('reloadPageState() - pID: ' + oldPrimaryTaskTemplateID, 'sID: ' + oldSecondaryTaskTemplateID, 'tID: ' + oldTertiaryTaskTemplateID);

      if (oldPrimaryTaskTemplateID) {
        this.eventServerService.loadResourceFromServer('TaskTemplates', { taskTemplateID: oldPrimaryTaskTemplateID}).subscribe(
          (r1: WResource) => {
            this.primaryTaskTemplateSelectionSubject.next(r1);

            if (r1 && oldSecondaryTaskTemplateID) {
              this.eventServerService.loadResourceFromServer('TaskTemplates', { taskTemplateID: oldSecondaryTaskTemplateID}).subscribe(
                (r2: WResource) => {
                  this.secondaryTaskTemplateSelectionSubject.next(r2);

                  if (r2 && oldTertiaryTaskTemplateID) {
                    this.eventServerService.loadResourceFromServer('TaskTemplates', { taskTemplateID: oldTertiaryTaskTemplateID}).subscribe(
                      (r3: WResource) => {
                        this.tertiaryTaskTemplateSelectionSubject.next(r3);
                      }
                    );
                  }
                }
              );
            }
          }
        );
      }
    }
  }

  ngOnDestroy(): void {

    // console.log('TaskList.ngOnDestroy()');

    if (this.onTaskTemplateGroupChangeSubscription) {
      this.onTaskTemplateGroupChangeSubscription.unsubscribe();
    }

    if (this.onContractTypeChangeSubscription) {
      this.onContractTypeChangeSubscription.unsubscribe();
    }

    if (this.primaryTaskTemplateSelectionSubscription) {
        this.primaryTaskTemplateSelectionSubscription.unsubscribe();
    }

    if (this.secondaryTaskTemplateSelectionSubscription) {
      this.secondaryTaskTemplateSelectionSubscription.unsubscribe();
    }

    if (this.tertiaryTaskTemplateSelectionSubscription) {
      this.tertiaryTaskTemplateSelectionSubscription.unsubscribe();
    }

    super.ngOnDestroy();
  }

  showPanel(panelNumber: number): void {
    this.activePhonePanel = panelNumber;
  }

  addTaskTemplateGroup(): void {
    try {
      // console.log('TaskTemplates.addTaskTemplateGroup() - Got here!');

      const title = 'Task Template Group';
      const placeholder = 'task template group name';
      const message = 'Please enter the name of the new template group:';

      this.modalDialogService.showPrompt(message,  title, placeholder).subscribe(
        (inputValue: string | boolean) => {
          try {
            if (inputValue && (inputValue !== '')) {
              const parms: any = {};
              parms.name = inputValue;

              // console.log('TaskTemplates.addTaskTemplateGroup.internalPromptCallback() - Got there! parms: ' + JSON.stringify(parms));

              this.eventServerService.fireEvent('TaskTemplateGroups', 'add', parms).subscribe(
                (event: WEvent) => {
                  if (event.status !== 'OK') {
                    throw new Error(event.message);
                  }
                  const newTaskTemplateGroupID = event.parameters.taskTemplateGroupID;
                  this.userInterfaceService.setPageState('TaskTemplates', 'taskTemplateGroupID', newTaskTemplateGroupID);
                  this.userInterfaceService.reloadCurrentPage();
                }
              );
            }
          } catch (ex) {
            const msg2 = 'TaskTemplates.addTaskTemplateGroup.internalPromptCallback()\n';
            this.userInterfaceService.alertUserToException(ex, msg2);
          }
        }
      );

    } catch (ex) {
      const msg = 'TaskTemplates.addTaskTemplateGroup()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  modifyTaskTemplateGroup(): void {
    try {
      // console.log('TaskTemplates.modifyTaskTemplateGroup() - Got here!');

      const taskTemplateGroupID = this.taskTemplateGroupID.value;

      const title = 'Task Template Group';
      const placeholder = 'task template group name';
      const defaultValue = this.taskTemplateGroupID.displayValue;
      const message = 'Please enter a NEW name for the "' + defaultValue + '" template group:';

      this.modalDialogService.showPrompt(message, title, placeholder, defaultValue).subscribe(
        (inputValue) => {
          try {
            if (inputValue && (inputValue !== '') && (inputValue !== defaultValue)) {
              const parms: any = {};
              parms.taskTemplateGroupID = taskTemplateGroupID;
              parms.name = inputValue;

              // console.log('TaskTemplates.modifyTaskTemplateGroup.internalPromptCallback() - Got there! parms: ' + JSON.stringify(parms));

              this.eventServerService.fireEvent('TaskTemplateGroups', 'modify', parms).subscribe(
                (event: WEvent) => {
                  if (event.status !== 'OK') {
                    throw new Error(event.message);
                  }
                  const newTaskTemplateGroupID = event.parameters.taskTemplateGroupID;
                  this.userInterfaceService.setPageState('TaskTemplates', 'taskTemplateGroupID', newTaskTemplateGroupID);
                  this.userInterfaceService.reloadCurrentPage();
                }
              );
            }
          } catch (ex) {
            const msg2 = 'TaskTemplates.modifyTaskTemplateGroup.internalPromptCallback()\n';
            this.userInterfaceService.alertUserToException(ex, msg2);
          }
        }
      );

    } catch (ex) {
      const msg = 'TaskTemplates.modifyTaskTemplateGroup()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  copyTaskTemplateGroup(): void {
    try {

      const oldTaskTemplateGroupID = this.taskTemplateGroupID.value;
      const groupName = this.taskTemplateGroupID.displayValue;

      const title = 'Copy Task Template Group: ' + groupName;
      const placeholder = 'task template group name';
      // const defaultValue = this.getGroupName();
      const message = 'Please enter the name of the NEW template group:';

      this.modalDialogService.showPrompt(message, title, placeholder).subscribe(
        (inputValue: string | boolean) => {
          try {
            if (!inputValue) {
              // user clicked cancel...
            } else if (inputValue === '') {
              this.modalDialogService.showAlert('Not copying to empty template group name', 'WARNING!');
            // } else if (inputValue === defaultValue) {
            //   this.modalDialogService.showAlert('Not copying to un-changed template group name: ' + inputValue, 'WARNING!');
            } else {

              const confirmMsg = 'This will create a new template group called "'
                      + inputValue
                      + '",\nwhich will be a copy of the "'
                      +  groupName
                      + '" template group.\n\nAre you sure?';

              this.modalDialogService.showConfirm(confirmMsg, 'Confirm - ' + title).subscribe(
                (flag: boolean) => {
                  if (flag) {
                    this.modalDialogService.showPleaseWait('...while we copy the templates.');

                    // add the new list name...

                    const parms: any = {};
                    parms.name = inputValue;

                    this.eventServerService.fireEvent('TaskTemplateGroups', 'add', parms).subscribe(
                      (event: WEvent) => {
                        try {
                          if (event.status !== 'OK') {
                            throw new Error('Unable to add ' + inputValue + ' to TaskTemplateGroups: ' + event.message);
                          }

                          const newTaskTemplateGroupID = event.parameters.taskTemplateGroupID;
                          if (newTaskTemplateGroupID === null) {
                            const msg = 'Failed to get ID for new list: ' + inputValue;
                            // wackadoo.lib.alert(msg + '\n' + JSON.stringify(event));
                            throw new Error(msg);
                          }

                          // dupe all the items...

                          const parms2: any = {};
                          parms2.taskTemplateGroupID = oldTaskTemplateGroupID;
                          parms2.fieldName = 'taskTemplateGroupID';
                          parms2.newValue = newTaskTemplateGroupID;

                          // console.log('TaskTemplates.copyTaskTemplateGroup.internalPromptCallback.okCallback() - Got there! parms2:', parms2);

                          this.eventServerService.fireEvent('TaskTemplates', 'copyHierarchyWithNewFieldValue', parms2).subscribe(
                            (event2: WEvent) => {
                              try {
                                // console.log('TaskTemplates', 'copyHierarchyWithNewFieldValue', parms2, event2);
                                if (event.status !== 'OK') {
                                  throw new Error('Unable to copy template hierarchy: ' + event2.message);
                                }

                                this.modalDialogService.showPleaseWait(false);
                                this.userInterfaceService.setPageState('TaskTemplates', 'taskTemplateGroupID', newTaskTemplateGroupID);
                                this.userInterfaceService.reloadCurrentPage();

                              } catch (ex) {
                                this.modalDialogService.showPleaseWait(false);
                                const msg = 'TaskTemplates.copyTaskTemplateGroup.copyHierarchyWithNewFieldValue.callback()\n';
                                this.userInterfaceService.alertUserToException(ex, msg);
                              }
                            }
                          );

                        } catch (ex) {
                          this.modalDialogService.showPleaseWait(false);
                          const msg = 'TaskTemplates.copyTaskTemplateGroup.addTemplateGroup.callback()\n';
                          this.userInterfaceService.alertUserToException(ex, msg);
                        }
                      }
                    );
                  }
                }
              );
            }
          } catch (ex) {
            const msg = 'TaskTemplates.copyTaskTemplateGroup.internalPromptCallback()\n';
            this.userInterfaceService.alertUserToException(ex, msg, true);
          }
        }
      );

    } catch (ex) {
      const msg = 'TaskTemplates.copyTaskTemplateGroup()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  deleteTaskTemplateGroup(): void{
    try {
      const taskTemplateGroupID = this.taskTemplateGroupID.value;
      const groupName = this.taskTemplateGroupID.displayValue;

      if (taskTemplateGroupID === 1) {
        const msg = 'You are not allowed to delete group number ' + taskTemplateGroupID + ', the "' + groupName + '" group!' ;
        this.modalDialogService.showAlert(msg, 'WARNING!');

      }  else {

        const confirmMsg = 'This is going to delete the "'
                + groupName
                + '" group, AND ALL of the Task templates assigned to it!'
                + '\n\nRemember, this will not affect Tasks for existing contracts.'
                + '\n\nAre you REALLY sure you want to do this?';

        this.modalDialogService.showConfirm(confirmMsg, 'WARNING!').subscribe(
          (flag: boolean) => {
            if (flag) {
              const parms = this.taskTemplateGroupID.asParm;

              this.modalDialogService.showPleaseWait('Deleting task group "' + groupName + '" and all Task templates associated with it...');
              this.eventServerService.fireEvent('TaskTemplateGroups', 'delete', parms).subscribe(
                (event: WEvent) => {
                  if (event.status !== 'OK') {
                    throw new Error(event.message);
                  }
                  this.modalDialogService.showPleaseWait(false);
                  this.userInterfaceService.setPageState('TaskTemplates', 'taskTemplateGroupID', null);
                  this.userInterfaceService.reloadCurrentPage();
                }
              );
            }
          }
        );

      }
    } catch (ex) {
      const msg = 'TaskTemplates.deleteTaskTemplateGroup()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  printTaskTemplateGroup(): void{
    const parms = {
      reportName: 'Print TaskList',
      fileExtension: 'xlsx',
      taskTemplateGroupID: this.taskTemplateGroupID.value,
      contractType: this.contractType.value
    };
    this.eventServerService.downloadFile('Reports', 'export', parms);
  }

  exportTaskTemplateGroup(): void{
    const parms = {
      reportName: 'Generate New System Default TaskTemplates',
      fileExtension: 'txt',
      taskTemplateGroupID: this.taskTemplateGroupID.value,
    };
    this.eventServerService.downloadFile('Reports', 'export', parms);
  }

  private _loadDefaultTemplates(): void {

    // by passing no parms, we are re-loading ALL the defaults from the file...

    this.eventServerService.fireEvent('TaskTemplateGroups', 'reloadDefaultTemplateDefinitions', {}).subscribe(
      (event: WEvent) => {
        if (event.status !== 'OK') {
          throw new Error(event.message);
        }

        this.userInterfaceService.setPageState('TaskTemplates', 'taskTemplateGroupID', null);

        // by passing no parms, we are re-loading ALL the defaults from the file...

        this.eventServerService.fireEvent('TaskTemplates', 'reloadDefaultTemplateDefinitions', {}).subscribe(
          (event2: WEvent) => {
            this.modalDialogService.showPleaseWait(false);
            if (event2.status !== 'OK') {
              throw new Error(event2.message);
            }
            this.userInterfaceService.reloadCurrentPage();
          }
        );
      }
    );
  }

  initialLoad(): void{
    try {
      const msgTitle = 'WELCOME!';

      let msg = 'This looks like the very first time you have ever come to this page. That means that we have to start you from scratch and set you up with our initial "example/default" TaskTemplate and TaskTemplateGroup definitions.';
      msg += '\n\n(NOTE: If there are ANY existing TaskTemplate and TaskTemplateGroup definitions, then this is not your first time on this page, and something went wrong - so do NOT click OK, because that will delete ALL your hard work. If this is your situation, click Cancel, and contact support...)';
      msg += '\n\nIf this is, in fact, your first time on this page, click OK. Otherwise, click Cancel.';

      this.modalDialogService.showConfirm(msg, msgTitle).subscribe(
        (flag: boolean) => {
          if (flag) {
            this.modalDialogService.showPleaseWait('Loading default TaskTemplate and TaskTemplateGroup definitions from the server. This will take a moment or two, so please be patient.');
            this._loadDefaultTemplates();
          }
        }
      );

    } catch (ex) {
      const msg = 'TaskTemplates.postLoad.reloadTaskTemplates()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  reload(): void{
    try {
      const msgTitle = 'WARNING! It is REALLY not a good idea to do this!';

      let msg = 'This completely erases ALL of your existing TaskTemplate and TaskTemplateGroup definitions - including your customized ones - and then re-loads JUST the "example/default" from the server.';
      msg += '\n\nIf you want to keep a copy of your customized TaskTemplates, you should run a "Templates" Backup first.';
      msg += '\n\nNote that this does NOT affect the Tasks that were previously created for your existing contracts.\n\nAre you SURE?';

      this.modalDialogService.showConfirm(msg, msgTitle).subscribe(
        (flag: boolean) => {
          if (flag) {
            this.modalDialogService.showPleaseWait('Re-loading default TaskTemplate and TaskTemplateGroup definitions from the server. This will take a moment or two, so please be patient.');
            this._loadDefaultTemplates();
          }
        }
      );

    } catch (ex) {
      const msg = 'TaskTemplates.postLoad.reloadTaskTemplates()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

}
