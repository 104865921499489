<div *ngIf="(resource != null) && (resource.taskName.value != null) else taskNotFound">
  <div class="smaller padding3 link {{resource.taskPriority.value}}" 
    [ngClass]="{'white red-bg' : (resource.taskPriority.value === 'high'), 'yellow-bg' : (resource.taskPriority.value === 'medium'), 'border-top border-bottom' : displayDetail }"
    [title]="resource.taskName.value"
  >
    <div class="small link" (click)="toggleDisplayDetail($event)">
      <wackadoo-field [f]="resource.taskName" mode="read"></wackadoo-field>
    </div>
    <div *ngIf="!resource.taskComplete.value else taskComplete">
      <div *ngIf="displayDetail">
        <div class="d-flex justify-content-around">
          <wackadoo-field [f]="resource.taskComplete" mode="write" [onChange]="autoSaveResourceOnFieldChange"></wackadoo-field>
          <div class="marginTop2 marginLeftQuarterEm marginRightQuarterEm large {{ iconClassNames }} rollover" *ngIf="(resource.taskType.value === 'email')" title="Click to send email" (click)="openEMailer($event)"></div>
          <div class="marginTop2 marginLeftQuarterEm marginRightQuarterEm large {{ iconClassNames }}" *ngIf="(resource.taskType.value === 'phone')" title="Click to make phone call" (click)="openDialer($event)"></div>
          <wackadoo-field [f]="resource.taskPriority" mode="write" [onChange]="autoSaveResourceOnFieldChange"></wackadoo-field>
        </div>
<!--
        <div class="d-flex flexrow justify-content-center">
          <wackadoo-field-string class="min-width-5-em" [f]="resource.taskNote" mode="write" [rows]="rows" [forceWrap]="true" [onChange]="autoSaveResourceOnFieldChange"></wackadoo-field-string>
          <div class="marginLeftHalfEm marginTopQuarterEm large {{ rowIcon }}" title="Click to toggle note height" (click)="toggleNoteHeight($event)"></div>
        </div>
-->
      </div>
    </div>
    <ng-template #taskComplete>
      <div>&#10003;</div>
    </ng-template>
  </div>
</div>
<ng-template #taskNotFound>
  <div class="smaller border-bottom paddingLeft2 paddingRight2 red bold">
    Unable to find task.
  </div>
</ng-template>
