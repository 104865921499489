<div class="card">
    <div class="card-header">
        Add A Reminder / Follow-Up Task
    </div>
    <div class="card-body">
        <div class="row marginTopHalfEm">
            <div class="col-sm-12">
                Schedule the new notification task
                <wackadoo-field [f]="resource.subsequentTaskLeadTime" [mode]="mode"></wackadoo-field>
                &nbsp;
                <wackadoo-field [f]="resource.subsequentTaskLeadTimeUnit" [mode]="mode"></wackadoo-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <span *ngIf="resource.subsequentTaskLeadTime.value< -0.5">
                    BEFORE the <span class="bold">start</span> of 
                </span>
                <span *ngIf="resource.subsequentTaskLeadTime.value > -0.5">
                    AFTER the <span class="bold">completion</span> of 
                </span>
                &nbsp;
                <span *wackadooResourceBlock="let r of []; let idx = index; eventHandler:'TaskTemplates'; action:'list'; parms:{taskTemplateID: resource.subsequentTaskTemplateID.value}; debug:false;">
                    <wackadoo-task-name-with-icon [resource]="r"></wackadoo-task-name-with-icon> 
                </span>
            </div>
        </div>
        <div class="row marginTopWholeEm">
            <div class="col-sm-12 small italic">
                Note:
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 small italic">
                Time periods &lt; 0 mean BEFORE the task's <span class="bold">start date</span>.
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 small italic">
                Time periods &gt; 0 mean AFTER the task is <span class="bold">completed</span>.
            </div>
        </div>
    </div>
</div>
