import { Component } from '@angular/core';
import { DetailButtonBarComponent } from 'src/app/client-core/pages/repository-page/detail-button-bar/detail-button-bar.component';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { WEvent } from 'src/app/client-core/data/event.model';
import { ClosingProService } from '../../../closing-pro.service';

@Component({
  selector: 'wackadoo-agents-detail-button-bar',
  templateUrl: './agents-detail-button-bar.component.html',
})
export class AgentsDetailButtonBarComponent extends DetailButtonBarComponent {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'AgentsDetailButtonBarComponent';

  constructor(
    eventServerService: EventServerService,
    userInterfaceService: UserInterfaceService,
    modalDialogService: ModalDialogService,
    public closingProService: ClosingProService,
  ) {
    super(eventServerService, userInterfaceService, modalDialogService);
  }

  emailInBoxLink(): void {
    try {
      const parms: any = this.resource.asParms;
      parms.templateName = 'Agent Inbox Link';
      parms.to = parms.agentEmail;
      parms.subject = 'Custom link for submitting contracts';

      // EMailer is a modal dialog...
      this.modalDialogService.showEMailer('EMailer', parms, 'Agent InBox Link');

    } catch (ex) {
      const msg = 'Agents.postLoad.emailInboxLink()';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  createCoOpAgent(): void {
    try {
      const resource = this.userInterfaceService.getSelectedResource('Agents');
      const res = resource.asParms;

      const parms: any = {};
      parms.coOpAgentFirstName = res.agentFirstName;
      parms.coOpAgentLastName = res.agentLastName;
      parms.coOpAgentCompany = res.agentCompany;
      parms.coOpAgentAddress = res.agentAddress;
      parms.coOpAgentCity = res.agentCity;
      parms.coOpAgentState = res.agentState;
      parms.coOpAgentPostalCode = res.agentPostalCode;
      parms.coOpAgentCountry = res.agentCountry;
      parms.coOpAgentPhone = res.agentPhone;
      parms.coOpAgentEmail = res.agentEmail;
      parms.coOpAgentNote = 'Copied from Agents list';

      this.eventServerService.fireEvent('CoOpAgents', 'add', parms).subscribe(
        (event: WEvent) => {
          try {
            if (event.status === 'OK') {
              this.modalDialogService.showAlert('Added new CoOpAgent');
            } else {
              throw new Error(event.message);
            }
          } catch (ex) {
            const msg = 'Agents.createCoOpAgent.callback()\n';
            this.userInterfaceService.alertUserToException(ex, msg);
          }
        }
      );

    } catch (ex) {
      const msg = 'Agents.createCoOpAgent()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  createEscrowAgent(): void {
    try {
      const resource = this.userInterfaceService.getSelectedResource('Agents');
      const res = resource.asParms;

      const parms: any = {};
      parms.escrowAgentFirstName = res.agentFirstName;
      parms.escrowAgentLastName = res.agentLastName;
      parms.escrowAgentCompany = res.agentCompany;
      parms.escrowAgentAddress = res.agentAddress;
      parms.escrowAgentCity = res.agentCity;
      parms.escrowAgentState = res.agentState;
      parms.escrowAgentPostalCode = res.agentPostalCode;
      parms.escrowAgentCountry = res.agentCountry;
      parms.escrowAgentPhone = res.agentPhone;
      parms.escrowAgentEmail = res.agentEmail;
      parms.escrowAgentNote = 'Copied from Agents list';

      this.eventServerService.fireEvent('EscrowAgents', 'add', parms).subscribe(
        (event: WEvent) => {
          try {
            if (event.status === 'OK') {
              this.modalDialogService.showAlert('Added new EscrowAgent');
            } else {
              throw new Error(event.message);
            }
          } catch (ex) {
            const msg = 'Agents.createEscrowAgent.callback()\n';
            this.userInterfaceService.alertUserToException(ex, msg);
          }
        }
      );

    } catch (ex) {
      const msg = 'Agents.createEscrowAgent()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  downloadICS(): void {
    this.closingProService.downloadICS(this.eventHandler);
  }

  downloadCompanyICS(): void {
    this.closingProService.downloadICS(this.eventHandler, 'agentCompany');
  }

  downloadVCF(): void {
    this.closingProService.downloadVCF(this.eventHandler);
  }

}
