<div class="card">
    <div class="card-header">
        <div class="" [ngClass]="{'d-flex justify-content-between': (screenType !== 'phone')}">
            <div class="d-flex" [ngClass]="{'marginRightWholeEm': (screenType !== 'phone')}">
                <div>
                    <span class="fas fa-wrench marginRightHalfEm"></span>
                </div>
                <div>
                    <wackadoo-field [f]="resource.taskName" [mode]="mode"></wackadoo-field>
                </div>
            </div>
            <div class="" [ngClass]="{'d-flex marginLeftWholeEm': (screenType !== 'phone')}">
                <div class="unbold">
                    Vendor Type:
                </div>
                <div [ngClass]="{'marginLeftHalfEm': (screenType !== 'phone')}">
                    <wackadoo-field [f]="resource.vendorType" [mode]="mode" [onChange]="onChangeVendorType"></wackadoo-field>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="marginTopHalfEm marginBottomWholeEm"  [ngClass]="{'d-flex justify-content-between': (screenType !== 'phone')}">
            <div [ngClass]="{'d-flex': (screenType !== 'phone')}">
                <div>
                    vendor:
                </div>
                <div [ngClass]="{'marginLeftHalfEm': (screenType !== 'phone')}">
                    <wackadoo-field-foreign-key [f]="resource.vendorID" [mode]="mode"  [onChange]="onChangeVendorID" [foreignKeyFilterParms]="{ vendorType: resource.vendorType.value }"></wackadoo-field-foreign-key>
                    <div *ngIf="resource.reasonForChange">
                        <wackadoo-field [f]="resource.reasonForChange" [mode]="mode"></wackadoo-field> 
                    </div>
                </div>
            </div>
            <div [ngClass]="{'marginLeftWholeEm': (screenType !== 'phone')}">
                <button title="Add new vendor..." (click)="addNewVendor()" class="btn btn-secondary paddingQuarterEm paddingRightHalfEm smallish"><span class="fas fa-plus" aria-hidden="true"></span> New</button>
                <button title="Edit current vendor details..." (click)="editCurrentVendor()" class="btn btn-secondary paddingQuarterEm paddingRightHalfEm smallish"><span class="fas fa-pencil-alt" aria-hidden="true"></span> Edit</button>
            </div>
        </div>
        <div class="marginBottomWholeEm" [ngClass]="{'d-flex': (screenType !== 'phone')}">
            <div class="">
                date/time:
            </div>
            <div [ngClass]="{'marginLeftHalfEm': (screenType !== 'phone')}">
                <wackadoo-field [f]="resource.taskStartDate" [mode]="mode"></wackadoo-field> 
            </div>
        </div>
        <div class="marginBottomWholeEm" [ngClass]="{'d-flex': (screenType !== 'phone')}">
            <div class="">
                amount:
            </div>
            <div [ngClass]="{'marginLeftHalfEm': (screenType !== 'phone')}">
                <wackadoo-field [f]="resource.amount" [mode]="mode"></wackadoo-field> 
            </div>
        </div>
        <div class="marginBottomWholeEm" [ngClass]="{'d-flex': (screenType !== 'phone')}">
            <div class="">
                note:
            </div>
            <div class="fillAvailableWidth" [ngClass]="{'marginLeftHalfEm': (screenType !== 'phone')}">
                <wackadoo-field [f]="resource.taskNote" [mode]="mode"></wackadoo-field> 
            </div>
        </div>
    </div>
</div>
