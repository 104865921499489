import { Component, OnInit } from '@angular/core';
import { DetailContentComponent } from 'src/app/client-core/pages/repository-page/detail-content/detail-content.component';

@Component({
  selector: 'wackadoo-account-meta-data-detail',
  templateUrl: './account-meta-data-detail.component.html',
})
export class AccountMetaDataDetailComponent extends DetailContentComponent implements OnInit {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'AccountMetaDataDetailComponent';
}
