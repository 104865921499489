import { Directive, HostListener, Input, OnInit, ElementRef, Renderer2, OnDestroy, RendererStyleFlags2 } from '@angular/core';
import { ModalDialogService } from '../services/modal-dialog.service';
import { DragAndDropService } from '../services/drag-and-drop.service';
import { Subscription } from 'rxjs';
import { WResource } from '../data/resource.model';

@Directive({
  selector: '[wackadooResourceDragOver]'
})
export class ResourceDragOverDirective implements OnInit, OnDestroy {

  @HostListener('mouseover', ['$event']) mouseover = this.handleResourceDragOver;
  @HostListener('drop', ['$event']) drop = this.handleWrongPlaceForResourceDrop;

  @Input() resourceType = 'resource';

  hasPayload = false;

  payloadSubscription: Subscription = null;

  constructor(
    public elementRef: ElementRef,
    public renderer: Renderer2,
    public dragAndDropService: DragAndDropService,
    public modalDialogService: ModalDialogService,
  ) {}

  ngOnInit(): void {
    this.payloadSubscription = this.dragAndDropService.payload.subscribe(
      (payload: WResource) => {
        this.hasPayload = payload !== null;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.payloadSubscription) {
      this.payloadSubscription.unsubscribe();
    }
  }

  handleResourceDragOver(e: any): void {
    if (this.hasPayload) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'cursor', 'move', RendererStyleFlags2.Important);
    }
  }

  handleWrongPlaceForResourceDrop(e: any): void {

    if (this.hasPayload) {
      // console.log('handleWrongPlaceForResourceDrop()', this.userInterfaceService.dragNDropResource);
      // clear the payload, then set the cursor for "default"
      this.dragAndDropService.payload.next(null);
      this.modalDialogService.showAlert('This is not a valid place to drop a ' + this.resourceType + '...', 'Please Try Again');
    }

  }

}
