import { Component, OnInit, OnDestroy } from '@angular/core';
import { ResourceComponent } from 'src/app/client-core/data/resource/resource.component';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { WResource } from 'src/app/client-core/data/resource.model';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { WEvent } from 'src/app/client-core/data/event.model';
import { Subscription, Subject } from 'rxjs';
import { WField, WString } from 'src/app/client-core/data/field.model';
import { ClosingProService } from '../../closing-pro.service';

@Component({
  selector: 'wackadoo-task-detail-modal',
  templateUrl: './task-modal.component.html',
})
export class TaskModalComponent extends ResourceComponent implements OnInit, OnDestroy {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'TaskModalComponent';

  onChangeVendorType: Subject<WField> = null;
  onChangeVendorTypeSubscription: Subscription = null;

  onChangeVendorID: Subject<WField> = null;
  onChangeVendorIDSubscription: Subscription = null;

  onTaskTypeChange: Subject<WField> = null;
  onTaskTypeChangeSubscription: Subscription = null;

  constructor(
    public modalDialogService: ModalDialogService,
    userInterfaceService: UserInterfaceService,
    public closingProService: ClosingProService,
    eventServerService: EventServerService,
  ) {
    super(userInterfaceService, eventServerService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    // console.log('TaskModalComponent.ngOnInit()', this.resource);

    this.onChangeVendorType = new Subject<WField>();

    this.onChangeVendorTypeSubscription = this.onChangeVendorType.subscribe(
      (f: WField) => {
        if (f) {
          this.modalDialogService.modifyResourceModal('TaskModalComponent', 'Tasks', this.resource).subscribe(
            (event: WEvent) => {
              // if we cancel or properly saved the page...
              if (event && (event.status !== 'OK')) {
                // console.log('modifyResourceModal.closed()', event);
                this.modalDialogService.showAlert('Unable to modify task...\n' + event.message, 'WARNING!');
              }
            }
          );
        }
      }
    );

    this.onChangeVendorID = new Subject<WField>();

    this.onChangeVendorIDSubscription = this.onChangeVendorID.subscribe(
      (f: WField) => {
        if (f.changed) {
          // now we add the reason for change field...
          const f2 = new WString('reasonForChange', null);
          f2.required = true;
          f2.length = 255;
          this.resource.addExtraField(f2);
        } else {
          delete this.resource.reasonForChange;
        }
      }
    );

    this.onTaskTypeChange = new Subject<WField>();

    this.onTaskTypeChangeSubscription = this.onTaskTypeChange.subscribe(
      (f: WField) => {
        if (f) {
          // console.log('field changed:', f);
          if (f.value === 'email') {
            // We load up taskEMailTemplate with select options from EMailTemplates
            if (f.value === 'email') {
              this.userInterfaceService.convertToSelectFieldFromOtherResourceType(this.resource, 'taskEMailTemplate', 'templateName', 'EMailTemplate');
            }
          }

          this.resource.vendor.value = this.isVendorActivity;
          this.resource.vendor.changed = true;
        }
      }
    );

    // We load up taskEMailTemplate with select options from EMailTemplates
    if (this.resource.taskType.value === 'email') {
      this.userInterfaceService.convertToSelectFieldFromOtherResourceType(this.resource, 'taskEMailTemplate', 'templateName', 'EMailTemplate');
    }
  }

  ngOnDestroy(): void {
    if (this.onChangeVendorTypeSubscription) {
      this.onChangeVendorTypeSubscription.unsubscribe();
    }
    if (this.onChangeVendorIDSubscription) {
      this.onChangeVendorIDSubscription.unsubscribe();
    }
    if (this.onTaskTypeChangeSubscription) {
      this.onTaskTypeChangeSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  relativeTaskMessage(r: WResource): string {
    const n = Math.abs(r.subsequentTaskLeadTime.value);
    let msg = '';
    msg += n;
    msg += ' ';
    msg += (n !== 1 ? r.subsequentTaskLeadTimeUnit.value : (r.subsequentTaskLeadTimeUnit.value.replace('s', '')));
    msg += ' ';
    msg += (r.subsequentTaskLeadTime.value < -0.5) ? 'BEFORE' : 'AFTER';
    return msg;
  }

  openTaskModal(task: WResource): void {
    this.modalDialogService.modifyResourceModal('TaskModalComponent', 'Tasks', task).subscribe(
      (event: WEvent) => {
        // if we cancel or properly saved the page...
        if (event && (event.status !== 'OK')) {
          console.log('modifyResourceModal.closed()', event);
          this.modalDialogService.showAlert('Unable to modify task...\n' + event.message, 'WARNING!');
        }
      }
    );
  }

  //////////////////////////////////////////////////////////////////////////////
  // functionality for vendor activities...
  //////////////////////////////////////////////////////////////////////////////

  get isVendorActivity(): boolean {
    return this.closingProService.isVendorActivity(this.resource.taskType.value);
  }

  addNewVendor(): void {
    try {
      const vendorType = this.resource.vendorType.value;

      const newVendor = this.eventServerService.newResource('Vendors');
      newVendor.vendorType.value = vendorType;
      newVendor.vendorType.readOnly = true;

      // console.log('TaskModalComponent.addNewVendor() - Got here!\n\nnew vendor: ', newVendor.asParms));
      // console.log('TaskModalComponent.addNewVendor() - Got here!\n\nvendorType: ' + vendorType + '\n\nGotta add Vendor-modal dialog template, and make the OK save it');

      // pop up a modal vendors-detail page in addMode

      this.modalDialogService.addResourceModal('VendorModalComponent', 'Vendors', newVendor).subscribe(
        (e: WEvent) => {
          if (e) {
            // console.log('TaskModalComponent.addNewVendor.add.vendor.callback() - Got here!', e);

            // upon OK, save the new vendorID
            this.resource.vendorID.value = e.parameters.vendorID;

            // and re-display this modal dialog...

            const parms: any = this.resource.taskID.asParm;
            parms.vendorID = e.parameters.vendorID;

            this.eventServerService.fireEvent('Tasks', 'modify', parms).subscribe(
              (e2: WEvent) => {
                // console.log('TaskModalComponent.addNewVendor.task.modify.callback() - Got here!', e2);
                if (e2) {
                  this.modalDialogService.modifyResourceModal('TaskModalComponent', 'Tasks', this.resource).subscribe(
                    (event: WEvent) => {
                      // if we cancel or properly saved the page...
                      if (event && (event.status !== 'OK')) {
                        console.log('modifyResourceModal.closed()', event);
                        this.modalDialogService.showAlert('Unable to modify task...\n' + event.message, 'WARNING!');
                      }
                    }
                  );
                }
              }
            );
          } else {
            this.modalDialogService.modifyResourceModal('TaskModalComponent', 'Tasks', this.resource).subscribe(
              (event: WEvent) => {
                // if we cancel or properly saved the page...
                if (event && (event.status !== 'OK')) {
                  console.log('modifyResourceModal.closed()', event);
                  this.modalDialogService.showAlert('Unable to modify task...\n' + event.message, 'WARNING!');
                }
              }
            );
          }
        }
      );

    } catch (ex) {
      const msg = 'TaskModalComponent.addNewVendor()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  editVendor(): void {
    try {
      const vendorIDParms = this.resource.vendorID.asParm;

      this.eventServerService.loadResourceFromServer('Vendors', vendorIDParms).subscribe(
        (vendor: WResource) => {
          // console.log('TaskModalComponent.editVendor.load.vendor.callback() - Got here!', vendor);
          // console.log('TaskModalComponent.editVendor() - Got here!\n\nvendorType: ' + vendorType + '\n\nGotta add Vendor-modal dialog template, and make the OK save it');
          this.modalDialogService.modifyResourceModal('VendorModalComponent', 'Vendors', vendor).subscribe(
            (e: WEvent) => {
              if (e) {
                // console.log('TaskModalComponent.editVendor.edit.vendor.callback() - Got here!', e);
                this.modalDialogService.modifyResourceModal('TaskModalComponent', 'Tasks', this.resource);
              }
            }
          );
        }
      );

    } catch (ex) {
      const msg = 'TaskModalComponent.editVendor()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  editEMailTemplate(): void {
    if ((this.resource.taskEMailTemplate.value !== null) && (this.resource.taskEMailTemplate.value !== '')) {
      const parms: any = {};
      parms.templateName = this.resource.taskEMailTemplate.value;
      this.eventServerService.loadResourceFromServer('EMailTemplates', parms).subscribe(
        (r: WResource) => {
          if (r) {
            // in case we fired this from a modal dialog...
            this.modalDialogService.closeModals();

            this.userInterfaceService.selectResource('EMailTemplates', r);
            this.userInterfaceService.loadPage('EMailTemplates');
          } else {
            this.modalDialogService.showAlert('Bad template name: "' + this.resource.taskEMailTemplate.value + '"', 'Error');
          }
        }
      );
    }
  }

  openEMailer(): void {
    const parms = this.resource.keyField.asParm;
    parms.contractID = this.resource.contractID.value;
    parms.contractType = this.resource.contractType.value;
    parms.templateName = this.resource.taskEMailTemplate.value;

    // Here, we determine what kind of resource can be in the resourceBlock in the template...
    const matches = this.resource.taskName.value.match(/.*inspection.*|.*estimate.*|.*repair.*|.*survey.*|.*vendor.*/i);
    parms.resourceType = (matches === null ? 'Client' : 'Vendor');

    // EMailer is a modal dialog...
    this.modalDialogService.showEMailer('EMailer', parms);
  }

  // Need to insert this logic in the proper location to take out the .footer email templates from the WSelect field,
  // but convertToSelectFieldFromOtherResourceType() does NOT have any kind of call-back Subject at this point.
  _scrubOutFooters(): void {
    const templateNameSelectOptions = this.resource.taskEMailTemplate.select;
    let s = '';
    const templateNames = templateNameSelectOptions.split('|');
    for (const templateName of templateNames) {
        if (!templateName.endsWith('.footer')) {
          s += '|' + templateName;
        }
    }
    this.resource.taskEMailTemplate.select = s;
  }

}
