import { Component, OnInit } from '@angular/core';
import { RepositoryPageComponent } from 'src/app/client-core/pages/repository-page/repository-page.component';

@Component({
  selector: 'wackadoo-attorneys',
  templateUrl: '../../../../../../src/app/client-core/pages/repository-page/repository-page.component.html',
})
export class LendersComponent extends RepositoryPageComponent implements OnInit {

  ngOnInit(): void {
    this.gridFieldsToHide = [
                              'lenderContact2',
                              'lenderContact2Phone',
                              'lenderContact2Email',
                              'lenderContact2Role',
                              'lenderContact3',
                              'lenderContact3Phone',
                              'lenderContact3Email',
                              'lenderContact3Role',
                              'lenderContact4',
                              'lenderContact4Phone',
                              'lenderContact4Email',
                              'lenderContact4Role',
                            ];
    this.detailContentComponentClassName = 'LenderDetailContentComponent';
    this.detailButtonBarComponentClassName = 'LenderDetailButtonBarComponent';

    super.ngOnInit();
  }

}
