import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { WResource } from 'src/app/client-core/data/resource.model';
import { Subject, Subscription } from 'rxjs';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { WEvent } from 'src/app/client-core/data/event.model';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { Globals } from 'src/app/client-core/services/global.service';
import { ClosingProService } from '../../../closing-pro.service';

@Component({
  selector: 'wackadoo-task-templates-panel-item',
  templateUrl: './task-templates-panel-item.component.html',
})
export class TaskTemplatesPanelItemComponent implements OnInit, OnDestroy {

  @Input() taskTemplate: WResource = null;
  @Input() sequence: number = null;
  @Input() isSelected = false;

  @Input() selectTaskTemplateSubject: Subject<WResource> = null;

  @Input() notifyParentSubject: Subject<WResource> = null;

  private _childListenerSubscription: Subscription = null;
  @Input() childListenerSubject: Subject<WResource> = null;

  @Input() dragNDropMode: string = null;

  handleResourceDropSubject: Subject<WResource> = null;
  handleResourceDropSubscription: Subscription = null;

  @Input() refreshPanelSubject: Subject<void> = null;

  constructor(
    public closingProService: ClosingProService,
    public eventServerService: EventServerService,
    public userInterfaceService: UserInterfaceService,
    public modalDialogService: ModalDialogService,
  ) {
  }

  ngOnInit(): void {

    // First, if necessary, automatically re-sequence the task in the panel

    if (this.taskTemplate && this.sequence && (this.taskTemplate.sequence.value !== this.sequence)) {
      // console.log('adjusting sequence:', this.taskTemplate.sequence.value, this.sequence);
      // set the sequence locally
      this.taskTemplate.sequence.value = this.sequence;
      // save the sequence to the server
      const parms = this.taskTemplate.keyField.asParm;
      parms.sequence = this.sequence;

      // to fire-and-forget, we have to subscribe() because HttpClient.post() returns a "cold" Observable<>
      this.eventServerService.fireEvent('TaskTemplates', 'modify', parms).subscribe();
    }

    // Second, if necessary, set up to listen for CHILD tasks to be completed...

    if (this.childListenerSubject) {
      this._childListenerSubscription = this.childListenerSubject.subscribe(
        (task: WResource) => {
          if (this.isSelected) {
            if (this.taskTemplate && task) {
              // notifications may be fired on this subject for other tasks...
              // we only care about "our" child tasks...
              if (this.taskTemplate.taskTemplateID.value === task.parentTaskTemplateID.value) {

                // console.log('TaskTemplatesPanelItem._childListenerSubscription(' + this.taskTemplate.taskName.value + ')');

                this.eventServerService.fireEvent('TaskTemplates', 'listWithChildCounts', this.taskTemplate.keyField.asParm).subscribe(
                  (event: WEvent) => {
                    try {
                      if (event.status === 'OK') {

                        this.taskTemplate = event.resources[0];

                      } else {
                        throw new Error(event.message);
                      }
                    } catch (ex) {
                      this.userInterfaceService.alertUserToException(ex, 'Failed to get Task child counts!');
                    }
                  }
                );
              }
            }
          }
        }
      );
    }

    // Third, set up to listen for another task to be dropped on this one...

    this.handleResourceDropSubject = new Subject<WResource>();

    this.handleResourceDropSubscription = this.handleResourceDropSubject.subscribe(
      (payloadTaskTemplate: WResource) => {

        // console.log('handleResourceDrop', this.dragNDropMode, payloadTaskTemplate);

        if (this.dragNDropMode === 'relative') {

          // console.log('Make the timing of task "' + payloadTask.taskName.value + '" relative to "' + this.taskTemplate.taskName.value + '"');

          this.closingProService.relateTaskTemplates(payloadTaskTemplate, this.taskTemplate).subscribe();

        } else if (this.dragNDropMode === 'move') {

          // Dropping a Task with children on the tertiary panel is not allowed!
          if (!this.childListenerSubject && (payloadTaskTemplate.totalTasks && (payloadTaskTemplate.totalTasks.value > 0))) {

            this.modalDialogService.showAlert('You may NOT drop a TaskTemplate with sub-task-templates here...');

          // Dropping a TaskTemplate on itself... (does nothing...)
          } else if (payloadTaskTemplate.taskTemplateID.value === this.taskTemplate.taskTemplateID.value) {
            // console.log('handleResourceDrop - Ignoring request to drop task template on itself...', payloadTaskTemplate);

          // Dropping a TaskTemplate, but NOT on itself...
          } else {

            // console.log('handleResourceDrop - moving task template!', payloadTaskTemplate);

            const parms = payloadTaskTemplate.keyField.asParm;

            // We need MAGIC_NULL_FKEY_VALUE if dropping on the primary panel, which has no parentTaskTemplateID...
            parms.parentTaskTemplateID = (this.taskTemplate.parentTaskTemplateID.value ? this.taskTemplate.parentTaskTemplateID.value : Globals.MAGIC_NULL_FKEY_VALUE);

            // drop the payload in BEFORE the target...
            if (this.taskTemplate.parentTaskTemplateID.value === payloadTaskTemplate.parentTaskTemplateID.value) {
              parms.sequence = this.taskTemplate.sequence.value - (payloadTaskTemplate.sequence.value > this.taskTemplate.sequence.value ? 1 : 0);
            } else {
              parms.sequence = this.taskTemplate.sequence.value - 1;
            }

            this.eventServerService.fireEvent('TaskTemplates', 'modify', parms).subscribe(
              (event: WEvent) => {
                if (event.status === 'OK') {
                  if (this.notifyParentSubject) {
                    this.notifyParentSubject.next(this.taskTemplate);
                  }
                  this.userInterfaceService.reloadCurrentPage();
                } else {
                  this.modalDialogService.showAlert(event.message, 'Failed to insert task!');
                }
              }
            );
          }
        }
      }
    );

  }

  ngOnDestroy(): void {
    if (this._childListenerSubscription) {
      this._childListenerSubscription.unsubscribe();
    }

    if (this.handleResourceDropSubscription) {
      this.handleResourceDropSubscription.unsubscribe();
    }

  }

  get iconClassNames(): string {
    return this.closingProService.getTaskIconClassNames(this.taskTemplate.taskType.value);
  }

  selectTaskTemplate(): void {
    if (this.selectTaskTemplateSubject) {
      this.selectTaskTemplateSubject.next(this.taskTemplate);
    }
  }

  openTaskTemplateModal(e: any): void {
    e.stopPropagation();
    e.preventDefault();

    this.modalDialogService.modifyResourceModal('TaskTemplateModalComponent', 'TaskTemplates', this.taskTemplate).subscribe(
      (event: WEvent) => {
        // if we cancel or properly saved the page...
        if (event && (event.status !== 'OK')) {
          // console.log('modifyResourceModal.closed()', event);
          this.modalDialogService.showAlert('Unable to modify taskTemplate...\n' + event.message, 'WARNING!');
        }
      }
    );
  }

  duplicateTaskTemplate(e: any): void {
    e.stopPropagation();
    e.preventDefault();

    try {

      const confirmMsg = 'This is going to <strong>DUPLICATE</strong> the "'
                          +  this.taskTemplate.taskName.value
                          + '" Task template, and EVERY "sub-task-template" beneath it!'
                          + '\n\nAre you SURE you want to do this?';

      this.modalDialogService.showConfirm(confirmMsg, 'WARNING!').subscribe(
        (flag: boolean) => {
          if (flag) {
            const msg = 'Duplicating task template: ' + this.taskTemplate.taskName.value + ' (and sub-task-templates...)';
            this.modalDialogService.showPleaseWait(msg, true);
            this.eventServerService.fireEvent('TaskTemplates', 'duplicateHierarchyBranch', this.taskTemplate.keyField.asParm).subscribe(
              (event: WEvent) => {
                this.modalDialogService.showPleaseWait(false);
                if (event.status !== 'OK') {
                  this.modalDialogService.showAlert(event.message, 'Error Duplicating Task template (and/or sub-task-templates)');
                }
                // refresh the current panel...
                this.refreshPanelSubject.next();
                // update the parent child task template count, if applicable...
                if (this.notifyParentSubject) {
                  this.notifyParentSubject.next(this.taskTemplate);
                }
              }
            );
          }
        }
      );

    } catch (ex) {
      const msg = 'TaskTemplatesPanelItemComponent.duplicateTaskTemplate()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  copyToDifferentTaskTemplateGroup(e: any): void {
    e.stopPropagation();
    e.preventDefault();

    try {
      const oldTaskTemplateGroupID = this.taskTemplate.taskTemplateGroupID.value;

      const temp = this.taskTemplate.clone;

      // console.log('TaskTemplatesPanelItemComponent.copyToDifferentTaskTemplateGroup() - Got here!', temp, temp.asParms);

      this.modalDialogService.showSelectValue('TaskTemplatesSelectTemplateGroupModalComponent', temp, 'taskTemplateGroupID').subscribe(
        (newTaskTemplateGroupID: number) => {
          try {
            // console.log('TaskTemplatesPanelItemComponent.copyToDifferentTaskTemplateGroup() - Got there! newTaskTemplateGroupID: ' + newTaskTemplateGroupID + ' (oldTaskTemplateGroupID: ' + oldTaskTemplateGroupID+ ')');

            if (newTaskTemplateGroupID) {

              if (newTaskTemplateGroupID !== oldTaskTemplateGroupID) {

                const parms = this.taskTemplate.keyField.asParm;
                parms.fieldName = 'taskTemplateGroupID';
                parms.newValue = newTaskTemplateGroupID;
                // console.log('TaskTemplatesPanelItemComponent.copyToDifferentTaskTemplateGroup.click()  - Got there!', parms);

                const userMessage = 'Duplicating Task template (and sub-task-templates) in a different Task template group: ' + this.taskTemplate.taskName.value;
                this.modalDialogService.showPleaseWait(userMessage);

                this.eventServerService.fireEvent('TaskTemplates', 'duplicateHierarchyBranch', parms).subscribe(
                  (event: WEvent) => {
                    // console.log('TaskTemplates', 'duplicateHierarchyBranch', parms, event);
                    if (event.status === 'OK') {
                      this.modalDialogService.showPleaseWait(false);

                      this.userInterfaceService.setPageState('TaskTemplates', 'taskTemplateGroupID', newTaskTemplateGroupID);
                      this.userInterfaceService.reloadCurrentPage();
                    } else {
                      this.modalDialogService.showAlert('Failed to duplicate task hierarchy: ' + event.message, 'WARNING!');
                    }
                  }
                );

              } else {
                this.modalDialogService.showAlert('Not copying this task to the same contract.\n(Use the "Duplicate Task" button if that is what you really want to do.)');
              }
            }

          } catch (ex) {
            const msg = 'TaskTemplatesPanelItemComponent.copyToDifferentTaskTemplateGroup.click()\n';
            this.userInterfaceService.alertUserToException(ex, msg);
          }
        }
      );

    } catch (ex) {
      const msg = 'TaskTemplatesPanelItemComponent.copyToDifferentTaskTemplateGroup()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  copyToDifferentContractType(e: any): void {
    e.stopPropagation();
    e.preventDefault();

    try {
      // console.log('TaskTemplates.copyToDifferentContractType() - Got here!', this.taskTemplate);

      const oldContractType = this.taskTemplate.contractType.value;

      const temp = this.taskTemplate.clone;

      this.modalDialogService.showSelectValue('TaskTemplatesSelectContractTypeModalComponent', temp, 'contractType').subscribe(
        (newContractType: string) => {
          if (newContractType) {
              if (newContractType !== oldContractType) {
                // wackadoo.lib.alert('TaskTemplates.copyToDifferentContractType.click() - Got click! - ' + newContractType);

                const parms = this.taskTemplate.keyField.asParm;
                parms.fieldName = 'contractType';
                parms.newValue = newContractType;
                // console.log('TaskTemplates.copyToDifferentContractType.click()  - Got there!',parms);

                const userMessage = 'Duplicating Task template (and sub-task-templates) "' + this.taskTemplate.taskName.value + '" to new contractType: ' + newContractType;
                this.modalDialogService.showPleaseWait(userMessage);

                this.eventServerService.fireEvent('TaskTemplates', 'duplicateHierarchyBranch', parms).subscribe(
                  (event: WEvent) => {
                    // console.log('TaskTemplates', 'duplicateHierarchyBranch', parms, event);
                    if (event.status === 'OK') {
                      this.modalDialogService.showPleaseWait(false);

                      this.userInterfaceService.setPageState('TaskTemplates', this.taskTemplate.taskTemplateGroupID.value + '-contractType', newContractType);
                      this.userInterfaceService.reloadCurrentPage();
                    } else {
                      this.modalDialogService.showAlert('Failed to duplicate task hierarchy: ' + event.message, 'WARNING!');
                    }
                  }
                );

              } else {
                this.modalDialogService.showAlert('Not copying this Task template to the same contractType.\n(Use the "Duplicate Template" button if that is what you really want to do.)');
              }
          }
        }

      );

    } catch (ex) {
      const msg = 'TaskLists.copyToDifferentContractType()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  deleteTaskTemplate(e: any): void {
    e.stopPropagation();
    e.preventDefault();

    try {
      const confirmMsg = 'This is going to <strong>DELETE</strong> the "'
              +  this.taskTemplate.taskName.value
              + '" Task template, and EVERY "sub-task-template" beneath it!'
              + '\n\nAre you SURE you want to do this?';

      this.modalDialogService.showConfirm(confirmMsg, 'WARNING!').subscribe(
        (flag: boolean) => {
          if (flag) {
            this.eventServerService.fireEvent('TaskTemplates', 'delete', this.taskTemplate.taskTemplateID.asParm).subscribe(
              (event: WEvent) => {
                try {
                  if (event.status !== 'OK') {
                    throw new Error(event.message);
                  }
                  // unselect the resource
                  this.selectTaskTemplateSubject.next(null);
                  // refresh the current panel...
                  this.refreshPanelSubject.next();
                  // update the parent, if applicable...
                  if (this.notifyParentSubject) {
                    this.notifyParentSubject.next(this.taskTemplate);
                  }
                } catch (ex) {
                  const msg2 = 'TaskTemplatesPanelItemComponent.deleteTaskTemplate()\n';
                  this.userInterfaceService.alertUserToException(ex, msg2);
                }
              }
            );
          }
        }
      );

    } catch (ex) {
      const msg = 'TaskTemplatesPanelItemComponent.deleteTaskTemplate()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  addReminder(e: any): void {
    e.stopPropagation();
    e.preventDefault();

    // console.log('TaskTemplatesPanelItemComponent.addReminder()');
    this.closingProService.addReminderToTaskTemplate(this.taskTemplate);
  }

}
