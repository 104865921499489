import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserAuthService } from 'src/app/client-core/services/user-auth.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { PageComponent } from 'src/app/client-core/pages/page/page.component';

@Component({
  selector: 'wackadoo-business-dashboard',
  templateUrl: './business-dashboard.component.html',

})
export class BusinessDashboardComponent extends PageComponent implements OnInit, OnDestroy {

  thisYear = new Date().getFullYear();
  lastYear = new Date().getFullYear() - 1;
  twoYearsAgo = new Date().getFullYear() - 2;
  threeYearsAgo = new Date().getFullYear() - 3;

  showPieCharts = false;
  showBarCharts = true;

  constructor(
    userAuthService: UserAuthService,
    userInterfaceService: UserInterfaceService,
  ) {
    super(userAuthService, userInterfaceService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  togglePieCharts(): void {
    this.showPieCharts = !this.showPieCharts;
  }

  toggleBarCharts(): void {
    this.showBarCharts = !this.showBarCharts;
  }

}
