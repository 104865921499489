<div *ngIf="(resource != null) && (resource.taskName.value != null) else taskNotFound">
  <div *ngIf="!resource.taskComplete.value else taskComplete"
    class="smaller border-bottom padding3 {{resource.taskPriority.value}}" 
    [ngClass]="{'white red-bg' : (resource.taskPriority.value === 'high'), 'yellow-bg' : (resource.taskPriority.value === 'medium') }"
    [title]="resource.taskName.value"
  >
    <div class="d-flex justify-content-around padding2">
      <div class="max-width-15-em paddingRightQuarterEm">
        <span class="center rollover" 
              [title]="t.taskName.value" 
              *wackadooResourceBlock="let t of []; let idx = index; let isLast = last; eventHandler: 'Tasks'; action: 'search'; parms: { query: append3QueryTerms('taskType:compliance taskComplete:false', getContractIDQueryTerm(), getParentTaskIDQueryTerm()), sortBy: 'sequence', pageSize:25 }; defaultText: '&#10003;'; debug: false;"
              (click)="openTaskListPageToTask(t)"
          >
            {{ getInitials(t.taskName.value) + (isLast ? '' : ', ') }}
        </span>
      </div>
      <div class="center">
        <wackadoo-field [f]="resource.taskPriority" mode="write" [onChange]="autoSaveResourceOnFieldChange"></wackadoo-field>
      </div>
    </div>
    <div class="d-flex flexrow justify-content-center">
      <wackadoo-field-string class="min-width-5-em" [f]="resource.taskNote" mode="write" [rows]="rows" [forceWrap]="true" [onChange]="autoSaveResourceOnFieldChange"></wackadoo-field-string>
      <div class="marginLeftHalfEm marginTopQuarterEm large {{ rowIcon }}" title="Click to toggle note height" (click)="toggleNoteHeight($event)"></div>
    </div>
  </div>
  <ng-template #taskComplete>
    <div class="smaller border-bottom paddingLeft2 paddingRight2">
      done
    </div>
  </ng-template>
</div>
<ng-template #taskNotFound>
  <div class="smaller border-bottom paddingLeft2 paddingRight2 red bold">
    Error while searching for top level Compliance task.
  </div>
</ng-template>

