import { Component, OnInit, OnDestroy } from '@angular/core';
import { RepositoryPageComponent } from 'src/app/client-core/pages/repository-page/repository-page.component';
import { Subscription } from 'rxjs';
import { SelectedResource } from 'src/app/client-core/ui/main/current-selection/selected-resource.model';

@Component({
  selector: 'wackadoo-active-contracts',
  templateUrl: '../../../../../../src/app/client-core/pages/repository-page/repository-page.component.html',
})
export class ActiveContractsComponent extends RepositoryPageComponent implements OnInit, OnDestroy {

  private _contractSelectionChangeSubscription: Subscription;

  ngOnInit(): void {

    // first, we set the component names and grid fields...
    // second, we directly reference the repository-page.component.html above...

    this.gridButtonBarComponentClassName = 'ContractGridButtonBarComponent';
    this.gridFieldsToHide = ['contractStatus', 'escrowAgentID', 'mlsNumber'];
    this.detailContentComponentClassName = 'ContractDetailContentComponent';
    this.detailButtonBarComponentClassName = 'ContractDetailButtonBarComponent';

    super.ngOnInit();

    this.userInterfaceService.unSelectResource('Contracts');

    this._contractSelectionChangeSubscription = this.userInterfaceService.selectionChange.subscribe(
      (sr: SelectedResource) => {
        // console.log('ActiveContractsComponent.selectionChange()', this.eventHandler, sr.ehName);

        if ((sr.ehName === this.eventHandler) && (sr.resource) && (this.user.role !== 'guest')) {

          // console.log('ActiveContractsComponent.selectionChange() - selected an ActiveContract:', sr.resource.contractName.value, sr.resource.contractStatus.value);

          if (sr.resource.contractStatus.value !== 'active') {
            // console.log('BUT IT IS REALLY IN-ACTIVE!');
            this.userInterfaceService.unSelectResource(this.eventHandler);
            this.userInterfaceService.selectResource('Contracts', sr.resource);
            this.bounceToPage('Contracts');
          // } else {
          //   this.userInterfaceService.unSelectResource('Contracts');
          }

        }

      }
    );

  }

  ngOnDestroy(): void {
    if (this._contractSelectionChangeSubscription) {
      this._contractSelectionChangeSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  bounceToPage(pageName: string): void {
    window.setTimeout(
      () => {
        this.userInterfaceService.loadPage(pageName);
      }
      , 250
    );
  }
}
