import { Component } from '@angular/core';
import { DetailButtonBarComponent } from 'src/app/client-core/pages/repository-page/detail-button-bar/detail-button-bar.component';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { ClosingProService } from '../../closing-pro.service';

@Component({
  selector: 'wackadoo-ics-vcf-detail-button-bar',
  templateUrl: './ics-vcf-detail-button-bar.component.html',
})
export class ICSVCFDetailButtonBarComponent extends DetailButtonBarComponent{

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'ICSVCFDetailButtonBarComponent';

  constructor(
    eventServerService: EventServerService,
    userInterfaceService: UserInterfaceService,
    modalDialogService: ModalDialogService,
    public closingProService: ClosingProService,
  ) {
    super(eventServerService, userInterfaceService, modalDialogService);
  }

  downloadICS(): void {
    this.closingProService.downloadICS(this.eventHandler);
  }

  downloadVCF(): void {
    this.closingProService.downloadVCF(this.eventHandler);
  }
}
