import { Component, OnInit } from '@angular/core';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { ClosingProService } from 'projects/closingpro-net/src/app/closing-pro.service';
import { UtilityLibService } from 'src/app/client-core/services/utility-lib.service';
import { WResource } from 'src/app/client-core/data/resource.model';
import { QuickViewTaskComponent } from '../../quick-view-task.component';

@Component({
  selector: 'wackadoo-closing-compliance-task',
  templateUrl: './closing-compliance-task.component.html',
})
export class ClosingComplianceTaskComponent extends QuickViewTaskComponent implements OnInit {

  constructor(
    userInterfaceService: UserInterfaceService,
    eventServerService: EventServerService,
    modalDialogService: ModalDialogService,
    closingProService: ClosingProService,
    public utilLibService: UtilityLibService,

  ) {
    super(userInterfaceService, eventServerService, modalDialogService, closingProService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    // console.log(this.resource);
  }

  getInitials(phrase: string): string {
    return this.utilLibService.getInitials(phrase);
  }

  getContractIDQueryTerm(): string {
    return 'contractID:' + this.resource.contractID.value;
  }

  getParentTaskIDQueryTerm(): string {
    return 'parentTaskID:' + this.resource.taskID.value;
  }

  append2QueryTerms(t1: string, t2: string): string {
    return t1 + ' ' + t2;
  }

  append3QueryTerms(t1: string, t2: string, t3: string): string {
    return t1 + ' ' + this.append2QueryTerms(t2, t3);
  }

  append4QueryTerms(t1: string, t2: string, t3: string, t4: string): string {
    return t1 + ' ' + this.append3QueryTerms(t2, t3, t4);
  }

  append5QueryTerms(t1: string, t2: string, t3: string, t4: string, t5: string): string {
    return t1 + ' ' + this.append4QueryTerms(t2, t3, t4, t5);
  }

  openTaskListPageToTask(complianceTask: WResource): void {
    const parms: any = {};
    parms.contractID = this.resource.contractID.value;
    this.eventServerService.loadResourceFromServer('ActiveContracts', parms).subscribe(
      (resource: WResource) => {
        this.userInterfaceService.selectResource('ActiveContracts', resource);
        this.closingProService.openTaskListPageToTask(this.resource.contractID.value, complianceTask);
      }
    );
  }

}
