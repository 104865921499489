import { Component, OnInit, OnDestroy, Input, OnChanges } from '@angular/core';
import { WCalendarEntry } from '../calendar-entry.model';
import { CalendarService } from '../calendar.service';
import { Subscription } from 'rxjs';


export enum DateMode { n = 'n', summary = 'summary', detail = 'detail' }

@Component({
  selector: 'wackadoo-day',
  templateUrl: './day.component.html',
})
export class DayComponent implements OnInit, OnDestroy, OnChanges {

  @Input() calendarName: string = null;
  @Input() currentMonth: number = null;
  @Input() date: Date = null;
  @Input() mode = DateMode.n;
  @Input() restrictHeight = true;

  calendarEntries: WCalendarEntry [] = [];
  calendarEntriesSubscription: Subscription = null;

  constructor(
    public calendarService: CalendarService,
  ) {
  }

  ngOnInit(): void {
    // console.log('DayComponent.ngOnInit()', this.currentMonth, this.date.toJSON());

    this.getCalendarEntries();

    this.calendarEntriesSubscription = this.calendarService.getCalendarEntrySubject(this.calendarName).subscribe(
      () => {
        this.getCalendarEntries();
      }
    );
  }

  ngOnDestroy(): void {
    // console.log('DayComponent.ngOnDestroy()', this.currentMonth, this.date.toJSON());
    if (this.calendarEntriesSubscription) {
      this.calendarEntriesSubscription.unsubscribe();
    }
  }

  ngOnChanges(): void {
    this.getCalendarEntries();
  }

  getCalendarEntries(): void {
    if (this.mode !== DateMode.n) {
      this.calendarEntries = this.calendarService.calendarEntriesForDate(this.calendarName, this.date);
      // console.log('DayComponent.ngOnInit()', this.date.toJSON(), this.calendarEntries);
    }
  }

  navigateToDate(): void {
    this.calendarService.setCurrentDate(this.calendarName, this.date);
  }

  get isThisMonth(): boolean {
    return this.currentMonth === this.date.getMonth();
  }

  getDate(): number {
    return this.date.getDate();
  }

  get isCurrentDate(): boolean {
    return this.calendarService.isCurrentDate(this.calendarName, this.date);
  }

  get isToday(): boolean {
    return this.calendarService.sameDay(new Date(), this.date);
  }

  getTimeSlotLabel(d: Date): string {
    const t = this.calendarService.getTopOfTimeSlot(d);
    return this.calendarService.formatLocalTimeStamp(t);
  }

  openCalendarEntry(ce: WCalendarEntry): void {
      const subject = this.calendarService.getOpenEntrySubject(this.calendarName);
      if (subject) {
          subject.next(ce);
      }
  }

}
