<div *ngIf="contract">
    <div *ngIf="(screenType !== 'phone')">
        <div class="card">
            <div class="card-header">
                <div class="bold green">
                    <wackadoo-field [f]="contract.contractName" mode="read"></wackadoo-field>
                </div>
            </div>
            <div class="card-body">
                <table class="table table-responsive small marginBottom0">
                    <tr>
                        <td class="bold right nowrap">
                            Address
                        </td>
                        <td class="nowrap">
                            <div>
                                <wackadoo-field [f]="contract.contractAddress" [mode]="mode"></wackadoo-field>
                            </div>
                            <div>
                                <wackadoo-field [f]="contract.contractCity" [mode]="mode"></wackadoo-field>, 
                                <wackadoo-field [f]="contract.contractState" [mode]="mode" [size]="2"></wackadoo-field>
                                <wackadoo-field [f]="contract.contractPostalCode" [mode]="mode"></wackadoo-field>
                            </div>
                        </td>
                        <td class="bold right nowrap">
                            Contract Type
                        </td>
                        <td>
                            <wackadoo-field [f]="contract.contractType" mode="read"></wackadoo-field>
                        </td>
                        <td class="bold right nowrap">
                            Price
                        </td>
                        <td>
                            <wackadoo-field [f]="contract.purchasePrice" [mode]="mode"></wackadoo-field>
                        </td>
                        <td class="bold right nowrap">
                            Loan Type
                        </td>
                        <td>
                            <wackadoo-field [f]="contract.loanType" [mode]="mode"></wackadoo-field>
                        </td>
                        <td class="bold right nowrap">
                            Property Type
                        </td>
                        <td>
                            <wackadoo-field [f]="contract.propertyType" [mode]="mode"></wackadoo-field>
                        </td>
                        <td class="bold right nowrap" *ngIf="contract.contractType.value !== 'Listing'">
                            Client At Closing
                        </td>
                        <td *ngIf="contract.contractType.value !== 'Listing'">
                            <wackadoo-field [f]="contract.clientAtClosing" [mode]="mode"></wackadoo-field>
                        </td>
                        <td class="bold right nowrap">
                            MLS
                        </td>
                        <td>
                            <wackadoo-field [f]="contract.mlsNumber" mode="read"></wackadoo-field>
                        </td>
                        <td class="bold right nowrap">
                            LockBox
                        </td>
                        <td>
                            <wackadoo-field [f]="contract.lockboxNumber" [mode]="mode"></wackadoo-field>
                        </td>
                    </tr>
                    <tr *ngIf="contract.contractType.value !== 'Listing'">
                        <td class="bold right nowrap">
                            Contract Date
                        </td>
                        <td class="nowrap">
                            <wackadoo-field [f]="contract.contractDate" [mode]="mode"></wackadoo-field>
                        </td>
                        <td class="bold right nowrap">
                            Due Diligence
                        </td>
                        <td class="nowrap">
                            <wackadoo-field [f]="contract.ddDate" [mode]="mode"></wackadoo-field>
                        </td>
                        <td class="bold right nowrap">
                            Closing Date
                        </td>
                        <td class="nowrap">
                            <wackadoo-field [f]="contract.closingDate" [mode]="mode"></wackadoo-field>
                        </td>
                        <td class="bold right nowrap">
                            Special Considerations
                        </td>
                        <td colspan="9">
                            <wackadoo-field [f]="contract.specialConsiderations" [mode]="mode"></wackadoo-field>
                        </td>
                    </tr>
                    <tr *ngIf="contract.contractType.value === 'Listing'">
                        <td class="bold right nowrap">
                            Listing Date
                        </td>
                        <td class="nowrap">
                            <wackadoo-field [f]="contract.listingDate" [mode]="mode"></wackadoo-field>
                        </td>
                        <td class="bold right nowrap">
                            Expiration
                        </td>
                        <td class="nowrap">
                            <wackadoo-field [f]="contract.listingExpirationDate" [mode]="mode"></wackadoo-field>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <wackadoo-task-list-panel 
                    [eventHandler]="firstPanelEventHandler"
                    [action]="firstPanelAction"
                    [title]="'Top-Level Tasks'" 
                    [contract]="contract"
                    [parentTask]="null"
                    [selectTaskSubject]="primaryTaskSelectionSubject"
                    [dragNDropMode]="dragNDropMode"
                    [notifyParentSubject]="null"
                    [parentListenerSubject]="null"
                    [notifyChildrenSubject]="taskCompletionPrimaryToSecondarySubject"
                    [childListenerSubject]="taskCompletionSecondaryToPrimarySubject"
                ></wackadoo-task-list-panel>
            </div>
            <div class="col-sm-4">
                <wackadoo-task-list-panel 
                    eventHandler="Tasks"
                    action="listChildrenWithChildCounts"
                    [title]="'Mid-Level Tasks'" 
                    [contract]="contract"
                    [parentTask]="primaryPanelSelectedTask"
                    [selectTaskSubject]="secondaryTaskSelectionSubject"
                    [dragNDropMode]="dragNDropMode"
                    [notifyParentSubject]="taskCompletionSecondaryToPrimarySubject"
                    [parentListenerSubject]="taskCompletionPrimaryToSecondarySubject"
                    [notifyChildrenSubject]="taskCompletionSecondaryToTertiarySubject"
                    [childListenerSubject]="taskCompletionTertiaryToSecondarySubject"
                ></wackadoo-task-list-panel>
            </div>
            <div class="col-sm-4">
                <wackadoo-task-list-panel 
                    eventHandler="Tasks"
                    action="listChildrenWithChildCounts"
                    [title]="'Low-Level Tasks'" 
                    [contract]="contract"
                    [parentTask]="secondaryPanelSelectedTask"
                    [selectTaskSubject]="tertiaryTaskSelectionSubject"
                    [dragNDropMode]="dragNDropMode"
                    [notifyParentSubject]="taskCompletionTertiaryToSecondarySubject"
                    [parentListenerSubject]="taskCompletionSecondaryToTertiarySubject"
                    [notifyChildrenSubject]="null"
                    [childListenerSubject]="null"
                ></wackadoo-task-list-panel>
            </div>
        </div>
    </div>
    <div *ngIf="(screenType === 'phone')">
        <div class="card">
            <div class="card-header d-flex justify-content-between">
                <div class="bold green">
                    <wackadoo-field [f]="contract.contractName" mode="read"></wackadoo-field>
                </div>
                <div class="no-wrap">
                    <button type="button" 
                        class="btn btn-secondary small paddingBottomQuarterEm paddingTopQuarterEm paddingRightHalfEm paddingLeftHalfEm"
                        (click)="togglePhoneHeader()"
                    >
                        <span class="fas fa-sync" aria-hidden="true"></span> Toggle
                    </button>
                </div>
            </div>
            <div class="card-body" #collapse="ngbCollapse" [(ngbCollapse)]="!phoneHeaderOpen">
                <table class="table table-responsive small">
                    <tr>
                        <td class="bold right nowrap">
                            Contract Type
                        </td>
                        <td>
                            <wackadoo-field [f]="contract.contractType" mode="read"></wackadoo-field>
                        </td>
                        <td class="bold right nowrap">
                            Price
                        </td>
                        <td>
                            <wackadoo-field [f]="contract.purchasePrice" [mode]="mode"></wackadoo-field>
                        </td>
                    </tr>
                    <tr *ngIf="contract.contractType.value === 'Listing'">
                        <td>
                        </td>
                        <td>
                        </td>
                        <td class="bold right nowrap">
                            Listing
                        </td>
                        <td class="nowrap">
                            <wackadoo-field [f]="contract.listingDate" [mode]="mode"></wackadoo-field>
                        </td>
                    </tr>
                    <tr *ngIf="contract.contractType.value !== 'Listing'">
                        <td class="bold right nowrap">
                            Closing
                        </td>
                        <td class="nowrap">
                            <wackadoo-field [f]="contract.closingDate" [mode]="mode"></wackadoo-field>
                        </td>
                        <td class="bold right nowrap">
                            Due Diligence
                        </td>
                        <td class="nowrap">
                            <wackadoo-field [f]="contract.ddDate" [mode]="mode"></wackadoo-field>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div>
            <nav class="nav nav-tabs smallish">
                <a class="nav-link" (click)="showPanel(1)" [ngClass]="{active:(activePhonePanel === 1)}">Top-Level</a>
                <a class="nav-link" (click)="showPanel(2)" [ngClass]="{active:(activePhonePanel === 2)}" *ngIf="primaryPanelSelectedTask">Mid-Level</a>
                <a class="nav-link" (click)="showPanel(3)" [ngClass]="{active:(activePhonePanel === 3)}" *ngIf="secondaryPanelSelectedTask">Low-Level</a>
            </nav>
            <div *ngIf="activePhonePanel === 1">
                <wackadoo-task-list-panel 
                    [eventHandler]="firstPanelEventHandler"
                    [action]="firstPanelAction"
                    [title]="'Top-Level Tasks'" 
                    [contract]="contract"
                    [parentTask]="null"
                    [selectTaskSubject]="primaryTaskSelectionSubject"
                    [dragNDropMode]="dragNDropMode"
                    [notifyParentSubject]="null"
                    [parentListenerSubject]="null"
                    [notifyChildrenSubject]="taskCompletionPrimaryToSecondarySubject"
                    [childListenerSubject]="taskCompletionSecondaryToPrimarySubject"
                ></wackadoo-task-list-panel>
            </div>
            <div *ngIf="activePhonePanel === 2">
                <wackadoo-task-list-panel 
                    eventHandler="Tasks"
                    action="listChildrenWithChildCounts"
                    [title]="'Mid-Level Tasks'" 
                    [contract]="contract"
                    [parentTask]="primaryPanelSelectedTask"
                    [selectTaskSubject]="secondaryTaskSelectionSubject"
                    [dragNDropMode]="dragNDropMode"
                    [notifyParentSubject]="taskCompletionSecondaryToPrimarySubject"
                    [parentListenerSubject]="taskCompletionPrimaryToSecondarySubject"
                    [notifyChildrenSubject]="taskCompletionSecondaryToTertiarySubject"
                    [childListenerSubject]="taskCompletionTertiaryToSecondarySubject"
                ></wackadoo-task-list-panel>
            </div>
            <div *ngIf="activePhonePanel === 3">
                <wackadoo-task-list-panel 
                    eventHandler="Tasks"
                    action="listChildrenWithChildCounts"
                    [title]="'Low-Level Tasks'" 
                    [contract]="contract"
                    [parentTask]="secondaryPanelSelectedTask"
                    [selectTaskSubject]="tertiaryTaskSelectionSubject"
                    [dragNDropMode]="dragNDropMode"
                    [notifyParentSubject]="taskCompletionTertiaryToSecondarySubject"
                    [parentListenerSubject]="taskCompletionSecondaryToTertiarySubject"
                    [notifyChildrenSubject]="null"
                    [childListenerSubject]="null"
                ></wackadoo-task-list-panel>
            </div>
        </div>
    </div>
</div>
