import { Component, OnInit, HostListener, Input, OnDestroy } from '@angular/core';
import { CalendarService } from './calendar.service';
import { UserInterfaceService } from '../../services/user-interface.service';
import { Subscription } from 'rxjs';
import { ScreenType } from '../../services/screen-type.enum';

@Component({
  selector: 'wackadoo-calendar-page',
  templateUrl: './calendar-page.component.html',
})
export class CalendarPageComponent implements OnInit, OnDestroy {

  screenType: ScreenType = null;
  screenTypeSubscription: Subscription = null;

  @Input() calendarName: string = null;

  // state information...

  currentDate: Date = new Date();
  currentDateSubscription: Subscription = null;

  // radio button controls...

  timePeriod = 'month';

  @HostListener('input', ['$event']) captureInputEvents = (e: any) => {
    // console.log(e, e.target.name, e.target.value);
    if (e.target.name === 'timePeriod') {
      this.timePeriod = e.target.value;
      this.userInterfaceService.setPageState('CalendarPage', this.calendarName + '-timePeriod', this.timePeriod);
      this.calendarService.getCalendarEntrySubject(this.calendarName).next();
    }
  }

  constructor(
    public calendarService: CalendarService,
    public userInterfaceService: UserInterfaceService,
  ) {
    //////////////////////////////////////////////////////////////////////////////////////////
    // When implementing a Calendar, the getCalendarEntries is required.
    // The openCalendarEntry and addCalendarEntry functions are ignored if they are null.
    //////////////////////////////////////////////////////////////////////////////////////////
  }

  ngOnInit(): void {

    this.screenType = this.userInterfaceService.screenType.getValue();
    this.screenTypeSubscription = this.userInterfaceService.screenType.subscribe(
      (screenType: ScreenType) => {
        this.screenType = screenType;
      }
    );

    const oldTimePeriod = this.userInterfaceService.getPageState('CalendarPage', this.calendarName + '-timePeriod');
    if (oldTimePeriod) {
      this.timePeriod = String(oldTimePeriod);
    }
    this.userInterfaceService.setPageState('CalendarPage', this.calendarName + '-timePeriod', this.timePeriod);

    this.calendarService.initCalendar(this.calendarName);

    this.currentDateSubscription = this.calendarService.getCurrentDateSubject(this.calendarName).subscribe(
      (d: Date) => {
        this.currentDate = d;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.currentDateSubscription) {
      this.currentDateSubscription.unsubscribe();
    }
    if (this.screenTypeSubscription) {
      this.screenTypeSubscription.unsubscribe();
    }
    this.calendarService.destroyCalendar(this.calendarName);
  }

  goToToday(): void {
    this.calendarService.setCurrentDate(this.calendarName, new Date());
  }

  currentMonth(): number {
      return this.currentDate.getMonth();
  }

  currentYear(): number {
      return this.currentDate.getFullYear();
  }

  get topOfWeek(): Date {
      return this.calendarService.getTopOfWeek(this.currentDate);
  }

  get topOfLastMonth(): Date {
      return this.calendarService.getTopOfMonth(this.currentDate, 1);
  }

  get topOfMonth(): Date {
      return this.calendarService.getTopOfMonth(this.currentDate, 0);
  }

  get topOfNextMonth(): Date {
      return this.calendarService.getTopOfMonth(this.currentDate, -1);
  }

}
