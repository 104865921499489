<div class="row">
  <div class="col-sm-6">
    <div class="card margin0">
      <div class="card-header">
        <div class="floatGroup">
          <div class="large floatLeft">{{leftGridTitle}}</div>
          <div class="floatRight unbold link" (click)="unSelectLeftResource()" *ngIf="leftResource">(x) {{leftResource.keyField.displayValue}}</div>
        </div>
      </div>
      <div class="card-body no-overflow"
        wackadooMaximizeContentHeight
        maxHeightThingBelowSelector=".deDupeButtonBar"
        [maxHeightFudgeFactor]="-24"
        [maxHeightTimoutDelay]="100"
        [maxHeightLockThingBelowToBottomOfPage]="true"
        [maxHeightThingBelowDefaultHeight]="38"
      >
        <wackadoo-grid 
            [eventHandler]="eventHandler" 
            [action]="action" 
            [parameters]="leftParameters"
            [resources]="leftResources"
            [screenType]="screenType"
            [gridFieldsToShow]="leftGridFields"
            [resourceClickSubject]="onLeftGridClickSubject"
            [gridRollover]="true"
            gridHint="Click to see similar..."
            [showPageControl]="true"
            [showSortControl]="false"
            [showSearchBox]="false"
            [selectedResource]="leftResource"
            gridName="leftDupeMergerGrid"
        ></wackadoo-grid>
      </div>
    </div>
  </div>
  <div class="col-sm-6">
    <div class="card margin0" *ngIf="leftResource">
      <div class="card-header">
        <div class="floatGroup">
          <div class="large floatLeft">{{rightGridTitle}}</div>
          <div class="floatRight unbold link" (click)="unSelectRightResource()" *ngIf="rightResource">(x) {{rightResource.keyField.displayValue}}</div>
        </div>
      </div>
      <div class="card-body no-overflow"
        wackadooMaximizeContentHeight
        maxHeightThingBelowSelector=".deDupeButtonBar"
        [maxHeightFudgeFactor]="-24"
        [maxHeightTimoutDelay]="100"
        [maxHeightLockThingBelowToBottomOfPage]="true"
        [maxHeightThingBelowDefaultHeight]="38"
      >
        <wackadoo-grid 
            [eventHandler]="eventHandler" 
            [action]="action" 
            [parameters]="rightParameters"
            [resources]="rightResources"
            [screenType]="screenType"
            [gridFieldsToShow]="rightGridFields"
            [resourceClickSubject]="onRightGridClickSubject"
            [gridRollover]="true"
            gridHint="Click to select as merge target..."
            [showPageControl]="true"
            [showSortControl]="false"
            [showSearchBox]="false"
            [disabled]="leftResource === null"
            [selectedResource]="rightResource"
            gridName="rightDupeMergerGrid"
        ></wackadoo-grid>
      </div>
    </div>
  </div>
</div>
<div class="deDupeButtonBar btn-group marginTopQuarterEm d-flex" role="group">
    <button type="button" title="Merge"  data-abbrev="" class="btn btn-secondary actionButton" (click)="mergeItems()" [disabled]="(leftResource === null) || (rightResource === null)"><span class="fas fa-check"></span></button>
    <button type="button" title="Cancel" data-abbrev="" class="btn btn-secondary actionButton" (click)="cancel()"><span class="fas fa-times"></span></button>
</div>
