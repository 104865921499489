import { Directive, Input, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { UserInterfaceService } from '../services/user-interface.service';
import { WResource } from '../data/resource.model';

@Directive({
  selector: '[wackadooSelectResource]'
})
export class SelectResourceDirective implements OnInit {

  @Input() resource: WResource = null;
  @Input() eventHandler: string = null;
  @Input() pageName: string = null;
  @Input() loadPage = false;

  constructor(
    public elementRef: ElementRef,
    public renderer: Renderer2,
    public userInterfaceService: UserInterfaceService,
  ) {}

  ngOnInit(): void {

    // tell the element to listen for on-click...

    this.renderer.listen(
      this.elementRef.nativeElement,
      'click',
      (e: any) => {
        // console.log('PageLoadDirective()', this.eventHandler, this.action, this.parms);
        this.userInterfaceService.selectResource(this.eventHandler, this.resource);
        if (this.loadPage) {
          this.userInterfaceService.loadPage((this.pageName ? this.pageName : this.eventHandler));
        }

        e.stopPropagation();
        e.preventDefault();

      }
    );

  }
}
