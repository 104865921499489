import { Component, OnInit, Input } from '@angular/core';
import { DateMode } from '../day/day.component';

@Component({
  selector: 'wackadoo-week',
  templateUrl: './week.component.html',
})
export class WeekComponent implements OnInit {

  @Input() calendarName: string = null;
  @Input() currentMonth: number = null;
  @Input() startOfWeek: Date = null;
  @Input() mode = DateMode.n;
  @Input() restrictHeight = true;

  constructor() { }

  ngOnInit(): void {
    // console.log('WeekComponent.ngOnInit()', this.startOfWeek.toJSON(), this.calendarName, this.currentMonth, this.mode);
  }

  getDate(dayOfWeek: number): Date {
    const d = new Date(this.startOfWeek.getFullYear(), this.startOfWeek.getMonth(), this.startOfWeek.getDate() + dayOfWeek);
    // console.log('WeekComponent.getDate()', this.startOfWeek.toJSON(), dayOfWeek, d.toJSON());
    return(d);
  }

}
