import { Component, Input } from '@angular/core';

@Component({
  selector: 'wackadoo-contract-history',
  templateUrl: './contract-history.component.html',
})
export class ContractHistoryComponent {

  @Input() title = '';
  @Input() cardHeightClassName = '';

  // ResourceBlock stuff...

  @Input() eventHandler = '';
  @Input() action = '';
  @Input() parms = {};
  @Input() omitCriteria = {};
  @Input() defaultText = '';
  @Input() debug = false;

}
