<div class="panel panel-default">
    <div class="panel-heading floatGroup">
        <div class="floatLeft">
            Contact Information
        </div>
        <div class="floatRight">
            {{ resource.lenderID.value }}
        </div>
    </div>
    <div class="panel-body">
        <div class="row">
            <div class="col-sm-4">
                <div class="small underline italic">Lender</div>
                <div><wackadoo-field [f]="resource.lenderFirstName" [mode]="mode"></wackadoo-field> <wackadoo-field [f]="resource.lenderLastName" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.lenderEmail" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.lenderPhone" [mode]="mode"></wackadoo-field></div>
            </div>
            <div class="col-sm-4">
                <div class="small underline italic">Firm</div>
                <div><wackadoo-field [f]="resource.lenderCompany" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.lenderAddress" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.lenderCity" [mode]="mode"></wackadoo-field>, <wackadoo-field [f]="resource.lenderState" [mode]="mode" [size]="2"></wackadoo-field> <wackadoo-field [f]="resource.lenderPostalCode" [mode]="mode"></wackadoo-field></div>
            </div>
            <div class="col-sm-4">
                <div class="small underline italic"><wackadoo-field [f]="resource.lenderContact1Role" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.lenderContact1" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.lenderContact1Email" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.lenderContact1Phone" [mode]="mode"></wackadoo-field></div>
            </div>
        </div>
        <div class="row marginTopWholeEm">
            <div class="col-sm-4">
                <div class="small underline italic"><wackadoo-field [f]="resource.lenderContact2Role" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.lenderContact2" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.lenderContact2Email" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.lenderContact2Phone" [mode]="mode"></wackadoo-field></div>
            </div>
            <div class="col-sm-4">
                <div class="small underline italic"><wackadoo-field [f]="resource.lenderContact3Role" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.lenderContact3" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.lenderContact3Email" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.lenderContact3Phone" [mode]="mode"></wackadoo-field></div>
            </div>
            <div class="col-sm-4">
                <div class="small underline italic"><wackadoo-field [f]="resource.lenderContact4Role" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.lenderContact4" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.lenderContact4Email" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.lenderContact4Phone" [mode]="mode"></wackadoo-field></div>
            </div>
        </div>
        <div class="row marginTopWholeEm">
            <div class="col-sm-12">
                <div class="small underline italic">
                    Note
                </div>
                <div>
                    <wackadoo-field [f]="resource.lenderNote" [mode]="mode"></wackadoo-field>
                </div>
            </div>
        </div>
    </div>
</div>
