<div class="row">
    <div class="col-sm-6">
        <div class="card">
            <div class="card-header floatGroup">
                <div class="floatLeft">
                    Contact Information
                </div>
                <div class="floatRight">
                    {{ resource.coOpAgentID.value }}
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12">
                        <div><wackadoo-field [f]="resource.coOpAgentFirstName" [mode]="mode"></wackadoo-field> <wackadoo-field [f]="resource.coOpAgentLastName" [mode]="mode"></wackadoo-field></div>
                        <div class="marginTopQuarterEm"><wackadoo-field [f]="resource.coOpAgentPhone" [mode]="mode"></wackadoo-field></div>
                    </div>
                </div>
                <div class="row marginTopQuarterEm">
                    <div class="col-sm-6">
                        <div class="italic underline"><wackadoo-field [f]="resource.coOpAgentCompany" [mode]="mode"></wackadoo-field></div>
                        <div class="marginTopQuarterEm marginBottomQuarterEm"><wackadoo-field [f]="resource.coOpAgentAddress" [mode]="mode"></wackadoo-field></div>
                        <div><wackadoo-field [f]="resource.coOpAgentCity" [mode]="mode"></wackadoo-field>, <wackadoo-field [f]="resource.coOpAgentState" [mode]="mode" [size]="2"></wackadoo-field> <wackadoo-field [f]="resource.coOpAgentPostalCode" [mode]="mode"></wackadoo-field></div>
                        <div><wackadoo-field [f]="resource.coOpAgentEmail" [mode]="mode"></wackadoo-field></div>
                    </div>
                    <div class="col-sm-6">
                        <div class="italic underline">Co-Op Coordinator</div>
                        <div class="marginTopQuarterEm marginBottomQuarterEm"><wackadoo-field [f]="resource.coOpCoord" [mode]="mode"></wackadoo-field></div>
                        <div><wackadoo-field [f]="resource.coOpCoordEmail" [mode]="mode"></wackadoo-field></div>
                        <div class="marginTopQuarterEm"><wackadoo-field [f]="resource.coOpCoordPhone" [mode]="mode"></wackadoo-field></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                Note
            </div>
            <div class="card-body">
                <wackadoo-field [f]="resource.coOpAgentNote" [mode]="mode"></wackadoo-field>
            </div>
        </div>
    </div>
    <div class="col-sm-6" *ngIf="resource.coOpAgentID.value">
        <wackadoo-contract-history
            title="Active Contracts"
            cardHeightClassName="maxHeightViewPort25"
            eventHandler="ActiveContracts"
            action="list"
            [parms]="{coOpAgentID: resource.coOpAgentID.value, pageSize:-1, sortBy:'closingDate', sortDirection:-1 }"
            defaultText="No active contracts..."
            [debug]="false"
        ></wackadoo-contract-history>
        <wackadoo-contract-history
            title="In-Active Contracts"
            cardHeightClassName="maxHeightViewPort40"
            eventHandler="Contracts"
            action="list"
            [parms]="{ coOpAgentID: resource.coOpAgentID.value, pageSize:-1, sortBy:'closingDate', sortDirection:-1 }"
            [omitCriteria]="{contractStatus: 'active'}"
            defaultText="No contract history..."
            [debug]="false"
        ></wackadoo-contract-history>
    </div>
</div>
