import { Component } from '@angular/core';
import { ResourceComponent } from 'src/app/client-core/data/resource/resource.component';

@Component({
  selector: 'wackadoo-contract-header',
  templateUrl: './contract-header.component.html',
})
export class ContractHeaderComponent extends ResourceComponent {

}
