import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { WResource } from 'src/app/client-core/data/resource.model';
import { WEvent } from 'src/app/client-core/data/event.model';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { User } from 'src/app/client-core/data/user.model';
import { UserAuthService } from 'src/app/client-core/services/user-auth.service';
import { ClosingProService } from '../../../closing-pro.service';
import { ScreenType } from 'src/app/client-core/services/screen-type.enum';

@Component({
  selector: 'wackadoo-task-list-panel',
  templateUrl: './task-list-panel.component.html',
})
export class TaskListPanelComponent implements OnInit, OnDestroy {

  @Input() title: string = null;
  @Input() contract: WResource = null;

  @Input() parentTask: WResource = null;

  private _selectedTask: WResource = null;
  private _selectTaskSubscription: Subscription = null;
  @Input() selectTaskSubject: Subject<WResource> = null;

  private _refreshPanelDate: Date = null;

  private _parentListenerSubscription: Subscription = null;
  @Input() parentListenerSubject: Subject<WResource> = null;

  @Input() childListenerSubject: Subject<WResource> = null;
  @Input() notifyChildrenSubject: Subject<WResource> = null;
  @Input() notifyParentSubject: Subject<WResource> = null;

  @Input() dragNDropMode: string = null;

  private _refreshPanelSubscription: Subscription = null;
  @Input() refreshPanelSubject: Subject<void> = null;

  @Input() eventHandler: string = null;
  @Input() action: string = null;

  public user: User = null;

  constructor(
    public closingProService: ClosingProService,
    public userInterfaceService: UserInterfaceService,
    public eventServerService: EventServerService,
    public userAuthService: UserAuthService,
  ) {}

  ngOnInit(): void {

    this.user = this.userAuthService.currentUser.getValue();

    this._selectTaskSubscription = this.selectTaskSubject.subscribe(
      (task: WResource) => {
        // console.log(this.title + ' - selectTaskSubject()', task.taskName.value);
        this._selectedTask = task;
      }
    );

    if (this.parentListenerSubject) {
      this._parentListenerSubscription = this.parentListenerSubject.subscribe(
        (task: WResource) => {
          if (this.parentTask && task) {

            // we only care when this fires for the parentTask for this panel.
            // (we ignore all other notifications...)

            if (this.parentTask.taskID.value === task.taskID.value) {
              // console.log('TaskListPanel._parentListenerSusbscription(' + this.title + ') - Parent task completion notification: ' + task.taskName.value);

              // setting this date to something new will force the panel to refresh...
              this._refreshPanelDate = new Date();
            }
          }
        }
      );
    }

    this.refreshPanelSubject = new Subject<void>();

    this._refreshPanelSubscription = this.refreshPanelSubject.subscribe(
      () => {
        this.refreshPanel();
      }
    );

  }

  ngOnDestroy(): void {

    if (this._selectTaskSubscription) {
      this._selectTaskSubscription.unsubscribe();
    }

    if (this._parentListenerSubscription) {
      this._parentListenerSubscription.unsubscribe();
    }

    if (this._refreshPanelSubscription) {
      this._refreshPanelSubscription.unsubscribe();
    }

  }

  isSelected(taskID: number): boolean {
    return (this._selectedTask && this._selectedTask.taskID && (this._selectedTask.taskID.value === taskID));
  }

  get screenType(): ScreenType {
    return this.userInterfaceService.screenType.getValue();
  }

  get selectedTaskID(): number {
    return (this._selectedTask ? this._selectedTask.taskID.value : null);
  }

  refreshPanel(): void {
    // console.log(this.title + ' - refreshPanel()');
    // setting this date to something new will force the panel to refresh...
    this._refreshPanelDate = new Date();
  }

  newTask(): void {
    // console.log(this.title + ' - newTask()');
    this.closingProService.addNewAdHocTask((this.parentTask ? this.parentTask : this.contract)).subscribe(
      (event: WEvent) => {
        // console.log('TaskListPanel.newTask()', event);
        try {
          if (event) {
            if (event.status === 'OK') {
              if (this.notifyParentSubject) {

                const tempTask = this.eventServerService.newResource('Tasks');
                tempTask.setFieldsFromParms(event.parameters);

                this.notifyParentSubject.next(tempTask);
              }
              this.refreshPanel();
            } else {
              throw new Error(event.message);
            }
          }
        } catch (ex) {
          const msg = 'Error Adding New Task';
          this.userInterfaceService.alertUserToException(ex, msg);
        }
      }
    );
  }

  addNote(): void {
    // console.log(this.title + ' - addNote()');
    this.closingProService.updateContractNote(this.contract.contractID.value);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Admittedly, this is a little screwy, but we fire different Events, with
  // different actions and parms, for the top-level vs 2nd/3rd panels...
  // And then the top panel is different for TaskList vs MyTaskList.
  // This just gives us the proper flexibility to handle all the use cases.
  /////////////////////////////////////////////////////////////////////////////

  getParmsForResourceBlock(): any {
    const parms: any = {};
    if (this.parentTask) {
      parms.taskID = this.parentTask.taskID.value;
    } else {
      parms.contractID = this.contract.contractID.value;
    }
    parms.pageSize = -1;
    parms.sortBy = 'sequence';
    parms.sortDirection = 1;

    // We force a ResourceBlock to refresh by making the parameters different than they were before...
    if (this._refreshPanelDate) {
      parms.someFakeParameterThatWillBeIgnored = this._refreshPanelDate;
    }

    return parms;
  }

}
