<div class="card {{cardHeightClassName}}">
    <div class="card-header">
        {{ title }}
    </div>
    <div class="card-body smallish fillAvailableHeight scrollable-y">
        <table class="table">
            <tbody>
                <tr class="link" *wackadooResourceBlock="let r of []; let idx = index; eventHandler: eventHandler; action: action; parms: parms; defaultText: defaultText; debug: debug; omitCriteria: omitCriteria"
                    
                    wackadooPageLoad
                    eventHandler="{{(r.contractStatus.value === 'active') ? 'ActiveContracts' : 'Contracts'}}" 
                    action="search" 
                    [parms]="{contractID: r.contractID.value}"
                >
                    <td>
                        {{ r.contractStatus.value }}
                    </td>
                    <td>
                        <wackadoo-field [f]="r.contractID" mode="read"></wackadoo-field>
                    </td>
                    <td>
                        {{ r.contractType.value }}
                    </td>
                    <td *ngIf="r.contractType.value !== 'Listing'">
                        <wackadoo-field [f]="r.closingDate" mode="read"></wackadoo-field>
                    </td>
                    <td *ngIf="r.contractType.value === 'Listing'">
                        <wackadoo-field [f]="r.listingDate" mode="read"></wackadoo-field>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

