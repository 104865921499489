import { Injectable } from '@angular/core';
import { WResource } from 'src/app/client-core/data/resource.model';
import { Subject, Subscription, Observable } from 'rxjs';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { UserAuthService } from 'src/app/client-core/services/user-auth.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { User } from 'src/app/client-core/data/user.model';
import { WString, WSelect, WBoolean } from 'src/app/client-core/data/field.model';
import { WEvent } from 'src/app/client-core/data/event.model';

@Injectable({
  providedIn: 'root'
})
export class ClosingProService {

  user: User = null;
  userAuthSubscription: Subscription = null;

    /* Important Notes:
        Use of parenthesis () is OK.
        Use of &&, ||, and - is OK  (Do NOT use AND, OR and NOT!!!)
        Use single-quotes to delineate a phrase (i.e. ' and NOT ")
        We CANNOT use wildcards * and ? inside phrase searches - only with single terms.

        e.g. These are OK...

          contractName:(Greene && (Cross || Craft))
          contractName:(Frank && Gre*) && contractType:Seller
          contractName:(Frank && Greene) && -contractType:Seller
    */

  upcomingClosingQueries: {name: string, description: string, query: string} [] =
    [
      {   name: 'Closing Email',        description: 'Send Closing Details',                query: 'taskName:(Send && Closing && Detail*)'}
      // We should also filter Compliance query for null/empty parentTaskID (e.g. ! or NOT _exists_:parentTaskID ???)
      , { name: 'Compliance',           description: 'Compliance',                          query: 'taskName:Compliance'}
      , { name: 'Prof Svcs Discl',      description: 'Professional Services Disclosure',    query: 'taskName:((Prof* && Serv* && Discl*) || PSD)'}
      , { name: 'Commmission',          description: 'Commission / Distribution Authorization or DA',    query: 'taskName:(Commiss* || (Distrib* && Authoriz*) || DA || (Funding && Request))'}
      , { name: 'ALTA',                 description: 'Final Settlement Statement or ALTA',  query: 'taskName:((Final && Settlement && Statement) || ALTA)'}
      , { name: 'Congrats EMail',       description: 'Congratulations',                     query: 'taskName:Congrat*'}
      , { name: 'Invoice',              description: 'Transaction Invoice Agent',           query: 'taskName:(Trans* && Invoice && Agent)'}
    ];

  newContractQueries: {name: string, description: string, query: string} [] =
    [
      {   name: 'Lender Email',     description: 'Lender Email Sent',                               query: 'taskName:(Lender && (Sent || Send) && Contract) && taskType:email'}
      , { name: 'Attorney Email',   description: 'Attorney Email Sent',                             query: 'taskName:(Attorney && (Sent || Send) && Contract) && taskType:email'}
      , { name: 'Closing Appt',     description: 'Schedule Settlement / Closing Meeting',           query: 'taskName:(Schedule && (Settlement || Closing) && (Appt || Appointment || Meeting || Mtg))'}
      , { name: 'Welcome Email',    description: 'Welcome Email Sent',                              query: 'taskName:(Welcome && New) && taskType:email'}
      , { name: 'Co-Op Email',      description: 'Co-Op Email Sent',                                query: 'taskName:(Intro* && (Coop || (Co && Op)) && Agent) && taskType:email'}
      , { name: 'Home Inspection',  description: 'Schedule Home Inspection',                        query: 'taskName:(Home && Inspection && Schedule)'}
      , { name: 'Survey',           description: 'Schedule Survey',                                 query: 'taskName:(Survey && Schedule)'}
      , { name: 'Compliance',       description: 'Set Up Compliance / Disclosure(s)',               query: 'taskName:((Compliance && (Setup || (Set && Up))) || (Disclosure && -(Prof* && (Service* || Svc*))))'}
      , { name: 'Calendar',         description: 'Dates Added / Put On Calendar',                   query: 'taskName:((Add* || Put) && Calendar)'}
    ];

  initialFundQueries: {name: string, description: string, query: string} [] =
    [
      { name: 'DD Pmt',           description: 'Due Diligence / DD Payment / Funds / Check / Transfer / ACH / Wire',  query: 'taskName:((Builder || (Due && Diligence) || DD) && (Payment || Deposit || Check || Funds || Transfer || Wire || ACH)) && -taskType:compliance'}
      , { name: 'DD Rcpt',        description: 'Due Diligence / DD Receipt / Acknowledgement',                      query: 'taskName:((Deposit || (Due && Diligence) || DD) && (Receipt || Acknowl*)) && -taskType:compliance'}
      , { name: 'EMD Pmt',        description: 'Earnest Money Deposit / EMD Payment / Funds / Check / Transfer / ACH / Wire', query: 'taskName:(((Builder || (Earnest && Money) || (EM || EMD)) && -Add*) && (Payment || Deposit || Check || Funds || Transfer || Wire || ACH)) && -taskType:compliance'}
      , { name: 'EMD Rcpt',       description: 'Earnest Money Deposit / EMD Receipt / Acknowledgement',             query: 'taskName:(((Deposit || (Earnest && Money) || (EM || EMD)) && -Add*) && (Receipt || Acknowl*)) && -taskType:compliance'}
    ];

  constructor(
    public userInterfaceService: UserInterfaceService,
    public userAuthService: UserAuthService,
    public modalDialogService: ModalDialogService,
    public eventServerService: EventServerService,
  ) {
    this.user = this.userAuthService.currentUser.getValue();
    this.userAuthSubscription = this.userAuthService.currentUser.subscribe(
      (user: User) => {
        this.user = user;
      }
    );
  }

  ////////////////////////////////////////////////////
  // Query defnitions for the UpcomingClosings page
  ////////////////////////////////////////////////////

  getQueryNameForUpcomingClosings(i: number): string {
    return this.upcomingClosingQueries[i].name;
  }

  getQueryDescriptionForUpcomingClosings(i: number): string {
    return this.upcomingClosingQueries[i].description;
  }

  getQueryForUpcomingClosings(i: number): string {
    return this.upcomingClosingQueries[i].query;
  }

  ////////////////////////////////////////////////////
  // Query defnitions for the NewContracts page
  ////////////////////////////////////////////////////

  getQueryNameForNewContracts(i: number): string {
    return this.newContractQueries[i].name;
  }

  getQueryDescriptionForNewContracts(i: number): string {
    return this.newContractQueries[i].description;
  }

  getQueryForNewContracts(i: number): string {
    return this.newContractQueries[i].query;
  }

  ////////////////////////////////////////////////////
  // Query defnitions for the PreliminaryFunds page
  ////////////////////////////////////////////////////

  getQueryNameForInitialFunds(i: number): string {
    return this.initialFundQueries[i].name;
  }

  getQueryDescriptionForInitialFunds(i: number): string {
    return this.initialFundQueries[i].description;
  }

  getQueryForInitialFunds(i: number): string {
    return this.initialFundQueries[i].query;
  }

  ////////////////////////////////////////////////////
  // Task and TaskList methods...
  ////////////////////////////////////////////////////

  isVendorActivity(taskType: string): boolean {
    const flag = (taskType === 'estimate')
              || (taskType === 'marketing')
              || (taskType === 'inspection')
              || (taskType === 'reinspection')
              || (taskType === 'repair');
    return flag;
  }

  isTaskTypeACalendarItem(taskType: string): boolean {
    return (taskType === 'appointment')
                    || (taskType === 'deadline')
                    || this.isVendorActivity(taskType);
  }

  getTaskIconClassNames(taskType: string, taskComplete?: boolean): string {
    taskComplete = typeof taskComplete !== 'undefined' ? taskComplete : false;

    if (!taskComplete && (taskType === 'task')) {
      return 'far fa-square';
    } else if (taskComplete && (taskType === 'task')) {
      return 'far fa-check-square';
    } else if (!taskComplete && this.isTaskTypeACalendarItem(taskType)) {
      return 'far fa-calendar';
    } else if (taskComplete && this.isTaskTypeACalendarItem(taskType)) {
      return 'far fa-calendar-check';
    } else if (taskType === 'email') {
      return 'fas fa-envelope';
    } else if (taskType === 'notification') {
      return 'fas fa-bell';
    } else if (taskType === 'text') {
      return 'fas fa-sms';
    } else if (taskType === 'phone') {
      return 'fas fa-mobile-alt';
    } else if (!taskComplete && (taskType === 'compliance')) {
      return 'fas fa-clipboard-list';
    } else if (taskComplete && (taskType === 'compliance')) {
      return 'fas fa-clipboard-check';
    } else if (taskType === 'dependency') {
      return 'fas fa-people-arrows';
    }

    // and just in case we missed something...
    return 'far fa-square';
  }

  addNewAdHocTask(contractOrParentTask: WResource, sequence?: number): Observable<WEvent> {
    try {
      const res = contractOrParentTask.asParms;

      // console.log('ClosingProService.addNewAdHocTask() - res', res);

      const taskResource = this.eventServerService.newResource('Tasks');
      taskResource.taskName.value = 'New Task Name';
      // taskResource.taskNote.value = '';
      taskResource.contractID.value = res.contractID;
      taskResource.contractType.value = res.contractType;
      taskResource.taskType.value = 'task';

      // if we don't have a sequence number, put it first on the list to be very noticiable...
      if (isNaN(sequence)) {
        sequence = 0;
      }

      // We ONLY set the parentTaskID if we have a taskID.
      // (if we DON'T set the parentTaskID, it is added as a top level task)
      if (res.taskID) {
        taskResource.parentTaskID.value = res.taskID;
      }

      taskResource.sequence.value = sequence;

      // console.log('ClosingProService.addNewAdHocTask() - taskResource', taskResource.asParms);

      return this.modalDialogService.addResourceModal('TaskModalComponent', 'Tasks', taskResource);

    } catch (ex) {
      const msg = 'ClosingProService.addNewAdHocTask()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  sendPizzaTrackerEmailToAgent(fullContract: WResource): void {
    try {
      // console.log('sendPizzaTrackerEmailToAgent() - fullContract:', fullContract);
      const contract = fullContract.asParms;
      // console.log('sendPizzaTrackerEmailToAgent() - contract:', contract);

      this.eventServerService.loadResourceFromServer('Agents', fullContract.agentID.asParm).subscribe(
        (agentResource: WResource) => {

          // console.log('sendPizzaTrackerEmailToAgent()', agentResource);
          const agent = agentResource.asParms;
          // console.log('sendPizzaTrackerEmailToAgent()- agent:', agent);

          const hours = 2;
          const expiration = new Date();
          expiration.setTime(expiration.getTime() + hours * (1000 * 60 * 60));
          const expirationMessage = 'Please note: This temporary, personalized link expires ' + hours + ' hours from now.';

          let msg = 'You are about to send email to ' + agent.agentFirstName +  '  ' + agent.agentLastName + ' at ' + agent.agentEmail + ' containing a temporary, personalized link to the PizzaTracker page.' ;
          msg += ('\n\n' + expirationMessage);
          msg += '\n\nAre you sure?';

          this.modalDialogService.showConfirm(msg, null).subscribe(
            (flag: boolean) => {
              try {

                  if (flag) {

                    // first, save the data on the page...
                    // Q: Do we HAVE to do this w/Angular?
                    // $('.save').click();

                    // console.log('sendPizzaTrackerEmailToAgent()');

                    // set the guest parameters...
                    const parms: any = {};
                    // defining the guest...
                    parms.guestID = agent.agentID;
                    parms.guestType = 'RealEstateAgent';
                    parms.groupID = contract.contractID;
                    parms.groupType = 'Contract';
                    parms.linkType = null;
                    parms.expiration = expiration.toJSON();
                    parms.expirationMessage = expirationMessage;
                    // where the email goes to...
                    parms.to = agent.agentEmail;
                    parms.fromName = this.user.fullName;
                    parms.subject = 'PizzaTracker link for ' + contract.contractName;

                    // other parameters for filling in the email template...
                    parms.agentFirstName = agent.agentFirstName;
                    parms.agentLastName = agent.agentLastName;
                    parms.agentCompany = agent.agentCompany;
                    parms.contractName = contract.contractName;

                    // now send the agent their PizzaTracker email...

                    const agentFullName = agent.agentFirstName + ' ' + agent.agentLastName;

                    // console.log('sendPizzaTrackerEmailToAgent() - parms: ' + JSON.stringify(parms));

                    this.eventServerService.fireEvent('PizzaTrackerEMailGenerator', 'send', parms).subscribe(
                      (responseEvent: WEvent) => {
                          this.modalDialogService.showPleaseWait(false);

                          let msg2 = 'PizzaTracker link has been emailed to ' + agentFullName + '!';

                          if (responseEvent.status !== 'OK') {
                            msg2 = 'Error sending the email.\n Email to ' + agentFullName + ' was NOT sent.\nPlease try again.';
                          }

                          this.modalDialogService.showAlert(msg2, null).subscribe(
                            () => {
                              this.userInterfaceService.reloadCurrentPage();
                            }
                          );
                      }
                    );

                    this.modalDialogService.showPleaseWait('Emailing PizzaTracker link to ' + agent.agentFirstName + ' ' + agent.agentLastName + '...');

                  } else {
                    this.modalDialogService.showPleaseWait(false);
                    this.modalDialogService.showAlert('Email NOT sent...');
                  }

                } catch (ex) {
                  const msg2 = 'ClosingProService.sendPizzaTrackerEmailToAgent.okCallback()\n';
                  this.userInterfaceService.alertUserToException(ex, msg2);
                }
            }
          );

        }
      );


    } catch (ex) {
      const msg = 'sendPizzaTrackerEmailToAgent()';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
    this.modalDialogService.showPleaseWait(false);
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  // This adds a new Client
  //   <field name="clientRole" type="String" length="6" select="Buyer|Seller" />
  ///////////////////////////////////////////////////////////////////////////////////////

  addNewClientAndContractParticipant(contractID: number, contractType: string): void {
    try {
      const client = this.eventServerService.newResource('Clients');
      client.clientFirstName.required = true;
      client.clientLastName.required = true;

      const f = new WSelect('clientRole', null);
      f.select = 'Buyer|Seller';
      f.value = contractType;
      f.required = true;
      f.changed = true;
      client.addExtraField(f);

      // console.log('ClosingProService.addNewClientAndContractParticipant.click() - Got here!', client, client.fields, client.asParms);
      this.modalDialogService.addResourceModal('ContractParticipantModalComponent', 'Clients', client).subscribe(
        (resourceSaveResponseEvent: WEvent) => {
          try {
            // if they did NOT hit CANCEL, then we have a WEvent...
            if (resourceSaveResponseEvent) {

              // console.log('Got there! Add Client response:', resourceSaveResponseEvent);

              if (resourceSaveResponseEvent.status === 'OK') {

                const clientID = resourceSaveResponseEvent.parameters.clientID;
                const clientRole = resourceSaveResponseEvent.parameters.clientRole;

                const contractParticipant = this.eventServerService.newResource('ContractParticipants');
                contractParticipant.contractID.value = contractID;
                contractParticipant.clientID.value = clientID;
                contractParticipant.clientRole.value = clientRole;

                contractParticipant.contractID.changed = true;
                contractParticipant.clientID.changed = true;
                contractParticipant.clientRole.changed = true;

                // save it to the server...
                this.eventServerService.saveResource(contractParticipant).subscribe(
                  (responseEvent: WEvent) => {
                    // console.log('And got there too! ', responseEvent);
                    if (responseEvent && responseEvent.status !== 'OK') {
                      this.modalDialogService.showAlert(responseEvent.message, 'ContractParticipant add action failed!');
                    }
                    this.userInterfaceService.reloadCurrentPage();
                  }
                );

              } else {
                throw new Error('Client add action failed: ' + resourceSaveResponseEvent.message);
              }
            }

          } catch (ex) {
            const msg = 'ClosingProService.addNewClientAndContractParticipant.add.client.callback.()\n';
            this.userInterfaceService.alertUserToException(ex, msg);
          }
        }
      );

    } catch (ex) {
      const msg = 'ClosingProService.addNewClientAndContractParticipant.click()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  updateContractNote(contractID: number, defaultValue?: string, callback?: Subject<WEvent>): void {
    try {
      const placeholder = 'contractNote';

      const title = 'Contract Note';
      const message =  'Please ' + (defaultValue ? 'Edit' : 'Enter New') + ' Note:';

      this.modalDialogService.showPrompt(message, title, placeholder, defaultValue).subscribe(
        (inputValue) => {
          try {
            if (inputValue && (inputValue !== defaultValue)) {
              const parms: any = {};
              parms.contractID = contractID;
              parms.contractNote = inputValue;

              this.eventServerService.fireEvent('Contracts', 'modify', parms).subscribe(
                (event: WEvent) => {
                  if (callback) {
                    callback.next(event);
                  } else {
                    this.userInterfaceService.reloadCurrentPage();
                  }
                }
              );
            }
          } catch (ex) {
            const msg = 'ClosingProService.modifyContractNote.internalPromptCallback()\n';
            this.userInterfaceService.alertUserToException(ex, msg);
          }
        }
      );

    } catch (ex) {
      const msg = 'ClosingProService.modifyContractNote()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  setSelectedTasksForPanelsOnTaskListPage(contractID: number, taskID1: number, taskID2: number, taskID3: number): void {
    this.userInterfaceService.setPageState('TaskList', contractID + '-primaryTaskID', taskID1);
    this.userInterfaceService.setPageState('TaskList', contractID + '-secondaryTaskID', taskID2);
    this.userInterfaceService.setPageState('TaskList', contractID + '-tertiaryTaskID', taskID3);
  }

  openTaskListPageToTask(contractID: number, task: WResource): void {
    if ((task.parentTaskID.value === null) || (task.parentTaskID.value === -1)) {
      this.setSelectedTasksForPanelsOnTaskListPage(contractID, task.taskID.value, null, null);
      this.userInterfaceService.loadPage('TaskList');
    } else {
      const parms: any = {};
      parms.taskID = task.parentTaskID.value;
      this.eventServerService.loadResourceFromServer('Tasks', parms).subscribe(
        (parent: WResource) => {
          if (parent) {
            if ((parent.parentTaskID.value === null) || (parent.parentTaskID.value === -1)) {
              this.setSelectedTasksForPanelsOnTaskListPage(contractID, parent.taskID.value, task.taskID.value, null);
            } else {
              this.setSelectedTasksForPanelsOnTaskListPage(contractID, parent.parentTaskID.value, parent.taskID.value, task.taskID.value);
            }
          }
          this.userInterfaceService.loadPage('TaskList');
        }
      );
    }
  }

  reInitializeContractTasks(resource: WResource): void {
    try {
      // console.log('CURRENT resource.taskTemplatGroupID:', resource.taskTemplateGroupID);

      const res = resource.asParms;

      this.eventServerService.loadResourceFromServer('TaskTemplateGroups', resource.taskTemplateGroupID.asParm).subscribe(
        (taskTemplateGroup: WResource) => {
          if (!taskTemplateGroup) {
            const msg = 'The TaskTemplateGroup currently selected for this contract no longer exists.\nThis will not affect this contract, but you cannot re-initialize Tasks until you change this contract\'s TaskList.';
            this.modalDialogService.showAlert(msg, 'Please Be Aware...');
          } else {

            // console.log('SELECTED taskTemplateGroup:', taskTemplateGroup);

            let msg = 'Re-initializing the TaskList for the "' + res.contractName + '" contract is a pretty big deal, because it <strong>COMPLETELY ERASES</strong> the existing TaskList.';
            msg += ' (The new TaskList will be initialized with all Tasks set as incomplete. All your "ad hoc" Tasks will be lost.)';
            msg += '\n\nYou have selected <strong>' + res.contractType + '</strong> tasks, from the <strong>' + taskTemplateGroup.name.value + '</strong> task template group.';
            msg += '\n\nAre you sure?';

            this.modalDialogService.showConfirm(msg, null).subscribe(
              (flag: boolean) => {
                if (flag) {
                  const parms: any = {};
                  parms.contractID = res.contractID;
                  parms.contractType = res.contractType;
                  parms.taskTemplateGroupID = taskTemplateGroup.taskTemplateGroupID.value;

                  this.eventServerService.fireEvent('Contracts', 'modify', parms).subscribe(
                    (responseEvent: WEvent) => {
                      this.modalDialogService.showPleaseWait(false);
                      if (responseEvent.status === 'OK') {
                        this.setSelectedTasksForPanelsOnTaskListPage(res.contractID, null, null, null);
                        this.userInterfaceService.loadPage('TaskList');
                      } else {
                        this.modalDialogService.showAlert(responseEvent.status + ' - ' + responseEvent.message, 'Error Initializing TaskList').subscribe(
                          () => {
                            this.setSelectedTasksForPanelsOnTaskListPage(res.contractID, null, null, null);
                            this.userInterfaceService.loadPage('TaskList');
                          }
                        );
                      }
                    }
                  );

                  this.modalDialogService.showPleaseWait(true);
                }
              }
            );
          }
        }
      );

    } catch (ex) {
      const msg = 'ClosingProService.reInitializeContractTasks()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  // This adds a new CoOpAgent
  ///////////////////////////////////////////////////////////////////////////////////////

  addNewCoOpAgent(contractID: number): void {
    try {
      const coOpAgent = this.eventServerService.newResource('CoOpAgents');
      coOpAgent.coOpAgentFirstName.required = true;
      coOpAgent.coOpAgentLastName.required = true;
      coOpAgent.coOpAgentCompany.required = true;
      coOpAgent.coOpAgentEmail.required = true;
      coOpAgent.coOpAgentPhone.required = true;

      // console.log('ClosingProService.addNewCoOpAgent.click() - Got here!');

      this.modalDialogService.addResourceModal('CoOpAgentModalComponent', 'CoOpAgents', coOpAgent).subscribe(
        (resourceSaveResponseEvent: WEvent) => {
          try {

            // console.log('Got there! Add CoOpAgent response:', resourceSaveResponseEvent);

            // this is null if the user clicked "cancel" on the resource modal dialog...
            if (resourceSaveResponseEvent) {

              if (resourceSaveResponseEvent.status === 'OK') {

                // update the contract on the server...

                const coOpAgentID = resourceSaveResponseEvent.getParameter('coOpAgentID');
                const coOpAgentFirstName = resourceSaveResponseEvent.getParameter('coOpAgentFirstName');
                const coOpAgentLastName = resourceSaveResponseEvent.getParameter('coOpAgentLastName');

                const parms: any = {};
                parms.contractID = contractID;
                parms.coOpAgentID = coOpAgentID;
                parms.reasonForChange = 'New coOpAgent: ' + coOpAgentFirstName + ' ' + coOpAgentLastName;

                this.eventServerService.fireEvent('Contracts', 'modify', parms).subscribe(
                  (responseEvent: WEvent) => {
                    try {
                      if (responseEvent.status !== 'OK') {
                        throw new Error('Failed to set coOpAgentID into Contract: ' + responseEvent.message);
                      }
                      // reload the contract from the server in order to see the change...
                      this.userInterfaceService.loadPage(this.userInterfaceService.currentPage.getValue(), null, {contractID});
                    } catch (ex) {
                      const msg = 'ClosingProService.addNewCoOpAgent.modify.contract.callback.()\n';
                      this.userInterfaceService.alertUserToException(ex, msg);
                    }
                  }
                );

              } else {
                throw new Error('CoOpAgent add action failed: ' + resourceSaveResponseEvent.message);
              }
            }
          } catch (ex) {
            const msg = 'ClosingProService.addNewCoOpAgent.add.coOpAgent.callback.()\n';
            this.userInterfaceService.alertUserToException(ex, msg);
          }
        }
      );

    } catch (ex) {
      const msg = 'ClosingProService.addNewCoOpAgent.click()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  // This adds a new Attorney
  ///////////////////////////////////////////////////////////////////////////////////////

  addNewAttorney(contractID: number): void {
    try {
      const attorney = this.eventServerService.newResource('Attorneys');
      attorney.attorneyFirstName.required = true;
      attorney.attorneyLastName.required = true;
      attorney.attorneyCompany.required = true;
      attorney.attorneyEmail.required = true;
      attorney.attorneyPhone.required = true;

      // console.log('ClosingProService.addNewAttorney.click() - Got here!');
      this.modalDialogService.addResourceModal('AttorneyModalComponent', 'Attorneys', attorney).subscribe(
        (resourceSaveResponseEvent: WEvent) => {
          try {

            // this is null if the user clicked "cancel" on the resource modal dialog...
            if (resourceSaveResponseEvent) {
              // console.log('Got there! Add Attorney response status: ' + JSON.stringify(resourceSaveResponseEvent.status));

              if (resourceSaveResponseEvent.status === 'OK') {

                const attorneyID = resourceSaveResponseEvent.getParameter('attorneyID');
                const attorneyFirstName = resourceSaveResponseEvent.getParameter('attorneyFirstName');
                const attorneyLastName = resourceSaveResponseEvent.getParameter('attorneyLastName');

                const parms: any = {};
                parms.contractID = contractID;
                parms.attorneyID = attorneyID;
                parms.reasonForChange = 'New attorney: ' + attorneyFirstName + ' ' + attorneyLastName;

                // save it to the server...
                this.eventServerService.fireEvent('Contracts', 'modify', parms).subscribe(
                  (responseEvent: WEvent) => {
                    try {
                      if (responseEvent.status !== 'OK') {
                        throw new Error('Failed to set attorneyID into Contract: ' + responseEvent.message);
                      }
                      // reload the contract from the server in order to see the change...
                      this.userInterfaceService.loadPage(this.userInterfaceService.currentPage.getValue(), null, {contractID});
                    } catch (ex) {
                      const msg = 'ClosingProService.addNewAttorney.modify.contract.callback.()\n';
                      this.userInterfaceService.alertUserToException(ex, msg);
                    }
                  }
                );

              } else {
                throw new Error('CoOpAgent add action failed: ' + resourceSaveResponseEvent.message);
              }
            }
          } catch (ex) {
            const msg = 'ClosingProService.addNewCoOpAgent.add.attorney.callback.()\n';
            this.userInterfaceService.alertUserToException(ex, msg);
          }
        }
      );

    } catch (ex) {
      const msg = 'ClosingProService.addNewAttorney.click()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  // This adds a new Lender
  ///////////////////////////////////////////////////////////////////////////////////////

  addNewLender(contractID: number): void {
    try {
      const lender = this.eventServerService.newResource('Lenders');
      lender.lenderFirstName.required = true;
      lender.lenderLastName.required = true;
      lender.lenderCompany.required = true;
      lender.lenderEmail.required = true;
      lender.lenderPhone.required = true;

      // console.log('ClosingProService.addNewLender.click() - Got here!');
      this.modalDialogService.addResourceModal('LenderModalComponent', 'Lenders', lender).subscribe(
        (resourceSaveResponseEvent: WEvent) => {
          try {

            // this is null if the user clicked "cancel" on the resource modal dialog...
            if (resourceSaveResponseEvent) {
              // console.log('Got there! Add Lender response status: ' + JSON.stringify(resourceSaveResponseEvent.status));

              if (resourceSaveResponseEvent.status === 'OK') {

                const lenderID = resourceSaveResponseEvent.getParameter('lenderID');
                const lenderFirstName = resourceSaveResponseEvent.getParameter('lenderFirstName');
                const lenderLastName = resourceSaveResponseEvent.getParameter('lenderLastName');

                const parms: any = {};
                parms.contractID = contractID;
                parms.lenderID = lenderID;
                parms.reasonForChange = 'New lender: ' + lenderFirstName + ' ' + lenderLastName;

                // save it to the server...
                this.eventServerService.fireEvent('Contracts', 'modify', parms).subscribe(
                  (responseEvent: WEvent) => {
                    try {
                      if (responseEvent.status !== 'OK') {
                        throw new Error('Failed to set lenderID into Contract: ' + responseEvent.message);
                      }
                      // reload the contract from the server in order to see the change...
                      this.userInterfaceService.loadPage(this.userInterfaceService.currentPage.getValue(), null, {contractID});
                    } catch (ex) {
                      const msg = 'ClosingProService.addNewLender.modify.contract.callback.()\n';
                      this.userInterfaceService.alertUserToException(ex, msg);
                    }
                  }
                );

              } else {
                throw new Error('CoOpAgent add action failed: ' + resourceSaveResponseEvent.message);
              }
            }
          } catch (ex) {
            const msg = 'ClosingProService.addNewCoOpAgent.add.lender.callback.()\n';
            this.userInterfaceService.alertUserToException(ex, msg);
          }
        }
      );

    } catch (ex) {
      const msg = 'ClosingProService.addNewLender.click()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  // This adds a new EscrowAgent
  ///////////////////////////////////////////////////////////////////////////////////////

  addNewEscrowAgent(contractID: number): void {
    try {
      const escrowAgent = this.eventServerService.newResource('EscrowAgents');
      escrowAgent.escrowAgentFirstName.required = true;
      escrowAgent.escrowAgentLastName.required = true;
      escrowAgent.escrowAgentCompany.required = true;
      escrowAgent.escrowAgentEmail.required = true;
      escrowAgent.escrowAgentPhone.required = true;

      // console.log('ClosingProService.addNewEscrowAgent.click() - Got here!');
      this.modalDialogService.addResourceModal('EscrowAgentModalComponent', 'EscrowAgents', escrowAgent).subscribe(
        (resourceSaveResponseEvent: WEvent) => {
          try {

            // this is null if the user clicked "cancel" on the resource modal dialog...
            if (resourceSaveResponseEvent) {
              // console.log('Got there! Add EscrowAgent response status: ' + JSON.stringify(resourceSaveResponseEvent.status));

              if (resourceSaveResponseEvent.status === 'OK') {

                const escrowAgentID = resourceSaveResponseEvent.getParameter('escrowAgentID');
                const escrowAgentFirstName = resourceSaveResponseEvent.getParameter('escrowAgentFirstName');
                const escrowAgentLastName = resourceSaveResponseEvent.getParameter('escrowAgentLastName');

                const parms: any = {};
                parms.contractID = contractID;
                parms.escrowAgentID = escrowAgentID;
                parms.reasonForChange = 'New escrowAgent: ' + escrowAgentFirstName + ' ' + escrowAgentLastName;

                // save it to the server...
                this.eventServerService.fireEvent('Contracts', 'modify', parms).subscribe(
                  (responseEvent: WEvent) => {
                    try {
                      if (responseEvent.status !== 'OK') {
                        throw new Error('Failed to set escrowAgentID into Contract: ' + responseEvent.message);
                      }
                      // reload the contract from the server in order to see the change...
                      this.userInterfaceService.loadPage(this.userInterfaceService.currentPage.getValue(), null, {contractID});
                    } catch (ex) {
                      const msg = 'ClosingProService.addNewEscrowAgent.modify.contract.callback.()\n';
                      this.userInterfaceService.alertUserToException(ex, msg);
                    }
                  }
                );

              } else {
                throw new Error('EscrowAgent add action failed: ' + resourceSaveResponseEvent.message);
              }
            }
          } catch (ex) {
            const msg = 'ClosingProService.addNewEscrowAgent.add.escrowAgent.callback.()\n';
            this.userInterfaceService.alertUserToException(ex, msg);
          }
        }
      );

    } catch (ex) {
      const msg = 'ClosingProService.addNewEscrowAgent.click()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  assignEscrowAgentToMatchTheAttorney(contractID: number, attorneyID: number): void {
    this.eventServerService.loadResourceFromServer('Attorneys', { attorneyID } ).subscribe(
      (attorneyResource: WResource) => {
        if (attorneyResource) {
          const res = attorneyResource.asParms;

          let query = '';
          query += 'escrowAgentFirstName:"' + res.attorneyFirstName + '"';
          query += ' escrowAgentLastName:"' + res.attorneyLastName + '"';
          query += ' escrowAgentCompany:"' + res.attorneyCompany + '"';
          // apparently, the double quotes mess up the Lucene query syntax when wrapping @ chars...
          query += ' escrowAgentEmail:' + res.attorneyEmail + '';

          const parms = {query};

          this.eventServerService.fireEvent('EscrowAgents', 'search', parms).subscribe(
            (event: WEvent) => {
                try {
                  // console.log('Searched for EscrowAgent', attorneyResource, res, event);
                  if (event.status === 'OK') {

                    // console.log('query: ' + event.getParameter('query'));
                    // console.log('matching escrowAgents: ' + event.resource.length);

                    if (!event.resources || (event.resources.length === 0)) {

                      const parms2: any = {};
                      parms2.escrowAgentFirstName = attorneyResource.attorneyFirstName.value;
                      parms2.escrowAgentLastName = attorneyResource.attorneyLastName.value;
                      parms2.escrowAgentCompany = attorneyResource.attorneyCompany.value;
                      parms2.escrowAgentAddress = attorneyResource.attorneyAddress.value;
                      parms2.escrowAgentCity = attorneyResource.attorneyCity.value;
                      parms2.escrowAgentState = attorneyResource.attorneyState.value;
                      parms2.escrowAgentPostalCode = attorneyResource.attorneyPostalCode.value;
                      parms2.escrowAgentCountry = attorneyResource.attorneyCountry.value;
                      parms2.escrowAgentPhone = attorneyResource.attorneyPhone.value;
                      parms2.escrowAgentEmail = attorneyResource.attorneyEmail.value;

                      this.eventServerService.fireEvent('EscrowAgents', 'add', parms2).subscribe(
                        (event2: WEvent) => {
                          try {
                            // console.log('Added new EscrowAgent', event2);
                            if (event2.status === 'OK') {
                              const escrowAgentID = event2.getParameter('escrowAgentID');

                              const parms3: any = {};
                              parms3.contractID = contractID;
                              parms3.escrowAgentID = escrowAgentID;
                              parms3.reasonForChange = 'Setting attorney as new escrow agent';

                              // save it to the server...
                              this.eventServerService.fireEvent('Contracts', 'modify', parms3).subscribe(
                                (responseEvent: WEvent) => {
                                  try {
                                    if (responseEvent.status !== 'OK') {
                                      throw new Error('Failed to set escrowAgentID into Contract: ' + responseEvent.message);
                                    }
                                    // reload the contract from the server in order to see the change...
                                    this.userInterfaceService.loadPage(this.userInterfaceService.currentPage.getValue(), null, {contractID});
                                  } catch (ex) {
                                    const msg = 'ClosingProService.assignEscrowAgentToMatchTheAttorney.modify.contract.callback.()\n';
                                    this.userInterfaceService.alertUserToException(ex, msg);
                                  }
                                }
                              );

                            } else {
                              throw new Error(event2.message);
                            }
                          } catch (ex) {
                            const msg = 'createEscrowAgent.callback()\n';
                            this.userInterfaceService.alertUserToException(ex, msg);
                          }
                        }
                      );

                    } else if (event.resources.length === 1) {

                      // console.log('Found existing EscrowAgent that matches this Attorney', event.resource[0].asParms);

                      try {
                        const escrowAgentID = event.resources[0].escrowAgentID.value;

                        const parms3: any = {};
                        parms3.contractID = contractID;
                        parms3.escrowAgentID = escrowAgentID;
                        parms3.reasonForChange = 'Setting attorney as escrow agent';

                        // save it to the server...
                        this.eventServerService.fireEvent('Contracts', 'modify', parms3).subscribe(
                          (responseEvent: WEvent) => {
                            try {
                              if (responseEvent.status !== 'OK') {
                                throw new Error('Failed to set escrowAgentID into Contract: ' + responseEvent.message);
                              }
                              // reload the contract from the server in order to see the change...
                              this.userInterfaceService.loadPage(this.userInterfaceService.currentPage.getValue(), null, {contractID});
                            } catch (ex) {
                              const msg = 'ClosingProService.assignEscrowAgentToMatchTheAttorney.modify.contract.callback.()\n';
                              this.userInterfaceService.alertUserToException(ex, msg);
                            }
                          }
                        );

                      } catch (ex) {
                        const msg2 = 'ActiveContracts.goodCallBack()\n';
                        this.userInterfaceService.alertUserToException(ex, msg2);
                      }

                    } else if (event.resources.length > 1) {
                      throw new Error('You have created ' + event.resources.length + ' duplicate EscrowAgents for this Attorney, and you really need to clean those up.');
                    }

                  } else {
                    throw new Error('Error searching for EscrowAgent: ' + event.message);
                  }
                } catch (ex) {
                  const msg = 'assignEscrowAgentForThisAttorney.callback()\n';
                  this.userInterfaceService.alertUserToException(ex, msg);
                }
            }
          );
        } else {
          throw new Error('No attorney found for attorneyID: ' + attorneyID );
        }
      }
    );

  }

  relateTasks(reminderResource: WResource, toResource: WResource): Observable<WEvent> {

    // console.log('ClosingProService.relateTasks() - Got here!');

    try {
      const temp: any = {};
      temp.subsequentTask = true;
      temp.subsequentTaskID = toResource.keyField.value;

      // console.log('ClosingProService.relateTasks() - subTaskLeadTime: ' + JSON.stringify(toResource.subsequentTaskLeadTime.value));

      if (!toResource.subsequentTaskLeadTime.value) {
        temp.subsequentTaskLeadTime = toResource.subsequentTaskLeadTime.default;
      }
      if (!toResource.subsequentTaskLeadTimeUnit.value) {
        temp.subsequentTaskLeadTimeUnit = toResource.subsequentTaskLeadTimeUnit.default;
      }

      // we create a proper "TaskTemplate" resource to pass to the modal...
      reminderResource.setFieldsFromParms(temp);
      reminderResource.markAllFieldsAsChanged();
      reminderResource.subsequentTaskLeadTime.required = true;
      reminderResource.subsequentTaskLeadTimeUnit.required = true;

      const tf = new WString('subsequentTaskName', toResource.asParms.taskName);
      reminderResource.addExtraField(tf);

      const tf2 = new WString('subsequentTaskType', toResource.asParms.taskType);
      reminderResource.addExtraField(tf2);

      const tf3 = new WBoolean('subsequentTaskComplete', toResource.asParms.taskComplete);
      reminderResource.addExtraField(tf3);

      return this.modalDialogService.modifyResourceModal('TaskRelativeModalComponent', 'Tasks', reminderResource);

    } catch (ex) {
      const msg = 'ClosingProService.relateTasks()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  relateTaskTemplates(reminderResource: WResource, toResource: WResource): Observable<WEvent> {

    // console.log('ClosingProService.relateTaskTemplates() - Got here!');

    try {
      const temp: any = {};
      temp.subsequentTask = true;
      temp.subsequentTaskTemplateID = toResource.keyField.value;

      if (!toResource.subsequentTaskLeadTime.value) {
        temp.subsequentTaskLeadTime = toResource.subsequentTaskLeadTime.default;
      }
      if (!toResource.subsequentTaskLeadTimeUnit.value) {
        temp.subsequentTaskLeadTimeUnit = toResource.subsequentTaskLeadTimeUnit.default;
      }

      // we create a proper "TaskTemplate" resource to pass to the modal...
      reminderResource.setFieldsFromParms(temp);
      reminderResource.markAllFieldsAsChanged();
      reminderResource.subsequentTaskLeadTime.required = true;
      reminderResource.subsequentTaskLeadTimeUnit.required = true;

      const tf = new WString('subsequentTaskName', toResource.asParms.taskName);
      reminderResource.addExtraField(tf);

      const tf2 = new WString('subsequentTaskType', toResource.asParms.taskType);
      reminderResource.addExtraField(tf2);

      return this.modalDialogService.addResourceModal('TaskTemplatesRelativeModalComponent', 'TaskTemplates', reminderResource);


    } catch (ex) {
      const msg = 'ClosingProService.relateTaskTemplates()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  logThisNote(contractID: number, note: string, taskCompletionMessage: boolean): void {
    // console.log('ClosingProService.logThisNote(' + note + ', ' + JSON.stringify(taskCompletionMessage) + ')');
    taskCompletionMessage = typeof taskCompletionMessage === 'boolean' ? taskCompletionMessage : false;

    try {
      const parms: any = {};
      parms.contractID = contractID;
      parms.note = note;
      parms.noteDate = new Date().toJSON();
      parms.noteAuthor = this.user.fullName;
      if (taskCompletionMessage) {
        parms.taskCompletionMessage = taskCompletionMessage;
      }

      // console.log('ClosingProService.logThisNote()', parms);

      // fire and forget...
      this.eventServerService.fireEvent('History', 'add', parms).subscribe();

    } catch (ex) {
      const msg = 'ClosingProService.logThisNote()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  scheduleTask(resource: WResource): Observable<WEvent> {
    // console.log('ClosingProService.scheduleTask() - Got here!');

    try {
      // we have to create a proper "Task" resource because in some cases,
      // the incoming "related resource" was generated by the CustomGenerator...
      const taskResource = this.eventServerService.newResource('Tasks');
      taskResource.setFieldsFromParms(resource.asParms);

      return this.modalDialogService.modifyResourceModal('TaskSchedulerModalComponent', 'Tasks', taskResource);

    } catch (ex) {
      const msg = 'ClosingProService.scheduleTask()';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  addReminder(resource: WResource): Observable<WEvent> {
    try {
      const res = resource.asParms;

      const temp: any = {};
      temp.contractID = res.contractID;
      temp.contractType = res.contractType;
      temp.parentTaskID = res.parentTaskID;
      temp.sequence = res.sequence;
      temp.taskRole = res.taskRole;
      temp.taskPriority = res.taskPriority;
      temp.clientRole = res.clientRole;
      temp.taskDescription = 'ad hoc reminder/follow-up task';
      temp.taskType = 'notification';
      temp.taskName = 'Reminder for ' + res.taskName;
      temp.subsequentTask = true;
      temp.subsequentTaskID = res.taskID;
      temp.subsequentTaskLeadTime = resource.subsequentTaskLeadTime.default;
      temp.subsequentTaskLeadTimeUnit = resource.subsequentTaskLeadTimeUnit.default;

      // we create a proper "Task" resource to pass to the modal...
      const reminderResource = this.eventServerService.newResource('Tasks');
      reminderResource.setFieldsFromParms(temp);
      reminderResource.markAllFieldsAsChanged();
      reminderResource.subsequentTaskLeadTime.required = true;
      reminderResource.subsequentTaskLeadTimeUnit.required = true;

      return this.modalDialogService.addResourceModal('TaskReminderModalComponent', 'Tasks', reminderResource);

    } catch (ex) {
      const msg = 'ClosingProService.addReminder()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }

  }

  addReminderToTaskTemplate(resource: WResource): Observable<WEvent> {
    // console.log('ClosingProService.addReminderToTaskTemplate() - Got here!');

    try {
      const res = resource.asParms;

      const temp: any = {};
      temp.taskTemplateGroupID = res.taskTemplateGroupID;
      temp.contractType = res.contractType;
      temp.parentTaskTemplateID = res.parentTaskTemplateID;
      temp.sequence = res.sequence;
      temp.taskRole = res.taskRole;
      temp.clientRole = res.clientRole;
      temp.taskDescription = 'ad hoc reminder/follow-up task';
      temp.taskType = 'notification';
      temp.taskName = 'Reminder for ' + res.taskName;
      temp.subsequentTask = true;
      temp.subsequentTaskTemplateID = res.taskTemplateID;
      temp.subsequentTaskLeadTime = resource.subsequentTaskLeadTime.default;
      temp.subsequentTaskLeadTimeUnit = resource.subsequentTaskLeadTimeUnit.default;

      // we create a proper "TaskTemplate" resource to pass to the modal...
      const reminderResource = this.eventServerService.newResource('TaskTemplates');
      reminderResource.setFieldsFromParms(temp);
      reminderResource.markAllFieldsAsChanged();
      reminderResource.subsequentTaskLeadTime.required = true;
      reminderResource.subsequentTaskLeadTimeUnit.required = true;

      // console.log('ClosingProService.addReminderToTaskTemplate()', reminderResource);

      return this.modalDialogService.addResourceModal('TaskTemplatesReminderModalComponent', 'TaskTemplates', reminderResource);

    } catch (ex) {
      const msg = 'ClosingProService.addReminderToTaskTemplate()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }

  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // This method works with the output of the "Next Incomplete" and "Open Hot List" SQL queries, as well as the
  // AssignedTasks.getJustAssignedTasks() event, alls of which provide information on primary, secondary and tertiary
  // positioning of the Task within the TaskList hierarchy for the given contract...
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  openTaskListToProperPanel(task: WResource): void {
    try {
        const taskID = Number(task.taskID.value);
        const contractID = task.contractID.value;

        // these are some of the "extra" fields returned by the specialized SQL queries...
        if (task.taskID1) {
          const taskID1 = task.taskID1.value;
          const taskID2 = task.taskID2.value;
          const taskID3 = task.taskID3.value;

          // console.log('ClosingProService.openTaskListToProperPanel()',
          //             'contractID: ' + contractID + ', taskID: ' + taskID + ', taskID1: ' + taskID1 + ', taskID2: ' + taskID2 + ', taskID3: ' + taskID3,
          //             '(typeof taskID): ' + (typeof taskID),
          //             '(typeof taskID1): ' + (typeof taskID1),
          //             '(typeof taskID2): ' + (typeof taskID2),
          //             '(typeof taskID3): ' + (typeof taskID3),
          //             '(taskID === taskID3): ' + (taskID === taskID3),
          //             '(taskID === taskID2): ' + (taskID === taskID2)
          //           );

          if (taskID === taskID3) {
            this.setSelectedTasksForPanelsOnTaskListPage(contractID, taskID1, taskID2, taskID3);
          } else if (taskID === taskID2) {
            this.setSelectedTasksForPanelsOnTaskListPage(contractID, taskID1, taskID2, null);
          } else if (taskID === taskID1) {
            this.setSelectedTasksForPanelsOnTaskListPage(contractID, taskID1, null, null);
          } else {
            this.setSelectedTasksForPanelsOnTaskListPage(contractID, null, null, null);
            this.userInterfaceService.logMessage('Error finding selected task in hierarchy: ' + JSON.stringify(task.asParms));
          }

          this.userInterfaceService.loadPage('TaskList');
        }

    } catch (ex) {
      const msg = 'ClosingProService.openTaskListToProperPanel()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  ///////////////////////
  // ICS file stuff...
  ///////////////////////

  downloadICS(ehName: string, companyFieldName?: string): void {
    // console.log('ClosingProService.downloadICS(' + ehName + ')');
    try {
      const resource = this.userInterfaceService.getSelectedResource(ehName);

      const now = new Date();
      const dateString = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();

      const parms: any = {};
      parms.icsCalendarName = resource.keyField.displayValue + ' ' + dateString;
      parms.relatedResourceType = resource.getType();

      //  let dateAndTimeLib = new DateAndTimeLib();
      // parms.startDate = dateAndTimeLib.getTopOfMonth(new Date());
      // parms.endDate = new Date('2016/12/31').toJSON();

      // The next companyFieldName determines if this gets ALL the calendar entries for the whole company or for a single person...

      if (companyFieldName) {
        parms.relatedResourceFilterFields = companyFieldName;
        parms.relatedResourceFilterValues = resource[parms.relatedResourceFilterFields].value;
        parms.icsCalendarName = parms.relatedResourceFilterValues + ' ' + dateString;
      } else {
        parms.relatedResourceID = resource.keyField.value;
      }

      this.eventServerService.downloadFile('CustomGenerator', 'getCalendar', parms);

    } catch (ex) {
      const msg = 'ClosingProService.downloadICS(' + ehName + ')\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  ///////////////////////
  // VCF file stuff...
  ///////////////////////

  downloadVCF(ehName: string, subType?: string): void {
    // console.log('ClosingProService.downloadVCF(' + ehName + ', ' + subType + ')');
    try {
      const resource = this.userInterfaceService.getSelectedResource(ehName);

      const parms = resource.keyField.asParm;
      parms.resourceType = resource.getType();
      parms.fileName = resource.keyField.displayValue;

      // now check to see if we want the vcf for a worker-bee at the Lender's office...
      if (subType) {
        delete parms.relatedResourceID;
        parms.subType = subType;
        parms.fileName = resource.getField(parms.subType).value;
      }

      this.eventServerService.downloadFile(ehName, 'generateVCF', parms);

    } catch (ex) {
      const msg = 'ClosingProService.downloadVCF(' + ehName + ', ' + subType + ')\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  //////////////////////////////////////////////
  // People De-Duper tagging stuff...
  //////////////////////////////////////////////

  isTaggedAsDuplicate(resource: WResource, noteFieldName: string): boolean {
    const noteFragment = 'Potential duplicate!\n';
    return (resource[noteFieldName].isPopulated ? resource[noteFieldName].value : '').indexOf(noteFragment) > -1;
  }

  tagAsDuplicate(resource: WResource, noteFieldName: string, deDuperPage: string): void {
    try {
      const noteFragment = 'Potential duplicate!\n';
      const newNote = noteFragment + (resource[noteFieldName].isPopulated ? resource[noteFieldName].value : '');

      const parms = resource.keyField.asParm;
      parms[noteFieldName] = newNote;

      const eh = this.eventServerService.getEventHandlerForResourceType(resource.getType());
      this.eventServerService.fireEvent(eh, 'modify', parms).subscribe(
        (event: WEvent) => {
          if (event.status !== 'OK') {
            throw new Error('Unable to tag ' + noteFieldName + ' with "' + noteFragment + '"');
          }
          this.userInterfaceService.reloadSelectedResourceFromServer(eh);
          this.userInterfaceService.loadPage(deDuperPage);
        }
      );

    } catch (ex) {
      const msg = 'ClosingProService.tagAsDuplicate() - ' + resource.keyField.displayValue + ' ' + noteFieldName + ' ' + deDuperPage;
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  unTagAsDuplicate(resource: WResource, noteFieldName: string): void {
    try {
      const noteFragment = 'Potential duplicate!\n';
      const newNote = (resource[noteFieldName].isPopulated ? resource[noteFieldName].value : '').replace(noteFragment, '');

      const parms = resource.keyField.asParm;
      parms[noteFieldName] = newNote;

      const eh = this.eventServerService.getEventHandlerForResourceType(resource.getType());
      this.eventServerService.fireEvent(eh, 'modify', parms).subscribe(
        (event: WEvent) => {
          if (event.status !== 'OK') {
            throw new Error('Unable to un-tag ' + noteFieldName + ' with "' + noteFragment + '"');
          }
          this.userInterfaceService.reloadSelectedResourceFromServer(eh);
        }
      );

    } catch (ex) {
      const msg = 'ClosingProService.unTagAsDuplicate() - ' + resource.keyField.displayValue + ' ' + noteFieldName;
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

/*
  scrubTimeZoneInMailToUrl() {
    try {
      let href = $(this).attr('href');

      // console.log('href: ' + href);
      // wackadoo.lib.appendDiv('.footer','<p>'+href+'</p>');

      // Example date string in encoded mailto url...
      // e.g. 1974-08-04T22%3A28%3A43.288Z
      let patt = new RegExp('\\d{4}-\\d{2}-\\d{2}T\\d{2}%3A\\d{2}%3A\\d{2}.\\d{3}Z', 'g');
      let res = patt.exec(href);

      // console.log('res: ' + JSON.stringify(res));

      if (res) {
        let newHref = href;
        for(let idx in res) {
          let dstr = res[idx];
          // console.log('dstr: ' + JSON.stringify(dstr));
          if (isNaN(dstr)) {
            let tempDstr = dstr.replace(/%3A/g,':');
            // Example DE-coded date string...
            // e.g. 1974-08-04T22:28:43.288Z
            // console.log('tempDstr: ' + tempDstr);
            let d = new Date(tempDstr);
            let newDstr = d.toLocaleDateString();
            let newTstr = '';
            // Remember that "no timestamp" Dates are set to dead midnight UTC...
            if (tempDstr.indexOf('T00:00:00.000Z') < 0) {
              newTstr = ' at ' + d.toLocaleTimeString();
            }
            newHref = newHref.replace(dstr,newDstr + newTstr);
          }
        }
        // console.log('newHref: ' + newHref);
        $(this).attr('href',newHref);
      }

    } catch (ex) {
      let msg = 'ClosingProService.scrubTimeZoneInMailToUrl()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

*/

}
