<td class="italic right white-bg" *ngIf="resource">
  {{ idx + 1 }}
</td>
<td class="border-left center">
  {{formatDateTime(resource.listingDate.value)}}
  {{formatDateTime(resource.closingDate.value)}}
</td>
<td class="border-left">
  {{ resource.contractName.value.replace(resource.contractAddress.value, '').replace( ' - ', '') }}
</td>
<td class="border-left center link">
  <span wackadooPageLoad [eventHandler]="(resource.contractStatus.value === 'active' ? 'ActiveContracts' : 'Contracts')" action="search" [parms]="{contractID: resource.contractID.value}">
    {{ resource.contractAddress.value }}
  </span>
</td>
<td class="border-left center">
  <span *ngIf="resource.agentID.value != -1">
    <span class="link" 
      wackadooPageLoad eventHandler="Agents" action="search" [parms]="{agentID: resource.agentID.value}"
    >
      {{ (resource.agentFirstName.value === null ? '' : resource.agentFirstName.value) }}
      {{ (resource.agentLastName.value === null ? '' : resource.agentLastName.value) }}
    </span> 
  </span> 
</td>
<td class="border-left center link">
  {{ resource.agency.value }}
</td>
<td class="border-left center">
  {{resource.contractStatus.value}}
</td>
<td class="border-left center">
  <div class="marginTopQuarterEm d-flex justify-content-center">
    <button type="button" title="Generate Invoice" data-abbrev="" 
            class="btn btn-secondary actionButton smallish" 
            [ngClass]="{darkgreen: !invoiceAlreadyGenerated, gray: invoiceAlreadyGenerated}"
            (click)="generateInvoice()"
    >
      <span class="fas fa-file-invoice"></span>
    </button>
  </div>
</td>
