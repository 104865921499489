<div class="card">
    <div class="card-header">
        {{ title }}
    </div>
    <div class="card-body scrollable-y">
        <div wackadooMaximizeContentHeight [maxHeightThingBelowSelector]="maxHeightThingBelowSelector" [maxHeightFudgeFactor]="maxHeightFudgeFactor">
            <div *wackadooResourceBlock="let r of []; let idx = index; eventHandler: this.eventHandler; action: this.action; parms: this.parms; defaultText: this.defaultText; debug: this.debug; nullOrMatchCriteria: this.selectionCriteria">
                <div class="rollover" [ngClass]="{selected: resourceMatchesSelectionCriteria(r)}" (click)="toggleSelection(r)">
                    <wackadoo-field [f]="r.keyField" mode="read"></wackadoo-field>
                </div>
            </div>
        </div>
    </div>
</div>
