import { Component, OnInit, Input } from '@angular/core';
import { ClosingProService } from '../../closing-pro.service';
import { ResourceComponent } from 'src/app/client-core/data/resource/resource.component';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { EventServerService } from 'src/app/client-core/services/event-server.service';

@Component({
  selector: 'wackadoo-task-name-with-icon',
  templateUrl: './task-name-with-icon.component.html',
})
export class TaskNameWithIconComponent extends ResourceComponent implements OnInit {

  @Input() size = 25;

  constructor(
    public closingProService: ClosingProService,
    userInterfaceService: UserInterfaceService,
    eventServerService: EventServerService,
  ) {
    super(userInterfaceService, eventServerService);
  }

  ngOnInit(): void {
  }

  get iconClassNames(): string {
    return this.closingProService.getTaskIconClassNames(this.resource.taskType.value, (this.resource.taskComplete ? this.resource.taskComplete.value : false));
  }

}
