<div *ngIf="taskTemplateGroupID" wackadooResourceDragOver [resourceType]="'TaskTemplate'">
    <div>
        <div [ngClass]="{'d-flex':(screenType !== 'phone')}">
            <div class="d-flex">
                <div class="no-wrap">template group:</div>
                <div class="marginLeftHalfEm"><wackadoo-field [f]="taskTemplateGroupID" mode="write" [onChange]="onTaskTemplateGroupChange"></wackadoo-field></div>
            </div>
            <div class="d-flex" [ngClass]="{marginLeftWholeEm:(screenType !== 'phone')}">
                <div class="btn-group marginLeftHalfEm">
                    <button type="button" class="btn btn-secondary small padding3" (click)="addTaskTemplateGroup()"><span class="fas fa-plus" aria-hidden="true"></span> Add</button>
                    <button type="button" class="btn btn-secondary small padding3" (click)="modifyTaskTemplateGroup()"><span class="fas fa-pencil-alt" aria-hidden="true"></span> Re-Name</button>
                    <button type="button" class="btn btn-secondary small padding3" (click)="copyTaskTemplateGroup()"><span class="fas fa-copy" aria-hidden="true"></span> Copy</button>
                    <button type="button" class="btn btn-secondary small padding3" (click)="deleteTaskTemplateGroup()"><span class="fas fa-trash" aria-hidden="true"></span> Delete</button>
                    <button type="button" class="btn btn-secondary small padding3" (click)="printTaskTemplateGroup()"><span class="fas fa-print" aria-hidden="true"></span> Print</button>
                    <button type="button" class="btn btn-secondary small padding3" (click)="exportTaskTemplateGroup()"><span class="fas fa-file-export" aria-hidden="true"></span> Export</button>
                </div>
                <div class="btn-group marginLeftWholeEm" *ngIf="user.isAdminOrAbove">
                    <button type="button" class="btn btn-secondary small padding3" (click)="reload()"><span class="fas fa-refresh" aria-hidden="true"></span> Re-Load Defaults</button>
                </div>
            </div>
        </div>
        <div class="d-flex marginTopQuarterEm">
            <div class="">Select a contract type:</div>
            <div class="marginLeftHalfEm"><wackadoo-field-select [f]="contractType" [mode]="'write'" [onChange]="onContractTypeChange"></wackadoo-field-select></div>
        </div>
    </div>
    <div *ngIf="(screenType !== 'phone')">
        <div class="row smallish marginTopHalfEm">
            <div class="col-sm-2 right bold">Drag-n-Drop:</div>
            <div class="col-sm-6">
                <wackadoo-field-select [f]="dragNDropMode" [mode]="'write'"></wackadoo-field-select> 
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <wackadoo-task-templates-panel 
                    [title]="'Top-Level Tasks'" 
                    [taskTemplateGroupID]="taskTemplateGroupID.value"
                    [contractType]="contractType.value"
                    [parentTaskTemplate]="null"
                    [selectTaskTemplateSubject]="primaryTaskTemplateSelectionSubject"
                    [dragNDropMode]="dragNDropMode.value"
                    [notifyParentSubject]="null"
                    [childListenerSubject]="taskTemplateAddedSecondaryToPrimarySubject"
                ></wackadoo-task-templates-panel>
            </div>
            <div class="col-sm-4">
                <wackadoo-task-templates-panel 
                    [title]="'Mid-Level Tasks'" 
                    [taskTemplateGroupID]="taskTemplateGroupID.value"
                    [contractType]="contractType.value"
                    [parentTaskTemplate]="primaryPanelSelectedTaskTemplate"
                    [selectTaskTemplateSubject]="secondaryTaskTemplateSelectionSubject"
                    [dragNDropMode]="dragNDropMode.value"
                    [notifyParentSubject]="taskTemplateAddedSecondaryToPrimarySubject"
                    [childListenerSubject]="taskTemplateAddedTertiaryToSecondarySubject"
                ></wackadoo-task-templates-panel>
            </div>
            <div class="col-sm-4">
                <wackadoo-task-templates-panel 
                    [title]="'Low-Level Tasks'" 
                    [taskTemplateGroupID]="taskTemplateGroupID.value"
                    [contractType]="contractType.value"
                    [parentTaskTemplate]="secondaryPanelSelectedTaskTemplate"
                    [selectTaskTemplateSubject]="tertiaryTaskTemplateSelectionSubject"
                    [dragNDropMode]="dragNDropMode.value"
                    [notifyParentSubject]="taskTemplateAddedTertiaryToSecondarySubject"
                    [childListenerSubject]="null"
                ></wackadoo-task-templates-panel>
            </div>
        </div>
    </div>
    <div *ngIf="(screenType === 'phone')">
        <nav class="nav nav-tabs smallish">
            <a class="nav-link" (click)="showPanel(1)" [ngClass]="{active:(activePhonePanel === 1)}">Top-Level</a>
            <a class="nav-link" (click)="showPanel(2)" [ngClass]="{active:(activePhonePanel === 2)}" *ngIf="primaryPanelSelectedTaskTemplate">Mid-Level</a>
            <a class="nav-link" (click)="showPanel(3)" [ngClass]="{active:(activePhonePanel === 3)}" *ngIf="secondaryPanelSelectedTaskTemplate">Low-Level</a>
        </nav>
        <div *ngIf="activePhonePanel === 1">
            <wackadoo-task-templates-panel 
                [title]="'Top-Level Tasks'" 
                [taskTemplateGroupID]="taskTemplateGroupID.value"
                [contractType]="contractType.value"
                [parentTaskTemplate]="null"
                [selectTaskTemplateSubject]="primaryTaskTemplateSelectionSubject"
                [dragNDropMode]="dragNDropMode.value"
                [notifyParentSubject]="null"
                [childListenerSubject]="taskTemplateAddedSecondaryToPrimarySubject"
            ></wackadoo-task-templates-panel>
        </div>
        <div *ngIf="activePhonePanel === 2">
            <wackadoo-task-templates-panel 
                [title]="'Mid-Level Tasks'" 
                [taskTemplateGroupID]="taskTemplateGroupID.value"
                [contractType]="contractType.value"
                [parentTaskTemplate]="primaryPanelSelectedTaskTemplate"
                [selectTaskTemplateSubject]="secondaryTaskTemplateSelectionSubject"
                [dragNDropMode]="dragNDropMode.value"
                [notifyParentSubject]="taskTemplateAddedSecondaryToPrimarySubject"
                [childListenerSubject]="taskTemplateAddedTertiaryToSecondarySubject"
            ></wackadoo-task-templates-panel>
        </div>
        <div *ngIf="activePhonePanel === 3">
            <wackadoo-task-templates-panel 
                [title]="'Low-Level Tasks'" 
                [taskTemplateGroupID]="taskTemplateGroupID.value"
                [contractType]="contractType.value"
                [parentTaskTemplate]="secondaryPanelSelectedTaskTemplate"
                [selectTaskTemplateSubject]="tertiaryTaskTemplateSelectionSubject"
                [dragNDropMode]="dragNDropMode.value"
                [notifyParentSubject]="taskTemplateAddedTertiaryToSecondarySubject"
                [childListenerSubject]="null"
            ></wackadoo-task-templates-panel>
        </div>
    </div>
</div>
