import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ClientCoreRoutingModule } from 'src/app/client-core/client-core-routing.module';
import { CheckForResRepComponent } from 'src/app/client-core/pages/check-for-res-rep/check-for-res-rep.component';

import { InBoxComponent } from './pages/in-box/in-box.component';
import { TaskListComponent } from './pages/task-list/task-list.component';
import { VendorActivityComponent } from './pages/vendor-activity/vendor-activity.component';
import { BusinessDashboardComponent } from './pages/business-dashboard/business-dashboard.component';
import { PizzaTrackerComponent } from './pages/pizza-tracker/pizza-tracker.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { ContractsComponent } from './pages/contracts/contracts.component';
import { AgentsComponent } from './pages/agents/agents.component';
import { ActiveContractsComponent } from './pages/active-contracts/active-contracts.component';
import { MyContractsComponent } from './pages/my-contracts/my-contracts.component';
import { MyTaskListComponent } from './pages/my-task-list/my-task-list.component';
import { TaskTemplatesComponent } from './pages/task-templates/task-templates.component';
import { StaffMembersComponent } from './pages/staff-members/staff-members.component';
import { CoOpAgentsComponent } from './pages/co-op-agents/co-op-agents.component';
import { EscrowAgentsComponent } from './pages/escrow-agents/escrow-agents.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { AttorneysComponent } from './pages/attorneys/attorneys.component';
import { LendersComponent } from './pages/lenders/lenders.component';
import { VendorsComponent } from './pages/vendors/vendors.component';
import { TodaysHotListComponent } from './pages/todays-hot-list/todays-hot-list.component';
import { NotesComponent } from './pages/notes/notes.component';
import { DuplicateClientsComponent } from './pages/merge-duplicate-people/duplicate-clients.component';
import { DuplicateAttorneysComponent } from './pages/merge-duplicate-people/duplicate-attorneys.component';
import { DuplicateLendersComponent } from './pages/merge-duplicate-people/duplicate-lenders.component';
import { DuplicateCoOpAgentsComponent } from './pages/merge-duplicate-people/duplicate-co-op-agents.component';
import { AccountMetaDataComponent } from './pages/account-meta-data/account-meta-data.component';
import { NewContractsComponent } from './pages/quick-view/new-contracts/new-contracts.component';
import { InitialFundsComponent } from './pages/quick-view/initial-funds/initial-funds.component';
import { UpcomingClosingsComponent } from './pages/quick-view/upcoming-closings/upcoming-closings.component';
import { RecentClosingsComponent } from './pages/quick-view/recent-closings/recent-closings.component';

///////////////////////////////////////
// NOW set the routes...
///////////////////////////////////////

const appRoutes: Routes = [
  // loadChildren w/import() is the lazy-loading mechanism...
  // Make sure your import statements are clean!

  { path: 'InBox', component: InBoxComponent, pathMatch: 'full' },
  { path: 'TaskList', component: TaskListComponent, pathMatch: 'full' },
  { path: 'VendorActivity', component: VendorActivityComponent, pathMatch: 'full' },
  { path: 'BusinessDashboard', component: BusinessDashboardComponent, pathMatch: 'full' },
  { path: 'VendorActivity', component: VendorActivityComponent, pathMatch: 'full' },
  { path: 'PizzaTracker', component: PizzaTrackerComponent, pathMatch: 'full' },
  { path: 'Calendar', component: CalendarComponent, pathMatch: 'full' },
  { path: 'TaskList', component: TaskListComponent, pathMatch: 'full' },
  { path: 'Contracts', component: ContractsComponent, pathMatch: 'full' },
  { path: 'Agents', component: AgentsComponent, pathMatch: 'full' },
  { path: 'ActiveContracts', component: ActiveContractsComponent, pathMatch: 'full' },
  { path: 'MyContracts', component: MyContractsComponent, pathMatch: 'full' },
  { path: 'MyTaskList', component: MyTaskListComponent, pathMatch: 'full' },
  { path: 'TaskTemplates', component: TaskTemplatesComponent, pathMatch: 'full' },
  { path: 'StaffMembers', component: StaffMembersComponent, pathMatch: 'full' },
  { path: 'CoOpAgents', component: CoOpAgentsComponent, pathMatch: 'full' },
  { path: 'EscrowAgents', component: EscrowAgentsComponent, pathMatch: 'full' },
  { path: 'Clients', component: ClientsComponent, pathMatch: 'full' },
  { path: 'Attorneys', component: AttorneysComponent, pathMatch: 'full' },
  { path: 'Lenders', component: LendersComponent, pathMatch: 'full' },
  { path: 'Vendors', component: VendorsComponent, pathMatch: 'full' },
  { path: 'DuplicateClients', component: DuplicateClientsComponent, pathMatch: 'full' },
  { path: 'DuplicateAttorneys', component: DuplicateAttorneysComponent, pathMatch: 'full' },
  { path: 'DuplicateLenders', component: DuplicateLendersComponent, pathMatch: 'full' },
  { path: 'DuplicateCoOpAgents', component: DuplicateCoOpAgentsComponent, pathMatch: 'full' },
  { path: 'Notes', component: NotesComponent, pathMatch: 'full' },
  { path: 'History', component: NotesComponent, pathMatch: 'full' },
  { path: 'AccountMetaData', component: AccountMetaDataComponent, pathMatch: 'full' },
  // WIP Stuff
  { path: 'TodaysHotList', component: TodaysHotListComponent, pathMatch: 'full' },
  { path: 'UpcomingClosings', component: UpcomingClosingsComponent, pathMatch: 'full' },
  { path: 'NewContracts', component: NewContractsComponent, pathMatch: 'full' },
  { path: 'InitialFunds', component: InitialFundsComponent, pathMatch: 'full' },
  { path: 'RecentClosings', component: RecentClosingsComponent, pathMatch: 'full' },

  // ** catch all paths you do NOT know as the LAST item on the list...
  { path: '**', component: CheckForResRepComponent, pathMatch: 'full' },

];


@NgModule({
  declarations: [],
  // forRoot() is used ONLY at the top "root" level... (i.e. in appModule)
  // forChild() is used in "child" route modules...
  imports: [

    ClientCoreRoutingModule,

    RouterModule.forRoot(
      appRoutes
      , {
          onSameUrlNavigation: 'reload',
          relativeLinkResolution: 'legacy'
      }
    ),
   ],
  exports: [
    RouterModule,
  ]
})

export class AppRoutingModule {
}
