<div *ngIf="date && (mode === 'n')">
    <div class="rollover-lightgray-bg paddingQuarterEm border" [ngClass]="{ 'yellow-bg': isCurrentDate && isThisMonth, 'red bold': isToday && isThisMonth }" (click)="navigateToDate()">
        <div class="center" [ngClass]="{ 'italic lightgrey': !isThisMonth, bold: isThisMonth }">
            {{ date.getDate() }}
        </div>
    </div>
</div>
<div *ngIf="date && (mode === 'summary')">
    <div class="rollover-lightgray-bg paddingQuarterEm border" [ngClass]="{ 'yellow-bg': isCurrentDate && isThisMonth, 'red bold': isToday && isThisMonth }" (click)="navigateToDate()" >
        <div class="center border-bottom" [ngClass]="{ 'italic lightgrey': !isThisMonth, bold: isThisMonth }">
            {{ date.getDate() }}
        </div>
        <div class="small scrollable" [ngClass]="{ 'max-height-9-em  min-height-9-em': restrictHeight }">
            <div *ngFor="let ce of calendarEntries; let idx = index;">
                <div class="d-flex border-bottom nowrap no-overflow rollover {{ ce.cssClass }}" (click)="openCalendarEntry(ce)">
                    <div class="marginRightHalfEm">
                        {{ getTimeSlotLabel(ce.start) }}
                    </div>
                    <div>
                        <div>
                            {{ ce.line1 }}
                        </div>
                        <div *ngIf="ce.line2 && (ce.line2 !== '')">
                            {{ ce.line2 }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="date && (mode === 'detail')">
    <div class="rollover-lightgray-bg paddingQuarterEm border" [ngClass]="{ 'red bold': isToday && isThisMonth }" (click)="navigateToDate()" >
        <div class="center" [ngClass]="{ 'italic lightgrey': !isThisMonth, bold: isThisMonth }">
            {{ date.getDate() }}
        </div>
        <div class="small scrollable" [ngClass]="{ 'max-height-10-em': restrictHeight }">
            <div *ngFor="let ce of calendarEntries; let idx = index;">
                <div class="d-flex border-top nowrap no-overflow rollover {{ ce.cssClass }}" (click)="openCalendarEntry(ce)">
                    <div class="marginRightHalfEm">
                        {{ getTimeSlotLabel(ce.start) }}
                    </div>
                    <div>
                        <div>
                            {{ ce.line1 }}
                        </div>
                        <div *ngIf="ce.line2 && (ce.line2 !== '')">
                            {{ ce.line2 }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
