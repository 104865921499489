import { Component, OnDestroy, OnInit } from '@angular/core';
import { ResourceComponent } from 'src/app/client-core/data/resource/resource.component';
import { Subject, Subscription } from 'rxjs';
import { WField, WString } from 'src/app/client-core/data/field.model';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { WEvent } from 'src/app/client-core/data/event.model';
import { WResource } from 'src/app/client-core/data/resource.model';
import { Globals } from 'src/app/client-core/services/global.service';

@Component({
  selector: 'wackadoo-vendor-activity-modal',
  templateUrl: './vendor-activity-modal.component.html',
})
export class VendorActivityModalComponent extends ResourceComponent implements OnInit, OnDestroy {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'VendorActivityModalComponent';

  onChangeVendorType: Subject<WField> = null;
  onChangeVendorTypeSubscription: Subscription = null;

  onChangeVendorID: Subject<WField> = null;
  onChangeVendorIDSubscription: Subscription = null;

  constructor(
    userInterfaceService: UserInterfaceService,
    public modalDialogService: ModalDialogService,
    eventServerService: EventServerService,
  ) {
    super(userInterfaceService, eventServerService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    // console.log('VendorActivityModalComponent.ngOnInit()', this.resource);

    this.onChangeVendorType = new Subject<WField>();

    this.onChangeVendorTypeSubscription = this.onChangeVendorType.subscribe(
      (f: WField) => {
        try {

          if (f) {
            this.resource.vendorID.value = Globals.MAGIC_NULL_FKEY_VALUE;
            this.resource.vendorID.changed = true;

            // RELOAD THIS MODAL exactly how we originally loaded it...

            this.modalDialogService.modifyResourceModal('VendorActivityModalComponent', 'Tasks', this.resource).subscribe(
              (event: WEvent) => {
                // console.log('VendorActivityModalComponent.self.closed()', event);
                // if we cancel or properly saved the page...
                if (event && (event.status !== 'OK')) {
                  this.modalDialogService.showAlert('Unable to modify task...\n' + event.message, 'WARNING!').subscribe(
                    () => {
                      this.userInterfaceService.reloadCurrentPage();
                    }
                  );
                } else {
                  this.userInterfaceService.reloadCurrentPage();
                }
              }
            );
          }

        } catch (ex) {
          const msg = 'VendorActivity.changeVendorType()\n';
          this.userInterfaceService.alertUserToException(ex, msg);
        }
      }
    );

    this.onChangeVendorID = new Subject<WField>();

    this.onChangeVendorIDSubscription = this.onChangeVendorID.subscribe(
      (f: WField) => {
        if (f.changed) {
          // now we add the reason for change field...
          const f2 = new WString('reasonForChange', null);
          f2.required = true;
          f2.length = 255;
          this.resource.addExtraField(f2);
        } else {
          delete this.resource.reasonForChange;
        }
      }
    );

  }

  ngOnDestroy(): void {
    // console.log('VendorActivityModalComponent.ngOnDestroy()', this.resource);

    if (this.onChangeVendorTypeSubscription) {
      this.onChangeVendorTypeSubscription.unsubscribe();
    }

    if (this.onChangeVendorIDSubscription) {
      this.onChangeVendorIDSubscription.unsubscribe();
    }

    super.ngOnDestroy();
  }

  addNewVendor(): void {
    // console.log('addNewVendor()');
    try {
      const newVendor = this.eventServerService.newResource('Vendors');
      newVendor.vendorType.value = this.resource.vendorType.value;
      newVendor.vendorType.changed = true;
      newVendor.vendorType.readOnly = true;

      newVendor.vendorType.required = true;
      newVendor.vendorCompany.required = true;
      newVendor.vendorFirstName.required = true;
      newVendor.vendorLastName.required = true;
      newVendor.vendorPhone.required = true;
      newVendor.vendorEmail.required = true;

      // add the new vendor for the current task, and
      // upon OK, re-display this page
      // (we'd like to re-open this modal dialog, but we'd have to
      // look into how to properly do the partial page update...)

      this.modalDialogService.addResourceModal('VendorModalComponent', 'Vendors', newVendor).subscribe(
        (event: WEvent) => {
          if (event && (event.status !== 'OK')) {
            this.modalDialogService.showAlert('Unable to add vendor...\n' + event.message, 'WARNING!').subscribe(
              () => {
                this.userInterfaceService.reloadCurrentPage();
              }
            );
          } else if (event) {
            this.modalDialogService.showAlert('You have created a new Vendor, whether you choose to save your changes to this ' + this.resource.taskType.value + ' task, or not...', 'Note').subscribe(
              () => {
                console.log('post Alert()', event.parameters);
                // assign the new vendor fields to the task...
                // (but don't save the task or anything, because that's handled by the modal that we are in...)
                for (const [name, value] of Object.entries(event.parameters)) {
                  if (this.resource[name] && (this.resource[name] instanceof WField)) {
                    console.log('post Alert() setting', name, value);
                    this.resource[name].value = value;
                    this.resource[name].changed = true;
                  }
                }
                console.log('post Alert()', this.resource);

                // RELOAD THIS MODAL exactly how we originally loaded it...

                this.modalDialogService.modifyResourceModal('VendorActivityModalComponent', 'Tasks', this.resource).subscribe(
                  (event2: WEvent) => {
                    // console.log('VendorActivityModalComponent.self.closed()', event);
                    // if we cancel or properly saved the page...
                    if (event2 && (event2.status !== 'OK')) {
                      this.modalDialogService.showAlert('Unable to modify task...\n' + event2.message, 'WARNING!').subscribe(
                        () => {
                          this.userInterfaceService.reloadCurrentPage();
                        }
                      );
                    } else {
                      this.userInterfaceService.reloadCurrentPage();
                    }
                  }
                );
              }
            );

          }
        }
      );
    } catch (ex) {
      const msg = 'VendorActivity.addNewVendor()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  editCurrentVendor(): void {
    // console.log('editCurrentVendor()');
    try {
      const parms = this.resource.vendorID.asParm;

      // edit the vendor for the current task, and
      // upon OK, re-display this page
      // (we'd like to re-open this modal dialog, but we'd have to
      // look into how to properly do the partial page update...)

      this.eventServerService.loadResourceFromServer('Vendors', parms).subscribe(
        (vendor: WResource) => {

          vendor.vendorType.readOnly = true;

          this.modalDialogService.modifyResourceModal('VendorModalComponent', 'Vendors', vendor).subscribe(
            (event: WEvent) => {
              if (event && (event.status !== 'OK')) {
                this.modalDialogService.showAlert('Unable to modify vendor...\n' + event.message, 'WARNING!');
              } else if (event) {

                // assign the edited vendor fields to the task...
                // (but don't save the task or anything, because that's handled by the modal that we are in...)
                for (const [name, value] of Object.entries(event.parameters)) {
                  if (this.resource[name] && (this.resource[name] instanceof WField)) {
                    this.resource[name].value = value;
                  }
                }

                // RELOAD THIS MODAL exactly how we originally loaded it...

                this.modalDialogService.modifyResourceModal('VendorActivityModalComponent', 'Tasks', this.resource).subscribe(
                  (event2: WEvent) => {
                    // console.log('VendorActivityModalComponent.self.closed()', event);
                    // if we cancel or properly saved the page...
                    if (event2 && (event2.status !== 'OK')) {
                      this.modalDialogService.showAlert('Unable to modify task...\n' + event2.message, 'WARNING!').subscribe(
                        () => {
                          this.userInterfaceService.reloadCurrentPage();
                        }
                      );
                    } else {
                      this.userInterfaceService.reloadCurrentPage();
                    }
                  }
                );
              }
            }
          );
        }
      );

    } catch (ex) {
      const msg = 'VendorActivity.editVendor()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

}
