import { Directive, ElementRef, Renderer2, Input, OnInit, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalDialogService } from '../services/modal-dialog.service';
import { UserInterfaceService } from '../services/user-interface.service';
import { EventServerService } from '../services/event-server.service';
import { WResource } from '../data/resource.model';
import { DragAndDropService } from '../services/drag-and-drop.service';

@Directive({
  selector: '[wackadooResourceDrop]'
})
export class ResourceDropDirective implements OnInit {

  @Input() resourceDropSubject: Subject<WResource> = null;
  @Input() resourceType: string = null;
  @Input() debug = false;

  // this is the "drop" portion of the drag-n-drop mechanism...
  @HostListener('drop', ['$event']) drop = this.handleResourceDrop;
  // @HostListener('mouseup', ['$event']) mouseup = this.handleResourceDrop;

  constructor(
    public elementRef: ElementRef,
    public renderer: Renderer2,
    public dragAndDropService: DragAndDropService,
    public modalDialogService: ModalDialogService,
    public userInterfaceService: UserInterfaceService,
    public eventServerService: EventServerService,
  ) {}

  ngOnInit(): void {
    if (!this.resourceType) {
      this.modalDialogService.showAlert('wackadooResourceDrop - missing resourceType');
    } else {
      const eventHandlerName = this.eventServerService.getEventHandlerForResourceType(this.resourceType);
      if (!eventHandlerName) {
        this.modalDialogService.showAlert('wackadooResourceDrop - missing this.eventHandlerName (bad resourceType: ' + this.resourceType + ')');
      }
    }
    if (!this.resourceDropSubject) {
      this.modalDialogService.showAlert('wackadooResourceDrop - missing resourceDropSubject<WResource>');
    }
  }

  handleResourceDrop(e: any): void {
    try {
      const payload = this.dragAndDropService.payload.getValue();

      if (this.debug) {
        console.log('handleResourceDrop()', e.type, e, payload);
      }

      if (payload) {
        this.resourceDropSubject.next(payload.clone);
        this.dragAndDropService.payload.next(null);
      }

    } catch (ex) {
      this.userInterfaceService.alertUserToException(ex, 'handleResourceDrop()');
    }
  }

}
