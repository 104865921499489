<div wackadooResourceDragOver [resourceType]="'Task'">
    <div *ngIf="(screenType !== 'phone')">
        <div class="row small">
            <div class="col-sm-12 smallish">
                <div class="card contractDetails">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-sm-2">
                                <div class="floatGroup">
                                    <div class="floatLeft">
                                        Status: <wackadoo-field [f]="contract.contractStatus" [mode]="mode"></wackadoo-field>
                                    </div>
                                    <div class="floatRight">
                                        <wackadoo-field [f]="contract.contractType" [mode]="mode"></wackadoo-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4 bold green">
                                <wackadoo-field [f]="contract.contractName" [mode]="mode"></wackadoo-field>
                            </div>
                            <div class="col-sm-2">
                                <wackadoo-field [f]="contract.userID" [mode]="mode"></wackadoo-field>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="card contractDetails">
                                    <div class="card-header floatGroup">
                                        <div class="floatLeft">
                                            Property / Contract Info
                                        </div>
                                        <div class="floatRight">
                                            <wackadoo-field [f]="contract.mlsNumber" [mode]="mode"></wackadoo-field>
                                        </div>
                                        <div class="floatRight marginRightHalfEm">
                                            MLS:
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <wackadoo-contract-header [resource]="contract" [mode]="mode" ></wackadoo-contract-header>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="card">
                                    <div class="card-header">
                                        <div class="row">
                                            <div class="col-sm-4">
                                                Contact Info
                                            </div>
                                            <div class="col-sm-2 italics right paddingRight0">
                                                Agency:
                                            </div>
                                            <div class="col-sm-6 italics paddingLeftHalfEm">
                                                <wackadoo-field [f]="contract.agency" [mode]="mode"></wackadoo-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row bold floatGroup">
                                            <div class="col-sm-12">
                                                Contract Participants
                                            </div>
                                        </div>
                                        <div class="" *wackadooResourceBlock="let cp of []; let idx = index; eventHandler:'ContractParticipants'; action:'search'; parms:{contractID: contract.contractID.value, sortBy: 'modified'};">
                                            <div class="row" >
                                                <div class="col-sm-3 right">
                                                    {{ cp.clientRole.value }}
                                                </div>
                                                <div class="col-sm-9">
                                                    <div *wackadooResourceBlock ="let c of []; let idx = index; eventHandler:'Clients'; action:'search'; parms:{clientID: cp.clientID.value};">
                                                        <div class="row">
                                                            <div class="col-sm-7 link" (click)="goToClientPage(c.clientID.value)">
                                                                {{ c.clientFirstName.value }} {{ c.clientLastName.value }} {{ c.clientCompany.value }}
                                                            </div>
                                                            <div class="col-sm-5 paddingLeftQuarterEm">
                                                                {{ c.clientPhone.value }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row marginTopHalfEm">
                                            <div class="col-sm-12 bold">
                                                Associated Professionals
                                            </div>
                                        </div>
                                        <div class="row marginTopHalfEm">
                                            <div class="col-sm-3 right">
                                                Agent
                                            </div>
                                            <div class="col-sm-5">
                                                <wackadoo-field [f]="contract.agentID" [mode]="mode"></wackadoo-field>
                                            </div>
                                            <div class="col-sm-4">
                                                <wackadoo-field [f]="contract.agentPhone" [mode]="mode"></wackadoo-field>
                                            </div>
                                        </div>
                                        <div class="row marginTopHalfEm" *ngIf="contract.contractType.value !== 'Listing'">
                                            <div class="col-sm-3 right">
                                                Coop Agent
                                            </div>
                                            <div class="col-sm-5">
                                                <wackadoo-field [f]="contract.coOpAgentID" [mode]="mode"></wackadoo-field>
                                            </div>
                                            <div class="col-sm-4">
                                                <wackadoo-field [f]="contract.coOpAgentPhone" [mode]="mode"></wackadoo-field>
                                            </div>
                                        </div>
                                        <div class="row marginTopHalfEm" *ngIf="contract.contractType.value === 'Buyer'">
                                            <div class="col-sm-3 right">
                                                Lender
                                            </div>
                                            <div class="col-sm-5">
                                                <wackadoo-field-foreign-key [f]="contract.lenderID" [mode]="mode" [nullValueLabel]="'NONE - CASH PURCHASE'"></wackadoo-field-foreign-key>
                                            </div>
                                            <div class="col-sm-4">
                                                <wackadoo-field [f]="contract.lenderPhone" [mode]="mode"></wackadoo-field>
                                            </div>
                                        </div>
                                        <div class="row marginTopHalfEm" *ngIf="contract.contractType.value !== 'Listing'">
                                            <div class="col-sm-3 right">
                                                Attorney
                                            </div>
                                            <div class="col-sm-5">
                                                <wackadoo-field [f]="contract.attorneyID" [mode]="mode"></wackadoo-field>
                                            </div>
                                            <div class="col-sm-4">
                                                <wackadoo-field [f]="contract.attorneyPhone" [mode]="mode"></wackadoo-field>
                                            </div>
                                        </div>
                                        <div class="row marginTopHalfEm" *ngIf="contract.contractType.value !== 'Listing'">
                                            <div class="col-sm-3 right">
                                                Escrow Agent
                                            </div>
                                            <div class="col-sm-5">
                                                <wackadoo-field [f]="contract.escrowAgentID" [mode]="mode"></wackadoo-field>
                                            </div>
                                            <div class="col-sm-4">
                                                <wackadoo-field [f]="contract.escrowAgentPhone" [mode]="mode"></wackadoo-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="card">
                                    <div class="card-header floatGroup">
                                        <div class="floatLeft">
                                            Last 5 Notes
                                        </div>
                                        <div class="floatRight">
                                            <div class="btn-group">
                                                <button type="button" title="Add Note" data-abbrev="" class="btn btn-secondary actionButton bold small" (click)="newNote()"><span class="fas fa-pencil" aria-hidden="true"></span></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div *wackadooResourceBlock="let r of []; let idx = index; eventHandler:'Notes'; action:'list'; parms:{contractID: contract.contractID.value, pageSize:5, sortBy:'noteDate',sortDirection:-1, startAt:1};">
                                                    <div class="row border-bottom marginQuarterEm">
                                                        <div class="col-sm-12">
                                                            <div class="floatGroup">
                                                                <div class="floatLeft">
                                                                    {{ r.note.value }}
                                                                </div>
                                                                <div class="floatRight small">
                                                                    {{ r.noteAuthor.value }} / <wackadoo-field [f]="r.noteDate" mode="read"></wackadoo-field>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row smallish">
            <div class="col-sm-2 right bold">Drag-n-Drop:</div>
            <div class="col-sm-6">
                <wackadoo-field-select [f]="dragNDropMode" [mode]="'write'"></wackadoo-field-select> 
            </div>
        </div>

        <div class="row smallish">
            <div class="col-sm-4">
                <wackadoo-task-list-panel 
                    [eventHandler]="firstPanelEventHandler"
                    [action]="firstPanelAction"
                    [title]="'Top-Level Tasks'" 
                    [contract]="contract"
                    [parentTask]="null"
                    [selectTaskSubject]="primaryTaskSelectionSubject"
                    [dragNDropMode]="dragNDropMode.value"
                    [notifyParentSubject]="null"
                    [parentListenerSubject]="null"
                    [notifyChildrenSubject]="taskCompletionPrimaryToSecondarySubject"
                    [childListenerSubject]="taskCompletionSecondaryToPrimarySubject"
                ></wackadoo-task-list-panel>
            </div>
            <div class="col-sm-4">
                <wackadoo-task-list-panel 
                    eventHandler="Tasks"
                    action="listChildrenWithChildCounts"
                    [title]="'Mid-Level Tasks'" 
                    [contract]="contract"
                    [parentTask]="primaryPanelSelectedTask"
                    [selectTaskSubject]="secondaryTaskSelectionSubject"
                    [dragNDropMode]="dragNDropMode.value"
                    [notifyParentSubject]="taskCompletionSecondaryToPrimarySubject"
                    [parentListenerSubject]="taskCompletionPrimaryToSecondarySubject"
                    [notifyChildrenSubject]="taskCompletionSecondaryToTertiarySubject"
                    [childListenerSubject]="taskCompletionTertiaryToSecondarySubject"
                ></wackadoo-task-list-panel>
            </div>
            <div class="col-sm-4">
                <wackadoo-task-list-panel 
                    eventHandler="Tasks"
                    action="listChildrenWithChildCounts"
                    [title]="'Low-Level Tasks'" 
                    [contract]="contract"
                    [parentTask]="secondaryPanelSelectedTask"
                    [selectTaskSubject]="tertiaryTaskSelectionSubject"
                    [dragNDropMode]="dragNDropMode.value"
                    [notifyParentSubject]="taskCompletionTertiaryToSecondarySubject"
                    [parentListenerSubject]="taskCompletionSecondaryToTertiarySubject"
                    [notifyChildrenSubject]="null"
                    [childListenerSubject]="null"
                ></wackadoo-task-list-panel>
            </div>
        </div>
    </div>
    <div *ngIf="(screenType === 'phone')">
        <nav class="nav nav-tabs smallish">
            <a class="nav-link" (click)="showPanel(1)" [ngClass]="{active:(activePhonePanel === 1)}">Top-Level</a>
            <a class="nav-link" (click)="showPanel(2)" [ngClass]="{active:(activePhonePanel === 2)}" *ngIf="primaryPanelSelectedTask">Mid-Level</a>
            <a class="nav-link" (click)="showPanel(3)" [ngClass]="{active:(activePhonePanel === 3)}" *ngIf="secondaryPanelSelectedTask">Low-Level</a>
        </nav>
        <div *ngIf="activePhonePanel === 1">
            <wackadoo-task-list-panel 
                [eventHandler]="firstPanelEventHandler"
                [action]="firstPanelAction"
                [title]="'Top-Level Tasks'" 
                [contract]="contract"
                [parentTask]="null"
                [selectTaskSubject]="primaryTaskSelectionSubject"
                [dragNDropMode]="dragNDropMode.value"
                [notifyParentSubject]="null"
                [parentListenerSubject]="null"
                [notifyChildrenSubject]="taskCompletionPrimaryToSecondarySubject"
                [childListenerSubject]="taskCompletionSecondaryToPrimarySubject"
            ></wackadoo-task-list-panel>
        </div>
        <div *ngIf="activePhonePanel === 2">
            <wackadoo-task-list-panel 
                eventHandler="Tasks"
                action="listChildrenWithChildCounts"
                [title]="'Mid-Level Tasks'" 
                [contract]="contract"
                [parentTask]="primaryPanelSelectedTask"
                [selectTaskSubject]="secondaryTaskSelectionSubject"
                [dragNDropMode]="dragNDropMode.value"
                [notifyParentSubject]="taskCompletionSecondaryToPrimarySubject"
                [parentListenerSubject]="taskCompletionPrimaryToSecondarySubject"
                [notifyChildrenSubject]="taskCompletionSecondaryToTertiarySubject"
                [childListenerSubject]="taskCompletionTertiaryToSecondarySubject"
            ></wackadoo-task-list-panel>
        </div>
        <div *ngIf="activePhonePanel === 3">
            <wackadoo-task-list-panel 
                eventHandler="Tasks"
                action="listChildrenWithChildCounts"
                [title]="'Low-Level Tasks'" 
                [contract]="contract"
                [parentTask]="secondaryPanelSelectedTask"
                [selectTaskSubject]="tertiaryTaskSelectionSubject"
                [dragNDropMode]="dragNDropMode.value"
                [notifyParentSubject]="taskCompletionTertiaryToSecondarySubject"
                [parentListenerSubject]="taskCompletionSecondaryToTertiarySubject"
                [notifyChildrenSubject]="null"
                [childListenerSubject]="null"
            ></wackadoo-task-list-panel>
        </div>
    </div>
</div>
