<div *ngIf="(parentTask || !parentListenerSubject)">
    <div class="card smallish">
        <div class="card-header">
            <div class="d-flex justify-content-around">
                <div *ngIf="(screenType !== 'phone')">
                    {{ title }}
                </div>
                <div class="unbold italic marginLeftHalfEm" *ngIf="parentTask">
                     ( {{ parentTask.taskName.value }} )
                </div>
                <div class="small italic marginLeftHalfEm" *ngIf="!parentTask && user.isMemberOrAbove && (screenType !== 'phone')">
                    <div>
                        Hover mouse to see what click will do.
                    </div>
                    <div *ngIf="dragNDropMode === 'move'">Drag-and-drop a task to MOVE IT AROUND.</div>
                    <div *ngIf="dragNDropMode === 'relative'">Drop a task on another to SCHEDULE IT RELATIVE to the other.</div>
                </div>
                <div class="marginLeftHalfEm nowrap" *ngIf="user.isMemberOrAbove">
                    <button type="button" class="btn btn-secondary smallish padding3" (click)="newTask()"><span class="fas fa-plus" aria-hidden="true"></span> New Task</button>
                </div>
            </div>
        </div>
        <div class="card-body scrollable-y"
            wackadooMaximizeContentHeight
            maxHeightThingBelowSelector=".wackadoo-footer"
            [maxHeightFudgeFactor]="-12"
        >
            <div *wackadooResourceBlock="let r of []; let idx = index; eventHandler:this.eventHandler; action:this.action; parms:getParmsForResourceBlock(); debug:false;">
                <wackadoo-task-list-panel-item 
                    [task]="r"
                    [sequence]="idx+1"
                    [dragNDropMode]="dragNDropMode"
                    [isSelected]="isSelected(r.taskID.value)"
                    [selectTaskSubject]="selectTaskSubject"
                    [notifyParentSubject]="notifyParentSubject"
                    [notifyChildrenSubject]="notifyChildrenSubject"
                    [childListenerSubject]="childListenerSubject"
                    [refreshPanelSubject]="refreshPanelSubject"
                ></wackadoo-task-list-panel-item>
            </div>
        </div>
    </div>
</div>
