import { Component, OnInit } from '@angular/core';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { ResourceComponent } from 'src/app/client-core/data/resource/resource.component';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { Subject } from 'rxjs';
import { WField } from 'src/app/client-core/data/field.model';
import { ClosingProService } from 'projects/closingpro-net/src/app/closing-pro.service';
import { WEvent } from 'src/app/client-core/data/event.model';

@Component({
  selector: 'wackadoo-quick-view-task',
  templateUrl: './quick-view-task.component.html',
})
export class QuickViewTaskComponent extends ResourceComponent implements OnInit {

  public autoSaveResourceOnFieldChange: Subject<WField> = null;

  public displayDetail = false;
  public rows = 1;

  constructor(
    userInterfaceService: UserInterfaceService,
    eventServerService: EventServerService,
    public modalDialogService: ModalDialogService,
    public closingProService: ClosingProService,
  ) {
    super(userInterfaceService, eventServerService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.autoSaveResourceOnFieldChange = this.autoSave(true);
    this.resource.taskPriority.selectLabels = 'H|M|N|L';
    // console.log(this.resource);
  }

  toggleDisplayDetail(e: any): void {
    e.stopPropagation();
    e.preventDefault();
    this.displayDetail = !this.displayDetail;
  }

  toggleNoteHeight(e: any): void {
    e.stopPropagation();
    e.preventDefault();
    if (this.rows === 1) {
      this.rows = 5;
    } else {
      this.rows = 1;
    }
    // console.log('rows: ' + this.rows, 'icon: ' + this.rowIcon);
  }

  get rowIcon(): string {
    return (this.rows === 1 ? 'fas fa-angle-double-down' : 'fas fa-angle-double-up');
  }

  get iconClassNames(): string {
    return this.closingProService.getTaskIconClassNames(this.resource.taskType.value, this.resource.taskComplete.value);
  }

  openTaskModal(e: any): void {
    e.stopPropagation();
    e.preventDefault();

    this.modalDialogService.modifyResourceModal('TaskModalComponent', 'Tasks', this.resource).subscribe(
      (event: WEvent) => {
        // if we cancel or properly saved the page...
        if (event && (event.status !== 'OK')) {
          // console.log('TaskListPanelItemComponent.openTaskModal.closed()', event);
          this.modalDialogService.showAlert('Unable to modify task...\n' + event.message, 'WARNING!').subscribe(
            () => {
              // NOP
            }
          );
        } else {
          // NOP
        }
      }
    );
  }

  openEMailer(e: any): void {
    e.stopPropagation();
    e.preventDefault();

    const parms = this.resource.keyField.asParm;
    parms.contractID = this.resource.contractID.value;
    parms.contractType = this.resource.contractType.value;
    parms.templateName = this.resource.taskEMailTemplate.value;

    // Here, we determine what kind of resource can be in the resourceBlock in the template...
    const matches = this.resource.taskName.value.match(/.*inspection.*|.*estimate.*|.*repair.*|.*survey.*|.*vendor.*/i);
    parms.resourceType = (matches === null ? 'Client' : 'Vendor');

    // EMailer is a modal dialog...
    this.modalDialogService.showEMailer('EMailer', parms);
  }

  openDialer(e: any): void {
    e.stopPropagation();
    e.preventDefault();

    this.modalDialogService.showAlert('The list of names & numbers associated with this task...', 'Coming Soon To A Phone Icon Near You!').subscribe(
      () => {
        // NOP
      }
    );

  }

}
