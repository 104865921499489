<div class="row">
    <div class="col-sm-6">
        <div class="card">
            <div class="card-header floatGroup">
                <div class="floatLeft">
                    Agent
                </div>
                <div class="floatRight">
                    {{ resource.agentID.value }}
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12">
                        <div><wackadoo-field [f]="resource.agentFirstName" [mode]="mode"></wackadoo-field> <wackadoo-field [f]="resource.agentLastName" [mode]="mode"></wackadoo-field></div>
                        <div class="marginTopQuarterEm"><wackadoo-field [f]="resource.agentPhone" [mode]="mode"></wackadoo-field></div>
                    </div>
                </div>
                <div class="row marginTopQuarterEm">
                    <div class="col-sm-6">
                        <div class="italic underline"><wackadoo-field [f]="resource.agentCompany" [mode]="mode"></wackadoo-field></div>
                        <div class="marginTopQuarterEm marginBottomQuarterEm"><wackadoo-field [f]="resource.agentAddress" [mode]="mode"></wackadoo-field></div>
                        <div><wackadoo-field [f]="resource.agentCity" [mode]="mode"></wackadoo-field>, <wackadoo-field [f]="resource.agentState" [mode]="mode" [size]="2"></wackadoo-field> <wackadoo-field [f]="resource.agentPostalCode" [mode]="mode"></wackadoo-field></div>
                        <div><wackadoo-field [f]="resource.agentEmail" [mode]="mode"></wackadoo-field></div>
                    </div>
                    <div class="col-sm-6">
                        <div class="italic underline">Personal</div>
                        <div class="marginTopQuarterEm marginBottomQuarterEm"><wackadoo-field [f]="resource.homeAddress" [mode]="mode"></wackadoo-field></div>
                        <div><wackadoo-field [f]="resource.homeCity" [mode]="mode"></wackadoo-field>, <wackadoo-field [f]="resource.homeState" [mode]="mode" [size]="2"></wackadoo-field> <wackadoo-field [f]="resource.homePostalCode" [mode]="mode"></wackadoo-field></div>
                        <div><wackadoo-field [f]="resource.personalEmail" [mode]="mode"></wackadoo-field></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                Note
            </div>
            <div class="card-body">
                <wackadoo-field [f]="resource.agentNote" [mode]="mode"></wackadoo-field>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                Preferred Professionals
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-4 right">Attorney</div>
                    <div class="col-8"><wackadoo-field [f]="resource.attorneyID" [mode]="mode"></wackadoo-field></div>
                </div>
                <div class="row">
                    <div class="col-4 right">Lender</div>
                    <div class="col-8"><wackadoo-field [f]="resource.lenderID" [mode]="mode"></wackadoo-field></div>
                </div>
                <div class="row">
                    <div class="col-4 right">EscrowAgent</div>
                    <div class="col-8"><wackadoo-field [f]="resource.escrowAgentID" [mode]="mode"></wackadoo-field></div>
                </div>
            </div>
        </div>
        <div class="card" *ngIf="resource.agentID.value">
            <div class="card-header floatGroup">
                <div class="floatLeft">
                    Preferred Vendors
                </div>
                <div class="floatRight">
                    <div class="btn-group">
                        <button 
                            type="button" title="Add New PreferredVendor" data-abbrev="Add" 
                            class="btn btn-secondary actionButton addParticipant small paddingQuarterEm"

                            wackadooPageLoad
                            eventHandler="PreferredVendors"
                            action="add"
                            [parms]="{agentID: resource.agentID.value}"
                    >
                        <span class="fas fa-plus" aria-hidden="true"></span></button>
                    </div>
                    <div class="btn-group">
                        <button
                            type="button" title="Manage PreferredVendors" data-abbrev="Select/Edit" 
                            class="btn btn-secondary actionButton editParticipants small paddingQuarterEm"
                            
                            wackadooPageLoad
                            eventHandler="PreferredVendors"
                            action="search"
                        >
                            <span class="fas fa-list" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <table class="table">
                    <tbody>
                        <tr class="link" 
                            *wackadooResourceBlock="let r of []; let idx = index; eventHandler: 'PreferredVendors'; action: 'search'; parms: {agentID: resource.agentID.value, sortBy:'orderOfPreference'}; defaultText: 'No assigned preferred vendors...'; debug: false;"
                            wackadooPageLoad eventHandler="Vendors" action="search" [parms]="{vendorID: r.vendorID.value}"
                        >
                            <td>
                                <wackadoo-field [f]="r.orderOfPreference" mode="read"></wackadoo-field>
                            </td>
                            <td>
                                <wackadoo-field [f]="r.vendorType" mode="read"></wackadoo-field>
                            </td>
                            <td>
                                <wackadoo-field [f]="r.vendorCompany" mode="read"></wackadoo-field>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="col-sm-6" *ngIf="resource.agentID.value">
        <div>
            <wackadoo-contract-history
                title="Active Contracts"
                cardHeightClassName="maxHeightViewPort25"
                eventHandler="ActiveContracts"
                action="list"
                [parms]="{agentID: resource.agentID.value, pageSize:-1, sortBy:'closingDate', sortDirection:-1}"
                defaultText="No active contracts..."
                [debug]="false"
            ></wackadoo-contract-history>
        </div>
        <div>
            <wackadoo-contract-history
                title="In-Active Contracts"
                cardHeightClassName="maxHeightViewPort40"
                eventHandler="Contracts"
                action="list"
                [parms]="{ agentID: resource.agentID.value, pageSize:-1, sortBy:'closingDate', sortDirection:-1 }"
                [omitCriteria]="{contractStatus: 'active'}"
                defaultText="No contract history..."
                [debug]="false"
            ></wackadoo-contract-history>
        </div>
    </div>
</div>
