import { Component } from '@angular/core';
import { DetailButtonBarComponent } from 'src/app/client-core/pages/repository-page/detail-button-bar/detail-button-bar.component';

@Component({
  selector: 'wackadoo-staff-member-detail-button-bar',
  templateUrl: './staff-member-detail-button-bar.component.html',
})
export class StaffMemberDetailButtonBarComponent extends DetailButtonBarComponent {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'StaffMemberDetailButtonBarComponent';

}
