<div class="card">
    <div class="card-header">
        <div>
            Copying Template "{{resource.taskName.value}}" (and sub-task templates), But With A Different Contract Type
        </div>
        <div class="small italic">
            Note: Clicking OK will copy this as a new "top level" template in this template group, but with a different contractType, AND you will switch to that contractType for this template group.
        </div>
    </div>
    <div class="card-body">
        <div class="d-flex justify-content-center">
            <div class="">
                New Contract Type:
            </div>
            <div class="marginLeftHalfEm">
                <wackadoo-field [f]="resource.contractType" [mode]="'write'"></wackadoo-field>
            </div>
        </div>
    </div>
</div>
