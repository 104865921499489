<div class="d-flex smallish border-bottom marginTopQuarterEm paddingBottomQuarterEm">
    <div class="d-flex middle height-2-em">
        <button type="button" (click)="duplicateTask()" title="Duplicate Task (and sub-Tasks)" class="btn btn-secondary smallish bold margin0 padding3">2x</button>
        <button type="button" (click)="deleteTask()" title="Delete Task (and sub-Tasks)" class="btn btn-secondary smallish margin0 padding3"><span class="fas fa-trash" aria-hidden="true"></span></button>
    </div>
    <div class="marginLeftHalfEm rollover {{ resource.taskPriority.value }}" title="Click to open details..." (click)="showVendorActivityModal()" >
        <div class="underline">
            <wackadoo-task-name-with-icon [resource]="resource"></wackadoo-task-name-with-icon>
        </div>
        <div>
            <div *ngIf="!resource.vendorID.value">
                No vendor has been assigned to this {{ resource.taskType.value }}!
            </div>
            <div *ngIf="resource.vendorID.value">
                <div *wackadooResourceBlock="let r of []; let idx = index; eventHandler: 'Vendors'; action: 'search'; parms: {vendorID: resource.vendorID.value, sortBy: 'vendorType'}; defaultText: 'Vendor not found'; debug: false;">
                    <div class="d-flex">
                        <div>
                            <wackadoo-field [f]="r.vendorCompany" [mode]="mode"></wackadoo-field>
                        </div>
                        <div class="marginLeftWholeEm">
                            <wackadoo-field [f]="resource.taskStartDate" mode="read"></wackadoo-field>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div class="d-flex">
                            <div>
                                <wackadoo-field [f]="r.vendorFirstName" [mode]="mode"></wackadoo-field>
                            </div>
                            <div class="marginLeftHalfEm">
                                <wackadoo-field [f]="r.vendorLastName" [mode]="mode"></wackadoo-field>
                            </div>
                        </div>
                        <div [ngClass]="{'marginLeftWholeEm': (screenType !== 'phone')}">
                            <wackadoo-field [f]="r.vendorPhone" [mode]="mode"></wackadoo-field>
                        </div>
                        <div [ngClass]="{'marginLeftWholeEm': (screenType !== 'phone')}">
                            <wackadoo-field [f]="resource.amount" mode="read"></wackadoo-field>
                        </div>
                    </div>
                    <div>
                        <wackadoo-field [f]="r.vendorEmail" [mode]="mode"></wackadoo-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
