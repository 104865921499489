import { Component } from '@angular/core';
import { ICSVCFDetailButtonBarComponent } from '../../../ui/ics-vcf-detail-button-bar/ics-vcf-detail-button-bar.component';

@Component({
  selector: 'wackadoo-client-detail-button-bar',
  templateUrl: './client-detail-button-bar.component.html',
})
export class ClientDetailButtonBarComponent extends ICSVCFDetailButtonBarComponent {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'ClientDetailButtonBarComponent';

  isTagged(): boolean {
    return this.closingProService.isTaggedAsDuplicate(this.resource, 'clientNote');
  }

  tagAsDuplicate(): void {
    this.closingProService.tagAsDuplicate(this.resource, 'clientNote', 'DuplicateClients');
  }

  unTagAsDuplicate(): void {
    this.closingProService.unTagAsDuplicate(this.resource, 'clientNote');
  }
}
