<div class="card">
    <div class="card-header row">
        <div class="col-sm-1">
            Client
        </div>
        <div class="col-sm-11">
            <wackadoo-field [f]="resource.clientRole" [mode]="mode"></wackadoo-field>
        </div>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-sm-4">
                <div><wackadoo-field [f]="resource.clientFirstName" [mode]="mode"></wackadoo-field> <wackadoo-field [f]="resource.clientLastName" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.clientEmail" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.clientPhone" [mode]="mode"></wackadoo-field></div>
            </div>
            <div class="col-sm-8">
                <div class="small underline italic">Address</div>
                <div><wackadoo-field [f]="resource.clientCompany" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.clientAddress" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.clientCity" [mode]="mode"></wackadoo-field>, <wackadoo-field [f]="resource.clientState" [mode]="mode" [size]="2"></wackadoo-field> <wackadoo-field [f]="resource.clientPostalCode" [mode]="mode"></wackadoo-field></div>
            </div>
        </div>
        <div class="row marginTopWholeEm">
            <div class="col-sm-12">
                <div class="small underline italic">
                    Note
                </div>
                <div>
                    <wackadoo-field [f]="resource.clientNote" [mode]="mode"></wackadoo-field>
                </div>
            </div>
        </div>
    </div>
</div>
