import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { WCalendarEntry } from './calendar-entry.model';
import { UserInterfaceService } from '../../services/user-interface.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  ////////////////////////////////
  // Set up the lookups...
  ////////////////////////////////

  private _daysOfWeek = new Array('Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday');
  private _daysOfWeekShort = new Array('Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat');
  private _daysOfWeekLetter = new Array('S', 'M', 'T', 'W', 'T',  'F', 'S');
  private _monthNames = new Array('January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December');
  private _monthNamesShort = new Array('Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec');

  // named calendars have CalendarEntries, and these 3 Subjects...

  private _currentDateSubjects: { [name: string]: BehaviorSubject<Date> } = {};
  private _calendarEntries: { [name: string]: WCalendarEntry [] } = {};
  private _calendarEntrySubjects: { [name: string]: Subject<void> } = {};
  private _openEntrySubjects: { [name: string]: Subject<WCalendarEntry> } = {};

  constructor(
    public userInterfaceService: UserInterfaceService,
  ) {
  }

  calendarEntriesForDate(name: string, d: Date): WCalendarEntry [] {
    const entries: WCalendarEntry [] = [];
    if (d) {
      for (const entry  of this._calendarEntries[name]) {
        if (this.sameDay(d, entry.start)) {
          entries.push(entry);
        }
      }
    }
    return entries;
  }

  sameDay(d1: Date, d2: Date): boolean {
    const flag = (
                    (d1.getFullYear() === d2.getFullYear())
                    &&
                    (d1.getMonth() === d2.getMonth())
                    &&
                    (d1.getDate() === d2.getDate())
                  )
                ;
    return flag;
  }

  initCalendar(name: string): void {
    this._calendarEntries[name] = [];
    this._calendarEntrySubjects[name] = new Subject<void>();
    this._currentDateSubjects[name] = new BehaviorSubject<Date>(new Date());
    this._openEntrySubjects[name] = new Subject<WCalendarEntry>();

    const d = this.userInterfaceService.getPageState('CalendarService', name + '-currentDate');
    if (d) {
      this.setCurrentDate(name, d as Date);
    }
  }

  destroyCalendar(name: string): void {
    this._openEntrySubjects[name].complete();
    this._currentDateSubjects[name].complete();
    this._calendarEntrySubjects[name].complete();

    delete this._openEntrySubjects[name];
    delete this._currentDateSubjects[name];
    delete this._calendarEntrySubjects[name];

    delete this._calendarEntries[name];
  }

  public setCurrentDate(name: string, d: Date): void {
    this._currentDateSubjects[name].next(d);
    this.userInterfaceService.setPageState('CalendarService', name + '-currentDate', d);
  }

  public getCurrentDate(name: string): Date {
    return this._currentDateSubjects[name].getValue();
  }

  public setCalendarEntries(name: string, calendarEntries: WCalendarEntry []): void {
    this._calendarEntries[name] = calendarEntries;
    this._calendarEntrySubjects[name].next();
  }

  public getCalendarEntrySubject(name: string): Subject<void> {
    return this._calendarEntrySubjects[name];
  }

  public getCurrentDateSubject(name: string): BehaviorSubject<Date> {
    return this._currentDateSubjects[name];
  }

  public getOpenEntrySubject(name: string): Subject<WCalendarEntry> {
    return this._openEntrySubjects[name];
  }

  public isCurrentDate(name: string, d: Date): boolean {
    return this.sameDay(this._currentDateSubjects[name].getValue(), d);
  }

  durationInMinutes(d1: Date, d2: Date): number {
    return Math.abs(Math.round((d2.getTime() - d1.getTime()) / 60000));
  }

  formatMonthYear(dateAtMidnight: Date): string {
      return (this._monthNames[dateAtMidnight.getMonth()] + ' ' + dateAtMidnight.getFullYear());
  }

  formatDateMonthDateYear(dateAtMidnight: Date): string {
      return (this._monthNames[dateAtMidnight.getMonth()] + ' ' + dateAtMidnight.getDate() + ', ' + dateAtMidnight.getFullYear());
  }

  formatDayDateMonthDateYear(dateAtMidnight: Date): string {
      return (this._daysOfWeek[dateAtMidnight.getDay()] + ', ' + this._monthNames[dateAtMidnight.getMonth()] + ' ' + dateAtMidnight.getDate() + ', ' + dateAtMidnight.getFullYear());
  }

  formatLocalTimeStamp(d: Date): string {
      const timestamp = ((d.getHours() % 12) === 0 ? '12' : (d.getHours() % 12)) + ':' + (d.getMinutes() < 10 ? '0' : '') + d.getMinutes() + ' ' + (d.getHours() < 12 ? 'AM' : 'PM');
      return timestamp;
  }

  getMonthName(month: number): string {
    return this._monthNames[month];
  }

  getMonthAbbreviation(month: number): string {
    return this._monthNamesShort[month];
  }

  getDayName(dayOfWeek: number): string {
    return this._daysOfWeek[dayOfWeek];
  }

  getDayAbbreviation(dayOfWeek: number): string {
    return this._daysOfWeekShort[dayOfWeek];
  }

  getDayLetter(dayOfWeek: number): string {
    return this._daysOfWeekLetter[dayOfWeek];
  }

  getDaysInMonth(month: number, year: number): number {
      const daysInMonths = new Array(31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31);
      if (year % 4 === 0 && year !== 1900)
      {
          daysInMonths[1] = 29;
      }
      return(daysInMonths[month]);
  }

  /**
   * @param now If null, then "now".
   * @param timeSlotSizeInMinutes (default 15)
   * @return The Date set for the beginning of the timeslot.
   */
  getTopOfTimeSlot(now?: Date, timeSlotSizeInMinutes?: number): Date {
    now = typeof now !== 'undefined' ? now : new Date();
    timeSlotSizeInMinutes = typeof timeSlotSizeInMinutes !== 'undefined' ? timeSlotSizeInMinutes : 15;

    const year = now.getFullYear();
    const month = now.getMonth();
    const day = now.getDate();
    const hours = now.getHours();
    let minutes = 0;

    while ((minutes + timeSlotSizeInMinutes) <= now.getMinutes()) {
      minutes += timeSlotSizeInMinutes;
    }

    const seconds = 0;
    const milliseconds = 0;

    const then = new Date(year, month, day, hours, minutes, seconds, milliseconds);

    return(then);
  }

  /**
   * @param now If null, then "now".
   * @param nHoursAgo How many hours ago you want the top of the hour for... (default is 0)
   * @return The Date set for the beginning of the hour, "n" hours ago, relative to "now". (now=null, n=0, then start of current hour)
   */
  getTopOfHour(now?: Date, nHoursAgo?: number): Date {
    now = typeof now !== 'undefined' ? now : new Date();
    nHoursAgo = typeof nHoursAgo !== 'undefined' ? nHoursAgo : 0;

    let then = new Date();
    then.setTime(now.getTime() - (1000 * 60 * 60 * nHoursAgo));

    const year = then.getFullYear();
    const month = then.getMonth();
    const day = then.getDate();
    const hours = then.getHours();
    const minutes = 0;
    const seconds = 0;
    const milliseconds = 0;

    then = new Date(year, month, day, hours, minutes, seconds, milliseconds);

    return(then);
  }

  /**
   * @param now If null, then "now".
   * @param nDaysAgo How many days ago you want the top of the day for... (default is 0)
   * @return The Date set for the beginning of the day, "n" days ago, relative to "now". (now=null, n=0, then start of day today)
   */
  getTopOfDay(now?: Date, nDaysAgo?: number): Date {
    now = typeof now !== 'undefined' ? now : new Date();
    nDaysAgo = typeof nDaysAgo !== 'undefined' ? nDaysAgo : 0;

    let then = new Date();
    then.setTime(now.getTime() - (1000 * 60 * 60 * 24 * nDaysAgo));

    const year = then.getFullYear();
    const month = then.getMonth();
    const day = then.getDate();
    const hours = 0;
    const minutes = 0;
    const seconds = 0;
    const milliseconds = 0;

    then = new Date(year, month, day, hours, minutes, seconds, milliseconds);

    return(then);
  }

  /**
   * @param now If null, then "now".
   * @param nWeeksAgo How many weeks ago you want the top of the week for... (default is 0)
   * @return The Date set for the beginning of the week, "n" weeks ago, relative to "now". (now=null, n=0, then start of day, last Sunday)
   */
  getTopOfWeek(now?: Date, nWeeksAgo?: number): Date {
    now = typeof now !== 'undefined' ? now : new Date();
    nWeeksAgo = typeof nWeeksAgo !== 'undefined' ? nWeeksAgo : 0;

    let then = new Date();
    then.setTime(now.getTime() - (1000 * 60 * 60 * 24 * nWeeksAgo * 7));

    // take off the days of the week...
    then.setDate(then.getDate() - then.getDay());

    const year = then.getFullYear();
    const month = then.getMonth();
    const day = then.getDate();
    const hours = 0;
    const minutes = 0;
    const seconds = 0;
    const milliseconds = 0;

    then = new Date(year, month, day, hours, minutes, seconds, milliseconds);

    return(then);
  }

  /**
   * @param now If null, then "now".
   * @param nMonthsAgo How many months ago you want the top of the month for... (default is 0)
   * @return The Date set for the beginning of the month, "n" months ago, relative to "now". (now=null, n=0, then start of day, first of this month)
   */
  getTopOfMonth(now?: Date, nMonthsAgo?: number): Date {
    now = typeof now !== 'undefined' ? now : new Date();
    nMonthsAgo = typeof nMonthsAgo !== 'undefined' ? nMonthsAgo : 0;

    let then = new Date();
    then.setTime(now.getTime());

    let year = then.getFullYear();
    let month = then.getMonth() - nMonthsAgo;
    while (month < 0) {
      month += 12;
      year--;
    }
    while (month >= 12) {
      month -= 12;
      year++;
    }
    const day = 1;
    const hours = 0;
    const minutes = 0;
    const seconds = 0;
    const milliseconds = 0;

    then = new Date(year, month, day, hours, minutes, seconds, milliseconds);

    // console.log('CalendarService.getTopOfMonth()\nnow:' + JSON.stringify(now) + '\nthen:' + JSON.stringify(then) + '\nmonth:' + JSON.stringify(month) + '\nnMonthsAgo:' + JSON.stringify(nMonthsAgo));

    return(then);
  }

}
