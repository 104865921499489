import { Component } from '@angular/core';
import { TaskListComponent } from '../task-list/task-list.component';

@Component({
  selector: 'wackadoo-pizza-tracker',
  templateUrl: './pizza-tracker.component.html',

})
export class PizzaTrackerComponent extends TaskListComponent {

  phoneHeaderOpen = false;

  togglePhoneHeader(): void {
    this.phoneHeaderOpen = !this.phoneHeaderOpen;
  }
}
