import { Component, OnInit } from '@angular/core';
import { RepositoryPageComponent } from 'src/app/client-core/pages/repository-page/repository-page.component';

@Component({
  selector: 'wackadoo-staff-members',
  templateUrl: '../../../../../../src/app/client-core/pages/repository-page/repository-page.component.html',
})
export class StaffMembersComponent extends RepositoryPageComponent implements OnInit {

  ngOnInit(): void {
    this.gridFieldsToShow = ['firstName', 'lastName', 'userName', 'email', 'phone', 'role'];
    this.detailContentComponentClassName = 'StaffMemberDetailContentComponent';
    this.detailButtonBarComponentClassName = 'StaffMemberDetailButtonBarComponent';

    super.ngOnInit();
  }

}
