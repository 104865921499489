import { Component, OnInit } from '@angular/core';
import { ClosingComponent } from '../../upcoming-closings/closing/closing.component';
import { WEvent } from 'src/app/client-core/data/event.model';

@Component({
  selector: '[wackadoo-recent-closing]',
  templateUrl: './recent-closing.component.html',
})
export class RecentClosingComponent extends ClosingComponent implements OnInit {

  invoiceGenerationPrefix = 'Invoice generated: ';

  get invoiceAlreadyGenerated(): boolean {
    return (this.resource.contractNote.value !== null) && (this.resource.contractNote.value.startsWith(this.invoiceGenerationPrefix));
  }

  generateInvoice(): void {
    if (this.invoiceAlreadyGenerated) {
      this.modalDialogService.showConfirm('Do you want to generate another one?', 'The contractNote says you have already generated an invoice.').subscribe(
        (ok: boolean) => {
          if (ok) {
            this.populateInvoiceTemplateAndDownload();
          }
        }
      );
    } else {
      this.populateInvoiceTemplateAndDownload();
    }
  }

  populateInvoiceTemplateAndDownload(): void {

    // generate and export the invoice...

    const parms = {
      reportName: 'Invoice',
      contractID: this.resource.contractID.value,
      applyTemplate: true,
      templateExtension: 'xlsx',
    };
    this.eventServerService.downloadFile('Reports', 'export', parms);

    // Now update the contractNote to indicate that it has been generated
    // (The previous note will be preserved in Notes/History...)

    const parms2 = {
      contractID: this.resource.contractID.value,
      contractNote: (this.invoiceGenerationPrefix + this.utilLibService.formatHumanReadableDateTime(new Date())),
    };
    this.eventServerService.fireEvent('Contracts', 'modify', parms2).subscribe(
      (event: WEvent) => {
        if (event && (event.status === 'OK')) {
          this.userInterfaceService.reloadCurrentPage();
        } else {
          const msg = 'Failed to update contractNote to indicate invoice generation.';
          this.userInterfaceService.alertUserToException(new Error(msg), msg);
        }
      }
    );
  }
}
