<div class="card">
    <div class="card-header floatGroup">
        <div class="floatLeft">
            Contact Information
        </div>
        <div class="floatRight">
            {{ resource.attorneyID.value }}
        </div>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-sm-4">
                <div class="small underline italic">Attorney</div>
                <div><wackadoo-field [f]="resource.attorneyFirstName" [mode]="mode"></wackadoo-field> <wackadoo-field [f]="resource.attorneyLastName" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.attorneyEmail" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.attorneyPhone" [mode]="mode"></wackadoo-field></div>
            </div>
            <div class="col-sm-4">
                <div class="small underline italic">Firm</div>
                <div><wackadoo-field [f]="resource.attorneyCompany" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.attorneyAddress" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.attorneyCity" [mode]="mode"></wackadoo-field>, <wackadoo-field [f]="resource.attorneyState" [mode]="mode" [size]="2"></wackadoo-field> <wackadoo-field [f]="resource.attorneyPostalCode" [mode]="mode"></wackadoo-field></div>
            </div>
            <div class="col-sm-4">
                <div class="small underline italic"><wackadoo-field [f]="resource.attorneyContact1Role" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.attorneyContact1" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.attorneyContact1Email" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.attorneyContact1Phone" [mode]="mode"></wackadoo-field></div>
            </div>
        </div>
        <div class="row marginTopWholeEm">
            <div class="col-sm-4">
                <div class="small underline italic"><wackadoo-field [f]="resource.attorneyContact2Role" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.attorneyContact2" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.attorneyContact2Email" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.attorneyContact2Phone" [mode]="mode"></wackadoo-field></div>
            </div>
            <div class="col-sm-4">
                <div class="small underline italic"><wackadoo-field [f]="resource.attorneyContact3Role" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.attorneyContact3" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.attorneyContact3Email" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.attorneyContact3Phone" [mode]="mode"></wackadoo-field></div>
            </div>
            <div class="col-sm-4">
                <div class="small underline italic"><wackadoo-field [f]="resource.attorneyContact4Role" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.attorneyContact4" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.attorneyContact4Email" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.attorneyContact4Phone" [mode]="mode"></wackadoo-field></div>
            </div>
        </div>
        <div class="row marginTopWholeEm">
            <div class="col-sm-12">
                <div class="small underline italic">
                    Note
                </div>
                <div>
                    <wackadoo-field [f]="resource.attorneyNote" [mode]="mode"></wackadoo-field>
                </div>
            </div>
        </div>
    </div>
</div>
