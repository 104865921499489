import { Component } from '@angular/core';
import { ResourceComponent } from 'src/app/client-core/data/resource/resource.component';

@Component({
  selector: 'wackadoo-task-templates-reminder-modal',
  templateUrl: './task-templates-reminder-modal.component.html',
})
export class TaskTemplatesReminderModalComponent extends ResourceComponent {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'TaskTemplatesReminderModalComponent';

}
