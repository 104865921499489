<div class="row" *ngIf="startOfWeek"  [ngClass]="{marginTopHalfEm: mode !== 'n' }">
    <div class="col-sm-1 marginLeft0 marginRight0 paddingLeft0 paddingRight0">
        <wackadoo-day [date]="getDate(0)" [mode]="mode" [calendarName]="calendarName" [currentMonth]="currentMonth" [restrictHeight]="restrictHeight"></wackadoo-day>
    </div>
    <div class="col-sm-2 marginLeft0 marginRight0 paddingLeft0 paddingRight0">
        <wackadoo-day [date]="getDate(1)" [mode]="mode" [calendarName]="calendarName" [currentMonth]="currentMonth" [restrictHeight]="restrictHeight"></wackadoo-day>
    </div>
    <div class="col-sm-2 marginLeft0 marginRight0 paddingLeft0 paddingRight0">
        <wackadoo-day [date]="getDate(2)" [mode]="mode" [calendarName]="calendarName" [currentMonth]="currentMonth" [restrictHeight]="restrictHeight"></wackadoo-day>
    </div>
    <div class="col-sm-2 marginLeft0 marginRight0 paddingLeft0 paddingRight0">
        <wackadoo-day [date]="getDate(3)" [mode]="mode" [calendarName]="calendarName" [currentMonth]="currentMonth" [restrictHeight]="restrictHeight"></wackadoo-day>
    </div>
    <div class="col-sm-2 marginLeft0 marginRight0 paddingLeft0 paddingRight0">
        <wackadoo-day [date]="getDate(4)" [mode]="mode" [calendarName]="calendarName" [currentMonth]="currentMonth" [restrictHeight]="restrictHeight"></wackadoo-day>
    </div>
    <div class="col-sm-2 marginLeft0 marginRight0 paddingLeft0 paddingRight0">
        <wackadoo-day [date]="getDate(5)" [mode]="mode" [calendarName]="calendarName" [currentMonth]="currentMonth" [restrictHeight]="restrictHeight"></wackadoo-day>
    </div>
    <div class="col-sm-1 marginLeft0 marginRight0 paddingLeft0 paddingRight0">
        <wackadoo-day [date]="getDate(6)" [mode]="mode" [calendarName]="calendarName" [currentMonth]="currentMonth" [restrictHeight]="restrictHeight"></wackadoo-day>
    </div>
</div>
