import { Component, OnInit } from '@angular/core';
import { DuplicateMergerComponent } from 'src/app/client-core/ui/duplicate-merger/duplicate-merger.component';
import { WResource } from 'src/app/client-core/data/resource.model';

@Component({
  selector: 'wackadoo-merge-duplicate-people',
  templateUrl: '../../../../../../src/app/client-core/ui/duplicate-merger/duplicate-merger.component.html',
})
export class MergeDuplicatePeopleComponent extends DuplicateMergerComponent implements OnInit {

  firstNameField: string = null;
  lastNameField: string = null;
  companyField: string = null;

  ngOnInit(): void {
    this.overWriteParent = false;
    super.ngOnInit();
  }

  // For a dupe merger, we have to define the buildQueryFunction on the page for the duplicate-merger plugin to work...
  buildQueryFunction(resource: WResource): string {
    let query = '';
    try {
      // console.log('MergeDuplicatePeopleComponent.buildQueryFunction()', resource);

      if (resource) {
        const parms = resource.asParms;

        query += '(';
        for (const name in parms) {

          // some includes & excludes...

          if ((name === 'id')
              || (name === 'created')
              || (name === 'modified')
              || (name === 'agent')
          ){
            continue;
          }
          if ((name !== this.firstNameField)
              && (name !== this.lastNameField)
              && (name !== this.companyField)
          ){
            continue;
          }

          if ((name === this.companyField) && !resource.getField(name).isPopulated) {
            continue;
          }

          // 'normal' processing...

          let value = parms[name].toLowerCase();
          if (value.trim().length > 0) {
            const firstLetter = value.substring(0, 1);
            if (value.indexOf(' ') >= 0) {
              value = '\'' + value + '\'';
            }
            query += (name + ':' + value);

            // some field boosting...

            if (name  === this.companyField) {
              query += '^6';
            } else if (name  === this.lastNameField) {
              query += '^4';
            } else if (name  === this.firstNameField) {
              query += '^2';
            }

            query += ' || ';
            query += (name + ':' + firstLetter + '*');

            // now back to normal processing...

            query += ' || ';
          }
        }
        // chop off the last ' || '
        query = query.substring(0, query.length - 4);
        query += ')';

        // this NOT term eliminates the "left-hand" resource from being returned as a match in the "right-hand" grid...

        if (parms.hasOwnProperty(resource.keyField.name)) {
          query += ' && -' + resource.keyField.name + ':' + resource.keyField.value;
        }
      } else {
        throw new Error('No resource provided!');
      }

    } catch (ex) {
      const msg = 'MergeDuplicatePeopleComponent.buildQueryFunction()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }

    // console.log('MergeDuplicatePeopleComponent.buildQueryFunction() - ' + query);

    return(query);
  }

}
