<div class="marginTop6 smallish">
    <div class="row">
        <div class="col-sm-6">
            <div class="card">
                <div class="card-header floatGroup">
                    <div class="floatLeft">
                        Lender
                    </div>
                    <div class="floatRight">
                        {{ resource.lenderID.value }}
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-3">Lender</div>
                        <div class="col-sm-3">
                            <wackadoo-field [f]="resource.lenderFirstName" [mode]="mode"></wackadoo-field>
                            <wackadoo-field [f]="resource.lenderLastName" [mode]="mode"></wackadoo-field>
                        </div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.lenderEmail" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.lenderPhone" [mode]="mode"></wackadoo-field></div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">Firm</div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.lenderCompany" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.lenderAddress" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3">
                            <wackadoo-field [f]="resource.lenderCity" [mode]="mode"></wackadoo-field>,
                            <wackadoo-field [f]="resource.lenderState" [mode]="mode" [size]="2"></wackadoo-field>
                            <wackadoo-field [f]="resource.lenderPostalCode" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                    <div class="row" *ngIf="(mode === 'write') || resource.lenderContact1.value">
                        <div class="col-sm-3"><wackadoo-field [f]="resource.lenderContact1Role" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.lenderContact1" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.lenderContact1Email" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.lenderContact1Phone" [mode]="mode"></wackadoo-field></div>
                    </div>
                    <div class="row" *ngIf="(mode === 'write') || resource.lenderContact2.value">
                        <div class="col-sm-3"><wackadoo-field [f]="resource.lenderContact2Role" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.lenderContact2" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.lenderContact2Email" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.lenderContact2Phone" [mode]="mode"></wackadoo-field></div>
                    </div>
                    <div class="row" *ngIf="(mode === 'write') || resource.lenderContact3.value">
                        <div class="col-sm-3"><wackadoo-field [f]="resource.lenderContact3Role" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.lenderContact3" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.lenderContact3Email" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.lenderContact3Phone" [mode]="mode"></wackadoo-field></div>
                    </div>
                    <div class="row" *ngIf="(mode === 'write') || resource.lenderContact4.value">
                        <div class="col-sm-3"><wackadoo-field [f]="resource.lenderContact4Role" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.lenderContact4" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.lenderContact4Email" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.lenderContact4Phone" [mode]="mode"></wackadoo-field></div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">Note</div>
                        <div class="col-sm-9">
                            <wackadoo-field [f]="resource.lenderNote" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-6" *ngIf="resource.lenderID.value">
            <ng-container *ngIf="resource.lenderID.value">
                <wackadoo-contract-history
                    title="Contract History"
                    cardHeightClassName="maxHeightViewPort80"
                    eventHandler="Contracts"
                    action="list"
                    [parms]="{ lenderID: resource.lenderID.value, pageSize:-1, sortBy:'closingDate', sortDirection:-1 }"
                    defaultText="No contract history..."
                    [debug]="false"
                ></wackadoo-contract-history>
            </ng-container>
<!--
            <div class="card">
                <div class="card-header">
                    Open, Related Tasks (Top 25)
                </div>
                <div class="card-body smallish maxHeightViewPort50 scrollable-y">
                    <div *wackadooResourceBlock="let r of []; let idx = index; eventHandler: 'CustomGenerator'; action: 'listRelatedOpenTasksForLender'; parms: {lenderID: resource.lenderID.value, sortBy:'sequence', sortDirection: 1, pageSize: 25 }; defaultText: 'No open tasks...'; debug: false;">
                        <wackadoo-related-task-detail [resource]="r" [mode]="mode"></wackadoo-related-task-detail>
                    </div>
                </div>
            </div>
-->
        </div>
    </div>
</div>
