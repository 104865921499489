<div class="row marginLeft0 marginTopHalfEm" *ngIf="calendarName">
    <div class="col-sm-2"  *ngIf="(screenType !== 'phone')">
        <div class="marginTopWholeEm marginBottomWholeEm d-flex justify-content-around">
            <div>
                Display
                <select class="btn btn-outline-secondary small" name="timePeriod" >
                    <option value="day"   [selected]="timePeriod === 'day'">Day</option>
                    <option value="week"  [selected]="timePeriod === 'week'">Week</option>
                    <option value="month" [selected]="timePeriod === 'month'">Month</option>
                </select>
            </div>
            <div>
                <button (click)="goToToday()" class="btn btn-outline-secondary small">Go To Today!</button>
            </div>
        </div>
        <div class="tiny">
            <wackadoo-calendar-header [currentDate]="topOfLastMonth" mode="mmm" ></wackadoo-calendar-header>
            <wackadoo-month mode="n" [calendarName]="calendarName" [topOfMonth]="topOfLastMonth" ></wackadoo-month>
        </div>
        <div class="small marginTopWholeEm marginBottomWholeEm">
            <wackadoo-calendar-header [currentDate]="currentDate" mode="mmm" ></wackadoo-calendar-header>
            <wackadoo-month mode="n" [calendarName]="calendarName" [topOfMonth]="topOfMonth" ></wackadoo-month>
        </div>
        <div class="tiny">
            <wackadoo-calendar-header [currentDate]="topOfNextMonth" mode="mmm" ></wackadoo-calendar-header>
            <wackadoo-month mode="n" [calendarName]="calendarName" [topOfMonth]="topOfNextMonth" ></wackadoo-month>
        </div>
    </div>
    <div class="" [ngClass]="{'col-sm-10':(screenType !== 'phone'), 'col-sm-12':(screenType === 'phone')}">
        <div class="marginLeftWholeEm marginRightWholeEm">
            <div class="marginBottomHalfEm">
                <wackadoo-calendar-header [currentDate]="currentDate" [mode]="timePeriod" [calendarName]="calendarName" ></wackadoo-calendar-header>
            </div>
            <div *ngIf="timePeriod === 'month'">
                <wackadoo-month mode="summary" [calendarName]="calendarName" [topOfMonth]="topOfMonth" ></wackadoo-month>
            </div>
            <div *ngIf="timePeriod === 'week'">
                <wackadoo-week [startOfWeek]="topOfWeek" mode="summary" [calendarName]="calendarName" [currentMonth]="currentMonth()" [restrictHeight]="false"></wackadoo-week>
            </div>
            <div *ngIf="timePeriod === 'day'">
                <wackadoo-day [date]="currentDate" mode="detail" [calendarName]="calendarName" [currentMonth]="currentMonth()" [restrictHeight]="false"></wackadoo-day>
            </div>
        </div>
    </div>
</div>
