import { Injectable, OnDestroy, ElementRef } from '@angular/core';
import { WResource } from '../data/resource.model';
import { Subscription, BehaviorSubject } from 'rxjs';
import { UserInterfaceService } from './user-interface.service';

@Injectable({
  providedIn: 'root'
})
export class DragAndDropService implements OnDestroy {

  private _payload: WResource = null;

  private _payloadSubscription: Subscription = null;
  public payload: BehaviorSubject<WResource> = null;

  public cursor: string = null;

  constructor(
    public userInterfaceService: UserInterfaceService,
  ) {
    this.payload = new BehaviorSubject<WResource>(this._payload);

    this._payloadSubscription = this.payload.subscribe(
      (resource: WResource) => {
        this._payload = resource;
        this.cursor = null;
      }
    );
  }

  ngOnDestroy(): void {
    if (this._payloadSubscription) {
      this._payloadSubscription.unsubscribe();
    }
  }

}
