<div class="card marginTop6">
  <div class="card-header">
    Account Meta Data
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-sm-6">
        <div class="row italic underline">
          <div class="col-sm-12">
            <div>
              Notification Email Address For Submitted Contracts
            </div>
            <div class="small italic">
              (If nothing is entered here, it defaults to the email address for your account...)
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12"><wackadoo-field [f]="resource.notificationEmail" [mode]="mode"></wackadoo-field></div>
        </div>
      </div>
    </div>
  </div>
</div>
