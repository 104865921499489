<wackadoo-repository-page
    gridButtonBarComponentClassName="ContractGridButtonBarComponent"
    detailContentComponentClassName="ContractDetailContentComponent"
    [gridFieldsToHide]="['contractStatus', 'escrowAgentID', 'mlsNumber', 'userID']"
    detailButtonBarComponentClassName="ContractDetailButtonBarComponent"
></wackadoo-repository-page>
<!--
    
    [gridFieldsToShow]="['mlsNumber', 'contractName', 'contractStatus']"
-->
