import { Component } from '@angular/core';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { DetailButtonBarComponent } from 'src/app/client-core/pages/repository-page/detail-button-bar/detail-button-bar.component';
import { WEvent } from 'src/app/client-core/data/event.model';
import { ClosingProService } from '../../../closing-pro.service';

@Component({
  selector: 'wackadoo-co-op-agents-detail-button-bar',
  templateUrl: './co-op-agents-detail-button-bar.component.html',
})
export class CoOpAgentsDetailButtonBarComponent extends DetailButtonBarComponent {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'CoOpAgentsDetailButtonBarComponent';

  constructor(
    eventServerService: EventServerService,
    userInterfaceService: UserInterfaceService,
    modalDialogService: ModalDialogService,
    public closingProService: ClosingProService,
  ) {
    super(eventServerService, userInterfaceService, modalDialogService);
  }

  createAgent(): void {
    try {
      const resource = this.userInterfaceService.getSelectedResource('CoOpAgents');
      const res = resource.asParms;

      const parms: any = {};
      parms.agentFirstName = res.coOpAgentFirstName;
      parms.agentLastName = res.coOpAgentLastName;
      parms.agentCompany = res.coOpAgentCompany;
      parms.agentAddress = res.coOpAgentAddress;
      parms.agentCity = res.coOpAgentCity;
      parms.agentState = res.coOpAgentState;
      parms.agentPostalCode = res.coOpAgentPostalCode;
      parms.agentCountry = res.coOpAgentCountry;
      parms.agentPhone = res.coOpAgentPhone;
      parms.agentEmail = res.coOpAgentEmail;
      parms.agentNote = 'Copied from Agents list';

      this.eventServerService.fireEvent('Agents', 'add', parms).subscribe(
        (event: WEvent) => {
          try {
            if (event.status === 'OK') {
              this.modalDialogService.showAlert('Added new Agent');
            } else {
              throw new Error(event.message);
            }
          } catch (ex) {
            const msg = 'CoOpAgents.createAgent.callback()\n';
            this.userInterfaceService.alertUserToException(ex, msg);
          }
        }
      );

    } catch (ex) {
      const msg = 'CoOpAgents.createAgent()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  downloadICS(): void {
    this.closingProService.downloadICS(this.eventHandler);
  }

  downloadVCF(subType?: string): void {
    this.closingProService.downloadVCF(this.eventHandler, subType);
  }

  isTagged(): boolean {
    return this.closingProService.isTaggedAsDuplicate(this.resource, 'coOpAgentNote');
  }

  tagAsDuplicate(): void {
    this.closingProService.tagAsDuplicate(this.resource, 'coOpAgentNote', 'DuplicateCoOpAgents');
  }

  unTagAsDuplicate(): void {
    this.closingProService.unTagAsDuplicate(this.resource, 'coOpAgentNote');
  }
}
