import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { WResource } from '../../data/resource.model';
import { MultiSelectListService } from '../../services/multi-select-list.service';
import { Subject } from 'rxjs';
import { EventServerService } from '../../services/event-server.service';
import { WEvent } from '../../data/event.model';
import { Globals } from '../../services/global.service';
import { ModalDialogService } from '../../services/modal-dialog.service';

@Component({
  selector: 'wackadoo-multi-select-list',
  templateUrl: './multi-select-list.component.html',
})
export class MultiSelectListComponent implements OnInit, OnDestroy {

  @Input() name: string = null;
  @Input() selectSubject: Subject<WResource>;
  @Input() unSelectSubject: Subject<WResource>;

  @Input() selectionCriteria: any = {};

  @Input() title = 'Items to Select From';

  // Now the stuff for the inner resource block...
  @Input() eventHandler: string;
  @Input() action: string;
  @Input() parms: any = {};
  @Input() defaultText = '';
  @Input() debug = false;

  @Input() maxHeightThingBelowSelector: string = null;
  @Input() maxHeightFudgeFactor = 0;

  constructor(
    public multiSelectListService: MultiSelectListService,
    public eventServerService: EventServerService,
    public modalDialogService: ModalDialogService,
  ) {
  }

  ngOnInit(): void {
    // console.log('ngOnInit()');

    this.multiSelectListService.createList(this.name, this.selectSubject, this.unSelectSubject);

    // Sometimes ngOnInit gets called more than once during page loading on this component.
    // And because the second ngOnInit starts before the server hit returns, we modified
    // MSLSvc.selectResource() to allow resources get "un-selected/re-selected" properly
    // without error. It does cost a little extra biusy-work, but it IS logically consistent.
    // (Now, we'd LOVE to figure out why they sometimes fire 2x, and we would
    // DEFINITELY like to avoid any extra server hits and Subject notifications...)

    this.modalDialogService.showPleaseWait(true);

    this.eventServerService.fireEvent(this.eventHandler, this.action, this.parms).subscribe(
      (event: WEvent) => {
        this.modalDialogService.showPleaseWait(false);

        if (event.status !== 'OK') {
          throw new Error(event.message);
        }
        for (const r of event.resources) {
          if (this.resourceMatchesSelectionCriteria(r)) {
            this.multiSelectListService.selectResource(this.name, r);
          }
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.multiSelectListService.destroyList(this.name);
  }

  resourceMatchesSelectionCriteria(r: WResource): boolean {
    let allFieldsMatch = true;
    for (const [criteriaName, criteriaValue] of Object.entries(this.selectionCriteria)) {
      allFieldsMatch = allFieldsMatch && (r[criteriaName] && r[criteriaName].value && (r[criteriaName].value === criteriaValue));
    }
    return allFieldsMatch;
  }

  toggleSelection(resource: WResource): void {
    if (this.resourceMatchesSelectionCriteria(resource)) {
      const parms = resource.keyField.asParm;
      for (const [criteriaName] of Object.entries(this.selectionCriteria)) {
        // this is USUALLY an FKEY value... but even if not, -1 is a good "clear" value.
        parms[criteriaName] = Globals.MAGIC_NULL_FKEY_VALUE;
        resource[criteriaName].value = Globals.MAGIC_NULL_FKEY_VALUE;
      }
      // console.log('toggleSelection() - UN-select', this.eventHandler, 'modify', parms, this.selectionCriteria);
      this.eventServerService.fireEvent(this.eventHandler, 'modify', parms).subscribe(
        (event: WEvent) => {
          // console.log('toggleSelection() - UN-select', event);
          if (event.status !== 'OK') {
            throw new Error(event.message);
          }
          this.multiSelectListService.unSelectResource(this.name, resource);
        }
      );
    } else {
      const parms = resource.keyField.asParm;
      for (const [criteriaName, criteriaValue] of Object.entries(this.selectionCriteria)) {
        parms[criteriaName] = criteriaValue;
        resource[criteriaName].value = criteriaValue;
      }
      // console.log('toggleSelection() - select', this.eventHandler, 'modify', parms, this.selectionCriteria);
      this.eventServerService.fireEvent(this.eventHandler, 'modify', parms).subscribe(
        (event: WEvent) => {
          // console.log('toggleSelection() - select', event);
          if (event.status !== 'OK') {
            throw new Error(event.message);
          }
          this.multiSelectListService.selectResource(this.name, resource);
        }
      );
    }
  }

}
