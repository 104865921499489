import { Component, OnInit } from '@angular/core';
import { MergeDuplicatePeopleComponent } from './merge-duplicate-people.component';
import { WEvent } from 'src/app/client-core/data/event.model';

@Component({
  selector: 'wackadoo-duplicate-clients',
  templateUrl: '../../../../../../src/app/client-core/ui/duplicate-merger/duplicate-merger.component.html',
})
export class DuplicateClientsComponent extends MergeDuplicatePeopleComponent implements OnInit {

  ngOnInit(): void {
    this.eventHandler = 'Clients';

    this.firstNameField = 'clientFirstName';
    this.lastNameField = 'clientLastName';
    this.companyField = 'clientCompany';

    this.leftGridTitle = 'Duplicate Clients?';
    this.leftGridFields = ['clientFirstName', 'clientLastName', 'clientCompany'];
    this.leftGridQueryFilter = 'clientNote:dupe OR clientNote:duplicate';

    this.rightGridTitle = 'Similar sounding Clients...';
    this.rightGridFields = ['clientFirstName', 'clientLastName', 'clientCompany'];

    this.childEventHandlersToMergeForeignKeyValues = ['Contracts'];

    super.ngOnInit();
  }

  mergeItems(): void {
    // console.log('DupeClients.mergeItems()');

    const parms: any = {};
    parms.clientID1 = this.leftResource.keyField.value;
    parms.clientID2 = this.rightResource.keyField.value;

    this.eventServerService.fireEvent('ContractParticipants', 'identifyCommonContracts', parms).subscribe(
      (event: WEvent) => {
        try {
          // console.log('DupeClients.mergeItems()', event);

          if (event.status !== 'OK') {
            throw new Error(event.message);
          }
          if (event.resources && (event.resources.length > 0)) {
            const title = 'Unable To Re-Assign Contracts!';
            let msg = 'These clients have ' + event.resources.length + ' common contracts:';
            for (const r of event.resources) {
              msg += '\n - ' + r.contractName.value;
            }
            this.modalDialogService.showAlert(msg, title);
          } else {
            super.mergeItems();
          }
        } catch (ex) {
          const msg = 'Error trying identify common contracts for the selected clients!\n' + ex.message;
          this.userInterfaceService.alertUserToException(ex, msg);
        }
      }
    );
  }

}
