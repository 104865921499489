import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { CalendarService } from '../calendar.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { UtilityLibService } from 'src/app/client-core/services/utility-lib.service';
import { ScreenType } from 'src/app/client-core/services/screen-type.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wackadoo-calendar-header',
  templateUrl: './calendar-header.component.html',
})
export class CalendarHeaderComponent implements OnInit, OnChanges, OnDestroy {

  @Input() calendarName: string = null;
  @Input() currentDate: Date = null;
  @Input() mode: string = null; // month, mmm, week or day

  screenType: ScreenType = null;
  screenTypeSubscription: Subscription = null;

  title = '';

  constructor(
    public calendarService: CalendarService,
    public userInterfaceService: UserInterfaceService,
    public utilityLibService: UtilityLibService,
  ) { }

  ngOnInit(): void {
    this.setTitle();

    this.screenType = this.userInterfaceService.screenType.getValue();
    this.screenTypeSubscription = this.userInterfaceService.screenType.subscribe(
      (screenType: ScreenType) => {
        this.screenType = screenType;
      }
    );

  }

  ngOnDestroy(): void {
    if (this.screenTypeSubscription) {
      this.screenTypeSubscription.unsubscribe();
    }
  }

  ngOnChanges(): void {
    this.setTitle();
  }

  setTitle(): void {
    if (this.mode === 'month') {
        this.title = this.calendarService.formatMonthYear(this.currentDate);
    } else if (this.mode === 'mmm') {
        this.title = this.calendarService.getMonthAbbreviation(this.currentDate.getMonth());
    } else if (this.mode === 'week') {
        const top = this.calendarService.getTopOfWeek(this.currentDate);
        this.title = 'Week of ' + this.calendarService.formatDateMonthDateYear(top);
    } else if (this.mode === 'day') {
      this.title = this.calendarService.formatDayDateMonthDateYear(this.currentDate);
    }
  }

  getDayLetter(dayNum?: number): string {
    dayNum = typeof dayNum !== 'undefined' ? dayNum : this.currentDate.getDay();
    return this.calendarService.getDayLetter(dayNum);
  }

  getDayAbbreviation(dayNum?: number): string {
    dayNum = typeof dayNum !== 'undefined' ? dayNum : this.currentDate.getDay();
    return this.calendarService.getDayAbbreviation(dayNum);
  }

  getDayName(dayNum?: number): string {
    dayNum = typeof dayNum !== 'undefined' ? dayNum : this.currentDate.getDay();
    return this.calendarService.getDayName(dayNum);
  }

  get minorLabel(): string {
    return this.utilityLibService.toTitleCase(this.mode);
  }

  get majorLabel(): string {
    return (this.mode === 'month' ? 'Year' : (this.mode === 'week' ? 'Month' : 'Week'));
  }

  previousMajor(): void {
    // console.log('previousMajor()', this.mode);
    let d = new Date();
    if (this.mode === 'month') {
      d = new Date(this.currentDate.getFullYear() - 1, this.currentDate.getMonth(), this.currentDate.getDate(), this.currentDate.getHours(), this.currentDate.getMinutes());
    } else if (this.mode === 'week') {
      d = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() - 1, this.currentDate.getDate(), this.currentDate.getHours(), this.currentDate.getMinutes());
    } else if (this.mode === 'day') {
      d = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate() - 7, this.currentDate.getHours(), this.currentDate.getMinutes());
    }
    // console.log('previousMajor()', this.mode, d.toJSON());
    this.calendarService.setCurrentDate(this.calendarName, d);
  }

  previousMinor(): void {
    // console.log('previousMinor()', this.mode);
    let d = new Date();
    if (this.mode === 'month') {
      d = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() - 1, this.currentDate.getDate(), this.currentDate.getHours(), this.currentDate.getMinutes());
    } else if (this.mode === 'week') {
      d = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate() - 7, this.currentDate.getHours(), this.currentDate.getMinutes());
    } else if (this.mode === 'day') {
      d = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate() - 1, this.currentDate.getHours(), this.currentDate.getMinutes());
    }
    // console.log('previousMinor()', this.mode, d.toJSON());
    this.calendarService.setCurrentDate(this.calendarName, d);
  }

  nextMinor(): void {
    // console.log('nextMinor()', this.mode);
    let d = new Date();
    if (this.mode === 'month') {
      d = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, this.currentDate.getDate(), this.currentDate.getHours(), this.currentDate.getMinutes());
    } else if (this.mode === 'week') {
      d = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate() + 7, this.currentDate.getHours(), this.currentDate.getMinutes());
    } else if (this.mode === 'day') {
      d = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate() + 1, this.currentDate.getHours(), this.currentDate.getMinutes());
    }
    // console.log('nextMinor()', this.mode, d.toJSON());
    this.calendarService.setCurrentDate(this.calendarName, d);
  }

  nextMajor(): void {
    // console.log('nextMajor()', this.mode);
    let d = new Date();
    if (this.mode === 'month') {
      d = new Date(this.currentDate.getFullYear() + 1, this.currentDate.getMonth(), this.currentDate.getDate(), this.currentDate.getHours(), this.currentDate.getMinutes());
    } else if (this.mode === 'week') {
      d = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, this.currentDate.getDate(), this.currentDate.getHours(), this.currentDate.getMinutes());
    } else if (this.mode === 'day') {
      d = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate() + 7, this.currentDate.getHours(), this.currentDate.getMinutes());
    }
    // console.log('nextMajor()', this.mode, d.toJSON());
    this.calendarService.setCurrentDate(this.calendarName, d);
  }

}
