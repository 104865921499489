import { Component, OnInit } from '@angular/core';
import { DetailButtonBarComponent } from 'src/app/client-core/pages/repository-page/detail-button-bar/detail-button-bar.component';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { WResource } from 'src/app/client-core/data/resource.model';
import { WEvent } from 'src/app/client-core/data/event.model';
import { WString } from 'src/app/client-core/data/field.model';
import { ClosingProService } from '../../../closing-pro.service';
import { FieldMode } from 'src/app/client-core/data/field/field-mode.model';

@Component({
  selector: 'wackadoo-active-contract-detail-button-bar',
  templateUrl: './contract-detail-button-bar.component.html',
})
export class ContractDetailButtonBarComponent extends DetailButtonBarComponent implements OnInit {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'ContractDetailButtonBarComponent';

  constructor(
    eventServerService: EventServerService,
    userInterfaceService: UserInterfaceService,
    modalDialogService: ModalDialogService,
    public closingProService: ClosingProService,
  ) {
    super(eventServerService, userInterfaceService, modalDialogService);
   }

  ngOnInit(): void {
    super.ngOnInit();
  }

  /////////////////////////////
  // Button display functions
  /////////////////////////////

  get showCopyToSellerContractButton(): boolean {
    const flag =  (this.resource.contractType.value === 'Seller')
                  || (this.resource.contractType.value === 'Listing');
    // console.log('showCopyToSellerContractButton()', this.resource.contractType, flag);
    return flag;
  }

  get showCopyToBuyerContractButton(): boolean {
    const flag =  (this.resource.contractType.value === 'Seller')
                  || (this.resource.contractType.value === 'Buyer');
    // console.log('showCopyToBuyerContractButton()', this.resource.contractType, flag);
    return flag;
  }

  ////////////////////
  // Button functions
  ////////////////////

  save(): void {

    if ((this.resource.attorneyID && this.resource.attorneyID.changed)
        || (this.resource.lendorID && this.resource.lendorID.changed)
        || (this.resource.escrowAgentID && this.resource.escrowAgentID.changed)
        || (this.resource.agentID && this.resource.agentID.changed)
        || (this.resource.coOpAgentID && this.resource.coOpAgentID.changed)
    ) {
        const promptMessage = 'Please enter a reason for changing the professional(s) working on this contract.';
        const promptTitle = 'Changing Associated Professional(s)';
        const promptPlaceHolder = '';
        const promptDefaultValue = '';

        this.modalDialogService.showPrompt(promptMessage, promptTitle, promptPlaceHolder, promptDefaultValue).subscribe(
          (reasonForChange: string | boolean) => {
            if (typeof reasonForChange === 'string') {
              if (reasonForChange.trim() !== '') {
                const f = new WString('reasonForChange', reasonForChange);
                f.changed = true;
                this.resource.addExtraField(f);
              }
            }
            this.tweakedVersionOfRegularSave();
          }
        );

    } else {
      this.tweakedVersionOfRegularSave();
    }
  }

  tweakedVersionOfRegularSave(): void {
    // This looks for the key field in the current resource.
    // In short, we do a "modify" if we have a valid key (i.e. it is present, populated and non-null)
    const addMode = !this.resource.keyField.isPopulated;

    // console.log(this.resource, this.resource.asParms, this.resource.getChangedFieldValuesAsParms(false));
    const badField = this.resource.identifyMissingOrInvalidField(addMode);
    if (badField) {
      this.modalDialogService.showAlert('Invalid field value or missing required field: ' + badField.name, 'Error');
    } else {

      // let msg = 'These are the parameters for the ' + (this.eventHandler + '.' + (addMode ? 'add' : 'modify')) + '() event we are about to fire...';
      // console.log(msg, this.resource.getChangedFieldValuesAsParms(!addMode));
      // msg += '\n\n' + JSON.stringify(this.resource.getChangedFieldValuesAsParms(!addMode));
      // this.modalDialogService.showAlert(msg, 'Debugging - Un-Comment The Real Code!');

      // console.log('save()', this.resource, this.eventHandler, (addMode ? 'add' : 'modify'), this.resource.getChangedFieldValuesAsParms(!addMode));

      // do the save here, then reload the page...

      this.modalDialogService.showPleaseWait(true);

      this.eventServerService.fireEvent(
          this.eventHandler,
          (addMode ? 'add' : 'modify'),
          this.resource.getChangedFieldValuesAsParms(!addMode)
        ).subscribe(
          (event: WEvent) => {

            this.modalDialogService.showPleaseWait(false);

            // console.log('save()', event);

            if (event.status === 'OK') {

                // re-set field mode...
                this.userInterfaceService.resourceDetailPageDisplayMode.next(FieldMode.read);

                // HACK ALERT: we tweak the "nextPage" if we're changing an Active and an In-Active Contract...
                let nextPage = this.eventHandler;
                const contractStatus = event.getParameter('contractStatus');
                if (contractStatus) {
                  if ((this.eventHandler === 'ActiveContracts') && (contractStatus !== 'active')) {
                    nextPage = 'Contracts';
                  } else if ((this.eventHandler === 'Contracts') && (contractStatus === 'active')) {
                    nextPage = 'ActiveContracts';
                  }
                }

                // we re-load the resource from the server, and tell the user...

                const parms: any = {};
                parms[this.resource.keyField.name] = event.getParameter(this.resource.keyField.name);

                this.modalDialogService.showPleaseWait(true);

                // remember, resource2 can be null if it's not found in this.eventHandler

                this.eventServerService.loadResourceFromServer(nextPage, parms, false).subscribe(
                  (resource2: WResource) => {
                    this.modalDialogService.showPleaseWait(false);

                    // old way...
                    // this.userInterfaceService.selectResource(this.eventHandler, resource2);
                    // this.userInterfaceService.reloadCurrentPage();

                    // new way...
                    this.userInterfaceService.loadPage(nextPage, null, resource2.keyField.asParm);
                  }
                );

            } else {
              this.modalDialogService.showAlert('The save operation failed!\n\n' + event.message, 'WARNING');
              // console.log('The save operation failed!', event);
            }
          }
        );
    }
  }

  copyToSellerContract(): void {
    const temp = this.resource.clone;
    if (temp.lenderID && (temp.lenderID.value !== null) && (temp.contractType.value === 'Buyer')) {
      temp.lenderID.value = null;
    }
    this.copyContractWithNewContractType(temp, 'Seller');
  }

  copyToBuyerContract(): void {
    this.copyContractWithNewContractType(this.resource, 'Buyer');
  }

  emailLinkToAgent(): void {
    this.closingProService.sendPizzaTrackerEmailToAgent(this.resource);
  }

  /////////////////////
  // Utility functions
  /////////////////////

  copyContractWithNewContractType(resource: WResource, newContractType: string): void {
    try {

      const oldContractType = resource.contractType.value;

      let msg = 'You are about to create a copy this Contract.';
      if (newContractType !== oldContractType) {
        msg += '\n\n' + 'The main difference is that the contractType will be changed from "' + oldContractType + '" to "' + newContractType + '" in the new Contract.';
      }
      msg += '\n\n' + 'This process takes a moment because it assigns the same Clients, ';
      msg += ((oldContractType === 'Listing') && (newContractType === 'Seller')) ? 'copies the Notes, ' : '' ;
      msg += 'and generates a new TaskList.';
      msg += '\n\n' + 'Do you want to proceed?';

      this.modalDialogService.showConfirm(msg, 'Copying Contract').subscribe(
        (flag: boolean) => {
          try {
            if (flag) {
              // console.log('ActiveContracts-detail.postLoad.copyContractWithNewContractType.callback() - Entering...');

              const oldContractID = resource.contractID.value;

              /////////////////////////////////////////////////////////////////////////////////////////
              // copy the contract with the new contractType...
              /////////////////////////////////////////////////////////////////////////////////////////

              const parms = resource.asParms;
              delete parms.contractID;
              parms.contractType = newContractType;

              // clean up the "other/unused" dates that were probably set...
              if (newContractType === 'Listing') {
                delete parms.contractDate;
                delete parms.ddDate;
                delete parms.closingDate;
                delete parms.emdDate;
                delete parms.addEMDDate;
              } else {
                delete parms.listingDate;
              }

              // console.log('ActiveContracts-detail.postLoad.copyContractWithNewContractType.callback1() - eventhandler: ' + thisPage.name + ', action: add, parms: '+JSON.stringify(parms));

              // console.log('ActiveContracts-detail.postLoad.copyContractWithNewContractType.callback() - firing Event w/callback1...');

              this.modalDialogService.showPleaseWait('Copying Contract...');

              this.eventServerService.fireEvent('ActiveContracts', 'add', parms).subscribe(
                (responseEvent: WEvent) => {
                  try {
                    // console.log('ActiveContracts-detail.postLoad.copyContractWithNewContractType.callback1() - Entering...');

                    if (responseEvent.status !== 'OK') {
                      // console.log('ActiveContracts-detail.postLoad.copyContractWithNewContractType.callback1() - eventhandler: ' + thisPage.name + ', action: add, parms: '+JSON.stringify(parms));
                      throw new Error('Contract copy failed! ' + responseEvent.message);
                    }

                    /////////////////////////////////////////////////////////////////////////////////////////
                    // copy the ContractParticipants assigned to the contract...
                    /////////////////////////////////////////////////////////////////////////////////////////

                    const newContractID = responseEvent.getParameter('contractID');

                    // console.log('ActiveContracts-detail.postLoad.copyContractWithNewContractType.callback() - eventhandler: ' + thisPage.name + ', eventAction: '+ eventAction + '\n'+JSON.stringify(parms));
                    const parms2: any = {};
                    parms2.contractID = oldContractID;
                    parms2.pageSize = '-1'; // i.e. get ALL of them...

                    // console.log('ActiveContracts-detail.postLoad.copyContractWithNewContractType.callback1() - firing Event w/callback2...');

                    this.eventServerService.fireEvent('ContractParticipants', 'list', parms2).subscribe(
                      (responseEvent2: WEvent) => {
                        try {
                          // console.log('ActiveContracts-detail.postLoad.copyContractWithNewContractType.callback2() - Entering...');

                          if (responseEvent2.status !== 'OK') {
                            throw new Error('Unable to list ContractParticipants for this Contract! ' + responseEvent2.message);
                          }
                          const resources = responseEvent2.resources;

                          for (const resource2 of resources) {
                            const r = resource2.asParms;

                            const parms3: any = {};
                            parms3.contractID = newContractID;
                            parms3.clientID = r.clientID;
                            parms3.clientRole = r.clientRole;

                            // console.log('ActiveContracts-detail.postLoad.copyContractWithNewContractType.callback2() - firing Event w/callback2b...');

                            this.eventServerService.fireEvent('ContractParticipants', 'add', parms3).subscribe(
                              (responseEvent3: WEvent) => {
                                try {
                                  // console.log('ActiveContracts-detail.postLoad.copyContractWithNewContractType.callback2b() - Entering...');

                                  if (responseEvent3.status !== 'OK') {
                                    throw new Error('Failed to add a ContractParticipants for this Contract! ' + responseEvent3.message);
                                  }
                                } catch (ex) {
                                  const msg3 = 'copyContractWithNewContractType.addContractParticipant()\n';
                                  this.userInterfaceService.alertUserToException(ex, msg3);
                                }
                              }
                            );
                          }

                          /////////////////////////////////////////////////////////////////////////////////////////
                          // generate the new TaskList...
                          // (Happens automatically on the server when you change taskTemplateGroupID...)
                          /////////////////////////////////////////////////////////////////////////////////////////

                          const tempID = resource.taskTemplateGroupID.value;

                          const parms4: any = {};
                          parms4.contractID = newContractID;
                          parms4.contractType = newContractType;
                          parms4.taskTemplateGroupID = tempID;

                          // console.log('ActiveContracts-detail.postLoad.copyContractWithNewContractType.callback2() - firing Event w/callback3...');

                          this.eventServerService.fireEvent('Contracts', 'modify', parms4).subscribe(
                            (responseEvent4: WEvent) => {
                              try {
                                if (responseEvent4.status === 'OK') {

                                  // if this is a Listing being copied, we clone the notes to the new Seller contract...
                                  if ((oldContractType === 'Listing') && (newContractType === 'Seller')) {
                                    this.copyNotesToNewContract(oldContractID, newContractID);
                                  }

                                  // console.log('ActiveContracts-detail.postLoad.copyContractWithNewContractType.callback3() - Entering...');
                                  this.modalDialogService.showPleaseWait(false);

                                  this.modalDialogService.showAlert('You will now be directed to the NEW copy of the contract.', 'Success! Contract Has Been Copied').subscribe(
                                    () => {
                                      try {
                                        const parms5: any = {};
                                        parms5.contractID = newContractID;
                                        // console.log('about to load ActiveContracts', parms5);
                                        this.userInterfaceService.loadPage('ActiveContracts', 'search', parms5);
                                      } catch (ex) {
                                        console.log(ex);
                                      }
                                    }
                                  );


                                } else {
                                  throw new Error('Error modifying the contract! ' + responseEvent4.message);
                                }

                              } catch (ex) {
                                const msg4 = 'copyContractWithNewContractType.modifyContract\n';
                                this.userInterfaceService.alertUserToException(ex, msg4);
                              }
                            }
                          );

                        } catch (ex) {
                          const msg5 = 'copyContractWithNewContractType.callback2()\n';
                          this.userInterfaceService.alertUserToException(ex, msg5);
                        }
                      }
                    );

                  } catch (ex) {
                    const msg6 = 'copyContractWithNewContractType.addContract()\n';
                    this.userInterfaceService.alertUserToException(ex, msg6);
                  }
                }
              );
            }
          } catch (ex) {
            const msg7 = 'copyContractWithNewContractType.callback()\n';
            this.userInterfaceService.alertUserToException(ex, msg7);
          }
        }
      );

    } catch (ex) {
      const msg = 'ActiveContract-detail.copyContractWithNewContractType()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  copyNotesToNewContract(oldContractID: number, newContractID: number): void {

    // console.log('ActiveContracts-detail.postLoad.copyContractWithNewContractType.callback() - eventhandler: ' + thisPage.name + ', eventAction: '+ eventAction + '\n'+JSON.stringify(parms));
    const parms2: any = {};
    parms2.contractID = oldContractID;
    parms2.pageSize = '-1'; // i.e. get ALL of them...

    // console.log('ActiveContracts-detail.postLoad.copyContractWithNewContractType.callback1() - firing Event w/callback2...');

    this.eventServerService.fireEvent('Notes', 'list', parms2).subscribe(
      (responseEvent2: WEvent) => {
        try {
          // console.log('ActiveContracts-detail.postLoad.copyContractWithNewContractType.callback2() - Entering...');

          if (responseEvent2.status !== 'OK') {
            throw new Error('Unable to list Notes for this Contract! ' + responseEvent2.message);
          }
          const resources = responseEvent2.resources;

          for (const resource2 of resources) {
            const parms3: any = resource2.asParms;
            parms3.contractID = newContractID;
            delete parms3.noteID;

            // console.log('copyNotesToNewContract() - firing add Event...');

            this.eventServerService.fireEvent('Notes', 'add', parms3).subscribe(
              (responseEvent3: WEvent) => {
                try {
                  // console.log('copyNotesToNewContract - fired add Event...');

                  if (responseEvent3.status !== 'OK') {
                    throw new Error('Failed adding Notes for this Contract! ' + responseEvent3.message);
                  }
                } catch (ex) {
                  const msg3 = 'copyNotesToNewContract.addNote()\n';
                  this.userInterfaceService.alertUserToException(ex, msg3);
                }
              }
            );
          }
        } catch (ex) {
          const msg6 = 'copyNotesToNewContract.cloneNotes()\n';
          this.userInterfaceService.alertUserToException(ex, msg6);
        }
      }
    );
  }


}
