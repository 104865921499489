//////////////////////////////////////////////////////////////////////////////////////////
// When implementing a Calendar, the get CalendarEntries is required.
// The openCalendarEntry and addCalendarEntry functions are ignored if they are null.
//////////////////////////////////////////////////////////////////////////////////////////

import { WResource } from '../../data/resource.model';

export class WCalendarEntry {

    private _start: Date;
    private _cssClass: string;
    private _line1: string;
    private _line2: string;
    private _resource: WResource;

    constructor(
        start: Date,
        line1: string,
        line2?: string,
        cssClass?: string,
        resource?: WResource
    ) {

        line2 = typeof line2 !== 'undefined' ? line2 : '';
        cssClass = typeof cssClass !== 'undefined' ? cssClass : '';

        ////////////////////////////////
        // set up the internal values...
        ////////////////////////////////

        this._start = start;
        this._line1 = line1;
        this._line2 = line2;
        this._cssClass = cssClass;
        this._resource = resource;
    }

    ////////////////////////////////
    // gets and sets
    ////////////////////////////////

    get start(): Date {
        return (this._start);
    }

    set start(start: Date) {
        this._start = start;
    }

    get cssClass(): string {
        return (this._cssClass);
    }

    set cssClass(cssClass: string) {
        this._cssClass = cssClass;
    }

    get line1(): string {
        return (this._line1);
    }

    set line1(line1: string) {
        this._line1 = line1;
    }

    get line2(): string {
        return (this._line2);
    }

    set line2(line2: string) {
        this._line2 = line2;
    }

    get resource(): WResource {
        return (this._resource);
    }

    set resource(resource: WResource) {
        this._resource = resource;
    }

}
