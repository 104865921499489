import { Component, OnInit, Input } from '@angular/core';
import { WResource } from 'src/app/client-core/data/resource.model';
import { ResourceComponent } from 'src/app/client-core/data/resource/resource.component';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { UtilityLibService } from 'src/app/client-core/services/utility-lib.service';
import { ClosingProService } from '../../../../closing-pro.service';

@Component({
  selector: '[wackadoo-initial-fund]',
  templateUrl: './initial-fund.component.html',
})
export class InitialFundComponent extends ResourceComponent implements OnInit {

  @Input() idx = -1;

  showAll = false;

  constructor(
      userInterfaceService: UserInterfaceService,
      eventServerService: EventServerService,
      public utilLibService: UtilityLibService,
      public closingProService: ClosingProService,
  ) {
    super(userInterfaceService, eventServerService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    // console.log(this.resource);
  }

  toggleRowHeight(e: any): void {
    e.stopPropagation();
    e.preventDefault();
    this.showAll = !this.showAll;
  }

  get rowIcon(): string {
    return  (this.showAll  ? 'fas fa-angle-double-up' : 'fas fa-angle-double-down');
  }

  buildAppointmentTaskQuery(nameTerms: string): string {
    const query = 'taskName:' + nameTerms
                + (this.resource.contractID.value != null ? ' && contractID:' + this.resource.contractID.value : '')
                + ' && taskType:inspection'
                + ' && taskComplete:false'
              ;
    return query;
  }

  buildScheduleAppointmentTaskQuery(parentTaskID: any): string {
    const query = 'parentTaskID:' + parentTaskID
                + ' && taskName:(Schedule && Appointment)'
                + ' && taskComplete:false'
              ;
    return query;
  }

  getQuery(i: number): string {
    const query = this.closingProService.getQueryForInitialFunds(i)
                + (this.resource.contractID.value != null ? ' && contractID:' + this.resource.contractID.value : '')
                + ' && taskComplete:false'
              ;
    return query;
  }

  getQueryName(i: number): string {
    return this.closingProService.getQueryDescriptionForInitialFunds(i);
  }

  formatDate(dateString: string): string {
    if (dateString === null) {
      return '';
    }
    const d = new Date(dateString);
    return this.utilLibService.formatHumanReadableDate(d, false);
  }

  formatDateTime(dateString: string): string {
    if (dateString === null) {
      return '';
    }
    const d = new Date(dateString);
    return this.utilLibService.formatHumanReadableDateTime(d, false);
  }

  openContractTaskList(): void {
    const parms: any = {};
    parms.contractID = this.resource.contractID.value;
    this.eventServerService.loadResourceFromServer('ActiveContracts', parms).subscribe(
      (resource: WResource) => {
        this.userInterfaceService.selectResource('ActiveContracts', resource);
        this.userInterfaceService.loadPage('TaskList');
      }
    );
  }

}
