import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { UserAuthService } from 'src/app/client-core/services/user-auth.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { PageComponent } from 'src/app/client-core/pages/page/page.component';
import { WResource } from 'src/app/client-core/data/resource.model';
import { FieldMode } from 'src/app/client-core/data/field/field-mode.model';
import { Subject, Subscription } from 'rxjs';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { ClosingProService } from '../../closing-pro.service';
import { WSelect, WField } from 'src/app/client-core/data/field.model';

@Component({
  selector: 'wackadoo-task-list',
  templateUrl: './task-list.component.html',
})
export class TaskListComponent extends PageComponent implements OnInit, OnDestroy {

  // these allow TaskList and MyTaskList to use the same component code... (i.e. avoid cut-n-paste duplication...)
  @Input() firstPanelEventHandler = 'Tasks';
  @Input() firstPanelAction = 'listWithChildCounts';

  contract: WResource = null;
  mode =  FieldMode.read;

  // current selected tasks

  primaryPanelSelectedTask: WResource = null;
  secondaryPanelSelectedTask: WResource = null;
  tertiaryPanelSelectedTask: WResource = null;

  // task selection subjects/subscriptions

  primaryTaskSelectionSubscription: Subscription = null;
  primaryTaskSelectionSubject: Subject<WResource> = null;

  secondaryTaskSelectionSubscription: Subscription = null;
  secondaryTaskSelectionSubject: Subject<WResource> = null;

  tertiaryTaskSelectionSubscription: Subscription = null;
  tertiaryTaskSelectionSubject: Subject<WResource> = null;

  // "down" task completion subjects/subscriptions

  taskCompletionPrimaryToSecondarySubject: Subject<WResource> = null;
  taskCompletionSecondaryToTertiarySubject: Subject<WResource> = null;

  // "up" task completion subjects/subscriptions

  taskCompletionSecondaryToPrimarySubject: Subject<WResource> = null;
  taskCompletionTertiaryToSecondarySubject: Subject<WResource> = null;

  // used to control which panel is currently displayed when on a phone...
  activePhonePanel = 1;

  dragNDropMode: WField = null;

  constructor(
    userAuthService: UserAuthService,
    userInterfaceService: UserInterfaceService,
    public eventServerService: EventServerService,
    public closingProService: ClosingProService,
  ) {
    super(userAuthService, userInterfaceService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    // console.log('TaskList.ngOnInit()');

    this.dragNDropMode = new WSelect('dragNDropMode', 'move');
    this.dragNDropMode.select = 'move|relative';
    this.dragNDropMode.selectLabels = 'Change Order|Relate Timing';
    this.dragNDropMode.displayComponent = 'radio';

    // determine which Contract EH we're dealing with...

    this.contract = this.userInterfaceService.getSelectedResource('MyContracts');
    if (!this.contract) {
      this.contract = this.userInterfaceService.getSelectedResource('ActiveContracts');
    }
    if (!this.contract) {
      this.contract = this.userInterfaceService.getSelectedResource('Contracts');
    }

    this.primaryTaskSelectionSubject = new Subject<WResource>();
    this.secondaryTaskSelectionSubject = new Subject<WResource>();
    this.tertiaryTaskSelectionSubject = new Subject<WResource>();

    this.taskCompletionPrimaryToSecondarySubject = new Subject<WResource>();
    this.taskCompletionSecondaryToTertiarySubject = new Subject<WResource>();

    this.taskCompletionSecondaryToPrimarySubject = new Subject<WResource>();
    this.taskCompletionTertiaryToSecondarySubject = new Subject<WResource>();

    // set up the subscriptions for selected tasks...

    this.primaryTaskSelectionSubscription = this.primaryTaskSelectionSubject.subscribe(
      (task: WResource) => {
        // console.log('primaryTaskSelectionSubscription()', (this.primaryPanelSelectedTask ? this.primaryPanelSelectedTask.taskName.value : null), task.taskName.value);
        this.userInterfaceService.setPageState('TaskList', this.contract.contractID.value + '-primaryTaskID', (task ? task.taskID.value : null));
        this.primaryPanelSelectedTask = task;
        this.secondaryTaskSelectionSubject.next(null);
        if (task && (this.screenType === 'phone')) {
          this.activePhonePanel = 2;
        }
        // console.log('primaryTaskSelectionSubject()', task, this.screenType, this.activePhonePanel);
      }
    );

    this.secondaryTaskSelectionSubscription = this.secondaryTaskSelectionSubject.subscribe(
      (task: WResource) => {
        // console.log('secondaryTaskSelectionSubscription()', task.taskName.value);
        this.userInterfaceService.setPageState('TaskList', this.contract.contractID.value + '-secondaryTaskID', (task ? task.taskID.value : null));
        this.secondaryPanelSelectedTask = task;
        this.tertiaryTaskSelectionSubject.next(null);
        if (task && (this.screenType === 'phone')) {
          this.activePhonePanel = 3;
        }
        // console.log('secondaryTaskSelectionSubject()', task, this.screenType, this.activePhonePanel);
      }
    );

    this.tertiaryTaskSelectionSubscription = this.tertiaryTaskSelectionSubject.subscribe(
      (task: WResource) => {
        // console.log('tertiaryTaskSelectionSubscription()', task.taskName.value);
        this.userInterfaceService.setPageState('TaskList', this.contract.contractID.value + '-tertiaryTaskID', (task ? task.taskID.value : null));
        this.tertiaryPanelSelectedTask = task;
        if (task && (this.screenType === 'phone')) {
          this.activePhonePanel = 3;
        }
        // console.log('tertiaryTaskSelectionSubject()', task, this.screenType, this.activePhonePanel);
      }
    );

    // Now, reload page state - cascade them so they fire in the proper order...

    const primaryTaskID = this.userInterfaceService.getPageState('TaskList', this.contract.contractID.value + '-primaryTaskID');
    const secondaryTaskID = this.userInterfaceService.getPageState('TaskList', this.contract.contractID.value + '-secondaryTaskID');
    const tertiaryTaskID = this.userInterfaceService.getPageState('TaskList', this.contract.contractID.value + '-tertiaryTaskID');

    if (primaryTaskID) {
      this.eventServerService.loadResourceFromServer('Tasks', { taskID: primaryTaskID}).subscribe(
        (r1: WResource) => {
          this.primaryPanelSelectedTask = r1;
          this.primaryTaskSelectionSubject.next(r1);

          if (r1 && secondaryTaskID) {
            this.eventServerService.loadResourceFromServer('Tasks', { taskID: secondaryTaskID}).subscribe(
              (r2: WResource) => {
                this.secondaryPanelSelectedTask = r2;
                this.secondaryTaskSelectionSubject.next(r2);

                if (r2 && tertiaryTaskID) {
                  this.eventServerService.loadResourceFromServer('Tasks', { taskID: tertiaryTaskID}).subscribe(
                    (r3: WResource) => {
                      this.tertiaryPanelSelectedTask = r3;
                      this.tertiaryTaskSelectionSubject.next(r3);
                    }
                  );
                }
              }
            );
          }
        }
      );
    }

  }

  ngOnDestroy(): void {

    // console.log('TaskList.ngOnDestroy()');

    if (this.primaryTaskSelectionSubscription) {
        this.primaryTaskSelectionSubscription.unsubscribe();
    }

    if (this.secondaryTaskSelectionSubscription) {
      this.secondaryTaskSelectionSubscription.unsubscribe();
    }

    if (this.tertiaryTaskSelectionSubscription) {
      this.tertiaryTaskSelectionSubscription.unsubscribe();
    }

    super.ngOnDestroy();
  }

  newNote(): void {
    this.closingProService.updateContractNote(this.contract.contractID.value);
  }

  goToClientPage(clientID: number): void {
    this.userInterfaceService.loadPage('Clients', 'search', {clientID});
  }

  showPanel(panelNumber: number): void {
    this.activePhonePanel = panelNumber;
  }

}
