import { Component } from '@angular/core';
import { DetailButtonBarComponent } from 'src/app/client-core/pages/repository-page/detail-button-bar/detail-button-bar.component';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { WEvent } from 'src/app/client-core/data/event.model';
import { ClosingProService } from '../../../closing-pro.service';

@Component({
  selector: 'wackadoo-attorney-detail-button-bar',
  templateUrl: './attorney-detail-button-bar.component.html',
})
export class AttorneyDetailButtonBarComponent extends DetailButtonBarComponent {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'AttorneyDetailButtonBarComponent';

  constructor(
    eventServerService: EventServerService,
    userInterfaceService: UserInterfaceService,
    modalDialogService: ModalDialogService,
    public closingProService: ClosingProService,
  ) {
    super(eventServerService, userInterfaceService, modalDialogService);
  }

  createEscrowAgent(): void {
    try {
      const resource = this.userInterfaceService.getSelectedResource('Attorneys');
      const res = resource.asParms;

      const parms: any = {};
      parms.escrowAgentFirstName = res.attorneyFirstName;
      parms.escrowAgentLastName = res.attorneyLastName;
      parms.escrowAgentCompany = res.attorneyCompany;
      parms.escrowAgentAddress = res.attorneyAddress;
      parms.escrowAgentCity = res.attorneyCity;
      parms.escrowAgentState = res.attorneyState;
      parms.escrowAgentPostalCode = res.attorneyPostalCode;
      parms.escrowAgentCountry = res.attorneyCountry;
      parms.escrowAgentPhone = res.attorneyPhone;
      parms.escrowAgentEmail = res.attorneyEmail;
      parms.escrowAgentNote = 'Copied from Attorneys list';

      this.eventServerService.fireEvent('EscrowAgents', 'add', parms).subscribe(
        (event: WEvent) => {
          try {
            if (event.status === 'OK') {
              this.modalDialogService.showAlert('Added new EscrowAgent');
            } else {
              throw new Error(event.message);
            }
          } catch (ex) {
            const msg = 'Attorneys.createAgent.callback()\n';
            this.userInterfaceService.alertUserToException(ex, msg);
          }
        }
      );

    } catch (ex) {
      const msg = 'Attorneys.createEscrowAgent()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  downloadICS(): void {
    this.closingProService.downloadICS(this.eventHandler);
  }

  downloadVCF(subType?: string): void {
    this.closingProService.downloadVCF(this.eventHandler, subType);
  }

  isTagged(): boolean {
    return this.closingProService.isTaggedAsDuplicate(this.resource, 'attorneyNote');
  }

  tagAsDuplicate(): void {
    this.closingProService.tagAsDuplicate(this.resource, 'attorneyNote', 'DuplicateAttorneys');
  }

  unTagAsDuplicate(): void {
    this.closingProService.unTagAsDuplicate(this.resource, 'attorneyNote');
  }
}
