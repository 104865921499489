import { Component } from '@angular/core';
import { ResourceComponent } from 'src/app/client-core/data/resource/resource.component';

@Component({
  selector: 'wackadoo-task-scheduler-modal',
  templateUrl: './task-scheduler-modal.component.html',
})
export class TaskSchedulerModalComponent extends ResourceComponent {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'TaskSchedulerModalComponent';

}
