import { Component, OnInit } from '@angular/core';
import { UpcomingClosingsComponent } from '../upcoming-closings/upcoming-closings.component';
import { WDate } from 'src/app/client-core/data/field.model';

@Component({
  selector: 'wackadoo-recent-closings',
  templateUrl: './recent-closings.component.html',
})
export class RecentClosingsComponent extends UpcomingClosingsComponent implements OnInit {

  public startDate: WDate = null;
  public endDate: WDate = null;

  ngOnInit(): void {
    super.ngOnInit();

    const d = new Date();
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    const oneWeekAgo = new Date(d.setDate(d.getDate() - 7));

    const now = new Date();

    this.startDate = new WDate('startDate', this.utilLibService.getStartOfWeek(oneWeekAgo).toISOString());
    this.startDate.noTimestamp = true;

    this.endDate = new WDate('endDate', this.utilLibService.getEndOfWeek(now).toISOString());
    this.endDate.noTimestamp = true;
  }

  reloadPage(): void {
    this.userInterfaceService.reloadCurrentPage();
  }

  buildQueryForRecentStuff(dateFieldName: string): string {
    let queryTerm = '';
    if (this.startDate.value && this.endDate.value) {
      const s = this.utilLibService.formatDateForRange(new Date(this.startDate.value));
      const e = this.utilLibService.formatDateForRange(new Date(this.endDate.value));
      queryTerm = dateFieldName + ':[' + s + ' TO ' + e + ']';
    }

    return queryTerm;
  }

}
