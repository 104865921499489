import { Component, OnInit } from '@angular/core';
import { MergeDuplicatePeopleComponent } from './merge-duplicate-people.component';

@Component({
  selector: 'wackadoo-duplicate-co-op-agents',
  templateUrl: '../../../../../../src/app/client-core/ui/duplicate-merger/duplicate-merger.component.html',
})
export class DuplicateCoOpAgentsComponent extends MergeDuplicatePeopleComponent implements OnInit {

  ngOnInit(): void {
    this.eventHandler = 'CoOpAgents';

    this.firstNameField = 'coOpAgentFirstName';
    this.lastNameField = 'coOpAgentLastName';
    this.companyField = 'coOpAgentCompany';

    this.leftGridTitle = 'Duplicate CoOpAgents?';
    this.leftGridFields = ['coOpAgentFirstName', 'coOpAgentLastName', 'coOpAgentCompany'];
    this.leftGridQueryFilter = 'coOpAgentNote:dupe OR coOpAgentNote:duplicate';

    this.rightGridTitle = 'Similar sounding CoOpAgents...';
    this.rightGridFields = ['coOpAgentFirstName', 'coOpAgentLastName', 'coOpAgentCompany'];

    this.childEventHandlersToMergeForeignKeyValues = ['Contracts'];

    super.ngOnInit();
  }

}
