import { Component, OnInit } from '@angular/core';
import { UserAuthService } from 'src/app/client-core/services/user-auth.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { PageComponent } from 'src/app/client-core/pages/page/page.component';
import { WResource } from 'src/app/client-core/data/resource.model';

@Component({
  selector: 'wackadoo-vendor-activity',
  templateUrl: './vendor-activity.component.html',

})
export class VendorActivityComponent extends PageComponent implements OnInit {

  contract: WResource = null;

  isCollapsedMarketing = false;
  isCollapsedInspections = false;
  isCollapsedEstimates = false;
  isCollapsedRepairs = false;
  isCollapsedReInspections = false;

  constructor(
    userAuthService: UserAuthService,
    userInterfaceService: UserInterfaceService,
  ) {
    super(userAuthService, userInterfaceService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.contract = this.userInterfaceService.getSelectedResource('ActiveContracts');

    if (!this.contract) {
      this.contract = this.userInterfaceService.getSelectedResource('Contracts');
    }
    if (!this.contract) {
      this.contract = this.userInterfaceService.getSelectedResource('MyContracts');
    }

    if (!this.contract) {
      throw new Error('Unable to display VendorActivity if no Contract is selected.');
    }

    this.isCollapsedMarketing = (this.screenType === 'phone');
    this.isCollapsedInspections = (this.screenType === 'phone');
    this.isCollapsedEstimates = (this.screenType === 'phone');
    this.isCollapsedRepairs = (this.screenType === 'phone');
    this.isCollapsedReInspections = (this.screenType === 'phone');

  }

  toggleMarketing(): void {
    if (this.screenType === 'phone') {
      this.isCollapsedMarketing = !this.isCollapsedMarketing;
    }
  }

  toggleInspections(): void {
    if (this.screenType === 'phone') {
      this.isCollapsedInspections = !this.isCollapsedInspections;
    }
  }

  toggleEstimates(): void {
    if (this.screenType === 'phone') {
      this.isCollapsedEstimates = !this.isCollapsedEstimates;
    }
  }

  toggleRepairs(): void {
    if (this.screenType === 'phone') {
      this.isCollapsedRepairs = !this.isCollapsedRepairs;
    }
  }

  toggleReInspections(): void {
    if (this.screenType === 'phone') {
      this.isCollapsedReInspections = !this.isCollapsedReInspections;
    }
  }

}
