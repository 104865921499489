import { Component } from '@angular/core';
import { ResourceComponent } from 'src/app/client-core/data/resource/resource.component';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { ClosingProService } from '../../../closing-pro.service';
import { EventServerService } from 'src/app/client-core/services/event-server.service';

@Component({
  selector: 'wackadoo-tasks-relative-modal',
  templateUrl: './task-relative-modal.component.html',
})
export class TaskRelativeModalComponent extends ResourceComponent {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'TaskRelativeModalComponent';

  constructor(
    public closingProService: ClosingProService,
    userInterfaceService: UserInterfaceService,
    eventServerService: EventServerService,
  ) {
    super(userInterfaceService, eventServerService);
  }

  getIconClassNames(taskType: string, taskComplete: boolean): string {
    return this.closingProService.getTaskIconClassNames(taskType, taskComplete);
  }
}
