<div class="border-bottom" title="Expand sub-tasks" (click)="selectTask()"  [ngClass]="{selected:isSelected, 'd-flex justify-content-between':(screenType !== 'phone')}"

        wackadooResourceDragStart
        [resource]="task"

        wackadooResourceDrop
        [resourceType]="task.getType()"
        [resourceDropSubject]="handleResourceDropSubject"
>
    <div class="marginBottom3 d-flex">
        <div class="marginTop2 nowrap" *ngIf="user.isMemberOrAbove">
            <input type="checkbox" title="Click completes the task" class="completed middle marginRightQuarterEm" (click)="clickTaskCheckbox($event)" [checked]="task && task.taskComplete.value"/>
            <div class="btn-group">
                <button type="button" title="View/Edit Task Details" class="btn btn-secondary ultralightgray-bg smallish bold paddingTop2 paddingBottom2 paddingLeftQuarterEm paddingRightQuarterEm marginRight0 " (click)="openTaskModal($event)"><span class="fas fa-window-restore"></span></button>
            </div>
            <div class="btn-group">
                <button type="button" title="Duplicate Task (and sub-Tasks)" class="btn btn-secondary ultralightgray-bg smallish bold paddingTop2 paddingBottom2 paddingLeftQuarterEm paddingRightQuarterEm marginLeft0 marginRight0 " (click)="duplicateTask($event)">2x</button>
            </div>
            <div class="btn-group">
                <button type="button" title="Copy Task (and sub-Tasks) To Different Contract" class="btn btn-secondary ultralightgray-bg smallish bold paddingTop2 paddingBottom2 paddingLeftQuarterEm paddingRightQuarterEm marginLeft0 marginRight0 " (click)="copyToDifferentContract($event)"><span class="fas fa-external-link-alt"></span></button>
            </div>
            <div class="btn-group">
                <button *ngIf="onHotList || task.taskComplete.value"   type="button" class="btn btn-secondary ultralightgray-bg smallish bold paddingTop2 paddingBottom2 paddingLeftQuarterEm paddingRightQuarterEm marginLeft0 marginRight0 " title="Already {{(task.taskComplete.value ? 'Complete' : (onHotList ? 'on HotList' : ''))}}" disabled ><span class="fas fa-tasks"></span></button>
                <button *ngIf="!onHotList && !task.taskComplete.value" type="button" class="btn btn-secondary ultralightgray-bg smallish bold paddingTop2 paddingBottom2 paddingLeftQuarterEm paddingRightQuarterEm marginLeft0 marginRight0 " title="Add to HotList" (click)="scheduleTaskNow($event)"><span class="fas fa-tasks red"></span></button>
            </div>
<!--
            <div class="btn-group">
                <button type="button" title="Add Reminder/Follow-Up" class="btn btn-secondary ultralightgray-bg smallish bold paddingTop2 paddingBottom2 paddingLeftQuarterEm paddingRightQuarterEm marginLeft0 marginRight0 " (click)="addReminder($event)"><span class="fas fa-bell"></span></button>
            </div>
-->
            <div class="btn-group">
                <button type="button" title="Delete Task (and sub-Tasks)" class="btn btn-secondary ultralightgray-bg smallish bold paddingTop2 paddingBottom2 paddingLeftQuarterEm paddingRightQuarterEm marginLeft0 " (click)="deleteTask($event)"><span class="fas fa-trash-alt" aria-hidden="true"></span></button>
            </div>
        </div>
        <div class="d-flex marginLeftHalfEm middle {{ task.taskPriority.value }}">
            <div class="paddingTop2 rollover marginTop2 marginRightQuarterEm">{{ task.sequence.value }}</div>
            <div class="marginQuarterEm paddingTop4 {{ iconClassNames }}" *ngIf="(task.taskType.value !== 'email') || user.isGuestOrBelow"></div>
            <div class="marginQuarterEm paddingTop4 {{ iconClassNames }} rollover" *ngIf="(task.taskType.value === 'email') && user.isMemberOrAbove" title="Click to send email" (click)="openEMailer($event)"></div>
            <div class="paddingTop2 rollover marginTop2">{{ task.taskName.value }}</div>
        </div>
    </div>
    <div class="marginBottom3 d-flex" *ngIf="(task.totalTasks && (task.totalTasks.value > 0))">
        <div class="paddingTopQuarterEm rollover" [ngClass]="{'width-8-em':(screenType !== 'phone'), 'fullWidthLessTwoEm':(screenType === 'phone')}">
            <wackadoo-progress-bar 
                [totalTasks]="task.totalTasks.value" 
                [completedTasks]="(task.completedTasks ? task.completedTasks.value : 0)"
                [extraClass]="(task.taskComplete.value && ((task.completedTasks ? task.completedTasks.value : 0) !== task.totalTasks.value) ? 'red-bg' : '')"
            ></wackadoo-progress-bar>
        </div>
        <div class="width-4-em center rollover">
            <span class="badge" [ngClass]="{selected:isSelected}">{{ task.totalTasks.value }}<span *ngIf="isSelected"> &gt;&gt;</span></span>
        </div>
    </div>
</div>
