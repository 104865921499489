import { Component, OnInit, AfterViewInit } from '@angular/core';
import { RepositoryPageComponent } from 'src/app/client-core/pages/repository-page/repository-page.component';

@Component({
  selector: 'wackadoo-in-box',
  templateUrl: '../../../../../../src/app/client-core/pages/repository-page/repository-page.component.html',
})
export class InBoxComponent extends RepositoryPageComponent implements OnInit, AfterViewInit {

  ngOnInit(): void {

    // hide the hide-able public content in the footer
    this.userInterfaceService.hidePublicFooterContent.next(true);

    this.gridFieldsToShow = [
        'contractType'
        , 'client1LastName'
        // , 'client1Company'
        , 'contractAddress'
        , 'contractDate'
        , 'ddDate'
        , 'closingDate'
        , 'listingDate'
        , 'agentFirstName'
        , 'agentLastName'
        , 'lenderID'
        , 'attorneyID'
        , 'modified'
    ];
    this.detailContentComponentClassName = 'InBoxDetailContentComponent';
    this.detailButtonBarComponentClassName = 'InBoxDetailButtonBarComponent';

    super.ngOnInit();

  }

  ngAfterViewInit(): void {
    // console.log('InBox.ngAfterViewInit()', this.event, this.user, this.selectedResource, this.eventHandler, this.action, this.parameters);

    if (this.user.isPublicOnly && (this.user.accountID === 1)) {
      // we know that InBox is the home page of choice for ALL accounts EXCEPT for
      // "wwww" (i.e. accountID === 1), so we re-direct to the proper home page...
      this.userInterfaceService.loadPage('home');
    } else if (this.user.isPublicOnly && !this.selectedResource) {
      // OK, we're public, but we're NOT "wwww" (i.e. accountID === 1), so we let the user enter a contract...
      this.userInterfaceService.loadPage('InBox', 'add', {});
    } else {
      // we're NOT public, so we just want to look at the items in our InBox...
      super.ngAfterViewInit();
    }
  }

}
