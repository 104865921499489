import { Component, OnInit, ElementRef } from '@angular/core';
import { DetailButtonBarComponent } from 'src/app/client-core/pages/repository-page/detail-button-bar/detail-button-bar.component';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { WEvent } from 'src/app/client-core/data/event.model';
import { WResource } from 'src/app/client-core/data/resource.model';
import { WField } from 'src/app/client-core/data/field.model';
import { FieldMode } from 'src/app/client-core/data/field/field-mode.model';

@Component({
  selector: 'wackadoo-in-box-detail-button-bar',
  templateUrl: './in-box-detail-button-bar.component.html',
})
export class InBoxDetailButtonBarComponent extends DetailButtonBarComponent implements OnInit {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'InBoxDetailButtonBarComponent';

  constructor(
    eventServerService: EventServerService,
    userInterfaceService: UserInterfaceService,
    modalDialogService: ModalDialogService,
    public elementRef: ElementRef,
  ) {
    super(eventServerService, userInterfaceService, modalDialogService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    // We force taskTemplateGroupID to include a "foreignType" lookup
    // but WITHOUT making it an actual "foreignKey"...
    if (this.resource.taskTemplateGroupID) {
      this.userInterfaceService.convertToFakeForeignKeyField(this.resource, 'TaskTemplateGroup', 'taskTemplateGroupID');
    }

  }

  save(): void {
    // console.log('InBox.save()');
    try {
      // This looks for the key field in the current resource.
      // In short, we do a "modify" if we have a valid key (i.e. it is present, populated and non-null)
      const addMode = !this.resource.keyField.isPopulated;

      // do a client-side check to make sure all the required parms are populated...

      let badField = this.resource.identifyMissingOrInvalidField(addMode);

      // If we don't have a key field value, we're doing an "add",
      // in which case, public users MUST have a captchaAnswer value...
      if (addMode && (this.user.isPublicOnly)) {
        badField = this._checkNextMissingField('captchaAnswer', badField);
      }

      if (badField) {

        // console.log('submit()', this.resource, badField);

        this.modalDialogService.showAlert('Invalid field value or missing required field: ' + badField.name, 'Input Error').subscribe(
          () => {
            this.userInterfaceService.resourceDetailPageDisplayMode.next(FieldMode.write);
            setTimeout(
              () => {
                this.userInterfaceService.focusOnField(this.repositoryPageElementRef, badField);
              }
              , 0
            );
          }
        );

      } else {

        // let msg = 'These are the parameters for the ' + (this.eventHandler + '.' + (addMode ? 'add' : 'modify')) + '() event we are about to fire...';
        // console.log(msg, this.resource.getChangedFieldValuesAsParms(!addMode));
        // msg += '\n\n' + JSON.stringify(this.resource.getChangedFieldValuesAsParms(!addMode));
        // this.modalDialogService.showAlert(msg, 'Debugging - Un-Comment The Real Code!');

        // console.log(this.resource, this.resource.asParms, this.resource.getChangedFieldValuesAsParms(!addMode));

        // do the save here, then reload the page...

        this.modalDialogService.showPleaseWait(true);

        this.eventServerService.fireEvent(
            this.eventHandler,
            (addMode ? 'add' : 'modify'),
            this.resource.getChangedFieldValuesAsParms(!addMode)
          ).subscribe(
            (event: WEvent) => {

              this.modalDialogService.showPleaseWait(false);

              if (event.status === 'OK') {
                // re-set field mode...
                this.userInterfaceService.resourceDetailPageDisplayMode.next(FieldMode.read);

                // we re-load the resource from the server, and tell the user...

                const parms: any = {};
                parms[this.resource.keyField.name] = event.getParameter(this.resource.keyField.name);

                this.modalDialogService.showPleaseWait(true);
                this.eventServerService.loadResourceFromServer(this.eventHandler, parms).subscribe(
                  (resource2: WResource) => {
                    this.modalDialogService.showPleaseWait(false);
                    this.userInterfaceService.selectResource(this.eventHandler, resource2);
                    // tell the user...
                    this.modalDialogService.showAlert('Save complete.', 'Success!').subscribe(
                      () => {
                        this.userInterfaceService.reloadCurrentPage();
                      }
                    );
                  }
                );

              } else {
                this.modalDialogService.showAlert('The save operation failed!\n\n' + event.message, 'WARNING');
              }
            }
          );
      }

    } catch (ex) {
      const msg = 'InBox.save()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  submit(): void {
    // console.log('InBox.submit()');
    try {

      // First, we run the "regular" resource missing field checks...
      let badField: WField = this.resource.identifyMissingOrInvalidField(true);

      // If we don't have a key field value, we're doing an "add",
      // in which case, public users MUST have a captchaAnswer value...
      if (!this.resource.keyField.isPopulated  && (this.user.isPublicOnly)) {
        badField = this._checkNextMissingField('captchaAnswer', badField);
      }

      // do a client-side check to make sure all the required parms are populated...

      badField = this.identifyMissingOrInvalidFieldsForInBox(badField, false);

      if (badField) {

        // console.log('submit()', this.resource, badField);

        this.modalDialogService.showAlert('Invalid field value or missing required field: ' + badField.name, 'Input Error').subscribe(
          () => {
            this.userInterfaceService.resourceDetailPageDisplayMode.next(FieldMode.write);
            setTimeout(
              () => {
                this.userInterfaceService.focusOnField(this.repositoryPageElementRef, badField);
              }
              , 0
            );
          }
        );

      } else {

        const parms: any = this.resource.asParms;

        this.eventServerService.fireEvent('InBox', 'add', parms).subscribe(
          (tempEvent: WEvent) => {
            try {
              // console.log(this.name+'.save.onclick() - server response...\n' + tempEvent.message);
              // console.log(this.name+'.save.onclick() - tempEvent.status = ' + tempEvent.status);
              if (tempEvent.status !== 'OK') {
                // if (JSON.stringify(tempEvent).indexOf('NumberFormatException') > -1) {
                if (JSON.stringify(tempEvent).indexOf('Invalid CAPTCHA') > -1) {
                  this.modalDialogService.showAlert('Invalid or missing CAPTCHA.').subscribe(
                    () => {
                      this.userInterfaceService.focusOnField(this.repositoryPageElementRef, this.resource.captchaAnswer);
                    }
                  );
                } else {
                  this.modalDialogService.showAlert('There was a problem submitting the resource: ' + tempEvent.message);
                }
              } else if (!this.user || (this.user.isPublicOnly)) {

                const msg = '<div>Your contract has been submitted to <b><i>' + this.user.accountName + '</i></b>.</div>'
                            + '<div>They will notify you if they have any questions.</div>';

                this.modalDialogService.showAlert(msg, 'Thank You!').subscribe(
                  () => {
                    this.userInterfaceService.loadPage('home');
                  }
                );
              } else {
                // we only select things that returned the id in an event parameter... (i.e. which covers both add and modify)

                this.eventServerService.loadResourceFromServer('InBox', {inputFormID: tempEvent.getParameter(this.resource.keyField.name)}).subscribe(
                  (resource: WResource) => {
                    this.userInterfaceService.selectResource('InBox', resource);
                    this.userInterfaceService.loadPage('InBox');
                  }
                );

              }
              // wackadoo.lib.clearCursor();
            } catch (ex) {
              const msg2 = 'InBox.callback()\n';
              this.userInterfaceService.alertUserToException(ex, msg2);
            }
          }
        );

      }

    } catch (ex) {
      const msg = 'InBox.submit()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  approve(): void {
    // console.log('InBox.approve()', this.resource);
    try {

      if (!this.resource.keyField.isPopulated) {
        this.modalDialogService.showAlert('You must save this at least once before you can approve it.');
      } else {
        // First, we run the "regular" resource missing field checks...
        let badField: WField = this.resource.identifyMissingOrInvalidField(true);

        // do a client-side check to make sure all the required parms are populated...

        badField = this.identifyMissingOrInvalidFieldsForInBox(badField, true);

        if (badField) {

          // console.log('submit()', this.resource, badField);

          this.modalDialogService.showAlert('Invalid field value or missing required field: ' + badField.name, 'Input Error').subscribe(
            () => {
              this.userInterfaceService.resourceDetailPageDisplayMode.next(FieldMode.write);
              setTimeout(
                () => {
                  this.userInterfaceService.focusOnField(this.repositoryPageElementRef, badField);
                }
                , 0
              );
            }
          );

        } else {
          // first, set the ddDate time to 5pm local time...

          // now we set the time on the ddDate to 5pm local time...
          if (this.resource.ddDate && this.resource.ddDate.value) {
            const ddDate = new Date(this.resource.ddDate.value);
            // console.log('InBox.approve()', this.resource, this.resource.ddDate, this.resource.ddDate.value, ddDate, typeof ddDate);
            ddDate.setHours(17); // 5pm local time...
            this.resource.ddDate.value = ddDate.toJSON();
          }

          // second, we clear the coOAgentID, lenderID and attorneyID fields if their detail fields are populated...
          // (This is because the server-side pipeline for approve() does not recognize the Globals.MAGIC_NULL_FKEY_VALUE as "empty"...)

          if (this.resource.coOpAgentFirstName.isPopulated
              || this.resource.coOpAgentLastName.isPopulated
              || this.resource.coOpAgentCompany.isPopulated
              || this.resource.coOpAgentEmail.isPopulated
              || this.resource.coOpAgentPhone.isPopulated
          ) {
            this.resource.coOpAgentID.value = null;
            this.resource.coOpAgentID.changed = false;
          }

          if (this.resource.lenderFirstName.isPopulated
              || this.resource.lenderLastName.isPopulated
              || this.resource.lenderCompany.isPopulated
              || this.resource.lenderEmail.isPopulated
              || this.resource.lenderPhone.isPopulated
          ) {
            this.resource.lenderID.value = null;
            this.resource.lenderID.changed = false;
          }

          if (this.resource.attorneyFirstName.isPopulated
              || this.resource.attorneyLastName.isPopulated
              || this.resource.attorneyCompany.isPopulated
              || this.resource.attorneyEmail.isPopulated
              || this.resource.attorneyPhone.isPopulated
          ) {
            this.resource.attorneyID.value = null;
            this.resource.attorneyID.changed = false;
          }

          // now set up the callback on the approve Event

          const parms = this.resource.asParms;

          if (parms.hasOwnProperty('taskTemplateGroupID')) {

            if (parms.contractType === 'Dual Agency') {

              ///////////////////////////////////////////////////////
              // First, add the Seller contract...
              // (clients 4-6 are ignored by the server...)
              ///////////////////////////////////////////////////////

              parms.contractType = 'Seller';

              // note that we do NOT want a lenderID added to a Seller contract...
              const lenderID = parms.lenderID;
              delete parms.lenderID;

              // console.log(thisPage.name + '.submit() - eventhandler: ' + eventhandler + ', eventAction: '+ eventAction + '\n'+JSON.stringify(parms));
              this.eventServerService.fireEvent('InBox', 'approve', parms).subscribe();

              if (lenderID) {
                parms.lenderID = lenderID;
              }

              ///////////////////////////////////////////////////////
              // Second, add the Buyer contract...
              // (move clients 4-6 into clients 1-3...)
              ///////////////////////////////////////////////////////

              parms.contractType = 'Buyer';

              parms.client1FirstName = (parms.client4FirstName ? parms.client4FirstName : null);
              parms.client1LastName = (parms.client4LastName ? parms.client4LastName : null);
              parms.client1Company = (parms.client4Company ? parms.client4Company : null);
              parms.client1Phone = (parms.client4Phone ? parms.client4Phone : null);
              parms.client1Email = (parms.client4Email ? parms.client4Email : null);
              parms.client1Married = (parms.client4Married ? parms.client4Married : null);
              parms.client1Note = (parms.client4Note ? parms.client4Note : null);
              parms.client1Role = (parms.client4Role ? parms.client4Role : null);

              parms.client2FirstName = (parms.client5FirstName ? parms.client5FirstName : null);
              parms.client2LastName = (parms.client5LastName ? parms.client5LastName : null);
              parms.client2Company = (parms.client5Company ? parms.client5Company : null);
              parms.client2Phone = (parms.client5Phone ? parms.client5Phone : null);
              parms.client2Email = (parms.client5Email ? parms.client5Email : null);
              parms.client2Married = (parms.client5Married ? parms.client5Married : null);
              parms.client2Note = (parms.client5Note ? parms.client5Note : null);
              parms.client2Role = (parms.client5Role ? parms.client5Role : null);

              parms.client3FirstName = (parms.client6FirstName ? parms.client6FirstName : null);
              parms.client3LastName = (parms.client6LastName ? parms.client6LastName : null);
              parms.client3Company = (parms.client6Company ? parms.client6Company : null);
              parms.client3Phone = (parms.client6Phone ? parms.client6Phone : null);
              parms.client3Email = (parms.client6Email ? parms.client6Email : null);
              parms.client3Married = (parms.client6Married ? parms.client6Married : null);
              parms.client3Note = (parms.client6Note ? parms.client6Note : null);
              parms.client3Role = (parms.client6Role ? parms.client6Role : null);

              delete parms.client4FirstName;
              delete parms.client4LastName;
              delete parms.client4Company;
              delete parms.client4Phone;
              delete parms.client4Email;
              delete parms.client4Married;
              delete parms.client4Note;
              delete parms.client4Role;

              delete parms.client5FirstName;
              delete parms.client5LastName;
              delete parms.client5Company;
              delete parms.client5Phone;
              delete parms.client5Email;
              delete parms.client5Married;
              delete parms.client5Note;
              delete parms.client5Role;

              delete parms.client6FirstName;
              delete parms.client6LastName;
              delete parms.client6Company;
              delete parms.client6Phone;
              delete parms.client6Email;
              delete parms.client6Married;
              delete parms.client6Note;
              delete parms.client6Role;

              // console.log(thisPage.name + '.approve() - eventhandler: ' + eventhandler + ', eventAction: '+ eventAction + '\n'+JSON.stringify(parms));

              this.modalDialogService.showPleaseWait('Generating Dual Contracts...');

              this.eventServerService.fireEvent('InBox', 'approve', parms).subscribe(
                (tempEvent: WEvent) => {
                  try {
                    this.modalDialogService.showPleaseWait(false);

                    // console.log(tempEvent);

                    if (tempEvent.status !== 'OK') {
                      this.modalDialogService.showAlert('There was a problem submitting the resource: ' + tempEvent.message);
                    } else {
                      this.userInterfaceService.unSelectResource('InBox');

                      const parms2: any = {};
                      parms2.contractID = tempEvent.getParameter('contractID');

                      // console.log(parms2);

                      const eh = (this.user.isStaffOnly ? 'MyContracts' : 'ActiveContracts');

                      this.userInterfaceService.loadPage(eh, 'modify', parms2);

                    }
                  } catch (ex) {
                    const msg2 = 'InBox.approve.callback()\n';
                    this.userInterfaceService.alertUserToException(ex, msg2);
                  }
                }
              );

            } else {
              // console.log('InBox.approve()', this.resource, parms, JSON.stringify(parms), this.resource.inputFormID, parms.inputFormID);

              this.modalDialogService.showPleaseWait('Generating New Contract...');

              this.eventServerService.fireEvent('InBox', 'approve', parms).subscribe(
                (tempEvent: WEvent) => {
                  try {
                    this.modalDialogService.showPleaseWait(false);

                    // console.log(tempEvent);

                    if (tempEvent.status !== 'OK') {
                      this.modalDialogService.showAlert('There was a problem submitting the resource: ' + tempEvent.message);
                    } else {
                      this.userInterfaceService.unSelectResource('InBox');

                      const parms2: any = {};
                      parms2.contractID = tempEvent.getParameter('contractID');

                      // console.log(parms2);

                      const eh = (this.user.isStaffOnly ? 'MyContracts' : 'ActiveContracts');

                      this.userInterfaceService.unSelectResource(eh);
                      this.userInterfaceService.loadPage(eh, 'modify', parms2);

                    }
                  } catch (ex) {
                    const msg2 = 'InBox.approve.callback()\n';
                    this.userInterfaceService.alertUserToException(ex, msg2);
                  }
                }
              );
            }
          } else {
            this.modalDialogService.showAlert('You must first indicate which set of Tasks this Contract will require.').subscribe(
              () => {
                this.userInterfaceService.resourceDetailPageDisplayMode.next(FieldMode.write);
                setTimeout(
                  () => {
                    this.userInterfaceService.focusOnField(this.repositoryPageElementRef, this.resource.taskTemplateGroupID);
                  }
                  , 0
                );
              }
            );
          }
        }
      }

    } catch (ex) {
      const msg = 'InBox.approve()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  cancel(): void {
    if (this.user.isPublicOnly) {
      this.userInterfaceService.loadPage('home');
    } else {
      super.cancel();
    }
  }

  identifyMissingOrInvalidFieldsForInBox(missingField: WField, approvingASubmittedContract: boolean): WField {

    try {

      // This runs the "InBox-Specific" missing field checks based on contractType...
      // i.e. proper date fields, entity IDs, "new" entity content for Lender, Attorney, etc.

      // console.log('InBox.identifyMissingOrInvalidField()', missingField, !missingField, this.resource.contractType.value);

      if (!missingField) {

        if (this.resource.referral.value === true) {
          missingField = this._checkNextMissingField('referralName', missingField);
          missingField = this._checkNextMissingField('referralCompany', missingField);
          missingField = this._checkNextMissingField('referralEmail', missingField);
          missingField = this._checkNextMissingField('referralPhone', missingField);
        }

        if (this.resource.contractType.value === 'Listing') {

          missingField = this._checkNextMissingField('listingDate', missingField);

        } else { // contractType = Seller, Buyer or Dual Agency...

          missingField = this._checkNextMissingField('contractDate', missingField);
          missingField = this._checkNextMissingField('ddDate', missingField);

          if (this.resource.contractType.value === 'Dual Agency') {
            missingField = this._checkNextMissingField('client4FirstName', missingField);
            missingField = this._checkNextMissingField('client4LastName', missingField);
            missingField = this._checkNextMissingField('client4Email', missingField);
            missingField = this._checkNextMissingField('client4Phone', missingField);
          }

          if (approvingASubmittedContract && ((this.resource.contractType.value === 'Buyer') || (this.resource.contractType.value === 'Dual Agency'))) {

            if (
              !this.resource.cashPurchase.value
              &&
              !this.resource.lenderID.isPopulated
              &&
              !(
                this.resource.lenderCompany.isPopulated
                && this.resource.lenderFirstName.isPopulated
                && this.resource.lenderLastName.isPopulated
                && (this.resource.lenderEmail.isPopulated || this.resource.lenderPhone.isPopulated)
              )
            ) {
              missingField = this._checkNextMissingField('lenderID', missingField);
            }

            if (
              !this.resource.attorneyID.isPopulated
              &&
              !(
                this.resource.attorneyCompany.isPopulated
                && this.resource.attorneyFirstName.isPopulated
                && this.resource.attorneyLastName.isPopulated
                && (this.resource.attorneyEmail.isPopulated || this.resource.attorneyPhone.isPopulated)
              )
            ) {
              missingField = this._checkNextMissingField('attorneyID', missingField);
            }
          }
        }
      }
    } catch (ex) {
      const msg = 'InBox.identifyMissingOrInvalidField()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
    // console.log(this.resource, missingField);
    return missingField;
  }

  private _checkNextMissingField(fieldName: string, previousMissingField?: WField): WField {
    previousMissingField = typeof previousMissingField !== 'undefined' ? previousMissingField : null;
    let f: WField = previousMissingField;
    if (!f) {
      const f2: WField = this.resource.getField(fieldName);
      // console.log('InBox._missingField()', fieldName, f2.isPopulated);
      if (f2 && !f2.isPopulated) {
        f = f2;
      }
    }
    return f;
  }

  cutAndPaste(): void {
    // console.log('cutAndPaste()', this.eventHandler, this.action, this.parameters, this.resource);
    this.resource._catchAllFieldName = 'contractNote';
    this.modalDialogService.showResourceModal('FormFillerComponent', this.resource).subscribe(
      () => {
        const oldValue = this.resource.client2Required.value;
        const newValue = (
                            this.resource.client2FirstName.isPopulated
                            || this.resource.client2LastName.isPopulated
                            || this.resource.client2Email.isPopulated
                            || this.resource.client2Phone.isPopulated
                          );
        this.resource.client2Required.value = newValue;
        this.resource.client2Required.changed = (oldValue !== newValue);
      }
    );
  }

}
