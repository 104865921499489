import { Directive, ElementRef, Renderer2, Input, OnInit, HostListener, RendererStyleFlags2 } from '@angular/core';
import { ModalDialogService } from '../services/modal-dialog.service';
import { UserInterfaceService } from '../services/user-interface.service';
import { WResource } from '../data/resource.model';
import { DragAndDropService } from '../services/drag-and-drop.service';

@Directive({
  selector: '[wackadooResourceDragStart]'
})
export class ResourceDragStartDirective implements OnInit {

  @Input() resource: WResource = null;
  @Input() debug = false;

  // this is the "grab" portion of the drag-n-drop mechanism...
  @HostListener('dragstart', ['$event']) dragstart = this.handleResourceDragStart;

  constructor(
    public elementRef: ElementRef,
    public renderer: Renderer2,
    public dragAndDropService: DragAndDropService,
    public modalDialogService: ModalDialogService,
    public userInterfaceService: UserInterfaceService,
  ) {}

  ngOnInit(): void {
    if (!this.resource) {
      this.modalDialogService.showAlert('wackadooResourceDragStart - missing resource');
    }

    this.renderer.setProperty(this.elementRef.nativeElement, 'draggable', true);
  }

  handleResourceDragStart(e: any): any {
    try {
      // By allowing this event to propagate, we get the proper "move" effect in the UX...
      // e.stopPropagation();
      // e.preventDefault();

      this.dragAndDropService.payload.next(this.resource.clone);

      if (this.debug) {
        console.log('handleResourceDragStart()', this.dragAndDropService.payload.getValue());
      }

    } catch (ex) {
      this.userInterfaceService.alertUserToException(ex, 'handleResourceDragStart()');
    }
  }

}
