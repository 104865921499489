<div class="card">
    <div class="card-header large bold">
        <wackadoo-task-name-with-icon [resource]="resource"></wackadoo-task-name-with-icon>
    </div>
    <div class="card-body">
        <div class="marginTopHalfEm">
            Please set the Task start date (and time):
        </div>
        <div>
            <wackadoo-field [f]="resource.taskStartDate" [mode]="mode"></wackadoo-field>
        </div>
    </div>
</div>
