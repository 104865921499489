<div class="card">
    <div class="card-header">
        Vendor
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-sm-6"><wackadoo-field [f]="resource.vendorFirstName" [mode]="mode"></wackadoo-field></div>
            <div class="col-sm-6"><wackadoo-field [f]="resource.vendorLastName" [mode]="mode"></wackadoo-field></div>
        </div>
        <div class="row marginTopQuarterEm">
            <div class="col-sm-8"><wackadoo-field [f]="resource.vendorCompany" [mode]="mode"></wackadoo-field></div>
            <div class="col-sm-4 right"><wackadoo-field [f]="resource.vendorType" [mode]="mode"></wackadoo-field></div>
        </div>
        <div class="row marginTopQuarterEm">
            <div class="col-sm-12"><wackadoo-field [f]="resource.otherDescription" [mode]="mode"></wackadoo-field></div>
        </div>
        <div class="row marginTopQuarterEm">
            <div class="col-sm-12"><wackadoo-field [f]="resource.vendorAddress" [mode]="mode"></wackadoo-field></div>
        </div>
        <div class="row">
            <div class="col-sm-6"><wackadoo-field [f]="resource.vendorCity" [mode]="mode"></wackadoo-field></div>
            <div class="col-sm-3"><wackadoo-field [f]="resource.vendorState" [mode]="mode" [size]="2"></wackadoo-field></div>
            <div class="col-sm-3"><wackadoo-field [f]="resource.vendorPostalCode" [mode]="mode"></wackadoo-field></div>
        </div>
        <div class="row marginTopQuarterEm">
            <div  class="col-sm-6"><wackadoo-field [f]="resource.vendorEmail" [mode]="mode"></wackadoo-field></div>
            <div class="col-sm-6"><wackadoo-field [f]="resource.vendorPhone" [mode]="mode"></wackadoo-field></div>
        </div>
        <div class="row marginLeftHalfEm marginRighttHalfEm paddingTopQuarterEm paddingLeftQuarterEm paddingRightQuarterEm">
            <div class="col-sm-12">Note:</div>
            <div class="col-sm-12"><wackadoo-field [f]="resource.vendorNote" [mode]="mode"></wackadoo-field></div>
        </div>
    </div>
</div>
