import { Component, OnInit, OnDestroy } from '@angular/core';
import { ResourceComponent } from 'src/app/client-core/data/resource/resource.component';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { Subject, Subscription } from 'rxjs';
import { WField } from 'src/app/client-core/data/field.model';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { WEvent } from 'src/app/client-core/data/event.model';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { ClosingProService } from '../../closing-pro.service';

@Component({
  selector: 'wackadoo-related-task-detail',
  templateUrl: './related-task-detail.component.html',
})
export class RelatedTaskDetailComponent extends ResourceComponent implements OnInit, OnDestroy {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'RelatedTaskDetailComponent';

  onChangeTaskComplete: Subject<WField> = null;
  onChangeTaskCompleteSubscription: Subscription = null;

  constructor(
    userInterfaceService: UserInterfaceService,
    public closingProService: ClosingProService,
    public modalDialogService: ModalDialogService,
    eventServerService: EventServerService,
  ) {
    super(userInterfaceService, eventServerService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    // HACK ALERT! When we populate this from CustomGenerator.listRelatedOpenTasksForEscrowAgent(), we don't get the key=true on the taskID field...
    this.resource.taskID.key = true;

    // now handle the TaskCompletion checkbox...

    this.onChangeTaskComplete = new Subject<WField>();

    this.onChangeTaskCompleteSubscription = this.onChangeTaskComplete.subscribe(
      (f: WField) => {
        // console.log('onChangeTaskCompleteSubscription()', f, this.resource);

        const parms: any = {};
        parms.taskID = this.resource.taskID.value;
        parms.taskComplete = f.value;
        // We do NOT propagate task completion because we're NOT on the TaskList page...
        // parms.propagate = true;

        this.modalDialogService.showPleaseWait('Setting task complete for this task <span class="italic">and all sub-tasks</span>...');

        this.eventServerService.fireEvent('Tasks', 'modify', parms).subscribe(
          (event: WEvent) => {
            this.modalDialogService.showPleaseWait(false);
            if (event) {
              if (event.status === 'OK') {
                this.userInterfaceService.reloadCurrentPage();
              }
            }
          }
        );
      }
    );
  }

  ngOnDestroy(): void {
    if (this.onChangeTaskCompleteSubscription) {
      this.onChangeTaskCompleteSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  scheduleTask(): void {
    // console.log('scheduleTask()');
    this.closingProService.scheduleTask(this.resource).subscribe(
      (event: WEvent) => {
        if (event && event.status !== 'OK') {
          throw new Error(event.message);
        }
      }
    );
  }

  newTask(): void {
    // console.log('newTask()');
    this.closingProService.addNewAdHocTask(this.resource).subscribe(
      (event: WEvent) => {
        // console.log('TaskListPanel.newTask()', event);
        try {
          if (event) {
            if (event.status !== 'OK') {
              throw new Error(event.message);
            }
          }
        } catch (ex) {
          const msg = 'Error Adding New Task';
          this.userInterfaceService.alertUserToException(ex, msg);
        }
      }
    );
  }

  addNote(): void {
    // console.log('addNote()');
    // no default value makes this an "add" instead of a "modify"
    this.closingProService.updateContractNote(this.resource.contractID.value);
  }

  openEMailer(): void {
    // console.log('openEMailer()');

    const parms: any = {};
    parms.taskID = this.resource.taskID.value;
    parms.contractID = this.resource.contractID.value;
    parms.contractType = this.resource.contractType.value;

    // Here, we determine what kind of resource can be in the resourceBlock in the template...
    const matches = this.resource.taskName.value.match(/.*inspection.*|.*estimate.*|.*repair.*|.*survey.*|.*vendor.*/i);
    parms.resourceType = (matches === null ? 'Client' : 'Vendor');

    parms.templateName = this.resource.taskEMailTemplate.value;

    this.modalDialogService.showEMailer('EMailer', parms, this.resource.taskName.value);
  }
}
