import { Component, OnInit } from '@angular/core';
import { ActiveContractsComponent } from '../active-contracts/active-contracts.component';

@Component({
  selector: 'wackadoo-my-contracts',
  templateUrl: './my-contracts.component.html',
})
export class MyContractsComponent extends ActiveContractsComponent implements OnInit {

  ngOnInit(): void {

    this.userInterfaceService.unSelectResource('ActiveContracts');
    this.userInterfaceService.unSelectResource('Contracts');

    super.ngOnInit();
  }

}
