<div class="heightViewPort85 wrap-scrollable-table">
  <table class="table table-hover small">
    <thead>
      <tr class="numbered-row center">
        <th class="italic right">
          #
        </th>
        <th class="border-left">
          Firm
        </th>
        <th class="border-left">
          Agent
        </th>
        <th class="border-left max-width-8-em">
          Client(s)
        </th>
        <th class="border-left">
          Address
        </th>
        <th class="border-left">
          Closing Date
        </th>
        <th class="border-left">
          Attorney
        </th>
        <th class="border-left">
          Lender
        </th>
        <th class="border-left">
          DD Date
        </th>
        <th class="border-left" [title]="getQueryDescription(0)">
          {{getQueryName(0)}}
        </th>
        <th class="border-left" [title]="getQueryDescription(1)">
          {{getQueryName(1)}}
        </th>
        <th class="border-left" [title]="getQueryDescription(2)">
          {{getQueryName(2)}}
        </th>
        <th class="border-left" [title]="getQueryDescription(3)">
          {{getQueryName(3)}}
        </th>
        <th class="border-left" [title]="getQueryDescription(4)">
          {{getQueryName(4)}}
        </th>
        <th class="border-left" [title]="getQueryDescription(5)">
          {{getQueryName(5)}}
        </th>
        <th class="border-left max-width-25-em">
          Closing Notes
        </th>
        <th class="border-left" [title]="getQueryDescription(6)">
          {{getQueryName(6)}}
        </th>
      </tr>
    </thead>
    <thead>
      <tr class="numbered-row"><th><br/></th></tr>
      <tr>
        <th></th>
        <th colspan="2">THIS WEEK</th>
        <th colspan="5" class="italic" >(Week of {{getWeekOfThisWeek()}})</th>
        <ng-container *ngIf="(screenType !== 'phone')"><th colspan="17"></th></ng-container>
      </tr>
    </thead>
    <tbody>
      <tr wackadoo-closing
        class="numbered-row" 
        *wackadooResourceBlock="let r of []; let idx = index; eventHandler: 'ActiveContracts'; action: 'search'; parms: { query: buildQueryForThisWeek(), pageSize: 100 }; defaultText: 'No closings.'; debug: false;"
        [idx]="idx" [resource]="r"
        [ngClass]="{'sandybrown-bg': (r.contractType.value === 'Seller') }"
      >
      </tr>
      <tr><td><br/></td><td  colspan="17"></td></tr>
    </tbody>
    <thead>
      <tr>
        <th></th>
        <th colspan="2">NEXT WEEK</th>
        <th colspan="5" class="italic" >(Week of {{getWeekOfNextWeek()}})</th>
        <ng-container *ngIf="(screenType !== 'phone')"><th colspan="17"></th></ng-container>
      </tr>
    </thead>
    <tbody>
      <tr wackadoo-closing 
        class="numbered-row" 
        *wackadooResourceBlock="let r of []; let idx = index; eventHandler: 'ActiveContracts'; action: 'search'; parms: { query: buildQueryForNextWeek(), pageSize: 100 }; defaultText: 'No closings.'; debug: false;"
        [idx]="idx" [resource]="r"
        [ngClass]="{'sandybrown-bg': (r.contractType.value === 'Seller') }"
      >
      </tr>
      <tr><td><br/></td><td  colspan="17"></td></tr>
    </tbody>
  </table>
</div>
