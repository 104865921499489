import { Component, OnInit } from '@angular/core';
import { MergeDuplicatePeopleComponent } from './merge-duplicate-people.component';

@Component({
  selector: 'wackadoo-duplicate-lenders',
  templateUrl: '../../../../../../src/app/client-core/ui/duplicate-merger/duplicate-merger.component.html',
})
export class DuplicateLendersComponent extends MergeDuplicatePeopleComponent implements OnInit {

  ngOnInit(): void {
    this.eventHandler = 'Lenders';

    this.firstNameField = 'lenderFirstName';
    this.lastNameField = 'lenderLastName';
    this.companyField = 'lenderCompany';

    this.leftGridTitle = 'Duplicate Lenders?';
    this.leftGridFields = ['lenderCompany', 'lenderFirstName', 'lenderLastName'];
    this.leftGridQueryFilter = 'lenderNote:dupe OR lenderNote:duplicate';

    this.rightGridTitle = 'Similar sounding Lenders...';
    this.rightGridFields = ['lenderCompany', 'lenderFirstName', 'lenderLastName'];

    this.childEventHandlersToMergeForeignKeyValues = ['Contracts'];

    super.ngOnInit();
  }

}
