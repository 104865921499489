import { Component, OnInit, OnDestroy, HostListener, AfterViewInit } from '@angular/core';
import { UserAuthService } from 'src/app/client-core/services/user-auth.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { PageComponent } from 'src/app/client-core/pages/page/page.component';
import { Subscription } from 'rxjs';
import { CalendarService } from 'src/app/client-core/pages/calendar-page/calendar.service';
import { WCalendarEntry } from 'src/app/client-core/pages/calendar-page/calendar-entry.model';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { WEvent } from 'src/app/client-core/data/event.model';
import { UtilityLibService } from 'src/app/client-core/services/utility-lib.service';
import { WResource } from 'src/app/client-core/data/resource.model';
import { Globals } from 'src/app/client-core/services/global.service';
import { SelectedResource } from 'src/app/client-core/ui/main/current-selection/selected-resource.model';

@Component({
  selector: 'wackadoo-calendar',
  templateUrl: './calendar.component.html',

})
export class CalendarComponent extends PageComponent implements OnInit, OnDestroy, AfterViewInit {

  calendarName = 'ClosingPro Calendar';

  currentDate: Date = null;

  currentDateSubscription: Subscription = null;
  openCalendarItemSubscription: Subscription = null;

  startDate: Date = null;
  endDate: Date = null;

  // radio button controls...

  tasksToReturn = 'activedeadlines';

  contractSelectionSubscription: Subscription = null;

  @HostListener('input', ['$event']) captureInputEvents = (e: any) => {
    // console.log(e, e.target.name, e.target.value);
    if (e.target.name === 'tasksToReturn') {
      this.tasksToReturn = e.target.value;
      this.userInterfaceService.setPageState('Calendar', 'tasksToReturn', this.tasksToReturn);
      this.getCalendarEntries(this.startDate, this.endDate);
    }
  }

  constructor(
    userAuthService: UserAuthService,
    userInterfaceService: UserInterfaceService,
    public calendarService: CalendarService,
    public eventServerService: EventServerService,
    public modalDialogService: ModalDialogService,
    public utilityLibService: UtilityLibService,
  ) {
    super(userAuthService, userInterfaceService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    const oldTaskType = this.userInterfaceService.getPageState('Calendar', 'tasksToReturn');
    if (oldTaskType) {
      this.tasksToReturn = String(oldTaskType);
    }

    this.userInterfaceService.setPageState('Calendar', 'tasksToReturn', this.tasksToReturn);

  }

  ngAfterViewInit(): void {

    this.currentDateSubscription = this.calendarService.getCurrentDateSubject(this.calendarName).subscribe(
      (d: Date) => {
        // console.log('Calendar.newDate()', this.currentDate, d);
        if (!this.currentDate
            || (this.currentDate.getFullYear() !== d.getFullYear())
            || (this.currentDate.getMonth() !== d.getMonth())
        ) {
          // for now, we get a month's worth
          this.startDate = this.calendarService.getTopOfMonth(d);
          this.endDate = this.calendarService.getTopOfMonth(d, -1);

          this.getCalendarEntries(this.startDate, this.endDate);

        }
        this.currentDate = d;
      }
    );

    this.openCalendarItemSubscription = this.calendarService.getOpenEntrySubject(this.calendarName).subscribe(
      (ce: WCalendarEntry) => {
        // console.log('Calendar.open()', ce, ce.resource);
        this.eventServerService.loadResourceFromServer('Tasks', ce.resource.taskID.asParm).subscribe(
          (task: WResource) => {
            // console.log('Calendar.open() - task', task);
            task.taskName.readOnly = true;
            this.modalDialogService.modifyResourceModal('TaskModalComponent', 'Tasks', task).subscribe(
              (event: WEvent) => {
                // if we cancel or properly saved the page...
                if (event && (event.status !== 'OK')) {
                  // console.log('modifyResourceModal.closed()', event);
                  this.modalDialogService.showAlert('Unable to modify task...\n' + event.message, 'WARNING!');
                }
              }
            );
          }
        );
      }
    );

    this.contractSelectionSubscription = this.userInterfaceService.selectionChange.subscribe(
      (sr: SelectedResource) => {
        if (this.startDate) {
          this.getCalendarEntries(this.startDate, this.endDate);
        }
      }
    );
  }

  ngOnDestroy(): void {

    if (this.currentDateSubscription) {
      this.currentDateSubscription.unsubscribe();
    }

    if (this.openCalendarItemSubscription) {
      this.openCalendarItemSubscription.unsubscribe();
    }

    if (this.contractSelectionSubscription) {
      this.contractSelectionSubscription.unsubscribe();
    }

    super.ngOnDestroy();
  }

  getSelectedContract(): WResource {
    let sc = this.userInterfaceService.getSelectedResource('MyContracts');
    if (sc) {
      return sc;
    }

    sc = this.userInterfaceService.getSelectedResource('ActiveContracts');
    if (sc) {
      return sc;
    }

    sc = this.userInterfaceService.getSelectedResource('Contracts');
    if (sc) {
      return sc;
    }

    return null;
  }

  get icsFileDescription(): string {

    let label = '';
    // at page initialization, this is not set yet...
    if (this.startDate) {
      const contract = this.getSelectedContract();

      if (contract) {
        label += 'Calendar for ' + contract.keyField.displayValue;
      } else {
        label += 'Calendar';
      }
    }
    return label;
  }

  getCalendarEntries(startDate: Date, endDate: Date): void {

    try {

      const contract = this.getSelectedContract();

      const parms = ( contract ? contract.keyField.asParm : {} );
      parms.startDate = startDate.toJSON();
      parms.endDate = endDate.toJSON();

      parms.justLookAtClosings = false;

      if (this.tasksToReturn === 'activedeadlines') {
        parms.taskFilterField = 'taskType';
        parms.taskFilterValue = 'deadline';
      } else if (this.tasksToReturn === 'allclosings') {
        parms.taskFilterField = 'taskType';
        parms.taskFilterValue = 'deadline';
        parms.justLookAtClosings = true;
      }

      // console.log('Calendar.getCalendarEntries() - CustomGenerator.getCalendar()', parms);

      this.modalDialogService.showPleaseWait(true);

      this.eventServerService.fireEvent('CustomGenerator', 'getCalendar', parms).subscribe(
          (resultEvent: WEvent) => {

            this.modalDialogService.showPleaseWait(false);

            // console.log('resultEvent:', resultEvent);

            try {
              const entries: WCalendarEntry [] = [];

              if (resultEvent.status !== 'OK') {
                throw new Error('Unable to get Calendar entries: ' + resultEvent.message);
              }

              const resources = resultEvent.resources;

              // console.log('parms', resultEvent.parameters, 'resources', resources);

              if (resources) {
                // console.log('resources.length : ' + resources.length);

                for (const resource of resources) {

                  const calEntry = new WCalendarEntry(
                    new Date(resource.taskStartDate.value),
                    resource.taskName.value,
                    resource.contractID.displayValue,
                    resource.taskPriority.value,
                    resource
                  );

                  // console.log('calEntry: ' + JSON.stringify(calEntry));
                  entries.push(calEntry);
                }
              }

              this.calendarService.setCalendarEntries(this.calendarName, entries);

            } catch (ex) {
              console.log(ex);
              const msg = 'Calendar.getCalendarEntries.callback()\n';
              this.userInterfaceService.alertUserToException(ex, msg);
            }

          }
      );

    } catch (ex) {
      const msg = 'Calendar.getCalendarEntries()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  downloadICS(): void {
    try {
      const now = new Date();
      const dateString = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();

      let parms: any = {};

      const contract = this.getSelectedContract();

      if (contract) {
        // get items for just this Contract...
        parms = contract.keyField.asParm; // "parms.contractID = X;"
        parms.icsCalendarName = contract.keyField.displayValue + ' ' + dateString + (this.tasksToReturn !== 'everything' ? ' deadline' : '');
      } else {
        // get EVERYthing... for ALL Contracts... starting with the top of this month...
        parms.icsCalendarName = Globals.thisApplication + ' ' + dateString + (this.tasksToReturn !== 'everything' ? ' deadline' : '');
        parms.startDate = this.calendarService.getTopOfMonth(now).toJSON();
      }

      if (this.tasksToReturn !== 'everything') {
        parms.taskFilterField = 'taskType';
        parms.taskFilterValue = 'deadline';
      }

      const action = 'getCalendar';
      const ehName = 'CustomGenerator';

      // console.log('Calendar.downloadICS() - ehName: '+ ehName + ', action: ' + action + ', parms: ' + JSON.stringify(parms));

      this.eventServerService.downloadFile(ehName, action, parms);

    } catch (ex) {
      const msg = 'Calendar.downloadICS()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }

  }

}
