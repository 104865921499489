<div class="card">
    <div class="card-header large bold">
        <div class="input-group">
            Setting Relative Task Timing
        </div>
    </div>
    <div class="card-body">
        <div class="row marginTopHalfEm">
            <div class="col-sm-2 right paddingRight0">
                From:
                <span class="paddingLeftHalfEm {{ getIconClassNames(resource.taskType.value, false) }}"></span>
            </div>
            <div class="col-sm-4 paddingLeftQuarterEm">
                {{ resource.taskName.value }}
            </div>
            <div class="col-sm-2 right paddingRight0">
                To:
                <span class="paddingLeftHalfEm {{ getIconClassNames(resource.subsequentTaskType.value, false) }}"></span>
            </div>
            <div class="col-sm-4 paddingLeftQuarterEm">
                {{ resource.subsequentTaskName.value }}
            </div>
        </div>
        <div class="row marginTopHalfEm">
            <div class="col-sm-3 right">
                Time Period:
            </div>
            <div class="col-sm-2">
                <wackadoo-field [f]="resource.subsequentTaskLeadTime" [mode]="mode"></wackadoo-field>
            </div>
            <div class="col-sm-3 right">
                Unit of Time:
            </div>
            <div class="col-sm-2">
                <wackadoo-field [f]="resource.subsequentTaskLeadTimeUnit" [mode]="mode"></wackadoo-field>
            </div>
        </div>
        <div class="row marginTopHalfEm">
            <div class="col-sm-12 center">
                <span class="small italic">
                    A time period &lt; 0 means the "from" task should occur BEFORE the "to" task's <span class="bold">start</span> date.
                    <br/>
                    A time period &gt; 0 means the "from" task should occur AFTER the "to" task is <span class="bold">completed</span>.
                </span>
            </div>
        </div>
    </div>
</div>
