import { Component, OnInit, OnDestroy } from '@angular/core';
import { RepositoryPageComponent } from 'src/app/client-core/pages/repository-page/repository-page.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wackadoo-notes',
  templateUrl: '../../../../../../src/app/client-core/pages/repository-page/repository-page.component.html',
})
export class NotesComponent extends RepositoryPageComponent implements OnInit, OnDestroy {

  screenTypeChangeSubscription: Subscription = null;

  ngOnInit(): void {

    this.hideSelectedFKeysInGrid = true;

    this.gridResourceComponentClassName = (this.screenType === 'phone' ? 'NoteGridPhoneContentComponent' : null);
    this.gridFieldsToHide = ['noteID', 'taskCompletionMessage'];

    super.ngOnInit();

    this.screenTypeChangeSubscription = this.userInterfaceService.screenType.subscribe(
      () => {
        this.gridResourceComponentClassName = (this.screenType === 'phone' ? 'NoteGridPhoneContentComponent' : null);
      }
    );

  }

  ngOnDestroy(): void {
    if (this.screenTypeChangeSubscription) {
      this.screenTypeChangeSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

}
