<div class="heightViewPort85 widthViewPort90">
  <div class="d-flex flex-row marginTopHalfEm marginBottomHalfEm">
    <div class="right marginLeftWholeEm marginRightWholeEm bold italic">Select Your Date Range</div>
    <div class="right marginLeftWholeEm marginRightHalfEm italic">From:</div>
    <wackadoo-field [f]="startDate" mode="write" onChange="reloadPage()"></wackadoo-field>
    <div class="right marginLeftWholeEm marginRightHalfEm italic">To:</div>
    <wackadoo-field [f]="endDate" mode="write" onChange="reloadPage()"></wackadoo-field>
  </div>
  <div class="wrap-scrollable-table">
    <table class="table table-hover small marginTopWholeEm">
      <thead>
        <tr class="numbered-row center">
          <th class="italic right">
            #
          </th>
          <th class="border-left">
            Closing Date
          </th>
          <th class="border-left max-width-8-em">
            Client(s)
          </th>
          <th class="border-left">
            Address
          </th>
          <th class="border-left">
            Agent
          </th>
          <th class="border-left">
            Firm
          </th>
          <th class="border-left">
            Status
          </th>
          <th class="border-left">
          </th>
        </tr>
      </thead>
      <tbody>
        <tr wackadoo-recent-closing
          class="numbered-row" 
          *wackadooResourceBlock="let r of []; let idx = index; eventHandler: 'Contracts'; action: 'search'; parms: { query: buildQueryForRecentStuff('closingDate'), pageSize: 100, sortBy: 'closingDate' , sortDirection: 1 }; defaultText: 'No Contracts'; debug: false;"
          [idx]="idx" [resource]="r"
          [ngClass]="{'sandybrown-bg': (r.contractType.value === 'Seller'), 'aquamarine-bg': (r.contractType.value === 'Listing') }"
        >
        </tr>
      </tbody>
    </table>
    <table class="table table-hover small marginTopWholeEm">
      <thead>
        <tr class="numbered-row center">
          <th class="italic right">
            #
          </th>
          <th class="border-left">
            Listing Date
          </th>
          <th class="border-left max-width-8-em">
            Client(s)
          </th>
          <th class="border-left">
            Address
          </th>
          <th class="border-left">
            Agent
          </th>
          <th class="border-left">
            Firm
          </th>
          <th class="border-left">
            Status
          </th>
          <th class="border-left">
          </th>
        </tr>
      </thead>
      <tbody>
        <tr wackadoo-recent-closing
          class="numbered-row" 
          *wackadooResourceBlock="let r of []; let idx = index; eventHandler: 'Contracts'; action: 'search'; parms: { query: buildQueryForRecentStuff('listingDate'), pageSize: 100, sortBy: 'listingDate' , sortDirection: 1 }; defaultText: 'No Listings'; debug: false;"
          [idx]="idx" [resource]="r"
          [ngClass]="{'sandybrown-bg': (r.contractType.value === 'Seller'), 'aquamarine-bg': (r.contractType.value === 'Listing') }"
        >
        </tr>
      </tbody>
    </table>
  </div>
</div>
