import { Component, OnInit, Input } from '@angular/core';
import { User } from 'src/app/client-core/data/user.model';
import { Globals } from 'src/app/client-core/services/global.service';

@Component({
  selector: 'wackadoo-closing-pro-public-content',
  templateUrl: './closing-pro-public-content.component.html',
})
export class ClosingProPublicContentComponent implements OnInit {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'ClosingProPublicContentComponent';

  @Input() user: User = null;

  constructor() { }

  ngOnInit(): void {
    window.location.href = 'https://www.' + Globals.domain;
  }

}
