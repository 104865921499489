import { Component, Input, Renderer2 } from '@angular/core';
import { WField } from 'src/app/client-core/data/field.model';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { WEvent } from 'src/app/client-core/data/event.model';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';

@Component({
  selector: 'wackadoo-closing-note',
  templateUrl: './closing-note.component.html',
})
export class ClosingNoteComponent {

  @Input() f: WField = null;
  @Input() contractID: number = null;

  constructor(
    public eventServerService: EventServerService,
    public renderer: Renderer2,
    public modalDialogService: ModalDialogService,
  ) { }

  onInputChange(e: any): boolean {
    // console.log(e, e.target.name, e.target.value);
    if (e.target.value === this.f.value) {
      this.renderer.removeClass(e.target, 'fieldChanged');
    } else {
      this.renderer.addClass(e.target, 'fieldChanged');

      const parms: any = {};
      parms.contractID = this.contractID;
      parms[this.f.name] = e.target.value;
      // console.log('ActiveContracts.modify()', parms);
      this.eventServerService.fireEvent('ActiveContracts', 'modify', parms).subscribe(
        (event: WEvent) => {
          if (event.status === 'OK') {
            this.renderer.removeClass(e.target, 'fieldChanged');
          } else {
            this.modalDialogService.showAlert('Failed to update contract field: ' + event.message, 'WARNING!');
          }
        }
      );
    }
    return false;
  }

}
