import { Component, OnInit, Input, OnChanges, SimpleChanges, Renderer2, ElementRef } from '@angular/core';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';

@Component({
  selector: 'wackadoo-progress-bar',
  templateUrl: './progress-bar.component.html',
})
export class ProgressBarComponent implements OnInit, OnChanges {

  @Input() totalTasks = 0;
  @Input() completedTasks = 0;
  @Input() extraClass = '';

  percent: string = null;

  constructor(
    public userInterfaceService: UserInterfaceService,
    public elementRef: ElementRef,
    public renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    this.populateProgressBar();
  }

  ngOnChanges(sc: SimpleChanges): void {
    if (sc.completedTasks && (sc.completedTasks.currentValue !== sc.completedTasks.previousValue)) {
      this.populateProgressBar();
    }
  }

  ////////////////////////////////////////////////////
  // UX for progress bars looks like this...
  //
  //  <div class="progress">
  //    <span class="progress-bar" role="progressbar"></span>
  //  </div>
  ////////////////////////////////////////////////////

  populateProgressBar(): void {
    try {
      if (this.totalTasks && (this.totalTasks > 0)) {

        const n = (Number(this.completedTasks) / Number(this.totalTasks)) * 100.0;

        this.percent = n.toFixed(0) + '%';

        // console.log('percentForWidth: ' + percentForWidth);
        // $(this).get(0).style.width = percentForWidth;

        const progressBar: HTMLElement = this.elementRef.nativeElement.querySelector('[role="progressbar"]');

        this.renderer.setStyle(progressBar, 'width', this.percent);

      }
    } catch (ex) {
      const msg = 'populateProgressBars()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

}
