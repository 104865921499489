import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { WResource } from 'src/app/client-core/data/resource.model';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { ClosingProService } from '../../../closing-pro.service';
import { ScreenType } from 'src/app/client-core/services/screen-type.enum';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { WEvent } from 'src/app/client-core/data/event.model';
import { UserAuthService } from 'src/app/client-core/services/user-auth.service';
import { User } from 'src/app/client-core/data/user.model';

@Component({
  selector: 'wackadoo-task-templates-panel',
  templateUrl: './task-templates-panel.component.html',
})
export class TaskTemplatesPanelComponent implements OnInit, OnDestroy {

  @Input() title: string = null;
  @Input() taskTemplateGroupID: number = null;
  @Input() contractType: string = null;

  @Input() parentTaskTemplate: WResource = null;

  private _selectedTaskTemplate: WResource = null;
  private _selectTaskTemplateSubscription: Subscription = null;
  @Input() selectTaskTemplateSubject: Subject<WResource> = null;

  private _refreshPanelDate: Date = null;

  @Input() childListenerSubject: Subject<WResource> = null;
  @Input() notifyParentSubject: Subject<WResource> = null;

  @Input() dragNDropMode: string = null;

  private _refreshPanelSubscription: Subscription = null;
  @Input() refreshPanelSubject: Subject<void> = null;

  constructor(
    public closingProService: ClosingProService,
    public userInterfaceService: UserInterfaceService,
    public eventServerService: EventServerService,
    public modalDialogService: ModalDialogService,
    public userAuthService: UserAuthService,
  ) {}

  ngOnInit(): void {

    this._selectTaskTemplateSubscription = this.selectTaskTemplateSubject.subscribe(
      (task: WResource) => {
        // console.log(this.title + ' - selectTaskSubject()', task.taskName.value);
        this._selectedTaskTemplate = task;
      }
    );

    this.refreshPanelSubject = new Subject<void>();

    this._refreshPanelSubscription = this.refreshPanelSubject.subscribe(
      () => {
        this.refreshPanel();
      }
    );

  }

  ngOnDestroy(): void {

    if (this._selectTaskTemplateSubscription) {
      this._selectTaskTemplateSubscription.unsubscribe();
    }

    if (this._refreshPanelSubscription) {
      this._refreshPanelSubscription.unsubscribe();
    }

  }

  get user(): User {
    return this.userAuthService.currentUser.getValue();
  }

  isSelected(taskTemplateID: number): boolean {
    // console.log('isSelected()', this._selectedTaskTemplate.taskTemplateID.value, taskTemplateID);
    return (this._selectedTaskTemplate && this._selectedTaskTemplate.taskTemplateID && (this._selectedTaskTemplate.taskTemplateID.value === taskTemplateID));
  }

  get screenType(): ScreenType {
    return this.userInterfaceService.screenType.getValue();
  }

  get selectedTaskTemplateID(): number {
    return (this._selectedTaskTemplate ? this._selectedTaskTemplate.taskTemplateID.value : null);
  }

  refreshPanel(): void {
    // console.log(this.title + ' - refreshPanel()');
    // setting this date to something new will force the panel to refresh...
    this._refreshPanelDate = new Date();
  }

  newTaskTemplate(): void {
    try {
      const taskTemplateResource = this.eventServerService.newResource('TaskTemplates');
      // console.log('TaskTemplatesPanelComponent.newTaskTemplate() - taskTemplateResource', taskTemplateResource);

      taskTemplateResource.taskTemplateGroupID.value = this.taskTemplateGroupID;
      taskTemplateResource.taskName.value = 'New Task Name Goes Here...';
      taskTemplateResource.taskType.value = 'task';

      // console.log('TaskTemplatesPanelComponent.newTaskTemplate()', 'contractType', this.contractType);
      taskTemplateResource.contractType.value = this.contractType;
      taskTemplateResource.clientRole.value = (this.contractType === 'Buyer' ? 'Buyer' : 'Seller');

      // we put it first on the list to be very noticiable...
      taskTemplateResource.sequence.value = 0;

      // We ONLY set the parentTaskTemplateID if we have a taskTemplateID.
      // (if we DON'T set the parentTaskTemplateID, it is added as a top level task)
      const parent = (this.parentTaskTemplate ? this.parentTaskTemplate.asParms : null);
      // console.log('TaskTemplatesPanelComponent.newTaskTemplate() - parent', parent);
      if (parent && (parent.taskTemplateID)) {
        taskTemplateResource.parentTaskTemplateID.value = parent.taskTemplateID;
      }

      // console.log('TaskTemplateModalComponent.newTaskTemplate()', 'taskTemplateResource', taskTemplateResource);

      this.modalDialogService.addResourceModal('TaskTemplateModalComponent', 'TaskTemplates', taskTemplateResource).subscribe(
        (event: WEvent) => {
          if (event) {
            if (event.status !== 'OK') {
              this.modalDialogService.showAlert('Unable to add new TaskTemplate!\n' + event.message, 'Error!');
            }
            this.refreshPanel();
          }
        }
      );

    } catch (ex) {
      console.log(ex.stack);
      const msg = 'TaskTemplatesPanelComponent.newTaskTemplate()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Admittedly, this is a little screwy, but we fire different Events, with
  // different actions and parms, for the top-level vs 2nd/3rd panels...
  /////////////////////////////////////////////////////////////////////////////

  getActionForResourceBlock(): string {
    return (this.parentTaskTemplate ? 'listChildrenWithChildCounts' : 'listWithChildCounts');
  }

  getParmsForResourceBlock(): any {
    const parms: any = {};
    if (this.parentTaskTemplate) {
      parms.taskTemplateID = this.parentTaskTemplate.taskTemplateID.value;
    } else {
      parms.taskTemplateGroupID = this.taskTemplateGroupID;
    }
    parms.contractType = this.contractType;
    parms.pageSize = -1;
    parms.sortBy = 'sequence';
    parms.sortDirection = 1;

    // We force a ResourceBlock to refresh by making the parameters different than they were before...
    if (this._refreshPanelDate) {
      parms.someFakeParameterThatWillBeIgnored = this._refreshPanelDate;
    }

    return parms;
  }

}
