import { Component } from '@angular/core';
import { ResourceComponent } from 'src/app/client-core/data/resource/resource.component';

@Component({
  selector: 'wackadoo-note-grid-phone-content',
  templateUrl: './note-grid-phone-content.component.html',
})
export class NoteGridPhoneContentComponent extends ResourceComponent {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'NoteGridPhoneContentComponent';

  get contractIsSelected(): boolean {
    // If a Contract is selected... ... by ANY page that deals w/Contracts...
    return (this.userInterfaceService.getSelectedResourceByResourceType('Contract') !== null);
  }

}
