import { Injectable } from '@angular/core';
import { WResource } from '../data/resource.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MultiSelectListService {

  private _activeLists: {[name: string]: Array<WResource>} = {};
  private _selectSubjects: {[name: string]: Subject<WResource> } = {};
  private _unSelectSubjects: {[name: string]: Subject<WResource>} = {};

  constructor(
  ) {
  }

  createList(name: string, selectSubject: Subject<WResource>, unSelectSubject: Subject<WResource>): void {
    this._validateNonExistence(name);

    // console.log('createList() - enter', this._activeLists[name], this._selectSubjects[name], this._unSelectSubjects[name]);

    this._activeLists[name] = new Array<WResource>();
    this._selectSubjects[name] = (selectSubject ? selectSubject : null);
    this._unSelectSubjects[name] = (unSelectSubject ? unSelectSubject : null);

    // console.log('createList() - exit', this._activeLists[name], this._selectSubjects[name], this._unSelectSubjects[name]);
  }

  destroyList(name: string): void {
    if (this._activeLists.hasOwnProperty(name)) {

      // console.log('destroyList() - enter', this._activeLists[name], this._selectSubjects[name], this._unSelectSubjects[name]);

      this._activeLists[name] = null;
      this._selectSubjects[name] = null;
      this._unSelectSubjects[name] = null;

      delete this._activeLists[name];
      delete this._selectSubjects[name];
      delete this._unSelectSubjects[name];

    }
    // console.log('destroyList() - exit', this._activeLists[name], this._selectSubjects[name], this._unSelectSubjects[name]);
  }

  clearList(name: string): void {
    if (this._activeLists.hasOwnProperty(name)) {
      this._activeLists[name] = [];
      // tell the listener to un-select all...
      this._unSelectSubjects[name].next(null);
    }
    // console.log('clearList() - exit', this._activeLists[name]);
  }

  getSelectedResources(name: string): Array<WResource> {
    this._validateExistence(name);

    return this._activeLists[name];
  }

  selectResource(name: string, resource: WResource): void {
    this._validateExistence(name);

    // console.log('selectResource()', this._activeLists[name], resource.keyField.name + ' = ' + resource.keyField.value, resource, new Error().stack);

    // if it's already in the list, we un-select it first, then replace it.

    const idxInList = this._activeLists[name].findIndex((r: WResource) => r.keyField.value === resource.keyField.value);
    if (idxInList >= 0 ) {
      // throw new Error('Item is already in the list: ' + resource.keyField.displayValue);
      // this._activeLists[name].splice(idxInList, 1, resource);
      this.unSelectResource(name, resource);
    }

    // we don't care about the order internally, so we just push the resource onto the array...
    this._activeLists[name].push(resource);

    // finally, we notify everybody that it was selected...
    if (this._selectSubjects[name]) {
      this._selectSubjects[name].next(resource);
    }
  }

  unSelectResource(name: string, resource: WResource): void {
    this._validateExistence(name);

    // delete the appropriate resource from the array...
    const idx = this._activeLists[name].findIndex((r: WResource) => r.keyField.value === resource.keyField.value);
    if (idx >= 0) {
      this._activeLists[name].splice(idx, 1);
      if (this._unSelectSubjects[name]) {
        this._unSelectSubjects[name].next(resource);
      }
    }
  }

  private _validateExistence(name: string): void {
    if (!name) {
      throw new Error('Invalid name: ' + name);
    }

    if (!this._activeLists.hasOwnProperty(name)) {
      throw new Error('List has not been created: ' + name);
    }
  }

  private _validateNonExistence(name: string): void {
    if (!name) {
      throw new Error('Invalid name: ' + name);
    }

    if (this._activeLists.hasOwnProperty(name)) {
      throw new Error('List has already been created: ' + name);
    }
  }

}
