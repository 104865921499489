import { NgModule, enableProdMode, APP_INITIALIZER } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ChartsModule } from 'ng2-charts';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { EditorModule } from '@tinymce/tinymce-angular';

// core wackadoo-client app stuff
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BusinessRules } from 'src/app/client-core/services/business-rules.model';
import { BusinessRuleService } from 'src/app/client-core/services/business-rule.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { WEvent } from 'src/app/client-core/data/event.model';
import { WLong } from 'src/app/client-core/data/field.model';

// common stuff pulled in by ALL web-app modules ...
import { Globals } from 'src/app/client-core/services/global.service';
import { ClientCoreModule } from 'src/app/client-core/client-core.module';
import { ClientCoreRoutingModule } from 'src/app/client-core/client-core-routing.module';

// for APP_INITIALIZER
import { UserAuthService } from 'src/app/client-core/services/user-auth.service';

// These are the custom pages
import { ActiveContractsComponent } from './pages/active-contracts/active-contracts.component';
import { AgentsComponent } from './pages/agents/agents.component';
import { ContractsComponent } from './pages/contracts/contracts.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { PizzaTrackerComponent } from './pages/pizza-tracker/pizza-tracker.component';
import { PizzaTracker2Component } from './pages/pizza-tracker2/pizza-tracker2.component';
import { BusinessDashboardComponent } from './pages/business-dashboard/business-dashboard.component';
import { TaskListComponent } from './pages/task-list/task-list.component';
import { VendorActivityComponent } from './pages/vendor-activity/vendor-activity.component';
import { MyContractsComponent } from './pages/my-contracts/my-contracts.component';
import { InBoxComponent } from './pages/in-box/in-box.component';

// These are the Content and ButtonBar and other sub-page components
import { ClosingProApplicationHeaderComponent } from './ui/application-header/closing-pro-application-header.component';
import { ClosingProPublicContentComponent } from './ui/public-content/closing-pro-public-content.component';
import { ContractGridButtonBarComponent } from './pages/contracts/contract-grid-button-bar/contract-grid-button-bar.component';
import { ContractDetailContentComponent } from './pages/contracts/contract-detail-content/contract-detail-content.component';
import { AgentsDetailContentComponent } from './pages/agents/agents-detail-content/agents-detail-content.component';
import { AgentsDetailButtonBarComponent } from './pages/agents/agents-detail-button-bar/agents-detail-button-bar.component';
import { ContractDetailButtonBarComponent } from './pages/contracts/contract-detail-button-bar/contract-detail-button-bar.component';
import { ContractHeaderComponent } from './ui/contract-header/contract-header.component';
import { TaskNameWithIconComponent } from './ui/task-name-with-icon/task-name-with-icon.component';
import { ClientModalComponent } from './ui/client-modal/client-modal.component';
import { ContractParticipantModalComponent } from './ui/contract-participant-modal/contract-participant-modal.component';
import { CoOpAgentModalComponent } from './ui/co-op-agent-modal/co-op-agent-modal.component';
import { EscrowAgentModalComponent } from './ui/escrow-agent-modal/escrow-agent-modal.component';
import { AttorneyModalComponent } from './ui/attorney-modal/attorney-modal.component';
import { LenderModalComponent } from './ui/lender-modal/lender-modal.component';
import { TaskListPanelComponent } from './pages/task-list/task-list-panel/task-list-panel.component';
import { TaskListPanelItemComponent } from './pages/task-list/task-list-panel-item/task-list-panel-item.component';
import { ProgressBarComponent } from './ui/progress-bar/progress-bar.component';
import { TaskRelativeModalComponent } from './pages/task-list/task-relative-modal/task-relative-modal.component';
import { TaskModalComponent } from './ui/task-modal/task-modal.component';
import { RelatedEntityDetailComponent } from './ui/task-modal/related-entity-detail/related-entity-detail.component';
import { SelectContractIDModalComponent } from './pages/task-list/select-contract-id-modal/select-contract-id-modal.component';
import { VendorModalComponent } from './ui/vendor-modal/vendor-modal.component';
import { TaskSchedulerModalComponent } from './pages/task-list/task-scheduler-modal/task-scheduler-modal.component';
import { TaskReminderModalComponent } from './pages/task-list/task-reminder-modal/task-reminder-modal.component';
import { TaskTemplatesComponent } from './pages/task-templates/task-templates.component';
import { TaskTemplatesPanelComponent } from './pages/task-templates/task-templates-panel/task-templates-panel.component';
import { TaskTemplatesPanelItemComponent } from './pages/task-templates/task-templates-panel-item/task-templates-panel-item.component';
import { TaskTemplatesRelativeModalComponent } from './pages/task-templates/task-templates-relative-modal/task-templates-relative-modal.component';
import { TaskTemplatesReminderModalComponent } from './pages/task-templates/task-templates-reminder-modal/task-templates-reminder-modal.component';
import { TaskTemplateModalComponent } from './pages/task-templates/task-template-modal/task-template-modal.component';
import { TaskTemplatesSelectContractTypeModalComponent } from './pages/task-templates/task-templates-select-contract-type-modal/task-templates-select-contract-type-modal.component';
import { TaskTemplatesSelectTemplateGroupModalComponent } from './pages/task-templates/task-templates-select-template-group-modal/task-templates-select-template-group-modal.component';
import { InBoxDetailContentComponent } from './pages/in-box/in-box-detail-content/in-box-detail-content.component';
import { InBoxDetailButtonBarComponent } from './pages/in-box/in-box-detail-button-bar/in-box-detail-button-bar.component';
import { StaffMembersComponent } from './pages/staff-members/staff-members.component';
import { StaffMemberDetailContentComponent } from './pages/staff-members/staff-member-detail-content/staff-member-detail-content.component';
import { StaffMemberDetailButtonBarComponent } from './pages/staff-members/staff-member-detail-button-bar/staff-member-detail-button-bar.component';
import { MyTaskListComponent } from './pages/my-task-list/my-task-list.component';
import { CoOpAgentsComponent } from './pages/co-op-agents/co-op-agents.component';
import { CoOpAgentsDetailContentComponent } from './pages/co-op-agents/co-op-agents-detail-content/co-op-agents-detail-content.component';
import { CoOpAgentsDetailButtonBarComponent } from './pages/co-op-agents/co-op-agents-detail-button-bar/co-op-agents-detail-button-bar.component';
import { EscrowAgentsComponent } from './pages/escrow-agents/escrow-agents.component';
import { EscrowAgentDetailContentComponent } from './pages/escrow-agents/escrow-agent-detail-content/escrow-agent-detail-content.component';
import { RelatedTaskDetailComponent } from './ui/related-task-detail/related-task-detail.component';
import { ICSVCFDetailButtonBarComponent } from './ui/ics-vcf-detail-button-bar/ics-vcf-detail-button-bar.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { ClientDetailContentComponent } from './pages/clients/client-detail-content/client-detail-content.component';
import { AttorneysComponent } from './pages/attorneys/attorneys.component';
import { AttorneyDetailContentComponent } from './pages/attorneys/attorney-detail-content/attorney-detail-content.component';
import { AttorneyDetailButtonBarComponent } from './pages/attorneys/attorney-detail-button-bar/attorney-detail-button-bar.component';
import { LendersComponent } from './pages/lenders/lenders.component';
import { LenderDetailContentComponent } from './pages/lenders/lender-detail-content/lender-detail-content.component';
import { LenderDetailButtonBarComponent } from './pages/lenders/lender-detail-button-bar/lender-detail-button-bar.component';
import { VendorsComponent } from './pages/vendors/vendors.component';
import { VendorDetailContentComponent } from './pages/vendors/vendor-detail-content/vendor-detail-content.component';
import { VendorActionComponent } from './pages/vendor-activity/vendor-action/vendor-action.component';
import { VendorActivityModalComponent } from './pages/vendor-activity/vendor-activity-modal/vendor-activity-modal.component';
import { TodaysHotListComponent } from './pages/todays-hot-list/todays-hot-list.component';
import { NotesComponent } from './pages/notes/notes.component';
import { NoteGridPhoneContentComponent } from './pages/notes/note-grid-phone-content/note-grid-phone-content.component';
import { MergeDuplicatePeopleComponent } from './pages/merge-duplicate-people/merge-duplicate-people.component';
import { DuplicateClientsComponent } from './pages/merge-duplicate-people/duplicate-clients.component';
import { DuplicateAttorneysComponent } from './pages/merge-duplicate-people/duplicate-attorneys.component';
import { DuplicateCoOpAgentsComponent } from './pages/merge-duplicate-people/duplicate-co-op-agents.component';
import { DuplicateLendersComponent } from './pages/merge-duplicate-people/duplicate-lenders.component';
import { ClientDetailButtonBarComponent } from './pages/clients/client-detail-button-bar/client-detail-button-bar.component';
import { ContractHistoryComponent } from './ui/contract-history/contract-history.component';
import { AccountMetaDataComponent } from './pages/account-meta-data/account-meta-data.component';
import { AccountMetaDataDetailComponent } from './pages/account-meta-data/account-meta-data-detail/account-meta-data-detail.component';
import { QuickViewTaskComponent } from './pages/quick-view/quick-view-task.component';
import { UpcomingClosingsComponent } from './pages/quick-view/upcoming-closings/upcoming-closings.component';
import { ClosingComponent } from './pages/quick-view/upcoming-closings/closing/closing.component';
import { ClosingComplianceTaskComponent } from './pages/quick-view/upcoming-closings/closing-compliance-task/closing-compliance-task.component';
import { ClosingNoteComponent } from './pages/quick-view/upcoming-closings/closing-note/closing-note.component';
import { NewContractsComponent } from './pages/quick-view/new-contracts/new-contracts.component';
import { NewContractComponent } from './pages/quick-view/new-contracts/new-contract/new-contract.component';
import { InitialFundsComponent } from './pages/quick-view/initial-funds/initial-funds.component';
import { InitialFundComponent } from './pages/quick-view/initial-funds/initial-fund/initial-fund.component';
import { RecentClosingsComponent } from './pages/quick-view/recent-closings/recent-closings.component';
import { RecentClosingComponent } from './pages/quick-view/recent-closings/recent-closing/recent-closing.component';

// enable production mode code operation
enableProdMode();

////////////////////////////////////////////////////////
// Let everybody know the ROOT application component...
////////////////////////////////////////////////////////

Globals.rootApplication = 'ClosingPro';
Globals.thisApplication = 'ClosingPro';

document.title = Globals.rootApplication;

// app initialization stuff
export function initializeUserAuthentication(userAuthService: UserAuthService): () => Promise<any> {
  return (): Promise<any> => {
    return userAuthService.bootupInitialization();
  };
}

@NgModule({
  declarations: [

    // app stuff
    AppComponent,

    ActiveContractsComponent,
    AgentsComponent,
    ContractsComponent,
    CalendarComponent,
    PizzaTrackerComponent,
    PizzaTracker2Component,
    BusinessDashboardComponent,
    TaskListComponent,
    VendorActivityComponent,
    ContractDetailContentComponent,
    MyContractsComponent,
    AgentsDetailContentComponent,
    AgentsDetailButtonBarComponent,
    InBoxComponent,

    // app-specific components
    ClosingProApplicationHeaderComponent,
    ClosingProPublicContentComponent,
    ContractGridButtonBarComponent,
    ContractDetailButtonBarComponent,
    ContractHeaderComponent,
    TaskNameWithIconComponent,
    ClientModalComponent,
    ContractParticipantModalComponent,
    CoOpAgentModalComponent,
    EscrowAgentModalComponent,
    AttorneyModalComponent,
    LenderModalComponent,
    TaskListPanelComponent,
    TaskListPanelItemComponent,
    ProgressBarComponent,
    TaskRelativeModalComponent,
    TaskModalComponent,
    RelatedEntityDetailComponent,
    SelectContractIDModalComponent,
    VendorModalComponent,
    TaskSchedulerModalComponent,
    TaskReminderModalComponent,
    TaskTemplatesComponent,
    TaskTemplatesPanelComponent,
    TaskTemplatesPanelItemComponent,
    TaskTemplatesRelativeModalComponent,
    TaskTemplatesReminderModalComponent,
    TaskTemplateModalComponent,
    TaskTemplatesSelectContractTypeModalComponent,
    TaskTemplatesSelectTemplateGroupModalComponent,
    InBoxDetailContentComponent,
    InBoxDetailButtonBarComponent,
    StaffMembersComponent,
    StaffMemberDetailContentComponent,
    StaffMemberDetailButtonBarComponent,
    MyTaskListComponent,
    CoOpAgentsComponent,
    CoOpAgentsDetailContentComponent,
    CoOpAgentsDetailButtonBarComponent,
    EscrowAgentsComponent,
    EscrowAgentDetailContentComponent,
    RelatedTaskDetailComponent,
    ICSVCFDetailButtonBarComponent,
    ClientsComponent,
    ClientDetailContentComponent,
    AttorneysComponent,
    AttorneyDetailContentComponent,
    AttorneyDetailButtonBarComponent,
    LendersComponent,
    LenderDetailContentComponent,
    LenderDetailButtonBarComponent,
    VendorsComponent,
    VendorDetailContentComponent,
    VendorActionComponent,
    VendorActivityModalComponent,
    TodaysHotListComponent,
    NotesComponent,
    NoteGridPhoneContentComponent,
    MergeDuplicatePeopleComponent,
    DuplicateClientsComponent,
    DuplicateAttorneysComponent,
    DuplicateCoOpAgentsComponent,
    DuplicateLendersComponent,
    ClientDetailButtonBarComponent,
    ContractHistoryComponent,
    AccountMetaDataComponent,
    AccountMetaDataDetailComponent,

    // New WIP Stuff
    QuickViewTaskComponent,
    UpcomingClosingsComponent,
    ClosingComponent,
    ClosingComplianceTaskComponent,
    ClosingNoteComponent,
    NewContractsComponent,
    NewContractComponent,
    InitialFundsComponent,
    InitialFundComponent,
    RecentClosingsComponent,
    RecentClosingComponent,

  ],
  imports: [
    // Angular stuff...
    BrowserModule,
    HttpClientModule,
    FormsModule,

    // 3rd party stuff everybody uses...
    NgbModule,
    FontAwesomeModule,
    ChartsModule,
    EditorModule,

    // top-level routing module...
    AppRoutingModule,

    // common web app components
    ClientCoreModule,
    ClientCoreRoutingModule,

  ],
  exports: [],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initializeUserAuthentication, deps: [UserAuthService], multi: true},
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ClosingProApplicationHeaderComponent,
    ClosingProPublicContentComponent,
    AgentsDetailContentComponent,
    AgentsDetailButtonBarComponent,
    ContractGridButtonBarComponent,
    ContractDetailContentComponent,
    ContractDetailButtonBarComponent,
    ClientModalComponent,
    ContractParticipantModalComponent,
    CoOpAgentModalComponent,
    AttorneyModalComponent,
    LenderModalComponent,
    EscrowAgentModalComponent,
    TaskRelativeModalComponent,
    TaskModalComponent,
    SelectContractIDModalComponent,
    VendorModalComponent,
    TaskSchedulerModalComponent,
    TaskReminderModalComponent,
    TaskTemplatesRelativeModalComponent,
    TaskTemplatesReminderModalComponent,
    TaskTemplateModalComponent,
    TaskTemplatesSelectContractTypeModalComponent,
    TaskTemplatesSelectTemplateGroupModalComponent,
    InBoxDetailContentComponent,
    InBoxDetailButtonBarComponent,
    StaffMemberDetailContentComponent,
    StaffMemberDetailButtonBarComponent,
    CoOpAgentsDetailContentComponent,
    CoOpAgentsDetailButtonBarComponent,
    EscrowAgentDetailContentComponent,
    RelatedTaskDetailComponent,
    ICSVCFDetailButtonBarComponent,
    ClientDetailButtonBarComponent,
    ClientDetailContentComponent,
    AttorneyDetailContentComponent,
    AttorneyDetailButtonBarComponent,
    LenderDetailContentComponent,
    LenderDetailButtonBarComponent,
    VendorDetailContentComponent,
    VendorActivityModalComponent,
    NoteGridPhoneContentComponent,
    AccountMetaDataDetailComponent,
  ]
})
export class AppModule {

  constructor(
    public businessRuleService: BusinessRuleService,
    // The only reason we included UserInterfaceService here is that it
    // HAS to be loaded PRIOR to the initial page load in order to catch
    // the NavigationStart routing event for that initial page load
    public userInterfaceService: UserInterfaceService,
  ) {
    this.loadBusinessRules();
  }

  // ServerRequestRule fn()s look like this simple example...
  // fn(eh: string, action: string, parms: any): EventRequestDefinition {
  //   const request: EventRequestDefinition = {eh, action, parms};
  //   // munge request here
  //   return request;
  // }

  // ServerResponseRule fn()s look like this simple example...
  // fn(event2: WEvent): WEvent {
  //   // munge event here...
  //   return event2;
  // }

  convertAccountIDFieldToLong(event: WEvent): WEvent {
    if (event.resources) {
      for (const resource of event.resources) {
        if (resource.accountID) {
          const f = new WLong('accountID', resource.accountID.value);
          f.displayValue = resource.accountID.displayValue;
          resource.accountID = f;
        }
      }
    }
    return event;
  }

  // async fireEventSynchronous(eventHandler: string, action: string, parms: any): Promise<WEvent> {
  //   const response = await this.fireEvent(eventHandler, action, parms).toPromise();
  //   return response;
  // }

  loadBusinessRules(): void {

    const rules: BusinessRules = {

        purchaserRole: 'director',

        firstEHPage : {
          public : 'InBox'
          , staff : 'MyContracts'
          , member : 'PizzaTracker'
          , '*' : 'ActiveContracts'
        },

        defaultPageSizes : {
          ActiveContracts : 25
          , MyContracts : 25
          , Contracts : 25
          , Clients : 25
          , Agents : 25
          , CoOpAgents : 25
          , Attorneys : 25
          , Lenders : 25
          , EscrowAgents : 25
          , EMailTemplates : 25
          , ReportTemplates : 25
          , Notes : 25
          , History : 25
        },

        defaultActions : {
          InBox: 'add'
        },

        otherMenuItems : [
          'Calendar'
          , 'TaskList'
          , 'MyTaskList'
          , 'PizzaTracker'
          , 'VendorActivity'
          , 'NewContracts'
          , 'InitialFunds'
          , 'UpcomingClosings'
          , 'RecentClosings'
          , 'BusinessDashboard'
          , 'DuplicateClients'
          , 'DuplicateAttorneys'
          , 'DuplicateLenders'
          , 'DuplicateCoOpAgents'
        ],

        otherPublicPages: [
        ],

        menuRules : [
          {
            _selection : 'ActiveContracts',
            _role: 'manager+'

            , AccountMetaData : { role : 'director+' }
            , InBox : { role : 'manager+' }

            , QuickView : {
              subMenu: {
                TodaysHotList : { role : 'manager+' }
                , NewContracts : { role : 'manager+' }
                , InitialFunds : { role : 'manager+' }
                , UpcomingClosings : { role : 'manager+' }
                , RecentClosings : { role : 'manager+' }
              }
            }

            , ActiveContracts : { role : 'manager+' }
            , TaskList : { role : 'manager+' }
            , Calendar : { role : 'manager+' }
            , VendorActivity : { role : 'manager+' }
            , History : { role : 'manager+' }
            , Tasks : { role : 'admin+' }
            , Reports : { role : 'manager+' }
          }
          , {
            _selection : 'Contracts',
            _role: 'manager+'

            , AccountMetaData : { role : 'director+' }
            , InBox : { role : 'manager+' }

            , QuickView : {
              subMenu: {
                TodaysHotList : { role : 'manager+' }
                , NewContracts : { role : 'manager+' }
                , InitialFunds : { role : 'manager+' }
                , UpcomingClosings : { role : 'manager+' }
                , RecentClosings : { role : 'manager+' }
              }
            }

            , Contracts : { role : 'manager+' }
            , TaskList : { role : 'manager+' }
            , Calendar : { role : 'manager+' }
            , VendorActivity : { role : 'manager+' }
            , History : { role : 'manager+' }
            , Tasks : { role : 'admin+' }
            , Reports : { role : 'manager+' }
          }
          , {
            _selection : 'MyContracts',
            _role: 'staff'

            , InBox : { role : 'staff+' }
            , MyContracts : { role : 'staff+' }
            , MyTaskList : { role : 'staff+' }
            , DueDiligence : { role : 'staff+' }
            , History : { role : 'staff+' }
            , VendorActivity : { role : 'staff+' }
          }
          , {
            _selection :  '*',
            _role : '*'

            , AccountMetaData : { role : 'director+' }
            , InBox : { role : 'staff+' }

            , QuickView : {
              subMenu: {
                TodaysHotList : { role : 'manager+' }
                , NewContracts : { role : 'manager+' }
                , InitialFunds : { role : 'manager+' }
                , UpcomingClosings : { role : 'manager+' }
                , RecentClosings : { role : 'manager+' }
              }
            }

            , ActiveContracts : { role : 'manager+' }
            , Calendar : { role : 'manager+' }

            , MyContracts : { role : 'staff' }

            , People : {
              subMenu: {
                StaffMembers : { role : 'manager+' }
                , Agents : { role : 'manager+' }
                , CoOpAgents : { role : 'manager+' }
                , Clients : { role : 'manager+' }
                , Attorneys : { role : 'manager+' }
                , Lenders : { role : 'manager+' }
                , EscrowAgents : { role : 'manager+' }
                , Vendors : { role : 'manager+' }
              }
            }

            , 'Contract Archives' : {
              subMenu: {
                Contracts : { role : 'manager+' }
                , Tasks : { role : 'admin+' }
                , Notes : { role : 'manager+' }
                , History : { role : 'manager+' }
              }
            }

            , CustomTemplates : {
              subMenu: {
                TaskTemplates : { role : 'manager+' }
                , EMailTemplates : { role : 'manager+' }
                , ReportTemplates : { role : 'manager+' }
                // , FormFillerTemplates : { role : 'manager+' }
                // , FormFillers : { role : 'admin+' }
                // , FormFillerFields : { role : 'admin+' }
              }
            }

            , BackupRestore : { role : 'manager+' }
            // , PizzaTracker : { role : 'member' }
            , PizzaTracker : { role : 'guest' }
            , BusinessDashboard : { role : 'director+' }
            // , SMTPServers : { role: 'staff+'}
            , Reports : { role : 'manager+' }

            // These are either not yet implemented, or never will be...
            // , AdminStuff : {
            //   subMenu : {
            //     Administration : { role : 'admin+' }
            //     , AccountMetaData : { role : 'admin+' }
            //     , EMailErrors : { role : 'admin+' }
            //     , ApplicationMetaData : { role : 'admin+' }
            //     , SearchService : { role : 'admin+' }
            //     , SearchEngineUpdater : { role : 'admin+' }
            //    }
            // }
          }
        ],

        menuIcons : {
            Administration: 'cog'
            , SecurityServices: 'lock'
            , BackupService: 'save'
            , Reports: 'print'
            , CustomTemplates: 'file-alt'
            , BackupRestore:  'cloud'
            , ServerMonitor: 'server'
            , SearchService: 'search'
            , SearchEngineUpdater: 'redo'
            , Usage: 'signal'
            , LogExtractor: 'share-alt'
            , BackupCleanupService: 'trash'
            , LogCleanupService: 'trash-alt'
            , Broadcast: 'volume-up'
            , EMailTemplates: 'envelope-square'
            , ReportTemplates: 'file-alt'
            , ImportTemplates: 'file-upload'
            , ImportTemplateFields:  'file-import'
            , FormFillerTemplates: 'paste'
            , FormFillers: 'paste'
            , FormFillerFields: 'paste'
            , EMailer : 'envelope'
            , BulkEMailer: 'mail-bulk'
            , EMailErrors: 'exclamation-triangle'
            , SMTPServers: 'mail-bulk'

            // main app
            , AccountMetaData: 'list'
            , InBox : 'inbox'
            , Contracts : 'home'
            , ActiveContracts : 'folder-open'

            , MyContracts : 'folder-open'
            , MyTaskList : 'tasks'

            , QuickView : 'list-alt'
            , TodaysHotList : 'fire red'
            , NewContracts : 'list-alt'
            , InitialFunds : 'list-alt'
            , UpcomingClosings : 'list-alt'
            , RecentClosings : 'list-alt'

            , Tasks : 'check'
            , TaskList : 'tasks'
            , TaskTemplates : 'check-square'
            , Notes : 'pencil-alt'
            , History : 'pencil-alt'
            , CustomGenerator : 'cog'

            // , TaskTemplateGroups : 'link'

            , AttachmentFiles : 'file'
            , Agents : 'user'
            , CoOpAgents : 'user-shield'
            , Clients : 'house-user'
            , Attorneys : 'briefcase'
            , Lenders : 'piggy-bank'
            , EscrowAgents : 'lock'
            , Vendors : 'wrench'
            , PreferredVendors : 'link'

            , ContractParticipants : 'link'

            , VendorActivity : 'wrench'
            , Inspections : 'calendar'
            , ReInspections : 'calendar'
            , Estimates : 'calendar'
            , Repairs : 'calendar'
            , Marketing : 'calendar'

            , Calendar : 'calendar'
            , PizzaTracker : 'pizza-slice'

            , Invoices : 'file-invoice-dollar'
            , BusinessDashboard : 'tachometer-alt'
            , StaffMembers : 'user-cog'

            , People : 'users'
            , 'Contract Archives' : 'archive'
        },

        serverRequestRules : [
        ],

        serverResponseRules : [
          { eh: 'StaffMembers', action: 'list', fn: this.convertAccountIDFieldToLong }
        ],

        readOnlyOnceRules : [
        ],

        menuClickRules : [
        ],

        foreignKeysThatMustBePopulated : {
        },

        importFormatRules : {
        },

        editableReportSystemTemplates: [
           'Invoice'
        ],

    };

    this.businessRuleService.loadRules('ClosingPro', rules);
  }

}
