import { Component, OnInit } from '@angular/core';
import { RepositoryPageComponent } from 'src/app/client-core/pages/repository-page/repository-page.component';

@Component({
  selector: 'wackadoo-escrow-agents',
  templateUrl: '../../../../../../src/app/client-core/pages/repository-page/repository-page.component.html',
})
export class EscrowAgentsComponent extends RepositoryPageComponent implements OnInit {

  ngOnInit(): void {
    this.detailContentComponentClassName = 'EscrowAgentDetailContentComponent';
    this.detailButtonBarComponentClassName = 'ICSVCFDetailButtonBarComponent';

    super.ngOnInit();
  }

}
