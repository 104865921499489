<div class="card">
    <div class="card-header">
        <div>
            Copying Task "{{ resource.taskName.value }}" (and sub-Tasks) To A Different Contract
        </div>
        <div class="smallish unbold italic">
            Note: Clicking "Save"" will copy this as a new "top level" task in the destination contract, AND you will switch to that contract.
        </div>
        
    </div>
    <div class="card-body">
        <div class="d-flex justify-content-center">
            <div class="">
                Destination Contract:
            </div>
            <div class="marginLeftHalfEm">
                <wackadoo-field [f]="resource.contractID" mode="write"></wackadoo-field>
            </div>
        </div>
    </div>
</div>
