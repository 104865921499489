<div class="marginTop6 row wackadoo-detail">
    <div class="col-sm-6">
        <div class="card">
            <div class="card-header floatGroup">
                <div class="floatLeft">
                    Escrow Agent
                </div>
                <div class="floatRight">
                    {{ resource.escrowAgentID.value }}
                </div>
            </div>
            <div class="card-body">
                <div><wackadoo-field [f]="resource.escrowAgentFirstName" [mode]="mode"></wackadoo-field><wackadoo-field [f]="resource.escrowAgentLastName" [mode]="mode"></wackadoo-field></div>
                <div class="marginTopQuarterEm"><wackadoo-field [f]="resource.escrowAgentCompany" [mode]="mode"></wackadoo-field></div>
                <div class="marginTopQuarterEm"><wackadoo-field [f]="resource.escrowAgentAddress" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.escrowAgentCity" [mode]="mode"></wackadoo-field><wackadoo-field [f]="resource.escrowAgentState" [mode]="mode" [size]="2"></wackadoo-field><wackadoo-field [f]="resource.escrowAgentPostalCode" [mode]="mode"></wackadoo-field></div>
                <div class="marginTopQuarterEm"><wackadoo-field [f]="resource.escrowAgentEmail" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.escrowAgentPhone" [mode]="mode"></wackadoo-field></div>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                Note
            </div>
            <div class="card-body">
                <wackadoo-field [f]="resource.escrowAgentNote" [mode]="mode"></wackadoo-field>
            </div>
        </div>
    </div>
    <div class="col-sm-6" *ngIf="resource.escrowAgentID.value">
        <ng-container *ngIf="resource.escrowAgentID.value">
            <wackadoo-contract-history
                title="Contract History"
                cardHeightClassName="maxHeightViewPort80"
                eventHandler="Contracts"
                action="list"
                [parms]="{ escrowAgentID: resource.escrowAgentID.value, pageSize:-1, sortBy:'closingDate', sortDirection:-1 }"
                defaultText="No contract history..."
                [debug]="false"
            ></wackadoo-contract-history>
        </ng-container>
<!--
        <div class="card">
            <div class="card-header">
                Open, Related Tasks (Top 25)
            </div>
            <div class="card-body smallish maxHeightViewPort50 scrollable-y">
                <div *wackadooResourceBlock="let r of []; let idx = index; eventHandler: 'CustomGenerator'; action: 'listRelatedOpenTasksForEscrowAgent'; parms: {escrowAgentID: resource.escrowAgentID.value, sortBy:'sequence', sortDirection: 1, pageSize: 25 }; defaultText: 'No open tasks...'; debug: false;">
                    <wackadoo-related-task-detail [resource]="r" [mode]="mode"></wackadoo-related-task-detail>
                </div>
            </div>
        </div>
-->
    </div>
</div>
