<div class="card smallish" [ngClass]="{maxHeightViewPort65:(screenType === 'phone')}">
    <div class="card-header large bold">
        <wackadoo-task-name-with-icon [resource]="resource" [mode]="mode" [size]="48"></wackadoo-task-name-with-icon>
    </div>
    <div class="card-body scrollable" [ngClass]="{smallish:(screenType === 'phone')}">
        <div class="" [ngClass]="{'d-flex justify-content-between':(resource.taskTemplateID.isPopulated && (screenType !== 'phone'))}">
            <div class="">
                <div class="d-flex marginTopHalfEm">
                    <div class="">
                        Description:
                    </div>
                    <div class="marginLeftHalfEm fillAvailableWidth smallish italic">
                        <wackadoo-field [f]="resource.taskDescription" [mode]="mode"></wackadoo-field>
                    </div>
                </div>
                <div class="marginTopHalfEm">
                    <table *ngIf="(screenType !== 'phone') else itsAPhone">
<!--
                        <tr>
                            <td class="">Tmplt Grp:</td>
                            <td class="marginLeftHalfEm"><wackadoo-field [f]="resource.taskTemplateGroupID" [mode]="mode"></wackadoo-field></td>
                        </tr>
                        <tr>
                            <td class="">Parent:</td>
                            <td class="marginLeftHalfEm"><wackadoo-field [f]="resource.parentTaskTemplateID" [mode]="mode"></wackadoo-field></td>
                        </tr>
                        <tr *ngIf="user.isManagerOrAbove">
                            <td class="">Seq #:</td>
                            <td class="marginLeftHalfEm"><wackadoo-field [f]="resource.sequence" [mode]="mode"></wackadoo-field></td>
                        </tr>
                        <tr>
                            <td class="">Txn Type:</td>
                            <td class="marginLeftHalfEm "><wackadoo-field [f]="resource.contractType" [mode]="mode"></wackadoo-field></td>
                        </tr>
                        <tr *ngIf="user.isManagerOrAbove">
                            <td class="">Assigned User:</td>
                            <td class="marginLeftHalfEm" *ngIf="user.isManagerOrAbove"><wackadoo-field [f]="resource.userID" [mode]="mode"></wackadoo-field></td>
                        </tr>
                        <tr *ngIf="user.isManagerOrAbove">
                            <td class="">User Role:</td>
                            <td class=""><wackadoo-field [f]="resource.taskRole" [mode]="mode"></wackadoo-field></td>
                        </tr>
-->
                        <tr>
                            <td class="bold">Task Type:</td>
                            <td class=""><wackadoo-field [f]="resource.taskType" [mode]="mode" [onChange]="onTaskTypeChange"></wackadoo-field></td>
                        </tr>
                        <ng-container *ngIf="isVendorActivity">
                            <tr>
                                <td class="right">
                                    Vendor Type:
                                </td>
                                <td>
                                    <div class="d-flex">
                                        <div class="">
                                            <wackadoo-field [f]="resource.vendorType" [mode]="mode"></wackadoo-field>
                                        </div>
                                        <div class="marginLeftWholeEm">
                                            Amount:
                                        </div>
                                        <div class="marginLeftHalfEm">
                                            <wackadoo-field [f]="resource.amount" [mode]="mode"></wackadoo-field>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                        <tr>
                            <td class="right">
                                Duration:
                            </td>
                            <td class="d-flex">
                                <div>
                                    <wackadoo-field [f]="resource.taskDuration" [mode]="mode"></wackadoo-field>
                                </div>
                                <div class="marginLeftHalfEm">
                                    <wackadoo-field [f]="resource.taskDurationTimeUnit" [mode]="mode"></wackadoo-field>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <ng-template #itsAPhone>
                        <div class="d-flex">
                            <div class="bold">Task Type:</div>
                            <div class="marginLeftHalfEm"><wackadoo-field [f]="resource.taskType" [mode]="mode" [onChange]="onTaskTypeChange"></wackadoo-field></div>
                        </div>
                        <div class="d-flex"  *ngIf="isVendorActivity">
                            <div class="">
                                Vendor Type:
                            </div>
                            <div class="marginLeftWholeEm">
                                <wackadoo-field [f]="resource.vendorType" [mode]="mode" ></wackadoo-field>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div>
                                Duration:
                            </div>
                            <div class="marginLeftHalfEm">
                                <wackadoo-field [f]="resource.taskDuration" [mode]="mode"></wackadoo-field>
                            </div>
                            <div class="marginLeftHalfEm">
                                <wackadoo-field [f]="resource.taskDurationTimeUnit" [mode]="mode"></wackadoo-field>
                            </div>
                        </div>
                    </ng-template>
                    <div class="bold marginTopHalfEm" *ngIf="resource.taskType.value === 'email'">
                        EMail Template Name
                    </div>
                    <div class="d-flex" *ngIf="resource.taskType.value === 'email'">
                        <div class="">
                            <wackadoo-field [f]="resource.taskEMailTemplate" [mode]="mode"></wackadoo-field>
                        </div>
                        <div class="marginLeftHalfEm" *ngIf="(screenType !== 'phone')">
                            <button type="button" title="Edit Template" data-abbrev="Template" class="btn btn-secondary actionButton bold small paddingHalfEm" (click)="editEMailTemplate()"><span class="fas fa-pencil-alt" aria-hidden="true"></span></button>
                        </div>
                    </div>
                    <div class="bold marginTopHalfEm">
                        People Associated With This Task
                    </div>
                    <table>
                        <tr>
                            <td class="right" [ngClass]="{'paddingLeft0 paddingRight0':(screenType === 'phone')}">
                                Agent:
                            </td>
                            <td [ngClass]="{'paddingLeft0 paddingRight0':(screenType === 'phone')}">
                                <wackadoo-field [f]="resource.realEstateAgent" [mode]="mode"></wackadoo-field>
                            </td>
                            <td class="right" [ngClass]="{'paddingLeft0 paddingRight0':(screenType === 'phone')}">
                                Client:
                            </td>
                            <td class="d-flex" [ngClass]="{'paddingLeft0 paddingRight0':(screenType === 'phone')}">
                                <div class=""><wackadoo-field [f]="resource.client" [mode]="mode"></wackadoo-field></div>
                                <div class="marginLeftHalfEm"><wackadoo-field [f]="resource.clientRole" [mode]="mode"></wackadoo-field></div>
                            </td>
                        </tr>
                        <tr>
                            <td class="right" [ngClass]="{'paddingLeft0 paddingRight0':(screenType === 'phone')}">
                                Co-Op Agent:
                            </td>
                            <td [ngClass]="{'paddingLeft0 paddingRight0':(screenType === 'phone')}">
                                <wackadoo-field [f]="resource.realEstateAgentCoOp" [mode]="mode"></wackadoo-field>
                            </td>
                            <td class="right" [ngClass]="{'paddingLeft0 paddingRight0':(screenType === 'phone')}">
                                Attorney:
                            </td>
                            <td [ngClass]="{'paddingLeft0 paddingRight0':(screenType === 'phone')}">
                                <wackadoo-field [f]="resource.attorney" [mode]="mode"></wackadoo-field>
                            </td>
                        </tr>
                        <tr>
                            <td class="right" [ngClass]="{'paddingLeft0 paddingRight0':(screenType === 'phone')}">
                                Lender:
                            </td>
                            <td [ngClass]="{'paddingLeft0 paddingRight0':(screenType === 'phone')}">
                                <wackadoo-field [f]="resource.lender" [mode]="mode"></wackadoo-field>
                            </td>
                            <td class="right" [ngClass]="{'paddingLeft0 paddingRight0':(screenType === 'phone')}">
                                Vendor:
                            </td>
                            <td [ngClass]="{'paddingLeft0 paddingRight0':(screenType === 'phone')}">
                                <wackadoo-field [f]="resource.vendor" [mode]="mode"></wackadoo-field>
                            </td>
                        </tr>
                        <tr>
                            <td class="right" [ngClass]="{'paddingLeft0 paddingRight0':(screenType === 'phone')}">
                                Escrow Agent:
                            </td>
                            <td [ngClass]="{'paddingLeft0 paddingRight0':(screenType === 'phone')}">
                                <wackadoo-field [f]="resource.escrowAgent" [mode]="mode"></wackadoo-field>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div *ngIf="resource.taskTemplateID.isPopulated">
                <div class="card">
                    <div class="card-header smallish">
                        <div>
                            Related Tasks
                        </div>
                        <div class="smallish unbold italic">
                            (Use the drag-and-drop tool on the TaskTemplates page to relate the timing of this task to another.)
                        </div>
                    </div>
                    <div class="card-body smallish">
                        <div class="row marginTopHalfEm" *ngIf="!resource.subsequentTaskTemplateID.value">
                            <div class="col-sm-12">
                                This task is NOT scheduled relative to another one.
                            </div>
                        </div>
                        <div class="row marginTopHalfEm"  *ngIf="resource.subsequentTaskTemplateID.value">
                            <div class="col-sm-12">
                                <div>
                                    This task is scheduled...
                                </div>
                                <div class="input-group">
                                    <span>
                                        {{ relativeTaskMessage(resource) }}
                                    </span>
                                    <span class="marginLeftHalfEm" *wackadooResourceBlock="let r of []; let idx = index; eventHandler:'TaskTemplates'; action:'list'; parms:{taskTemplateID: resource.subsequentTaskTemplateID.value}; debug:false;">
                                        <span class="link" (click)="openTaskTemplateModal(r)">
                                            <wackadoo-task-name-with-icon [resource]="r"></wackadoo-task-name-with-icon> 
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row marginTopWholeEm">
                            <div class="col-sm-12 bold">Other Tasks Scheduled Relative To This One</div>
                        </div>
                        <div class="marginLeftQuarterEm scrollable-y max-height-200-px">
                            <div class="floatGroup border-top smallish paddingLeftHalfEm" *wackadooResourceBlock="let r of []; let idx = index; eventHandler:'TaskTemplates'; action:'list'; parms:{subsequentTaskTemplateID: resource.taskTemplateID.value, pageSize:-1, sortBy:resource.subsequentTaskLeadTime.value, sortDirection:1}; debug:false; defaultText:'None.';">
                                <span class="link floatLeft" (click)="openTaskTemplateModal(r)">
                                    <wackadoo-task-name-with-icon [resource]="r"></wackadoo-task-name-with-icon>
                                </span>
                                <span class="italic floatRight">
                                    ({{ relativeTaskMessage(r) }}) 
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
