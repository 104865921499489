<div>
    <div class="row">
        <div class="col-sm-2">
        </div>
        <div class="col-sm-8 center">
            <div class="large bold italic">{{ icsFileDescription }}</div>
            <div>
                <label class="marginRightWholeEm">
                    <input type="radio" name="tasksToReturn" value="allclosings" [checked]="tasksToReturn === 'allclosings'" />
                    Closing Dates (active & closed)
                </label>
                <label class="marginLeftWholeEm marginRightWholeEm">
                    <input type="radio" name="tasksToReturn" value="activedeadlines" [checked]="tasksToReturn === 'activedeadlines'" />
                    Due Diligence & Closing Dates (active)
                </label>
                <label class="marginLeftWholeEm">
                    <input type="radio" name="tasksToReturn" value="everything" [checked]="tasksToReturn === 'everything'" />
                    Everything (active)
                </label>
            </div>
        </div>
        <div class="col-sm-2 center">
            <button (click)="downloadICS()" class="btn btn-outline-secondary"><span class="fas fa-calendar-alt"></span> Download Calendar</button>
        </div>
    </div>
    <!-- wackadoo-calendar-page IS a row... -->
    <wackadoo-calendar-page [calendarName]="calendarName"></wackadoo-calendar-page>
</div>
