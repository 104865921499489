<div class="" [ngClass]="{'d-flex justify-content-around':(screenType !== 'phone')}">
    <div class="repairs" *ngIf="(contract.contractType.value === 'Listing')">
        <div class="card">
            <div class="card-header d-flex justify-content-between" (click)="toggleMarketing()" [attr.aria-expanded]="!isCollapsedMarketing">
                <div>
                    Marketing 
                </div>
                <div class="unbold smallish italic" *ngIf="(screenType === 'phone')">
                     (Toggle {{ !isCollapsedMarketing ? 'Closed' : 'Open' }})
                </div>
            </div>
            <div class="card-body" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsedMarketing">
                <div *wackadooResourceBlock="let r of []; let idx = index; eventHandler: 'Tasks'; action: 'search'; parms: {contractID: contract.contractID.value, taskType: 'marketing' }; defaultText: 'No assigned marketing vendors'; debug: false;">
                    <wackadoo-vendor-action [resource]="r" mode="read"></wackadoo-vendor-action>
                </div>
            </div>
        </div>
    </div>
    <div class="inspections" *ngIf="(contract.contractType.value === 'Buyer')">
        <div class="card detail">
            <div class="card-header d-flex justify-content-between" (click)="toggleInspections()" [attr.aria-expanded]="!isCollapsedInspections">
                <div>
                    Inspections
                </div>
                <div class="unbold smallish italic" *ngIf="(screenType === 'phone')">
                     (Toggle {{ !isCollapsedInspections ? 'Closed' : 'Open' }})
                </div>
            </div>
            <div class="card-body" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsedInspections">
                <div *wackadooResourceBlock="let r of []; let idx = index; eventHandler: 'Tasks'; action: 'search'; parms: {contractID: contract.contractID.value, taskType: 'inspection' }; defaultText: 'No assigned inspectors'; debug: false;">
                    <wackadoo-vendor-action [resource]="r" mode="read"></wackadoo-vendor-action>
                </div>
            </div>
        </div>
    </div>
    <div class="estimates">
        <div class="card detail">
            <div class="card-header d-flex justify-content-between" (click)="toggleEstimates()" [attr.aria-expanded]="!isCollapsedEstimates">
                <div>
                    Estimates
                </div>
                <div class="unbold smallish italic" *ngIf="(screenType === 'phone')">
                     (Toggle {{ !isCollapsedEstimates ? 'Closed' : 'Open' }})
                </div>
            </div>
            <div class="card-body" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsedEstimates">
                <div *wackadooResourceBlock="let r of []; let idx = index; eventHandler: 'Tasks'; action: 'search'; parms: {contractID: contract.contractID.value, taskType: 'estimate' }; defaultText: 'No assigned contractors'; debug: false;">
                    <wackadoo-vendor-action [resource]="r" mode="read"></wackadoo-vendor-action>
                </div>
            </div>
        </div>
    </div>
    <div class="repairs" *ngIf="(contract.contractType.value !== 'Buyer')">
        <div class="card detail">
            <div class="card-header d-flex justify-content-between" (click)="toggleRepairs()" [attr.aria-expanded]="!isCollapsedRepairs">
                <div>
                    Repairs
                </div>
                <div class="unbold smallish italic" *ngIf="(screenType === 'phone')">
                     (Toggle {{ !isCollapsedRepairs ? 'Closed' : 'Open' }})
                </div>
            </div>
            <div class="card-body" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsedRepairs">
                <div *wackadooResourceBlock="let r of []; let idx = index; eventHandler: 'Tasks'; action: 'search'; parms: {contractID: contract.contractID.value, taskType: 'repair' }; defaultText: 'No assigned contractors'; debug: false;">
                    <wackadoo-vendor-action [resource]="r" mode="read"></wackadoo-vendor-action>
                </div>
            </div>
        </div>
    </div>
    <div class="reinspections" *ngIf="(contract.contractType.value === 'Buyer')">
        <div class="card detail">
            <div class="card-header d-flex justify-content-between" (click)="toggleReInspections()" [attr.aria-expanded]="!isCollapsedReInspections">
                <div>
                    Re-Inspections
                </div>
                <div class="unbold smallish italic" *ngIf="(screenType === 'phone')">
                     (Toggle {{ !isCollapsedReInspections ? 'Closed' : 'Open' }})
                </div>
            </div>
            <div class="card-body" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsedReInspections">
                <div *wackadooResourceBlock="let r of []; let idx = index; eventHandler: 'Tasks'; action: 'search'; parms: {contractID: contract.contractID.value, taskType: 'reinspection' }; defaultText: 'No assigned contractors'; debug: false;">
                    <wackadoo-vendor-action [resource]="r" mode="read"></wackadoo-vendor-action>
                </div>
            </div>
        </div>
    </div>
</div>
