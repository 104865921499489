<div class="btn-group marginTopQuarterEm d-flex" role="group">
    <button type="button" title="Save"    data-abbrev="" class="btn btn-secondary actionButton" (click)="save()"   *ngIf="!user.isPublicOnly && editMode && (methods.includes('add') || methods.includes('modify'))"><span class="fas fa-check"></span></button>
    <button type="button" title="Submit"  data-abbrev="Submit" class="btn btn-secondary actionButton" (click)="submit()" *ngIf="user.isPublicOnly"><span class="fas fa-share"></span></button>
    <button type="button" title="Edit"    data-abbrev="" class="btn btn-secondary actionButton" (click)="modify()" *ngIf="!user.isPublicOnly && !editMode && methods.includes('modify')"><span class="fas fa-pencil-alt"></span></button>
    <button type="button" title="Approve" data-abbrev="" class="btn btn-secondary actionButton" (click)="approve()" *ngIf="user.isStaffOrAbove"><span class="fas fa-share"></span></button>
    <button type="button" title="Copy"    data-abbrev="" class="btn btn-secondary actionButton" (click)="copy()"   *ngIf="!user.isPublicOnly && !editMode && methods.includes('add')"><span class="fas fa-copy"></span></button>
    <button type="button" title="Cut-N-Paste"    data-abbrev="" class="btn btn-secondary actionButton" (click)="cutAndPaste()"   *ngIf="user.isStaffOrAbove && resource.inputFormID.isNull"><span class="fas fa-paste"></span></button>
    <button type="button" title="Delete"  data-abbrev="" class="btn btn-secondary actionButton" (click)="remove()" *ngIf="!user.isPublicOnly && !editMode && methods.includes('delete')"><span class="fas fa-trash"></span></button>
    <button type="button" title="Cancel"  data-abbrev="Cancel" class="btn btn-secondary actionButton" (click)="cancel()"><span class="fas fa-times"></span></button>
</div>
