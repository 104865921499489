<div class="heightViewPort85 wrap-scrollable-table">
  <table class="table table-hover small">
    <thead>
      <tr class="numbered-row center">
        <th class="italic right">
          #
        </th>
        <th class="border-left">
          Contract Date
        </th>
        <th class="border-left max-width-8-em">
          Client(s)
        </th>
        <th class="border-left">
          Address
        </th>
        <th class="border-left">
          Type
        </th>
        <th class="border-left">
          Agent
        </th>
        <th class="border-left" [title]="getQueryDescription(0)">
          {{getQueryName(0)}}
        </th>
        <th class="border-left" [title]="getQueryDescription(1)">
          {{getQueryName(1)}}
        </th>
        <th class="border-left" [title]="getQueryDescription(2)">
          {{getQueryName(2)}}
        </th>
        <th class="border-left" [title]="getQueryDescription(3)">
          {{getQueryName(3)}}
        </th>
        <th class="border-left" [title]="getQueryDescription(4)">
          {{getQueryName(4)}}
        </th>
        <th class="border-left" [title]="getQueryDescription(5)">
          {{getQueryName(5)}}
        </th>
        <th class="border-left" [title]="getQueryDescription(6)">
          {{getQueryName(6)}}
        </th>
        <th class="border-left" [title]="getQueryDescription(7)">
          {{getQueryName(7)}}
        </th>
        <th class="border-left" [title]="getQueryDescription(8)">
          {{getQueryName(8)}}
        </th>
      </tr>
    </thead>
<!--
        wackadooSelectResource 
        eventHandler="ActiveContracts"
        [resource]="r"
        [loadPage]="false"
-->
    <tbody>
      <tr wackadoo-new-contract
        class="numbered-row" 
        *wackadooResourceBlock="let r of []; let idx = index; eventHandler: 'CustomGenerator'; action: 'listActiveContractsWithIncompleteTopLevelTask'; parms: { taskName: 'New Contract' }; defaultText: 'No new contracts.'; debug: false;"
        [idx]="idx" [resource]="r"
        [ngClass]="{'sandybrown-bg': (r.contractType.value === 'Seller') }"
      >
      </tr>
    </tbody>
  </table>
</div>
