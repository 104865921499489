<div class="row">
    <div class="col-sm-12 d-flex justify-content-between">
        <div class="">
            <button type="button" (click)="previousMajor()" class="btn btn-outline-secondary small" *ngIf="mode !== 'mmm'" >
                <span class="no-wrap">
                    <span class="fas fa-backward"></span>
                    <span class="fas fa-backward"></span>
                </span>
                {{ majorLabel }}
            </button>
        </div>
        <div class="">
            <button type="button" (click)="previousMinor()" class="btn btn-outline-secondary small" *ngIf="mode !== 'mmm'" >
                <span class="fas fa-backward"></span>
                {{ minorLabel }}
            </button>
        </div>
        <div class="center bold">
            {{ title }}
        </div>
        <div class="">
            <button type="button" (click)="nextMinor()" class="btn btn-outline-secondary small" *ngIf="mode !== 'mmm'" >
                {{ minorLabel }}
                <span class="fas fa-forward"></span>
            </button>
        </div>
        <div class="">
            <button type="button" (click)="nextMajor()" class="btn btn-outline-secondary small" *ngIf="mode !== 'mmm'" >
                {{ majorLabel }}
                <span class="no-wrap">
                    <span class="fas fa-forward"></span>
                    <span class="fas fa-forward"></span>
                </span>
            </button>
        </div>
    </div>
</div>
<div *ngIf="(screenType !== 'phone')">
    <div class="row" *ngIf="mode === 'month'">
        <div class="col-sm-1 center marginLeft0 marginRight0 paddingLeft0 paddingRight0">
            {{ getDayAbbreviation(0) }}
        </div>
        <div class="col-sm-2 center marginLeft0 marginRight0 paddingLeft0 paddingRight0">
            {{ getDayAbbreviation(1) }}
        </div>
        <div class="col-sm-2 center marginLeft0 marginRight0 paddingLeft0 paddingRight0">
            {{ getDayAbbreviation(2) }}
        </div>
        <div class="col-sm-2 center marginLeft0 marginRight0 paddingLeft0 paddingRight0">
            {{ getDayAbbreviation(3) }}
        </div>
        <div class="col-sm-2 center marginLeft0 marginRight0 paddingLeft0 paddingRight0">
            {{ getDayAbbreviation(4) }}
        </div>
        <div class="col-sm-2 center marginLeft0 marginRight0 paddingLeft0 paddingRight0">
            {{ getDayAbbreviation(5) }}
        </div>
        <div class="col-sm-1 center marginLeft0 marginRight0 paddingLeft0 paddingRight0">
            {{ getDayAbbreviation(6) }}
        </div>
    </div>
    <!--
        <div class="row" *ngIf="mode === 'mmm'">
            <div class="col-sm-1 center marginLeft0 marginRight0 paddingLeft0 paddingRight0">
                {{ getDayLetter(0) }}
            </div>
            <div class="col-sm-2 center marginLeft0 marginRight0 paddingLeft0 paddingRight0">
                {{ getDayLetter(1) }}
            </div>
            <div class="col-sm-2 center marginLeft0 marginRight0 paddingLeft0 paddingRight0">
                {{ getDayLetter(2) }}
            </div>
            <div class="col-sm-2 center marginLeft0 marginRight0 paddingLeft0 paddingRight0">
                {{ getDayLetter(3) }}
            </div>
            <div class="col-sm-2 center marginLeft0 marginRight0 paddingLeft0 paddingRight0">
                {{ getDayLetter(4) }}
            </div>
            <div class="col-sm-2 center marginLeft0 marginRight0 paddingLeft0 paddingRight0">
                {{ getDayLetter(5) }}
            </div>
            <div class="col-sm-1 center marginLeft0 marginRight0 paddingLeft0 paddingRight0">
                {{ getDayLetter(6) }}
            </div>
        </div>
    -->
    <div class="row" *ngIf="mode === 'week'">
        <div class="col-sm-1 center marginLeft0 marginRight0 paddingLeft0 paddingRight0">
            {{ getDayName(0) }}
        </div>
        <div class="col-sm-2 center marginLeft0 marginRight0 paddingLeft0 paddingRight0">
            {{ getDayName(1) }}
        </div>
        <div class="col-sm-2 center marginLeft0 marginRight0 paddingLeft0 paddingRight0">
            {{ getDayName(2) }}
        </div>
        <div class="col-sm-2 center marginLeft0 marginRight0 paddingLeft0 paddingRight0">
            {{ getDayName(3) }}
        </div>
        <div class="col-sm-2 center marginLeft0 marginRight0 paddingLeft0 paddingRight0">
            {{ getDayName(4) }}
        </div>
        <div class="col-sm-2 center marginLeft0 marginRight0 paddingLeft0 paddingRight0">
            {{ getDayName(5) }}
        </div>
        <div class="col-sm-1 center marginLeft0 marginRight0 paddingLeft0 paddingRight0">
            {{ getDayName(6) }}
        </div>
    </div>
</div>
