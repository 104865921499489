import { Component } from '@angular/core';
import { ResourceComponent } from 'src/app/client-core/data/resource/resource.component';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { WEvent } from 'src/app/client-core/data/event.model';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { ClosingProService } from '../../../closing-pro.service';

@Component({
  selector: 'wackadoo-vendor-action',
  templateUrl: './vendor-action.component.html',
})
export class VendorActionComponent extends ResourceComponent {

  constructor(
    userInterfaceService: UserInterfaceService,
    public modalDialogService: ModalDialogService,
    public closingProService: ClosingProService,
    eventServerService: EventServerService,
  ) {
    super(userInterfaceService, eventServerService);
  }

  duplicateTask(): void {
    try {
      // console.log('VendorActivity.duplicateTask() - Got here!\n\n' + JSON.stringify(resource.simplifyResource()));

      const confirmMsg = 'This is going to DUPLICATE the "'
              +  this.resource.taskName.value
              + '" Task for this Contract, and EVERY "sub-task" beneath it!'
              + '\n\nAre you SURE you want to do this?';

      this.modalDialogService.showConfirm(confirmMsg, 'WARNING!').subscribe(
        (flag: boolean) => {
          if (flag) {
            this.closingProService.logThisNote(this.resource.contractID.value, 'Duplicating task: ' + this.resource.taskName.value, false);
            const parms = this.resource.asParms;
            this.eventServerService.fireEvent('Tasks', 'duplicateHierarchyBranch', parms).subscribe(
              (event: WEvent) => {
                if (event) {
                  if (event.status === 'OK') {
                    this.userInterfaceService.reloadCurrentPage();
                  } else {
                    this.modalDialogService.showAlert('Failure duplicating task and sub-tasks...\n' + event.message, 'WARNING!');
                  }
                }
              }
            );
          }
        }
      );

    } catch (ex) {
      const msg = 'VendorActivity.duplicateTask()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  deleteTask(): void {
    try {
      // console.log('VendorActivity.deleteTask() - Got here!\n\n' + JSON.stringify(resource.simplifyResource()));

      const confirmMsg = 'This is going to DELETE the "'
              +  this.resource.taskName.value
              + '" Task for this Contract, and EVERY "sub-task" beneath it!'
              + '\n\nAre you SURE you want to do this?';

      this.modalDialogService.showConfirm(confirmMsg, 'WARNING!').subscribe(
        (flag: boolean) => {
          if (flag) {
            this.closingProService.logThisNote(this.resource.contractID.value, 'Deleting task: ' + this.resource.taskName.value, false);
            const parms = this.resource.asParms;
            this.eventServerService.fireEvent('Tasks', 'delete', parms).subscribe(
              (event: WEvent) => {
                if (event) {
                  if (event.status === 'OK') {
                    this.userInterfaceService.reloadCurrentPage();
                  } else {
                    this.modalDialogService.showAlert('Unable to delete task(s)...\n' + event.message, 'WARNING!');
                  }
                }
              }
            );
          }
        }
      );

    } catch (ex) {
      const msg = 'VendorActivity.deleteTask()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  showVendorActivityModal(): void {
    try {
      // console.log('VendorActionComponent.showVendorActivityModal()', this.resource, this.resource.keyField);

      this.modalDialogService.modifyResourceModal('VendorActivityModalComponent', 'Tasks', this.resource).subscribe(
        (event: WEvent) => {
          // console.log('VendorActivityModalComponent.parent.closed()', event);
          // if we cancel or properly saved the page...
          if (event && (event.status !== 'OK')) {
            this.modalDialogService.showAlert('Unable to modify task...\n' + event.message, 'WARNING!').subscribe(
              () => {
                this.userInterfaceService.reloadCurrentPage();
              }
            );
          } else {
            this.userInterfaceService.reloadCurrentPage();
          }
        }
      );

    } catch (ex) {
      this.userInterfaceService.alertUserToException(ex, 'VendorActivity.showVendorActivityModal()');
    }
  }

}
