import { Component, OnInit, OnDestroy } from '@angular/core';
import { RepositoryPageComponent } from 'src/app/client-core/pages/repository-page/repository-page.component';
import { SelectedResource } from 'src/app/client-core/ui/main/current-selection/selected-resource.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wackadoo-contracts',
  templateUrl: '../../../../../../src/app/client-core/pages/repository-page/repository-page.component.html',
})
export class ContractsComponent extends RepositoryPageComponent implements OnInit, OnDestroy {

  private _contractSelectionChangeSubscription: Subscription;

  ngOnInit(): void {

    this.gridButtonBarComponentClassName = 'ContractGridButtonBarComponent';
    this.detailContentComponentClassName = 'ContractDetailContentComponent';
    this.gridFieldsToHide = ['escrowAgentID', 'mlsNumber', 'ddDate', 'listingDate', 'userID', 'lastAccess' ];
    this.detailButtonBarComponentClassName = 'ContractDetailButtonBarComponent';

    super.ngOnInit();

    if (this.user.role === 'staff') {
      this.userInterfaceService.unSelectResource('MyContracts');
    } else {
      this.userInterfaceService.unSelectResource('ActiveContracts');
    }

    this._contractSelectionChangeSubscription = this.userInterfaceService.selectionChange.subscribe(
      (sr: SelectedResource) => {
        // console.log('ContractsComponent.selectionChange()', this.eventHandler, sr.ehName);

        if ((sr.ehName === this.eventHandler) && (sr.resource) && (this.user.role !== 'guest')) {

          // console.log('ContractsComponent.selectionChange() - selected a Contract:', sr.resource.contractName.value, sr.resource.contractStatus.value);

          if (sr.resource.contractStatus.value === 'active') {
            // console.log('BUT IT IS REALLY ACTIVE!');
            this.userInterfaceService.unSelectResource('Contracts');
            if (this.user.role === 'staff') {
              this.userInterfaceService.selectResource('MyContracts', sr.resource);
              this.bounceToPage('MyContracts');
            } else {
              this.userInterfaceService.selectResource('ActiveContracts', sr.resource);
              this.bounceToPage('ActiveContracts');
            }
          }

        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this._contractSelectionChangeSubscription) {
      this._contractSelectionChangeSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  bounceToPage(pageName: string): void {
    window.setTimeout(
      () => {
        this.userInterfaceService.loadPage(pageName);
      }
      , 250
    );
  }
}
