<div class="card">
    <div class="card-header">
        New Co-Op Agent
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-sm-6"><wackadoo-field [f]="resource.coOpAgentFirstName" [mode]="mode"></wackadoo-field></div>
            <div class="col-sm-6"><wackadoo-field [f]="resource.coOpAgentLastName" [mode]="mode"></wackadoo-field></div>
        </div>
        <div class="row marginTopQuarterEm">
            <div class="col-sm-12"><wackadoo-field [f]="resource.coOpAgentCompany" [mode]="mode"></wackadoo-field></div>
        </div>
        <div class="row marginTopQuarterEm">
            <div class="col-sm-12"><wackadoo-field [f]="resource.coOpAgentAddress" [mode]="mode"></wackadoo-field></div>
        </div>
        <div class="row marginTopQuarterEm">
            <div class="col-sm-6"><wackadoo-field [f]="resource.coOpAgentCity" [mode]="mode"></wackadoo-field></div>
            <div class="col-sm-3"><wackadoo-field [f]="resource.coOpAgentState" [mode]="mode" [size]="2"></wackadoo-field></div>
            <div class="col-sm-3"><wackadoo-field [f]="resource.coOpAgentPostalCode" [mode]="mode"></wackadoo-field></div>
        </div>
        <div class="row marginTopQuarterEm">
            <div  class="col-sm-6"><wackadoo-field [f]="resource.coOpAgentEmail" [mode]="mode"></wackadoo-field></div>
            <div class="col-sm-6"><wackadoo-field [f]="resource.coOpAgentPhone" [mode]="mode"></wackadoo-field></div>
        </div>
        <div class="row marginTopHalfEm">
            <div class="col-sm-12">Note</div>
        </div>
        <div class="row">
            <div class="col-sm-12"><wackadoo-field [f]="resource.coOpAgentNote" [mode]="mode"></wackadoo-field></div>
        </div>
    </div>
</div>
