import { Component, OnInit, OnDestroy } from '@angular/core';
import { DetailContentComponent } from 'src/app/client-core/pages/repository-page/detail-content/detail-content.component';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { WResource } from 'src/app/client-core/data/resource.model';
import { Subject, Subscription } from 'rxjs';
import { ClosingProService } from '../../../closing-pro.service';

@Component({
  selector: 'wackadoo-staff-member-detail-content',
  templateUrl: './staff-member-detail-content.component.html',
})
export class StaffMemberDetailContentComponent  extends DetailContentComponent implements OnInit, OnDestroy {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'StaffMemberDetailContentComponent';

  selectSubject: Subject<WResource>;
  selectSubscription: Subscription;

  unSelectSubject: Subject<WResource>;
  unSelectSubscription: Subscription;

  selectedContracts: Array<WResource> = [];

  constructor(
    userInterfaceService: UserInterfaceService,
    eventServerService: EventServerService,
    public closingProService: ClosingProService,
  ) {
    super(userInterfaceService, eventServerService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.selectSubject = new Subject<WResource>();

    this.selectSubscription = this.selectSubject.subscribe(
      (resource: WResource) => {
        // console.log('selected', resource);
        // just stick it on the end...
        this.selectedContracts.splice(this.selectedContracts.length, 0, resource);
      }
    );

    this.unSelectSubject = new Subject<WResource>();

    this.unSelectSubscription = this.unSelectSubject.subscribe(
      (resource: WResource) => {
        // console.log('un-selected', resource);
        const idx = this.selectedContracts.findIndex((r: WResource) => r.keyField.value === resource.keyField.value);
        if (idx >= 0) {
          this.selectedContracts.splice(idx, 1);
        }
      }
    );
  }

  ngOnDestroy(): void {

    if (this.selectSubject) {
      this.selectSubject.complete();
    }

    if (this.unSelectSubject) {
      this.unSelectSubject.complete();
    }

    if (this.selectSubscription) {
      this.selectSubscription.unsubscribe();
    }

    if (this.unSelectSubscription) {
      this.unSelectSubscription.unsubscribe();
    }

    super.ngOnDestroy();
  }

  onTaskClick(task: WResource): void {
    this.closingProService.openTaskListToProperPanel(task);
  }

}
