import { Component } from '@angular/core';
import { DetailButtonBarComponent } from 'src/app/client-core/pages/repository-page/detail-button-bar/detail-button-bar.component';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { ClosingProService } from '../../../closing-pro.service';

@Component({
  selector: 'wackadoo-attorney-detail-button-bar',
  templateUrl: './lender-detail-button-bar.component.html',
})
export class LenderDetailButtonBarComponent extends DetailButtonBarComponent {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'LenderDetailButtonBarComponent';

  constructor(
    eventServerService: EventServerService,
    userInterfaceService: UserInterfaceService,
    modalDialogService: ModalDialogService,
    public closingProService: ClosingProService,
  ) {
    super(eventServerService, userInterfaceService, modalDialogService);
  }

  downloadICS(): void {
    this.closingProService.downloadICS(this.eventHandler);
  }

  downloadVCF(subType?: string): void {
    this.closingProService.downloadVCF(this.eventHandler, subType);
  }

  isTagged(): boolean {
    return this.closingProService.isTaggedAsDuplicate(this.resource, 'lenderNote');
  }

  tagAsDuplicate(): void {
    this.closingProService.tagAsDuplicate(this.resource, 'lenderNote', 'DuplicateLenders');
  }

  unTagAsDuplicate(): void {
    this.closingProService.unTagAsDuplicate(this.resource, 'lenderNote');
  }
}
