import { Component } from '@angular/core';
import { GridButtonBarComponent } from 'src/app/client-core/pages/repository-page/grid-button-bar/grid-button-bar.component';

@Component({
  selector: 'wackadoo-contract-grid-button-bar',
  templateUrl: '../../../../../../../src/app/client-core/pages/repository-page/grid-button-bar/grid-button-bar.component.html',
})
export class ContractGridButtonBarComponent extends GridButtonBarComponent {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'ContractGridButtonBarComponent';

  add(): void {
    // console.log('ContractGridButtonBarComponent.add()');
    this.userInterfaceService.loadPage('InBox', 'add');
  }

}
