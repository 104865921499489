import { Component, OnInit } from '@angular/core';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { PageComponent } from 'src/app/client-core/pages/page/page.component';
import { UserAuthService } from 'src/app/client-core/services/user-auth.service';
import { UtilityLibService } from 'src/app/client-core/services/utility-lib.service';
import { ClosingProService } from '../../../closing-pro.service';

@Component({
  selector: 'wackadoo-new-contracts',
  templateUrl: './new-contracts.component.html',

})
export class NewContractsComponent extends PageComponent implements OnInit {

  constructor(
    userAuthService: UserAuthService,
    userInterfaceService: UserInterfaceService,
    public eventServerService: EventServerService,
    public utilLibService: UtilityLibService,
    public closingProService: ClosingProService,
  ) {
    super(userAuthService, userInterfaceService);
  }

  getQueryName(i: number): string {
    return this.closingProService.getQueryNameForNewContracts(i);
  }

  getQueryDescription(i: number): string {
    return this.closingProService.getQueryDescriptionForNewContracts(i);
  }

}
