<div class="card">
    <div class="card-header">
        <div class="" [ngClass]="{'d-flex justify-content-around':(screenType !== 'phone')}">
            <div class="unbold no-wrap">
                Status: <wackadoo-field [f]="resource.contractStatus" [mode]="mode"></wackadoo-field>
            </div>
            <div class="unbold no-wrap">
                Type: <wackadoo-field [f]="resource.contractType" [mode]="mode"></wackadoo-field>
            </div>
            <div class="green">
                <wackadoo-field [f]="resource.contractName" [mode]="mode" [size]="45"></wackadoo-field>
            </div>
            <div class="unbold">
                <wackadoo-field [f]="resource.userID" mode="read"></wackadoo-field>
            </div>
            <div class="d-flex">
                <div class="">
                    TaskList: 
                </div>
                <div class="unbold marginLeftHalfEm">
                    <wackadoo-field [f]="resource.taskTemplateGroupID" [mode]="mode" [onChange]="showReInitWarningSubject"></wackadoo-field>
                </div>
                <div class="marginLeftHalfEm">
                    <button type="button" class="btn btn-secondary small paddingQuarterEm" (click)="reInitTaskList()">Re-Init</button>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body smallish">
        <div class="row">
            <div class="col-sm-6">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex justify-content-between">
                            <div>
                                Contract Info
                            </div>
                            <div class="d-flex unbold smallish marginLeftHalfEm">
                                <div>
                                    MLS
                                </div>
                                <div class="marginLeftHalfEm">
                                    <wackadoo-field [f]="resource.mlsNumber" [mode]="mode"></wackadoo-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <wackadoo-contract-header [resource]="resource" [mode]="mode"></wackadoo-contract-header>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex justify-content-between">
                            <div>
                                Contact Info
                            </div>
                            <div class="d-flex justify-content-between unbold italics">
                                <div>
                                    Agency
                                </div>
                                <div class="marginLeftHalfEm">
                                    <wackadoo-field [f]="resource.agency" [mode]="mode"></wackadoo-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div class="bold">
                                Contract Participants
                            </div>
                            <div class="d-flex justify-content-around">
                                <div>
                                    <button type="button" title="Add New ContractParticipant"
                                            class="btn btn-secondary small paddingQuarterEm" 
                                            (click)="addNewContractParticipant()"
                                    >
                                        <span class="fas fa-plus" aria-hidden="true"> New</span>
                                    </button>
                                </div>
                                <div>
                                    <button type="button" title="Manage ContractParticipants" 
                                            class="btn btn-secondary small paddingQuarterEm" 
                                            wackadooPageLoad eventHandler="ContractParticipants" action="search" [parms]="{contractID: resource.contractID.value }"
                                    >
                                        <span class="fas fa-list" aria-hidden="true"> Edit</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <table class="fillAvailableWidth">
                            <tbody *ngIf="(resource.contractType.value === 'Buyer')" >
                                <tr *wackadooResourceBlock="let cp of []; eventHandler:'ContractParticipants'; action:'search'; parms:{contractID: resource.contractID.value, sortBy:'clientRole', sortDirection:'1'};">
                                    <td class="right">
                                        <button type="button" 
                                            class="btn btn-secondary small paddingBottomQuarterEm paddingTopQuarterEm paddingRightHalfEm paddingLeftHalfEm"
                                            (click)="toggleClientRole(cp)"
                                        >
                                            <span class="fas fa-sync" aria-hidden="true"></span> {{cp.clientRole.value}}
                                        </button>
                                    </td>
                                    <ng-container *wackadooResourceBlock ="let c of []; eventHandler:'Clients'; action:'search'; parms:{clientID: cp.clientID.value};">
                                        <td  class="link" (click)="displayModalResourceDialog(c)">
                                            {{ c.clientFirstName.value }} {{ c.clientLastName.value }} {{ c.clientCompany.value }}
                                        </td>
                                        <td>
                                            {{ c.clientPhone.value }}
                                        </td>
                                    </ng-container>
                                </tr>
                            </tbody>
                            <tbody *ngIf="(resource.contractType.value !== 'Buyer')" >
                                <tr *wackadooResourceBlock="let cp of []; eventHandler:'ContractParticipants'; action:'search'; parms:{contractID: resource.contractID.value, sortBy:'clientRole', sortDirection:'-1'};">
                                    <td class="right">
                                        <button type="button" 
                                            class="btn btn-secondary small paddingBottomQuarterEm paddingTopQuarterEm paddingRightHalfEm paddingLeftHalfEm"
                                            (click)="toggleClientRole(cp)"
                                        >
                                            <span class="fas fa-sync" aria-hidden="true"></span> {{cp.clientRole.value}}
                                        </button>
                                    </td>
                                    <ng-container *wackadooResourceBlock ="let c of []; eventHandler:'Clients'; action:'search'; parms:{clientID: cp.clientID.value};">
                                        <td  class="link" (click)="displayModalResourceDialog(c)">
                                            {{ c.clientFirstName.value }} {{ c.clientLastName.value }} {{ c.clientCompany.value }}
                                        </td>
                                        <td>
                                            {{ c.clientPhone.value }}
                                        </td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                        <div class="marginTopHalfEm bold">
                            Associated Professionals
                        </div>
                        <table class="fillAvailableWidth" *ngIf="(screenType !== 'phone')">
                            <tbody>
                                <tr>
                                    <td class="right">
                                        Agent
                                    </td>
                                    <td>
                                        <wackadoo-field [f]="resource.agentID" [mode]="mode"></wackadoo-field>
                                    </td>
                                    <td>
                                        <wackadoo-field [f]="resource.agentPhone" mode="read"></wackadoo-field>
                                    </td>
                                </tr>
                                <tr *ngIf="resource.contractType.value !== 'Listing'">
                                    <td class="right">
                                        Coop Agent
                                    </td>
                                    <td>
                                        <wackadoo-field [f]="resource.coOpAgentID" [mode]="mode"></wackadoo-field>
                                    </td>
                                    <td>
                                        <wackadoo-field [f]="resource.coOpAgentPhone" mode="read"></wackadoo-field>
                                    </td>
                                    <td>
                                        <button type="button" title="new CoOpAgent" class="btn btn-secondary newCoOpAgent small paddingQuarterEm" (click)="addNewCoOpAgent()"><span class="fas fa-plus" aria-hidden="true"></span></button>
                                    </td>
                                </tr>
                                <tr *ngIf="resource.contractType.value === 'Buyer'">
                                    <td class="right">
                                        Lender
                                    </td>
                                    <td>
                                        <wackadoo-field-foreign-key [f]="resource.lenderID" [mode]="mode" [nullValueLabel]="'NONE - CASH PURCHASE'"></wackadoo-field-foreign-key>
                                    </td>
                                    <td>
                                        <wackadoo-field [f]="resource.lenderPhone" mode="read"></wackadoo-field>
                                    </td>
                                    <td>
                                        <button type="button" title="new Lender" class="btn btn-secondary newLender small paddingQuarterEm" (click)="addNewLender()"><span class="fas fa-plus" aria-hidden="true"></span></button>
                                    </td>
                                </tr>
                                <tr *ngIf="resource.contractType.value !== 'Listing'">
                                    <td class="right">
                                        Attorney
                                    </td>
                                    <td>
                                        <wackadoo-field [f]="resource.attorneyID" [mode]="mode"></wackadoo-field>
                                    </td>
                                    <td>
                                        <wackadoo-field [f]="resource.attorneyPhone" mode="read"></wackadoo-field>
                                    </td>
                                    <td>
                                        <div class="btn-group">
                                            <button type="button" title="add new Attorney" class="btn btn-secondary small paddingQuarterEm" (click)="addNewAttorney()"><span class="fas fa-plus" aria-hidden="true"></span></button>
                                            <button type="button" title="copy Attorney to EscrowAgent" class="btn btn-secondary small paddingQuarterEm"  (click)="copyAttorneyToEscrowAgent()"><span class="fas fa-share fa-rotate-90" aria-hidden="true"></span></button>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="resource.contractType.value !== 'Listing'">
                                    <td class="right">
                                        Escrow Agent
                                    </td>
                                    <td>
                                        <wackadoo-field [f]="resource.escrowAgentID" [mode]="mode"></wackadoo-field>
                                    </td>
                                    <td>
                                        <wackadoo-field [f]="resource.escrowAgentPhone" mode="read"></wackadoo-field>
                                    </td>
                                    <td>
                                        <button type="button" title="add new EscrowAgent" class="btn btn-secondary small paddingQuarterEm" (click)="addNewEscrowAgent()"><span class="fas fa-plus" aria-hidden="true"></span></button>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="right no-wrap">
                                         Referral <wackadoo-field [f]="resource.referral" [mode]="mode" [onChange]="onReferralChangeSubject"></wackadoo-field>
                                    </td>
                                    <td colspan="3" *ngIf="resource.referral.value">
                                        <div class="d-flex">
                                            <div>
                                                <wackadoo-field [f]="resource.referralName" [mode]="mode"></wackadoo-field>
                                            </div>
                                            <div class="marginLeftHalfEm">
                                                <wackadoo-field [f]="resource.referralCompany" [mode]="mode"></wackadoo-field>
                                            </div>
                                            <div class="marginLeftHalfEm">
                                                <wackadoo-field [f]="resource.referralEmail" [mode]="mode"></wackadoo-field>
                                            </div>
                                            <div class="marginLeftHalfEm">
                                                <wackadoo-field [f]="resource.referralPhone" [mode]="mode"></wackadoo-field>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div *ngIf="(screenType === 'phone')">
                            <div>
                                Agent 
                            </div>
                            <div>
                                <wackadoo-field [f]="resource.agentID" [mode]="mode"></wackadoo-field>
                            </div>
                            <ng-container *ngIf="resource.contractType.value !== 'Listing'">
                                <div class="marginTopHalfEm">
                                    Coop Agent 
                                </div>
                                <div>
                                    <wackadoo-field [f]="resource.coOpAgentID" [mode]="mode"></wackadoo-field>
                                </div>
<!--
                                <div>
                                    <button type="button" title="new CoOpAgent" class="btn btn-secondary newCoOpAgent small paddingQuarterEm" (click)="addNewCoOpAgent()"><span class="fas fa-plus" aria-hidden="true"></span></button>
                                </div>
-->
                            </ng-container>
                            <ng-container *ngIf="resource.contractType.value === 'Buyer'">
                                <div class="marginTopHalfEm">
                                    Lender 
                                </div>
                                <div>
                                    <wackadoo-field-foreign-key [f]="resource.lenderID" [mode]="mode" [nullValueLabel]="'NONE - CASH PURCHASE'"></wackadoo-field-foreign-key>
                                </div>
<!--
                                <div>
                                    <button type="button" title="new Lender" class="btn btn-secondary newLender small paddingQuarterEm" (click)="addNewLender()"><span class="fas fa-plus" aria-hidden="true"></span></button>
                                </div>
-->
                            </ng-container>
                            <ng-container *ngIf="resource.contractType.value !== 'Listing'">
                                <div class="marginTopHalfEm">
                                    Attorney  
                                </div>
                                <div>
                                    <wackadoo-field [f]="resource.attorneyID" [mode]="mode"></wackadoo-field>
                                </div>
<!--
                                <div>
                                    <div class="btn-group">
                                        <button type="button" title="add new Attorney" class="btn btn-secondary small paddingQuarterEm" (click)="addNewAttorney()"><span class="fas fa-plus" aria-hidden="true"></span></button>
                                        <button type="button" title="copy Attorney to EscrowAgent" class="btn btn-secondary small paddingQuarterEm"  (click)="copyAttorneyToEscrowAgent()"><span class="fas fa-share fa-rotate-90" aria-hidden="true"></span></button>
                                    </div>
                                </div>
-->
                            </ng-container>
                            <ng-container *ngIf="resource.contractType.value !== 'Listing'">
                                <div class="marginTopHalfEm">
                                    Escrow Agent 
                                </div>
                                <div>
                                    <wackadoo-field [f]="resource.escrowAgentID" [mode]="mode"></wackadoo-field>
                                </div>
<!--
                                <div>
                                    <button type="button" title="add new EscrowAgent" class="btn btn-secondary small paddingQuarterEm" (click)="addNewEscrowAgent()"><span class="fas fa-plus" aria-hidden="true"></span></button>
                                </div>
-->
                            </ng-container>
                            <ng-container>
                                <div class="marginTopHalfEm no-wrap">
                                    Referral <wackadoo-field [f]="resource.referral" [mode]="mode" [onChange]="onReferralChangeSubject"></wackadoo-field>
                                </div>
                                <ng-container *ngIf="resource.referral.value">
                                    <div>
                                        <wackadoo-field [f]="resource.referralName" [mode]="mode"></wackadoo-field>
                                    </div>
                                    <div>
                                        <wackadoo-field [f]="resource.referralCompany" [mode]="mode"></wackadoo-field>
                                    </div>
                                    <div>
                                        <wackadoo-field [f]="resource.referralEmail" [mode]="mode"></wackadoo-field>
                                    </div>
                                    <div>
                                        <wackadoo-field [f]="resource.referralPhone" [mode]="mode"></wackadoo-field>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="card">
                    <div class="card-header">
                        Current Note
                    </div>
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div class=""  [ngClass]="{fullWidth:mode==='write'}">
                                <wackadoo-field [f]="resource.contractNote" [mode]="mode"></wackadoo-field>
                            </div>
                            <div *ngIf="mode==='read'">
                                <button type="button" id="newNote" title="Edit Note" data-abbrev="" class="btn btn-secondary actionButton newNote bold small" (click)="newNote()"><span class="fas fa-pencil" aria-hidden="true"></span></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        Note History
                    </div>
                    <div class="card-body">
                        <div class="max-height-15-em scrollable-y">
                            <div *wackadooResourceBlock="let r of []; let idx = index; eventHandler:'Notes'; action:'list'; parms:{contractID: resource.contractID.value, pageSize:-1, sortBy:'noteDate',sortDirection:-1, startAt:1};">
                                <div class="border-bottom marginQuarterEm" *ngIf="(idx !== 0) || (r.note.value !== resource.contractNote.value)">
                                    <div class="floatGroup">
                                        <div class="floatLeft">
                                            {{ r.note.value }}
                                        </div>
                                        <div class="floatRight small">
                                            {{ r.noteAuthor.value }} / <wackadoo-field [f]="r.noteDate" mode="read"></wackadoo-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="" [ngClass]="{'d-flex justify-content-between':(screenType !== 'phone')}">
                    <div class="card">
                        <div class="card-header">
                            Hot List
                        </div>
                        <div class="card-body">
                            <div class="max-height-25-em scrollable-y">
                                <div *wackadooResourceBlock="let r of []; let idx = index; eventHandler:'Reports'; action:'list'; parms:{reportName:'Open Hot List Tasks',contractID: resource.contractID.value};">
                                    <div class="border-bottom marginQuarterEm rollover {{ r.taskPriority.value }}" title="Click to open TaskList" (click)="openTaskListToProperPanel(r)">
                                        <wackadoo-task-name-with-icon [resource]="r"></wackadoo-task-name-with-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            Next Incomplete Task (for each top level Task group)
                        </div>
                        <div class="card-body">
                            <table class="max-height-25-em scrollable-y">
                                <tbody>
                                    <ng-container *wackadooResourceBlock="let r of []; let idx = index; eventHandler:'Reports'; action:'list'; parms:{reportName:'Next Incomplete Tasks',contractID: resource.contractID.value};">
                                        <tr class="rollover {{ r.taskPriority.value }}"  title="Click to open TaskList" (click)="openTaskListToProperPanel(r)">
                                            <td class="right">
                                                <span class="smallish italic" *ngIf="r.topLevelTaskName.value !== 'null'">
                                                    ({{ r.topLevelTaskName.value }})
                                                </span>
                                                <span *ngIf="r.topLevelTaskName.value === 'null'">
                                                    -
                                                </span>
                                            </td>
                                            <td>
                                                <wackadoo-task-name-with-icon [resource]="r"></wackadoo-task-name-with-icon>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
