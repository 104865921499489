<div class="marginTop6 smallish">
    <div class="row">
        <div class="col-sm-6">
            <div class="card">
                <div class="card-header floatGroup">
                    <div class="floatLeft">
                        Attorney
                    </div>
                    <div class="floatRight">
                        {{ resource.attorneyID.value }}
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-3">Attorney</div>
                        <div class="col-sm-3">
                            <wackadoo-field [f]="resource.attorneyFirstName" [mode]="mode"></wackadoo-field>
                            <wackadoo-field [f]="resource.attorneyLastName" [mode]="mode"></wackadoo-field>
                        </div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.attorneyEmail" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.attorneyPhone" [mode]="mode"></wackadoo-field></div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">Firm</div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.attorneyCompany" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.attorneyAddress" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3">
                            <wackadoo-field [f]="resource.attorneyCity" [mode]="mode"></wackadoo-field>,
                            <wackadoo-field [f]="resource.attorneyState" [mode]="mode" [size]="2"></wackadoo-field>
                            <wackadoo-field [f]="resource.attorneyPostalCode" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                    <div class="row" *ngIf="(mode === 'write') || resource.attorneyContact1.value">
                        <div class="col-sm-3"><wackadoo-field [f]="resource.attorneyContact1Role" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.attorneyContact1" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.attorneyContact1Email" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.attorneyContact1Phone" [mode]="mode"></wackadoo-field></div>
                    </div>
                    <div class="row" *ngIf="(mode === 'write') || resource.attorneyContact2.value">
                        <div class="col-sm-3"><wackadoo-field [f]="resource.attorneyContact2Role" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.attorneyContact2" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.attorneyContact2Email" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.attorneyContact2Phone" [mode]="mode"></wackadoo-field></div>
                    </div>
                    <div class="row" *ngIf="(mode === 'write') || resource.attorneyContact3.value">
                        <div class="col-sm-3"><wackadoo-field [f]="resource.attorneyContact3Role" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.attorneyContact3" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.attorneyContact3Email" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.attorneyContact3Phone" [mode]="mode"></wackadoo-field></div>
                    </div>
                    <div class="row" *ngIf="(mode === 'write') || resource.attorneyContact4.value">
                        <div class="col-sm-3"><wackadoo-field [f]="resource.attorneyContact4Role" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.attorneyContact4" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.attorneyContact4Email" [mode]="mode"></wackadoo-field></div>
                        <div class="col-sm-3"><wackadoo-field [f]="resource.attorneyContact4Phone" [mode]="mode"></wackadoo-field></div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">Note</div>
                        <div class="col-sm-9">
                            <wackadoo-field [f]="resource.attorneyNote" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">Closing Funds Note</div>
                        <div class="col-sm-9">
                            <wackadoo-field [f]="resource.attorneyClosingFundsNote" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                    <div class="row" *ngIf="(mode === 'write') || resource.clientInfoFileSize.value">
                        <div class="col-sm-3">Client Form</div>
                        <div class="col-sm-9"><wackadoo-field [f]="resource.clientInfoFileContent" [mode]="mode"></wackadoo-field></div>
                    </div>
                    <div class="row" *ngIf="(mode === 'write') || resource.clientInfoFileSize.value">
                        <div class="col-sm-3"></div>
                        <div class="col-sm-9">
                            <wackadoo-file-details [fileContentField]="resource.clientInfoFileContent"></wackadoo-file-details>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-6" *ngIf="resource.attorneyID.value">
            <div class="maxHeightViewPort50">
                <ng-container *ngIf="resource.attorneyID.value">
                    <wackadoo-contract-history
                        title="Contract History"
                        cardHeightClassName="maxHeightViewPort80"
                        eventHandler="Contracts"
                        action="list"
                        [parms]="{ attorneyID: resource.attorneyID.value, pageSize:-1, sortBy:'closingDate', sortDirection:-1 }"
                        defaultText="No contract history..."
                        [debug]="false"
                    ></wackadoo-contract-history>
                </ng-container>
            </div>
<!--
            <div class="card">
                <div class="card-header">
                    Open, Related Tasks (Top 25)
                </div>
                <div class="card-body smallish maxHeightViewPort50 scrollable-y">
                    <div *wackadooResourceBlock="let r of []; let idx = index; eventHandler: 'CustomGenerator'; action: 'listRelatedOpenTasksForAttorney'; parms: {attorneyID: resource.attorneyID.value, sortBy:'sequence', sortDirection: 1, pageSize: 25 }; defaultText: 'No open tasks...'; debug: false;">
                        <wackadoo-related-task-detail [resource]="r" [mode]="mode"></wackadoo-related-task-detail>
                    </div>
                </div>
            </div>
-->
        </div>
    </div>
</div>
