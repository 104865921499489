<div class="card">
    <div class="card-header">
        Team Member Info
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-sm-3">
                {{ resource.userName.value }}
            </div>
            <div class="col-sm-3">
                {{ resource.firstName.value }} 
                {{ resource.lastName.value }}
            </div>
            <div class="col-sm-3">
                {{ resource.email.value }}
            </div>
            <div class="col-sm-3">
                {{ resource.phone.value }}
            </div>
        </div>
        <div class="row marginTopWholeEm ">
            <div class="col-sm-6">
                <div class="card">
                    <div class="card-header">
                        Contracts Assigned To {{ resource.firstName.value }} {{ resource.lastName.value }}
                    </div>
                    <div class="card-body">
                        <div *ngIf="selectedContracts.length === 0">
                            No active contracts assigned...
                        </div>
                        <div *ngFor="let r of selectedContracts; let idx = index">
                            <div class="row rollover" 
                                
                                wackadooSelectResource 
                                eventHandler="ActiveContracts"
                                [resource]="r"
                                [loadPage]="true"
                            >
                                <div class="col-sm-8">
                                    <wackadoo-field [f]="r.keyField" mode="read"></wackadoo-field>
                                </div>
                                <div class="col-sm-2">
                                    <wackadoo-field [f]="r.contractType" mode="read"></wackadoo-field>
                                </div>
                                <div class="col-sm-2" *ngIf="r.contractType.value === 'Listing'">
                                    <wackadoo-field [f]="r.listingDate" mode="read"></wackadoo-field>
                                </div>
                                <div class="col-sm-2" *ngIf="r.contractType.value !== 'Listing'">
                                    <wackadoo-field [f]="r.contractDate" mode="read"></wackadoo-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        Tasks Assigned To {{ resource.firstName.value }} {{ resource.lastName.value }}
                    </div>
                    <div class="card-body">
                        <div *wackadooResourceBlock="let task of []; let idx = index; eventHandler:'AssignedTasks'; action:'getJustAssignedTasks'; parms:{userID: resource.userID.value, sortBy:'closingDate', sortDirection: 1}; defaultText: 'No assigned tasks...'; debug: false;">
                            <div class="row {{ task.taskPriority.value }} rollover" (click)="onTaskClick(task)">
                                <div class="col-sm-5">
                                    <wackadoo-field [f]="task.contractID" mode="read"></wackadoo-field>
                                </div>
                                <div class="col-sm-5">
                                    <wackadoo-task-name-with-icon [resource]="task"></wackadoo-task-name-with-icon>
                                </div>
                                <div class="col-sm-2 container">
                                    <wackadoo-progress-bar 
                                        [totalTasks]="task.totalTasks.value" 
                                        [completedTasks]="(task.completedTasks ? task.completedTasks.value : 0)"
                                    ></wackadoo-progress-bar>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <wackadoo-multi-select-list
                    name="assignedContracts"
                    [selectSubject]="selectSubject"
                    [unSelectSubject]="unSelectSubject"
                    [selectionCriteria]="{userID: resource.userID.value}"
                    title="Available Contracts"
                    eventHandler="ActiveContracts"
                    action="search"
                    [parms]="{ startAt:1, pageSize:1000000, sortBy:'contractName' }"
                    defaultText="No active contracts available to assign..."
                    [debug]="false"
                    maxHeightThingBelowSelector="wackadoo-staff-member-detail-button-bar"
                    [maxHeightFudgeFactor]="-24"
                ></wackadoo-multi-select-list>
            </div>
        </div>
    </div>
</div>
