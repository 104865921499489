<div class="panel panel-default">
    <div class="panel-heading floatGroup">
        <div class="floatLeft">
            Contact Information
        </div>
        <div class="floatRight">
            {{ resource.escrowAgentID.value }}
        </div>
    </div>
    <div class="panel-body">
        <div class="row">
            <div class="col-sm-4">
                <div><wackadoo-field [f]="resource.escrowAgentFirstName" [mode]="mode"></wackadoo-field> <wackadoo-field [f]="resource.escrowAgentLastName" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.escrowAgentEmail" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.escrowAgentPhone" [mode]="mode"></wackadoo-field></div>
            </div>
            <div class="col-sm-4">
                <div class="small underline italic">Firm</div>
                <div><wackadoo-field [f]="resource.escrowAgentCompany" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.escrowAgentAddress" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.escrowAgentCity" [mode]="mode"></wackadoo-field>, <wackadoo-field [f]="resource.escrowAgentState" [mode]="mode" [size]="2"></wackadoo-field> <wackadoo-field [f]="resource.escrowAgentPostalCode" [mode]="mode"></wackadoo-field></div>
            </div>
        </div>
        <div class="row marginTopWholeEm">
            <div class="col-sm-12">
                <div class="small underline italic">
                    Note
                </div>
                <div>
                    <wackadoo-field [f]="resource.escrowAgentNote" [mode]="mode"></wackadoo-field>
                </div>
            </div>
        </div>
    </div>
</div>
