import { Component,  OnInit,  Input, Renderer2 } from '@angular/core';
import { WResource } from '../../data/resource.model';
import { UserInterfaceService } from '../../services/user-interface.service';
import { EventServerService } from '../../services/event-server.service';
import { PageComponent } from '../../pages/page/page.component';
import { WField } from '../../data/field.model';
import { ModalDialogService } from '../../services/modal-dialog.service';
import { UserAuthService } from '../../services/user-auth.service';
import { WEvent } from '../../data/event.model';
import { Subscription, Subject } from 'rxjs';

@Component({
  selector: 'wackadoo-duplicate-merger',
  templateUrl: './duplicate-merger.component.html',
})
export class DuplicateMergerComponent  extends PageComponent implements OnInit {

  @Input() eventHandler: string = null;
  action = 'search';
  leftParameters: any = {};
  rightParameters: any = {};

  @Input() leftGridTitle = 'Items to merge FROM...';
  @Input() leftGridFields: string [] = [];
  @Input() leftGridQueryFilter: string = null;

  @Input() rightGridTitle = 'Similar items to merge TO...';
  @Input() rightGridFields: string [] = [];
  @Input() childEventHandlersToMergeForeignKeyValues: string [] = [];

  @Input() overWriteParent = false;

  onLeftGridClickSubject: Subject<WResource> = null;
  onLeftGridClickSubscription: Subscription = null;

  onRightGridClickSubject: Subject<WResource> = null;
  onRightGridClickSubscription: Subscription = null;

  // We do two of these because we show different columns on each grid...

  leftFieldAPIs: WField [] = [];
  rightFieldAPIs: WField [] = [];

  // These are set and cleared in the indicated grid as their respective search events fire...

  leftResources: WResource [] = [];
  rightResources: WResource [] = [];

  // These are set and cleared when the user clicks on the indicated grid...

  leftResource: WResource = null;
  rightResource: WResource = null;

  // these go with a Resource Repository eventHandler

  startAt = 1;
  pageSize: number;

  sortBy: string;
  sortDirection = 1; // >= 0 means ascending, < 0 means descending...

  query = '';

  sortDirectionIcon: string = null;

  constructor(
    userAuthService: UserAuthService,
    userInterfaceService: UserInterfaceService,
    public modalDialogService: ModalDialogService,
    public eventServerService: EventServerService,
    public renderer: Renderer2,
  ) {
    super(userAuthService, userInterfaceService);
  }

  ngOnInit(): void {
    try {
      super.ngOnInit();

      this.leftFieldAPIs = this.getFieldAPIs(this.leftGridFields);

      this.rightFieldAPIs = this.getFieldAPIs(this.rightGridFields);

      this.action = this.eventServerService.getDefaultMethod(this.eventHandler);

      // console.log('DuplicateMerger.ngOnInit()'
      //     + '\n' + 'eventHandler? ' + JSON.stringify(this.eventHandler)
      //     + '\n' + 'action? ' + JSON.stringify(this.action)
      //     + '\n' + 'leftGridTitle? ' + JSON.stringify(this.leftGridTitle)
      //     + '\n' + 'leftGridFields? ' + JSON.stringify(this.leftGridFields)
      //     + '\n' + 'leftGridQueryFilter? ' + JSON.stringify(this.leftGridQueryFilter)
      //     + '\n' + 'rightGridTitle? ' + JSON.stringify(this.rightGridTitle)
      //     + '\n' + 'rightGridFields? ' + JSON.stringify(this.rightGridFields)
      //     + '\n' + 'childEventHandlersToMergeForeignKeyValues? ' + JSON.stringify(this.childEventHandlersToMergeForeignKeyValues)
      //     + '\n' + 'overWriteParent? ' + JSON.stringify(this.overWriteParent)
      //     + '\n' + 'leftFieldAPIs? ' + JSON.stringify(this.leftFieldAPIs)
      //     + '\n' + 'rightFieldAPIs? ' + JSON.stringify(this.rightFieldAPIs)
      //   );

      this.onLeftGridClickSubject = new Subject<WResource>();

      this.onLeftGridClickSubscription = this.onLeftGridClickSubject.subscribe(
        (resource: WResource) => {
          // console.log('DuplicateMerger.onLeftGridClick() - running callback for resource:', resource.asParms);
          this.leftResource = resource;
          this.userInterfaceService.selectResource(this.eventHandler, resource);
          this.buildRightGrid();
        }
      );

      this.onRightGridClickSubject = new Subject<WResource>();

      this.onRightGridClickSubscription = this.onRightGridClickSubject.subscribe(
        (resource: WResource) => {
          // console.log('DuplicateMerger.onRightGridClick() - running callback for resource:', resource.asParms);
          this.rightResource = resource;
          // this.userInterfaceService.selectResource(this.eventHandler, resource);
        }
      );

      this.buildLeftGrid();

    } catch (ex) {
      const msg = 'DuplicateMerger.ngOnInit()';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  unSelectLeftResource(): void {
    this.userInterfaceService.unSelectResource(this.eventHandler);
    this.leftResource = null;
    this.buildRightGrid();
  }

  unSelectRightResource(): void {
    this.userInterfaceService.selectResource(this.eventHandler, this.leftResource);
    this.rightResource = null;
    this.buildRightGrid();
  }

  buildQueryFunction(resource: WResource): string {

    throw new Error('For a dupe merger, we have to over-ride the buildQueryFunction() on the page for the duplicate-merger plugin to work...!');

    // if (!resource) {
    //   throw new Error('No resource provided to buildQueryFunction!');
    // }
    // // console.log('DuplicateMerger.buildQueryFunction() - ' + JSON.stringify(resource.asParms));

    // const parms = resource.asParms;
    // let query = '(';
    // for (const [name, val] of Object.entries(parms)) {
    //   let value = String(val);
    //   if (value && value.trim().length > 0) {
    //     if (value.indexOf(' ') >= 0) {
    //       value = '"' + value + '"';
    //     }
    //     query += ('(' + name + ':' + value + ')');
    //     query += ' OR ';
    //   }
    // }
    // // chop off the last ' OR '
    // query = query.substring(0, query.length - 4);
    // query += ')';

    // console.log('DuplicateMerger.buildQueryFunction() - ' + query);

    // return(query);
  }

  buildLeftGrid(): void {

    try {
      // now set up the dupe-merger UX...

      if (this.eventHandler) {

        const leftGridParms: any = this.leftParameters;
        leftGridParms.query = this.leftGridQueryFilter;

        // console.log('DuplicateMerger.buildLeftGrid() - about to fire:', this.eventHandler,  'search',  leftGridParms);

        this.eventServerService.fireEvent(this.eventHandler,  'search',  leftGridParms).subscribe(
          (event: WEvent) => {

            // console.log('DuplicateMerger.buildLeftGrid()', event);

            if (event.status !== 'OK') {
              throw new Error(event.message);
            }
            this.leftResources = event.resources;

            this.leftParameters = event.parameters;

            // is the selected resource in the left grid?
            const selectedResource = this.userInterfaceService.getSelectedResource(this.eventHandler);
            if (selectedResource) {
              const selectedResourceFromGrid = this.leftResources.find((r) => r.keyField.value === selectedResource.keyField.value);
              if (selectedResourceFromGrid) {
                  this.leftResource = selectedResourceFromGrid;
                  this.buildRightGrid();
              } else {
                this.userInterfaceService.unSelectResource(this.eventHandler);
              }
            } else {
              this.userInterfaceService.unSelectResource(this.eventHandler);
            }

            // re-do component height calculations
            this.userInterfaceService.screenSizeChange.next();
          }
        );
      }

    } catch (ex) {
      const msg = 'DuplicateMerger.buildLeftGrid() failed!\n';
      this.userInterfaceService.alertUserToException(ex,  msg);
    }
  }

  buildRightGrid(): void {
    try {
      this.rightResource = null;
      this.rightResources = [];

      if (this.leftResource) {
        const rightGridParms: any = {};
        rightGridParms.query = this.buildQueryFunction(this.leftResource);

        // console.log('DuplicateMerger.buildRightGrid() - about to fire:', this.eventHandler,  'search',  rightGridParms);

        this.eventServerService.fireEvent(this.eventHandler,  'search',  rightGridParms).subscribe(
          (event: WEvent) => {

            // console.log('DuplicateMerger.buildRightGrid()', event);

            if (event.status !== 'OK') {
              throw new Error(event.message);
            }
            this.rightResources = event.resources;

            this.rightParameters = event.parameters;

            // re-do component height calculations
            this.userInterfaceService.screenSizeChange.next();
          }
        );
      } else {
        // re-do component height calculations
        this.userInterfaceService.screenSizeChange.next();
      }

    } catch (ex) {
      const msg = 'DuplicateMerger.buildRightGrid() failed!\n';
      this.userInterfaceService.alertUserToException(ex,  msg);
    }
  }

  mergeItems(): void {
    try {
      let msg = 'You are about to re-assign all related records\nfrom ' + this.leftResource.keyField.displayValue + ' to ' + this.rightResource.keyField.displayValue + ' for the following:';

      for (const childEH of this.childEventHandlersToMergeForeignKeyValues) {
        msg += '\n';
        msg += ' - ' + childEH;
      }

      msg += '\n\n';
      msg += 'WARNING: You are also about to delete ' + this.leftResource.keyField.displayValue;
      msg += '\n\n';
      msg += 'Are you sure?';

      this.modalDialogService.showConfirm(msg, 'WARNING').subscribe(
        (response: boolean) => {
          if (response === true) {
            const parms = this.leftResource.keyField.asParm;
            parms.newValue = this.rightResource.keyField.value;
            parms.ehNameToIgnore = 'DataArchives';
            parms.overWriteParent = this.overWriteParent;

            // console.log(this.eventHandler, 'reassignForeignKeysDeleteOldParent', parms);
            this.eventServerService.fireEvent(this.eventHandler,  'reassignForeignKeysDeleteOldParent',  parms).subscribe(
              (event: WEvent) => {
                // console.log(event);
                if (event.status !== 'OK') {
                  throw new Error('There was a problem reassigning the foreign key relationships for the just-merged item: ' + this.leftResource.keyField.displayValue + '\n' + event.message); // + '\n' + JSON.stringify(event);
                }
                this.userInterfaceService.reloadCurrentPage();
              }
            );
          }
        }
      );
    } catch (ex) {
      const msg = 'DuplicateMerger.mergeItems.onclick() failed!\n';
      this.userInterfaceService.alertUserToException(ex,  msg);
    }
  }

  cancel(): void {
    this.userInterfaceService.loadPage(this.eventHandler);
  }

  getFieldAPIs(fieldList: string []): WField [] {

    if (!this.eventHandler) {
      return null;
    }

    let fieldAPIs: WField [] = this.eventServerService.getFieldDefinitionsInOrder(this.eventHandler);

    // console.log('getFieldAPIs() - enter', fieldList, fieldAPIs);

    let defaultSortField: string = null;
    let defaultSortDirection: number = null;

    let n = fieldList.length + 1;

    fieldAPIs.forEach(
      (wf: WField) => {
        if (fieldList.includes(wf.name)) {
          wf.number = fieldList.findIndex((s: string) => s === wf.name) + 1;
          wf.detailOnly = false;
          // console.log('getFieldAPIs() - showing', wf.name, wf.number);
        } else {
          wf.number = n++;
          wf.detailOnly = true;
        }
        if (wf.defaultSort) {
          defaultSortField = wf.name;
          defaultSortDirection = wf.defaultSortDirection;
        }
      }
    );

    if (!this.sortBy) {
      if (defaultSortField) {
        // console.log('getFieldAPIs() - fieldList.includes(defaultSortField)', fieldList.includes(defaultSortField));
        if (fieldList.includes(defaultSortField)) {
          this.sortBy = defaultSortField;
          this.sortDirection = defaultSortDirection;
        } else {
          const newDFS = fieldAPIs.find((f) => f.number === 1);
          this.sortBy = newDFS.name;
          this.sortDirection = 1;
        }
      }
    }

    // console.log('getFieldAPIs() - defaults:', defaultSortField, defaultSortDirection, '\nsort parms:', this.sortBy, this.sortDirection, );

    // then we sort the fields by the "number" attribute value... (which are text values...)
    fieldAPIs = fieldAPIs.sort( (f1: WField, f2: WField) => {
        return (Number(f1.number) > Number(f2.number) ? 1 : (Number(f1.number) === Number(f2.number) ? 0 : -1));
    });

    // console.log('getFieldAPIs() - exit', fieldAPIs);

    return fieldAPIs;
  }

}
