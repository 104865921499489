<div class="marginBottomQuarterEm">
    <div class=""  [ngClass]="{'d-flex justify-content-between':(screenType !== 'phone')}" >
        <div class="jumbotron shadow marginWholeEm paddingWholeEm" *ngIf="user.isPublicOnly" [ngClass]="{large:(screenType !== 'tablet')}" >
            <div class="d-flex align-items-center" [ngClass]="{extraLarge:(screenType !== 'phone')}" >
                <div class="marginLeftHalfEm">
                    <img class="max-height-3-em" src="{{ accountLogoURL }}"/>
                </div>
                <div class="marginLeftHalfEm marginRightHalfEm">
                    Welcome to {{ user.accountName }}!
                </div>
            </div>
            <div class="italic center tiny marginTopWholeEm">
                (<span class="bold">{{ user.accountName }}</span> 
                uses <span class="bold">{{ appName }}</span>
                from <a href="{{ orgUrl }}">{{ orgName }}</a>, 
                a boutique web app company based in Chapel Hill, NC.)
            </div>
        </div>
        <div class="" [ngClass]="{'widthViewPort40 paddingTopWholeEm':(screenType !== 'phone'), small:(screenType !== 'fullscreen')}">
            <div class="marginHalfEm" [ngClass]="{marginTopWholeEm:(screenType !== 'phone')}" *ngIf="user.isPublicOnly">
                <div class="marginTopQuarterEm">
                    Please enter the information necessary to submit a new contract or listing to <span class="bold">{{ user.accountName }}</span> for processing. 
                </div>
                <div class="marginTopQuarterEm">
                    You will be notified when {{ user.accountName }} acknowledges receipt.  
                </div>
                <div class="marginTopQuarterEm">
                    Be sure to include copies of the <span class="underline">Due Diligence fee</span> and <span class="underline">EMD</span> checks when you post the <span class="underline">signed contract</span> to your document repository. (e.g. DocuSign, DotLoop, BackAgent, etc.)
                </div>
                <div class="marginTopQuarterEm">
                    <span class="bold italic">(* indicates required information)</span>
                </div>
            </div>
            <div class="marginHalfEm" *ngIf="user.isStaffOrAbove">
                <div>
                    Please remember to notify the Agent directly if you have any questions, need more information, or choose to reject this submission.
                </div>
                <div class="marginTopWholeEm d-flex">
                    <div class="marginRightHalfEm">Indicate the set of Tasks for this Contract:</div>
                    <div>
                        <wackadoo-field [f]="resource.taskTemplateGroupID" [mode]="mode"></wackadoo-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div [ngClass]="{'d-flex flex-wrap justify-content-around':(screenType !== 'phone')}">
        <div>
            <div class="card">
                <div class="card-header">
                    Agent Info
                </div>
                <div class="card-body">
                    <div class="d-flex flex-wrap">
                        <div>
                            <wackadoo-field [f]="resource.agentFirstName" [mode]="mode"></wackadoo-field>
                        </div>
                        <div class="marginLeftHalfEm">
                            <wackadoo-field [f]="resource.agentLastName" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap marginTopQuarterEm">
                        <div>
                            Commission
                        </div>
                        <div class="marginLeftHalfEm">
                            <wackadoo-field [f]="resource.commission" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap marginTopQuarterEm">
                        <div>
                            {{ (screenType === 'phone' ? 'Split?' : 'How do you split your commission?') }}
                        </div>
                        <div class="marginLeftHalfEm">
                            <wackadoo-field [f]="resource.commissionSplit" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap marginTopQuarterEm">
                        <div>
                            Admin Fee (Paid By Client)
                        </div>
                        <div class="marginLeftHalfEm">
                            <wackadoo-field [f]="resource.adminFee" [mode]="mode" ></wackadoo-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="card">
                <div class="card-header">
                    <div class="d-flex flex-wrap justify-content-between">
                        <div>
                            Contract Info
                        </div>
                        <div>
                            <span class="unbold italic right">
                                Type
                            </span>
                            <span class="unbold">
                                <wackadoo-field [f]="resource.contractType" [mode]="mode" [onChange]="onChangeContractType"></wackadoo-field>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="d-flex flex-wrap justify-content-between marginTopQuarterEm">
                        <div class="marginRightHalfEm">
                            MLS
                        </div>
                        <div>
                            <wackadoo-field [f]="resource.mlsNumber" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between marginTopQuarterEm">
                        <div class="marginRightHalfEm">
                            Property Type
                        </div>
                        <div>
                            <wackadoo-field [f]="resource.propertyType" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                    <div class="marginTopQuarterEm">
                        Property Address
                    </div>
                    <div><wackadoo-field [f]="resource.contractAddress" [mode]="mode"></wackadoo-field></div>
                    <div class="d-flex flex-wrap justify-content-between marginTopQuarterEm">
                        <div><wackadoo-field [f]="resource.contractCity" [mode]="mode"></wackadoo-field></div>
                        <div class="width-3-em"><wackadoo-field [f]="resource.contractState" [mode]="mode" [size]="2"></wackadoo-field></div>
                        <div class="width-6-em"><wackadoo-field [f]="resource.contractPostalCode" [mode]="mode"></wackadoo-field></div>
                    </div>
                    <div class="marginTopQuarterEm">
                        Contract Notes
                    </div>
                    <div class="height-5-em scrollable-y" [ngClass]="{maxWidthViewPort25 : screenType != 'phone'}">
                        <wackadoo-field [f]="resource.contractNote" [mode]="mode"></wackadoo-field>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="card" *ngIf="resource.contractType.value === 'Listing'">
                <div class="card-header">
                    Listing Terms
                </div>
                <div class="card-body">
                    <div class="d-flex flex-wrap justify-content-between">
                        <div>
                            Price
                        </div>
                        <div class="marginLeftWholeEm width-8-em">
                            <wackadoo-field [f]="resource.purchasePrice" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between">
                        <div>
                            MLS Live Date
                        </div>
                        <div class="marginLeftWholeEm">
                            <wackadoo-field [f]="resource.listingDate" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between marginTopQuarterEm">
                        <div>
                            {{ (screenType !== 'fullscreen' ? 'Coming Soon' : 'Coming Soon Date' ) }}
                        </div>
                        <div class="marginLeftWholeEm">
                            <wackadoo-field [f]="resource.comingSoon" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between marginTopQuarterEm">
                        <div>
                            Showings Begin
                        </div>
                        <div class="marginLeftWholeEm">
                            <wackadoo-field [f]="resource.showingsBegin" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between marginTopQuarterEm">
                        <div>
                            Staging Consult
                        </div>
                        <div class="marginLeftWholeEm">
                            <wackadoo-field [f]="resource.stagingConsult" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between marginTopQuarterEm">
                        <div>
                            Full Staging
                        </div>
                        <div class="marginLeftWholeEm">
                            <wackadoo-field [f]="resource.fullStaging" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between marginTopQuarterEm">
                        <div>
                            Currently Occupied
                        </div>
                        <div class="marginLeftWholeEm">
                            <wackadoo-field [f]="resource.currentlyOccupied" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between marginTopQuarterEm">
                        <div>
                            Vacant On Market
                        </div>
                        <div class="marginLeftWholeEm">
                            <wackadoo-field [f]="resource.vacantOnMarket" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between marginTopQuarterEm">
                        <div>
                            Need Lockbox
                        </div>
                        <div class="marginLeftWholeEm">
                            <wackadoo-field [f]="resource.needLockbox" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" *ngIf="resource.contractType.value !== 'Listing'">
                <div class="card-header">
                    Contract Terms
                </div>
                <div class="card-body">
                    <div class="d-flex flex-wrap justify-content-between">
                        <div>
                            Price
                        </div>
                        <div class="marginLeftWholeEm width-8-em">
                            <wackadoo-field [f]="resource.purchasePrice" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between marginTopQuarterEm">
                        <div>
                            {{ (screenType !== 'fullscreen' ? 'DD Fee' : 'Due Diligence Fee' ) }}
                        </div>
                        <div class="marginLeftWholeEm width-8-em" *ngIf="resource.propertyType.value !== 'New Construction'">
                            <wackadoo-field [f]="resource.ddFee" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between marginTopQuarterEm">
                        <div>
                            EMD
                        </div>
                        <div class="marginLeftWholeEm width-8-em">
                            <wackadoo-field [f]="resource.emd" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between marginTopQuarterEm">
                        <div>
                            {{ (screenType !== 'fullscreen' ? 'Add\'l EMD' : 'Additional EMD' ) }}
                        </div>
                        <div class="marginLeftWholeEm width-8-em">
                            <wackadoo-field [f]="resource.addEMD" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between marginTopQuarterEm">
                        <div>
                            {{ (screenType !== 'fullscreen' ? 'Closing $' : 'Closing Costs' ) }}
                        </div>
                        <div class="marginLeftWholeEm width-8-em">
                            <wackadoo-field [f]="resource.closingCosts" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between marginTopQuarterEm">
                        <div>
                            {{ (screenType !== 'fullscreen' ? 'Contract' : 'Contract Date' ) }}
                        </div>
                        <div class="marginLeftWholeEm">
                            <wackadoo-field [f]="resource.contractDate" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between marginTopQuarterEm">
                        <div>
                            {{ (screenType !== 'fullscreen' ? 'DD Date' : 'Due Diligence Date' ) }}
                        </div>
                        <div class="marginLeftWholeEm">
                            <wackadoo-field [f]="resource.ddDate" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between marginTopQuarterEm">
                        <div>
                            EMD Date
                        </div>
                        <div class="marginLeftWholeEm">
                            <wackadoo-field [f]="resource.emdDate" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between marginTopQuarterEm">
                        <div>
                            {{ (screenType !== 'fullscreen' ? 'Add EMD Date' : 'Additional EMD Date' ) }}
                        </div>
                        <div class="marginLeftWholeEm">
                            <wackadoo-field [f]="resource.addEMDDate" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between marginTopQuarterEm">
                        <div>
                            {{ (screenType !== 'fullscreen' ? 'Closing' : 'Closing Date' ) }}
                        </div>
                        <div class="marginLeftWholeEm">
                            <wackadoo-field [f]="resource.closingDate" [mode]="mode"></wackadoo-field>
                        </div>
                    </div>
                    <div *ngIf="(resource.propertyType.value === 'New Construction')">
                        <div class="marginTopHalfEm">
                            New Construction Deposits
                        </div>
                        <div class="d-flex flex-wrap justify-content-between marginTopQuarterEm marginLeftWholeEm">
                            <div class="width-8-em"><wackadoo-field [f]="resource.deposit1" [mode]="mode"></wackadoo-field></div>
                            <div class="marginLeftWholeEm"><wackadoo-field [f]="resource.deposit1Date" [mode]="mode"></wackadoo-field></div>
                        </div>
                        <div class="d-flex flex-wrap justify-content-between marginTopQuarterEm marginLeftWholeEm">
                            <div class="width-8-em"><wackadoo-field [f]="resource.deposit2" [mode]="mode"></wackadoo-field></div>
                            <div class="marginLeftWholeEm"><wackadoo-field [f]="resource.deposit2Date" [mode]="mode"></wackadoo-field></div>
                        </div>
                        <div class="d-flex flex-wrap justify-content-between marginTopQuarterEm marginLeftWholeEm">
                            <div class="width-8-em"><wackadoo-field [f]="resource.deposit3" [mode]="mode"></wackadoo-field></div>
                            <div class="marginLeftWholeEm"><wackadoo-field [f]="resource.deposit3Date" [mode]="mode"></wackadoo-field></div>
                        </div>
                        <div class="d-flex flex-wrap justify-content-between marginTopQuarterEm marginLeftWholeEm">
                            <div class="width-8-em"><wackadoo-field [f]="resource.deposit4" [mode]="mode"></wackadoo-field></div>
                            <div class="marginLeftWholeEm"><wackadoo-field [f]="resource.deposit4Date" [mode]="mode"></wackadoo-field></div>
                        </div>
                        <div class="d-flex flex-wrap justify-content-between marginTopQuarterEm marginLeftWholeEm">
                            <div class="width-8-em"><wackadoo-field [f]="resource.deposit5" [mode]="mode"></wackadoo-field></div>
                            <div class="marginLeftWholeEm"><wackadoo-field [f]="resource.deposit5Date" [mode]="mode"></wackadoo-field></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="card"  *ngIf="user.isPublicOnly && (screenType !== 'phone') && ((!resource.inputFormID || !resource.inputFormID.value) && resource.captchaQuery)">
                <div class="card-header">CAPTCHA</div>
                <div class="card-body">
                    <div class="d-flex flex-wrap justify-content-around">
                        <div class="center nowrap">{{ resource.captchaQuery.value }}</div>
                        <div class="width-5-em marginLeftHalfEm" title="Provide numeric answer (e.g. 42, -37, etc.)">
                            <wackadoo-field-whole-number [f]="resource.captchaAnswer" [mode]="mode" [size]="5"></wackadoo-field-whole-number> 
                        </div>
                    </div>
                    <div class="center marginTopQuarterEm">
                        <button type="button" class="btn btn-secondary refresh" (click)="refreshCaptcha()"><span class="fas fa-sync" aria-hidden="true"></span> Refresh</button>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header d-flex flex-wrap justify-content-between">
                    <div class="nowrap marginRightQuarterEm">
                        Referral Info  
                    </div>
                    <div>
                        <wackadoo-field [f]="resource.referral" [mode]="mode" [onChange]="onChangeReferral"></wackadoo-field>
                    </div>
                </div>
                <div class="collapse card-body" #collapse="ngbCollapse" [(ngbCollapse)]="!resource.referral.value">
                    <div><wackadoo-field [f]="resource.referralName" [mode]="mode"></wackadoo-field></div>
                    <div><wackadoo-field [f]="resource.referralCompany" [mode]="mode"></wackadoo-field></div>
                    <div><wackadoo-field [f]="resource.referralEmail" [mode]="mode"></wackadoo-field></div>
                    <div><wackadoo-field [f]="resource.referralPhone" [mode]="mode"></wackadoo-field></div>
                </div>
            </div>
        </div>
    </div>
    <div class="" [ngClass]="{'d-flex flex-wrap justify-content-around marginTopWholeEm':(screenType !== 'phone')}">

        <div>
            <div class="card">
                <div class="card-header">
                    <div class="d-flex flex-wrap justify-content-between">
                        <div>
                            Client Info
                        </div>
                        <div>
                            <span class="italic right paddingRightQuarterEm">
                                Client Role
                            </span>
                            <span class="paddingLeftQuarterEm">
                                <wackadoo-field [f]="resource.client1Role" [mode]="mode"></wackadoo-field>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="d-flex flex-wrap">
                        <div class="paddingRightHalfEm"><wackadoo-field [f]="resource.client1FirstName" [mode]="mode"></wackadoo-field></div>
                        <div class="paddingLeftHalfEm"><wackadoo-field [f]="resource.client1LastName" [mode]="mode"></wackadoo-field></div>
                   </div>
                    <div><wackadoo-field [f]="resource.client1Email" [mode]="mode"></wackadoo-field></div>
                    <div class="d-flex flex-wrap justify-content-between">
                        <div><wackadoo-field [f]="resource.client1Phone" [mode]="mode"></wackadoo-field></div>
                        <div class="right paddingLeftWholeEm paddingRight0">married</div>
                        <div><wackadoo-field [f]="resource.client1Married" [mode]="mode"></wackadoo-field></div>
                    </div>
                    <div><wackadoo-field [f]="resource.client1Company" [mode]="mode"></wackadoo-field></div>
                </div>
            </div>
            <div class="card">
                <div class="card-header">
                    <div class="d-flex flex-wrap justify-content-between">
                        <div>
                            Client 2 <wackadoo-field [f]="resource.client2Required" [mode]="mode" [onChange]="onChangeClientRequired"></wackadoo-field>
                        </div>
                        <div *ngIf="resource.client2Required.value">
                            <span class="italic right paddingRightQuarterEm" *ngIf="resource.client2Required.value">
                                Client Role
                            </span>
                            <span class="paddingLeftQuarterEm">
                                <wackadoo-field [f]="resource.client2Role" [mode]="mode"></wackadoo-field>
                            </span>
                        </div>
                        <div class="paddingLeftQuarterEm" *ngIf="resource.client2Required.value">
                            <button type="button" title="Copy" class="small btn btn-secondary actionButton paddingQuarterEm" (click)="copyClient(1, 2)"><span class="fas fa-copy" aria-hidden="true"></span></button>
                        </div>
                    </div>
                </div>
                <div class="collapse card-body" #collapse="ngbCollapse" [(ngbCollapse)]="!resource.client2Required.value">
                    <div class="d-flex flex-wrap">
                        <div class="paddingRightHalfEm"><wackadoo-field [f]="resource.client2FirstName" [mode]="mode"></wackadoo-field></div>
                        <div class="paddingLeftHalfEm"><wackadoo-field [f]="resource.client2LastName" [mode]="mode"></wackadoo-field></div>
                    </div>
                    <div><wackadoo-field [f]="resource.client2Email" [mode]="mode"></wackadoo-field></div>
                    <div class="d-flex flex-wrap justify-content-between">
                        <div><wackadoo-field [f]="resource.client2Phone" [mode]="mode"></wackadoo-field></div>
                        <div class="right paddingLeftWholeEm paddingRight0">married</div>
                        <div><wackadoo-field [f]="resource.client2Married" [mode]="mode"></wackadoo-field></div>
                    </div>
                </div>
            </div>
            <div class="card" *ngIf="resource.client2Required.value">
                <div class="card-header">
                    <div class="d-flex flex-wrap justify-content-between">
                        <div>
                            Client 3 <wackadoo-field [f]="resource.client3Required" [mode]="mode" [onChange]="onChangeClientRequired"></wackadoo-field>
                        </div>
                        <div *ngIf="resource.client3Required.value">
                            <span class="italic right paddingRightQuarterEm" *ngIf="resource.client3Required.value">
                                Client Role
                            </span>
                            <span class="paddingLeftQuarterEm">
                                <wackadoo-field [f]="resource.client3Role" [mode]="mode"></wackadoo-field>
                            </span>
                        </div>
                        <div class="paddingLeftQuarterEm" *ngIf="resource.client3Required.value">
                            <button type="button" title="Copy" class="small btn btn-secondary actionButton paddingQuarterEm" (click)="copyClient(2, 3)"><span class="fas fa-copy" aria-hidden="true"></span></button>
                        </div>
                    </div>
                </div>
                <div class="collapse card-body" #collapse="ngbCollapse" [(ngbCollapse)]="!resource.client3Required.value">
                    <div class="d-flex flex-wrap">
                        <div class="paddingRightHalfEm"><wackadoo-field [f]="resource.client3FirstName" [mode]="mode"></wackadoo-field></div>
                        <div class="paddingLefttHalfEm"><wackadoo-field [f]="resource.client3LastName" [mode]="mode"></wackadoo-field></div>
                    </div>
                    <div><wackadoo-field [f]="resource.client3Email" [mode]="mode"></wackadoo-field></div>
                    <div class="d-flex flex-wrap justify-content-between">
                        <div><wackadoo-field [f]="resource.client3Phone" [mode]="mode"></wackadoo-field></div>
                        <div class="right paddingLeftWholeEm paddingRight0">married</div>
                        <div><wackadoo-field [f]="resource.client3Married" [mode]="mode"></wackadoo-field></div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="(resource.contractType.value === 'Dual Agency')">
            <div class="card">
                <div class="card-header">
                    <div class="d-flex flex-wrap justify-content-between">
                        <div>
                            Other Client
                        </div>
                        <div>
                            <span class="italic right paddingRightQuarterEm">
                                Client Role
                            </span>
                            <span class="paddingLeftQuarterEm">
                                <wackadoo-field [f]="resource.client4Role" [mode]="mode"></wackadoo-field>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="d-flex flex-wrap">
                        <div class="paddingRightHalfEm"><wackadoo-field [f]="resource.client4FirstName" [mode]="mode"></wackadoo-field></div>
                        <div class="paddingLeftHalfEm"><wackadoo-field [f]="resource.client4LastName" [mode]="mode"></wackadoo-field></div>
                    </div>
                    <div><wackadoo-field [f]="resource.client4Email" [mode]="mode"></wackadoo-field></div>
                    <div class="d-flex flex-wrap justify-content-between">
                        <div><wackadoo-field [f]="resource.client4Phone" [mode]="mode"></wackadoo-field></div>
                        <div class="right paddingLeftWholeEm paddingRight0">married</div>
                        <div><wackadoo-field [f]="resource.client4Married" [mode]="mode"></wackadoo-field></div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header">
                    <div class="d-flex flex-wrap justify-content-between">
                        <div>
                            Other Client 2 <wackadoo-field [f]="resource.client5Required" [mode]="mode" [onChange]="onChangeClientRequired"></wackadoo-field>
                        </div>
                        <div *ngIf="resource.client5Required.value">
                            <span class="italic right paddingRightQuarterEm" *ngIf="resource.client5Required.value">
                                Client Role
                            </span>
                            <span class="paddingLeftQuarterEm">
                                <wackadoo-field [f]="resource.client5Role" [mode]="mode"></wackadoo-field>
                            </span>
                        </div>
                        <div class="paddingLeftQuarterEm" *ngIf="resource.client5Required.value">
                            <button type="button" title="Copy" class="small btn btn-secondary actionButton paddingQuarterEm" (click)="copyClient(4, 5)"><span class="fas fa-copy" aria-hidden="true"></span></button>
                        </div>
                    </div>
                </div>
                <div class="collapse card-body" #collapse="ngbCollapse" [(ngbCollapse)]="!resource.client5Required.value">
                    <div class="d-flex flex-wrap">
                        <div class="paddingRightHalfEm"><wackadoo-field [f]="resource.client5FirstName" [mode]="mode"></wackadoo-field></div>
                        <div class="paddingLeftHalfEm"><wackadoo-field [f]="resource.client5LastName" [mode]="mode"></wackadoo-field></div>
                    </div>
                    <div><wackadoo-field [f]="resource.client5Email" [mode]="mode"></wackadoo-field></div>
                    <div class="d-flex flex-wrap justify-content-between">
                        <div><wackadoo-field [f]="resource.client5Phone" [mode]="mode"></wackadoo-field></div>
                        <div class="right paddingLeftWholeEm paddingRight0">married</div>
                        <div><wackadoo-field [f]="resource.client5Married" [mode]="mode"></wackadoo-field></div>
                    </div>
                </div>
            </div>
            <div class="card" *ngIf="resource.client5Required.value">
                <div class="card-header">
                    <div class="d-flex flex-wrap justify-content-between">
                        <div>
                            Other Client 3 <wackadoo-field [f]="resource.client6Required" [mode]="mode" [onChange]="onChangeClientRequired"></wackadoo-field>
                        </div>
                        <div *ngIf="resource.client6Required.value">
                            <span class="italic right paddingRightQuarterEm" *ngIf="resource.client6Required.value">
                                Client Role
                            </span>
                            <span class="paddingLeftQuarterEm">
                                <wackadoo-field [f]="resource.client6Role" [mode]="mode"></wackadoo-field>
                            </span>
                        </div>
                        <div class="paddingLeftQuarterEm" *ngIf="resource.client6Required.value">
                            <button type="button" title="Copy" class="small btn btn-secondary actionButton paddingQuarterEm" (click)="copyClient(5, 6)"><span class="fas fa-copy" aria-hidden="true"></span></button>
                        </div>
                    </div>
                </div>
                <div class="collapse card-body" #collapse="ngbCollapse" [(ngbCollapse)]="!resource.client6Required.value">
                    <div class="d-flex flex-wrap">
                        <div class="paddingRightHalfEm"><wackadoo-field [f]="resource.client6FirstName" [mode]="mode"></wackadoo-field></div>
                        <div class="paddingLeftHalfEm"><wackadoo-field [f]="resource.client6LastName" [mode]="mode"></wackadoo-field></div>
                    </div>
                    <div><wackadoo-field [f]="resource.client6Email" [mode]="mode"></wackadoo-field></div>
                    <div class="d-flex flex-wrap justify-content-between">
                        <div><wackadoo-field [f]="resource.client6Phone" [mode]="mode"></wackadoo-field></div>
                        <div class="right paddingLeftWholeEm paddingRight0">married</div>
                        <div><wackadoo-field [f]="resource.client6Married" [mode]="mode"></wackadoo-field></div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="(resource.contractType.value === 'Buyer') || (resource.contractType.value === 'Seller')">
            <div class="card">
                <div class="card-header d-flex flex-wrap justify-content-between">
                    <div class="nowrap marginRightQuarterEm">
                        Co-Op Agent Info 
                    </div>
                    <div>
                        <input (click)="toggleCoOpAgentDisplay()" type="checkbox" [checked]="displayCoOpAgent" [disabled]="mode === 'read'" />
                    </div>
                </div>
                <div class="collapse card-body" #collapse="ngbCollapse" [(ngbCollapse)]="!displayCoOpAgent">
                    <div>Select a known Co-Op Agent...</div>
                    <div><wackadoo-field [f]="resource.coOpAgentID" [mode]="mode" [onChange]="onChangeCoOpAgent"></wackadoo-field></div>
                    <div>...or provide contact info for a new one.</div>
                    <div class="d-flex flex-wrap">
                        <div class="paddingRightHalfEm"><wackadoo-field [f]="resource.coOpAgentFirstName" [mode]="mode" [onChange]="onChangeCoOpAgent"></wackadoo-field></div>
                        <div class="paddingLeftHalfEm"><wackadoo-field [f]="resource.coOpAgentLastName" [mode]="mode" [onChange]="onChangeCoOpAgent"></wackadoo-field></div>
                    </div>
                    <div><wackadoo-field [f]="resource.coOpAgentCompany" [mode]="mode" [onChange]="onChangeCoOpAgent"></wackadoo-field></div>
                    <div><wackadoo-field [f]="resource.coOpAgentEmail" [mode]="mode" [onChange]="onChangeCoOpAgent"></wackadoo-field></div>
                    <div><wackadoo-field [f]="resource.coOpAgentPhone" [mode]="mode" [onChange]="onChangeCoOpAgent"></wackadoo-field></div>
                    <div class="marginTopHalfEm">coordinator</div>
                    <div><wackadoo-field [f]="resource.coOpCoord" [mode]="mode" [onChange]="onChangeCoOpAgent"></wackadoo-field></div>
                    <div><wackadoo-field [f]="resource.coOpCoordEmail" [mode]="mode" [onChange]="onChangeCoOpAgent"></wackadoo-field></div>
                    <div><wackadoo-field [f]="resource.coOpCoordPhone" [mode]="mode" [onChange]="onChangeCoOpAgent"></wackadoo-field></div>
                </div>
            </div>
        </div>
        <div *ngIf="(resource.contractType.value !== 'Listing')">
            <div class="card">
                <div class="card-header">
                    {{(resource.contractType === 'Seller' ? '' : '*')}} Closing Attorney
                </div>
                <div class="card-body">
                    <div>Select a known attorney...</div>
                    <div><wackadoo-field [f]="resource.attorneyID" [mode]="mode" [onChange]="onChangeAttorney"></wackadoo-field></div>
                    <div>...or provide contact info for a new one.</div>
                    <div class="d-flex flex-wrap">
                        <div class="paddingRightHalfEm" ><wackadoo-field [f]="resource.attorneyFirstName" [mode]="mode" [onChange]="onChangeAttorney"></wackadoo-field></div>
                        <div class="paddingLeftHalfEm"><wackadoo-field [f]="resource.attorneyLastName" [mode]="mode" [onChange]="onChangeAttorney"></wackadoo-field></div>
                    </div>
                    <div><wackadoo-field [f]="resource.attorneyCompany" [mode]="mode" [onChange]="onChangeAttorney"></wackadoo-field></div>
                    <div><wackadoo-field [f]="resource.attorneyEmail" [mode]="mode" [onChange]="onChangeAttorney"></wackadoo-field></div>
                    <div><wackadoo-field [f]="resource.attorneyPhone" [mode]="mode" [onChange]="onChangeAttorney"></wackadoo-field></div>
                </div>
            </div>
        </div>
        <div *ngIf="(resource.contractType.value !== 'Listing')">
            <div class="card" *ngIf="(resource.contractType.value === 'Buyer') || (resource.contractType.value === 'Dual Agency')">
                <div class="card-header d-flex flex-wrap justify-content-between">
                    <div class="marginRighttWholeEm">
                        * Lender Info
                    </div>
                    <div class="marginLeftWholeEm">
                        Cash Purchase? <wackadoo-field [f]="resource.cashPurchase" [mode]="mode" [onChange]="onChangeLender"></wackadoo-field>
                    </div>
                </div>
                <div class="collapse card-body" #collapse="ngbCollapse" [(ngbCollapse)]="resource.cashPurchase.value">
                    <div>Select a known lender...</div>
                    <div><wackadoo-field [f]="resource.lenderID" [mode]="mode" [onChange]="onChangeLender"></wackadoo-field></div>
                    <div>...or provide contact info for a new one.</div>
                    <div class="d-flex flex-wrap">
                        <div class="paddingRightHalfEm"><wackadoo-field [f]="resource.lenderFirstName" [mode]="mode" [onChange]="onChangeLender"></wackadoo-field></div>
                        <div class="paddingLeftHalfEm"><wackadoo-field [f]="resource.lenderLastName" [mode]="mode" [onChange]="onChangeLender"></wackadoo-field></div>
                    </div>
                    <div><wackadoo-field [f]="resource.lenderCompany" [mode]="mode" [onChange]="onChangeLender"></wackadoo-field></div>
                    <div><wackadoo-field [f]="resource.lenderEmail" [mode]="mode" [onChange]="onChangeLender"></wackadoo-field></div>
                    <div><wackadoo-field [f]="resource.lenderPhone" [mode]="mode" [onChange]="onChangeLender"></wackadoo-field></div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="user.isPublicOnly && (screenType === 'phone') && ((!resource.inputFormID || !resource.inputFormID.value) && resource.captchaQuery)">
        <div class="card">
            <div class="card-header">CAPTCHA</div>
            <div class="card-body">
                <div class=" d-flex flex-wrap justify-content-around">
                    <div class="center nowrap">{{ resource.captchaQuery.value }}</div>
                    <div class="width-5-em marginLeftWholeEm" title="Provide numeric answer (e.g. 42, -37, etc.)">
                        <wackadoo-field-whole-number [f]="resource.captchaAnswer" [mode]="mode" [size]="5"></wackadoo-field-whole-number> 
                    </div>
                </div>
                <div class="center marginTopQuarterEm">
                    <button type="button" class="btn btn-secondary refresh" (click)="refreshCaptcha()"><span class="fas fa-sync" aria-hidden="true"></span> Refresh</button>
                </div>
            </div>
        </div>
    </div>
</div>
