import { Component } from '@angular/core';
import { DetailContentComponent } from 'src/app/client-core/pages/repository-page/detail-content/detail-content.component';

@Component({
  selector: 'wackadoo-co-op-agents-detail-content',
  templateUrl: './co-op-agents-detail-content.component.html',
})
export class CoOpAgentsDetailContentComponent extends DetailContentComponent {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'CoOpAgentsDetailContentComponent';

}
