<div class="" [ngClass]="{'d-flex justify-content-around':(screenType !== 'phone')}">
    <table>
        <tr class="top">
            <td class="bold smallish">
                Address
            </td>
            <td>
                <div>
                    <wackadoo-field [f]="resource.contractAddress" [mode]="mode" [size]="25"></wackadoo-field>
                </div>
                <div class="d-flex">
                    <div>
                        <wackadoo-field [f]="resource.contractCity" [mode]="mode"></wackadoo-field>
                    </div>
                    <div class="marginLeftHalfEm">
                        <wackadoo-field [f]="resource.contractState" [mode]="mode" [size]="2"></wackadoo-field>
                    </div>
                    <div class="marginLeftHalfEm">
                        <wackadoo-field [f]="resource.contractPostalCode" [mode]="mode" [size]="5"></wackadoo-field>
                    </div>
                </div>
                <div class="d-flex justify-content-between marginTopHalfEm">
                    <div class="d-flex">
                        <div class="bold smallish right">
                            County
                        </div>
                        <div class="marginLeftHalfEm">
                            <div *ngIf="(mode === 'write') || resource.contractCounty.isPopulated else noCounty">
                                <wackadoo-field [f]="resource.contractCounty" [mode]="mode"></wackadoo-field>
                            </div>
                            <ng-template #noCounty>
                                <span class="italic">unknown</span>
                            </ng-template>
                        </div>
                    </div>
                    <div class="d-flex marginLeftWholeEm">
                        <div class="bold smallish right">
                            Within City Limits
                        </div>
                        <div class="marginLeftHalfEm">
                            <wackadoo-field [f]="resource.withinCityLimits" [mode]="mode" [size]="2"></wackadoo-field>
                        </div>
                    </div>
                    <div class="d-flex marginLeftWholeEm">
                        <div class="bold smallish right">
                            Year Built
                        </div>
                        <div class="marginLeftHalfEm">
                            <wackadoo-field-whole-number [f]="resource.yearBuilt" [mode]="mode" [size]="4" [skipCommas]="true"></wackadoo-field-whole-number>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    </table>
    <table class="marginLeftWholeEm">
        <tr>
            <td class="bold smallish right">
                LockBox
            </td>
            <td>
                <wackadoo-field [f]="resource.lockboxNumber" [mode]="mode"></wackadoo-field>
            </td>
        </tr>
        <tr>
            <td class="bold smallish right">
                Property Type
            </td>
            <td>
                <wackadoo-field [f]="resource.propertyType" [mode]="mode"></wackadoo-field>
            </td>
        </tr>
        <tr *ngIf="(resource.contractType.value !== 'Listing')">
            <td class="bold smallish right">
                Loan Type
            </td>
            <td>
                <wackadoo-field [f]="resource.loanType" [mode]="mode"></wackadoo-field>
            </td>
        </tr>
    </table>
</div>
<div class="marginTopHalfEm" [ngClass]="{'d-flex justify-content-around':(screenType !== 'phone')}">
    <table>
        <tr>
            <td class="bold smallish right">
                Price
            </td>
            <td>
                <wackadoo-field [f]="resource.purchasePrice" [mode]="mode"></wackadoo-field>
            </td>
        </tr>
        <tr>
            <td class="bold smallish right">
                Closing Costs
            </td>
            <td>
                <wackadoo-field [f]="resource.closingCosts" [mode]="mode"></wackadoo-field>
            </td>
        </tr>
        <tr>
            <td class="bold smallish right">
                Commission
            </td>
            <td>
                <wackadoo-field [f]="resource.commission" [mode]="mode"></wackadoo-field>
            </td>
        </tr>
        <tr title="Commission split describes how the agent's portion of the commision is divided between the agent, their agency, potential referrals, etc.">
            <td class="bold smallish right">
                Commission Split
            </td>
            <td>
                <wackadoo-field [f]="resource.commissionSplit" [mode]="mode"></wackadoo-field>
            </td>
        </tr>
        <tr *ngIf="(resource.contractType.value !== 'Listing')" >
            <td class="bold smallish right">
                Client At Closing
            </td>
            <td>
                <div class="d-flex justify-content-between">
                    <div class="marginRightWholeEm">
                        <wackadoo-field [f]="resource.clientAtClosing" [mode]="mode"></wackadoo-field>
                    </div>
                    <div class="bold smallish right marginLeftWholeEm marginTopQuarterEm">
                        Closing Type
                    </div>
                    <div class="marginLeftWholeEm">
                        <wackadoo-field [f]="resource.closingType" [mode]="mode"></wackadoo-field>
                    </div>
                </div>
            </td>
        </tr>
    </table>
    <table  class="marginLeftWholeEm" *ngIf="(resource.contractType.value !== 'Listing')" >
        <tr *ngIf="(resource.propertyType.value !== 'New Construction')">
            <td class="bold smallish right">
                DD Fee
            </td>
            <td>
                <wackadoo-field [f]="resource.ddFee" [mode]="mode"></wackadoo-field>
            </td>
        </tr>
        <tr>
            <td class="bold smallish right">
                EMD
            </td>
            <td>
                <wackadoo-field [f]="resource.emd" [mode]="mode"></wackadoo-field>
            </td>
        </tr>
        <tr>
            <td class="bold smallish right">
                EMD Date
            </td>
            <td>
                <wackadoo-field [f]="resource.emdDate" [mode]="mode"></wackadoo-field>
            </td>
        </tr>
        <tr>
            <td class="bold smallish right">
                Addit. EMD
            </td>
            <td>
                <wackadoo-field [f]="resource.addEMD" [mode]="mode"></wackadoo-field>
            </td>
        </tr>
        <tr>
            <td class="bold smallish right">
                Addit. EMD Date
            </td>
            <td>
                <wackadoo-field [f]="resource.addEMDDate" [mode]="mode"></wackadoo-field>
            </td>
        </tr>
    </table>
    <table  class="marginLeftWholeEm" *ngIf="(resource.contractType.value === 'Listing')" >
        <tr>
            <td class="bold smallish right">
                Coming Soon
            </td>
            <td>
                <wackadoo-field [f]="resource.comingSoon" [mode]="mode"></wackadoo-field>
            </td>
        </tr>
        <tr>
            <td class="bold smallish right">
                Showings Begin
            </td>
            <td>
                <wackadoo-field [f]="resource.showingsBegin" [mode]="mode"></wackadoo-field>
            </td>
        </tr>
        <tr>
            <td class="bold smallish right">
                Staging Consult
            </td>
            <td>
                <wackadoo-field [f]="resource.stagingConsult" [mode]="mode"></wackadoo-field>
            </td>
        </tr>
        <tr>
            <td class="bold smallish right">
                Full Staging
            </td>
            <td>
                <wackadoo-field [f]="resource.fullStaging" [mode]="mode"></wackadoo-field>
            </td>
        </tr>
        <tr>
            <td class="bold smallish right">
                Currently Occupied
            </td>
            <td>
                <wackadoo-field [f]="resource.currentlyOccupied" [mode]="mode"></wackadoo-field>
            </td>
        </tr>
        <tr>
            <td class="bold smallish right">
                Vacant On Market
            </td>
            <td>
                <wackadoo-field [f]="resource.vacantOnMarket" [mode]="mode"></wackadoo-field>
            </td>
        </tr>
        <tr>
            <td class="bold smallish right">
                Need Lockbox
            </td>
            <td>
                <wackadoo-field [f]="resource.needLockbox" [mode]="mode"></wackadoo-field>
            </td>
        </tr>
    </table>
</div>
<div class="marginTopHalfEm" [ngClass]="{'d-flex justify-content-around':(screenType !== 'phone')}">
    <div class="d-flex justify-content-center">
        <div class="bold smallish">
            Admin Fee (Paid By Client)
        </div>
        <div class="marginLeftWholeEm">
            <wackadoo-field [f]="resource.adminFee" [mode]="mode"></wackadoo-field>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <div class="bold smallish">
            Home Warranty
        </div>
        <div class="marginLeftWholeEm">
            <wackadoo-field [f]="resource.homeWarranty" [mode]="mode"></wackadoo-field>
        </div>
    </div>
</div>
<div class="marginTopHalfEm" *ngIf="(resource.contractType.value !== 'Listing') && (resource.propertyType.value === 'New Construction')">
    <div class="" [ngClass]="{'d-flex justify-content-between':(screenType !== 'phone')}">
        <div class="bold smallish">
            New Construction Deposit(s): 
        </div>
        <div class="d-flex justify-content-between">
            <div>
                <wackadoo-field [f]="resource.deposit1" [mode]="mode"></wackadoo-field>
            </div>
            <div class="marginLeftHalfEm">
                <wackadoo-field [f]="resource.deposit1Date" [mode]="mode"></wackadoo-field>
            </div>
        </div>
    </div>
    <div class="" [ngClass]="{'d-flex justify-content-between':(screenType !== 'phone')}">
        <div class="d-flex justify-content-between">
            <div>
                <wackadoo-field [f]="resource.deposit2" [mode]="mode"></wackadoo-field>
            </div>
            <div class="marginLeftHalfEm">
                <wackadoo-field [f]="resource.deposit2Date" [mode]="mode"></wackadoo-field>
            </div>
        </div>
        <div class="d-flex justify-content-between">
            <div>
                <wackadoo-field [f]="resource.deposit3" [mode]="mode"></wackadoo-field>
            </div>
            <div class="marginLeftHalfEm">
                <wackadoo-field [f]="resource.deposit3Date" [mode]="mode"></wackadoo-field>
            </div>
        </div>
    </div>
    <div class="" [ngClass]="{'d-flex justify-content-between':(screenType !== 'phone')}">
        <div class="d-flex justify-content-between">
            <div>
                <wackadoo-field [f]="resource.deposit4" [mode]="mode"></wackadoo-field>
            </div>
            <div class="marginLeftHalfEm">
                <wackadoo-field [f]="resource.deposit4Date" [mode]="mode"></wackadoo-field>
            </div>
        </div>
        <div class="d-flex justify-content-between">
            <div>
                <wackadoo-field [f]="resource.deposit5" [mode]="mode"></wackadoo-field>
            </div>
            <div class="marginLeftHalfEm">
                <wackadoo-field [f]="resource.deposit5Date" [mode]="mode"></wackadoo-field>
            </div>
        </div>
    </div>
</div>
<div class="marginTopHalfEm" *ngIf="resource.contractType.value !== 'Listing'">
    <div class="" [ngClass]="{'d-flex justify-content-around':(screenType !== 'phone')}">
        <div>
            <table class="no-wrap">
                <tr>
                    <td class="bold smallish right">
                        Contract Date
                    </td>
                    <td>
                        <wackadoo-field [f]="resource.contractDate" [mode]="mode"></wackadoo-field>
                    </td>
                </tr>
                <tr>
                    <td class="bold smallish right">
                        Due Diligence
                    </td>
                    <td>
                        <wackadoo-field [f]="resource.ddDate" [mode]="mode"></wackadoo-field>
                    </td>
                </tr>
                <tr>
                    <td class="bold smallish right">
                        Closing Date
                    </td>
                    <td>
                        <wackadoo-field [f]="resource.closingDate" [mode]="mode"></wackadoo-field>
                    </td>
                </tr>
            </table>
        </div>
        <div class="marginLeftWholeEm">
            <div class="bold smallish">
                Special Considerations
            </div>
            <div>
                <wackadoo-field [f]="resource.specialConsiderations" [mode]="mode" [size]="50"></wackadoo-field>
            </div>
            <div class="bold smallish">
                Closing Notes
            </div>
            <div>
                <wackadoo-field [f]="resource.closingNotes" [mode]="mode" [size]="50"></wackadoo-field>
            </div>
        </div>
    </div>
</div>
<table class="marginTopHalfEm" *ngIf="resource.contractType.value === 'Listing'">
    <tr>
        <td class="bold smallish right">
            MLS Live Date
        </td>
        <td>
            <wackadoo-field [f]="resource.listingDate" [mode]="mode"></wackadoo-field>
        </td>
        <td class="bold smallish right">
            Expiration
        </td>
        <td>
            <wackadoo-field [f]="resource.listingExpirationDate" [mode]="mode"></wackadoo-field>
        </td>
    </tr>
</table>
