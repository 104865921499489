import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DateMode } from '../day/day.component';
import { CalendarService } from '../calendar.service';

@Component({
  selector: 'wackadoo-month',
  templateUrl: './month.component.html',
})
export class MonthComponent implements OnInit, OnChanges {

  @Input() calendarName: string = null;
  @Input() topOfMonth: Date = null;
  @Input() mode = DateMode.n;

  year: number = null;
  month: number = null;

  topsOfWeeks: Date[] = [];

  constructor(
    public calendarService: CalendarService,
  ) { }

  ngOnInit(): void {
    // console.log('MonthComponent.ngOnInit()', this.topOfMonth);
    //   this.initTheMonth();
  }

  ngOnChanges(sc: SimpleChanges): void {
    if (!sc.topOfMonth.previousValue || (sc.topOfMonth.currentValue.getTime() !== sc.topOfMonth.previousValue.getTime())) {
      this.initTheMonth();
    }
  }

  initTheMonth(): void {
    // console.log('MonthComponent.initTheMonth()', this.topOfMonth.toJSON());

    this.year = this.topOfMonth.getFullYear();
    this.month = this.topOfMonth.getMonth();

    // initialize the start for each week...
    this.topsOfWeeks = [];
    const sundaysThisMonth: string [] = [];
    for (let i = 1; i <= this.calendarService.getDaysInMonth(this.month, this.year); i++) {
        const d = new Date(this.year, this.month, i);
        const top = this.calendarService.getTopOfWeek(d);
        if (!sundaysThisMonth.includes(top.getMonth() + '' + top.getDate())) {
            sundaysThisMonth.push(top.getMonth() + '' + top.getDate());
            this.topsOfWeeks.push(top);
        }
    }
    // console.log('MonthComponent.initTheMonth() - this.topsOfWeeks: ', this.topsOfWeeks);
  }

}
