<div class="fullWidth">
    <!--
         [ngClass]="{'d-flex justify-content-between':(screenType !== 'phone')}"
    -->
    <div class="border-bottom marginTopQuarterEm paddingBottomQuarterEm">
        <div class="d-flex justify-content-between">
            <div class="d-flex">
                <div class="marginRightHalfEm">
                    <wackadoo-field [f]="resource.taskComplete" mode="write" [onChange]="onChangeTaskComplete" title="Click to complete task..."></wackadoo-field>
                </div>
                <div class="{{ resource.taskPriority.value }}">
                    <wackadoo-task-name-with-icon [resource]="resource" mode="read"></wackadoo-task-name-with-icon>
                </div>
            </div>
            <div class="marginLeftWholeEm paddingLeftWholeEm" *ngIf="screenType !== 'phone'">
                <button type="button" (click)="scheduleTask()"  title="Schedule Task"   data-abbrev="" class="btn btn-secondary actionButton bold smallish nowrap padding3"><span class="fas fa-calendar" aria-hidden="true"></span></button>
            </div>
        </div>
        <div class="marginLeftWholeEm paddingLeftWholeEm">
            <div class="d-flex justify-content-between">
                <div>
                    <span class="link"
                        title="Click Here To Open Contract Detail..." 
                        wackadooPageLoad
                        eventHandler="ActiveContracts"
                        action="search"
                        [parms]="{contractID: resource.contractID.value}"
                    >
                        <wackadoo-field [f]="resource.contractID" mode="read"></wackadoo-field>
                    </span>
                    <span class="italic marginLeftWholeEm" *ngIf="screenType !== 'phone'">
                        ({{ resource.contractType.value }})
                    </span>
                </div>
                <div class="marginLeftWholeEm paddingLeftWholeEm" *ngIf="screenType !== 'phone'">
                    <div class="btn-group">
                        <button type="button" (click)="addNote()"       title="New Note"        data-abbrev="" class="btn btn-secondary actionButton bold smallish nowrap padding3"><span class="fas fa-pencil-alt" aria-hidden="true"></span></button>
                        <button type="button" (click)="newTask()"       title="New Task"        data-abbrev="" class="btn btn-secondary actionButton bold smallish nowrap padding3"><span class="fas fa-plus" aria-hidden="true"></span></button>
                    </div>
                </div>
            </div>
            <div *ngIf="screenType !== 'phone'">
                {{ resource.taskDescription.value }}
            </div>
            <div *ngIf="resource.taskStartDate && resource.taskStartDate.value">
                Date/Time: <wackadoo-field [f]="resource.taskStartDate" mode="read"></wackadoo-field>
            </div>
            <div class="link rollover" (click)="openEMailer()" *ngIf="(resource.taskType.value === 'email')">
                Click Here To Open EMail...
            </div>
            <div class="smallish italic" *ngIf="screenType !== 'phone'">
                <wackadoo-field [f]="resource.taskNote" mode="read"></wackadoo-field>
            </div>
        </div>
    </div>
</div>
