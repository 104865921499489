import { Component } from '@angular/core';
import { ResourceComponent } from 'src/app/client-core/data/resource/resource.component';

@Component({
  selector: 'wackadoo-attorney-modal',
  templateUrl: './attorney-modal.component.html',
})
export class AttorneyModalComponent extends ResourceComponent {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'AttorneyModalComponent';

}
