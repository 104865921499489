<div class="marginTop6 row wackadoo-detail">
    <div class="col-sm-6">
        <div class="card">
            <div class="card-header floatGroup">
                <div class="floatLeft">
                    Vendor
                </div>
                <div class="floatRight">
                    {{ resource.vendorID.value }}
                </div>
            </div>
            <div class="card-body">
                <div><wackadoo-field [f]="resource.vendorFirstName" [mode]="mode"></wackadoo-field><wackadoo-field [f]="resource.vendorLastName" [mode]="mode"></wackadoo-field></div>
                <div class="marginTopQuarterEm"><wackadoo-field [f]="resource.vendorCompany" [mode]="mode"></wackadoo-field></div>
                <div class="marginTopQuarterEm"><wackadoo-field [f]="resource.vendorAddress" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.vendorCity" [mode]="mode"></wackadoo-field><wackadoo-field [f]="resource.vendorState" [mode]="mode" [size]="2"></wackadoo-field><wackadoo-field [f]="resource.vendorPostalCode" [mode]="mode"></wackadoo-field></div>
                <div class="marginTopQuarterEm"><wackadoo-field [f]="resource.vendorEmail" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.vendorPhone" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.vendorType" [mode]="mode"></wackadoo-field></div>
                <div *ngIf="resource.vendorType.value === 'Other'"><wackadoo-field [f]="resource.otherDescription" [mode]="mode"></wackadoo-field></div>
            </div>
        </div>
        <div class="card">
            <div class="card-header d-flex justify-content-between">
                <div>Note</div>
                <div class="d-flex justify-content-end unbold">
                    <div class="marginRightHalfEm">Category:</div>
                    <div class="width-8-em"><wackadoo-field [f]="resource.vendorCategory" [mode]="mode"></wackadoo-field></div>
                </div>
            </div>
            <div class="card-body">
                <wackadoo-field [f]="resource.vendorNote" [mode]="mode"></wackadoo-field>
            </div>
        </div>
    </div>
    <div class="col-sm-6" *ngIf="resource.vendorID.value">
        <ng-container *ngIf="resource.vendorID.value">
            <wackadoo-contract-history
                title="Contract History"
                cardHeightClassName="maxHeightViewPort80"
                eventHandler="Reports"
                action="list"
                [parms]="{ reportName: 'Contract History for Vendor', vendorID: resource.vendorID.value }"
                defaultText="No contract history..."
                [debug]="false"
            ></wackadoo-contract-history>
        </ng-container>
<!--
        <div class="card">
            <div class="card-header">
                Open, Related Tasks (Top 25)
            </div>
            <div class="card-body smallish maxHeightViewPort50 scrollable-y">
                <div *wackadooResourceBlock="let r of []; let idx = index; eventHandler: 'CustomGenerator'; action: 'listRelatedOpenTasksForVendor'; parms: {vendorID: resource.vendorID.value, sortBy:'sequence', sortDirection: 1, pageSize: 25 }; defaultText: 'No open tasks...'; debug: false;">
                    <wackadoo-related-task-detail [resource]="r" [mode]="mode"></wackadoo-related-task-detail>
                </div>
            </div>
        </div>
-->
    </div>
</div>
