<div class="btn-group marginTopQuarterEm d-flex" role="group">
    <button type="button" title="Save"   data-abbrev="" class="btn btn-secondary actionButton" (click)="save()"   *ngIf="editMode && (methods.includes('add') || methods.includes('modify'))"><span class="fas fa-check"></span></button>
    <button type="button" title="Edit"   data-abbrev="" class="btn btn-secondary actionButton" (click)="modify()" *ngIf="!editMode && methods.includes('modify')"><span class="fas fa-pencil-alt"></span></button>
    <button type="button" title="Copy"   data-abbrev="" class="btn btn-secondary actionButton" (click)="copy()"   *ngIf="!editMode && methods.includes('add')"><span class="fas fa-copy"></span></button>
    <button type="button" title="Delete" data-abbrev="" class="btn btn-secondary actionButton" (click)="remove()" *ngIf="!editMode && methods.includes('delete')"><span class="fas fa-trash"></span></button>

    <button type="button" title="Download Calendar" data-abbrev="Calendar"    class="btn btn-secondary actionButton" (click)="downloadICS()"><span class="fas fa-calendar-alt"></span></button>
    <button type="button" title="Contact Card" data-abbrev="Contact"    class="btn btn-secondary actionButton" (click)="downloadVCF()"><span class="fas fa-address-card"></span></button>

    <button type="button" title="Cancel" data-abbrev="" class="btn btn-secondary actionButton" (click)="cancel()"><span class="fas fa-times"></span></button>
</div>
