<div class="card">
    <div class="card-header">
        <div>
            Copying Template "{{resource.taskName.value}}" (and sub-task-templates) To A Different Template Group
        </div>
        <div class="small italic">
            Note: Clicking OK will copy this as a new "top level" template in the destination template group, AND you will switch to that template group
        </div>
        
    </div>
    <div class="card-body">
        <div [ngClass]="{'d-flex justify-content-center':(screenType !== 'phone')}">
            <div>
                Destination Template Group:
            </div>
            <div [ngClass]="{marginLeftHalfEm:(screenType !== 'phone')}">
                <wackadoo-field [f]="resource.taskTemplateGroupID" [mode]="'write'"></wackadoo-field>
            </div>
            <div [ngClass]="{marginLeftHalfEm:(screenType !== 'phone')}">
                {{ resource.contractType.value }}
            </div>
        </div>
    </div>
</div>
