import { Component, OnInit, OnDestroy } from '@angular/core';
import { DetailContentComponent } from 'src/app/client-core/pages/repository-page/detail-content/detail-content.component';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { Globals } from 'src/app/client-core/services/global.service';
import { FieldMode } from 'src/app/client-core/data/field/field-mode.model';
import { WEvent } from 'src/app/client-core/data/event.model';
import { WString, WField, WInteger } from 'src/app/client-core/data/field.model';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { Subject, Subscription } from 'rxjs';
import { ScreenType } from 'src/app/client-core/services/screen-type.enum';

@Component({
  selector: 'wackadoo-in-box-detail-content',
  templateUrl: './in-box-detail-content.component.html',
})
export class InBoxDetailContentComponent extends DetailContentComponent implements OnInit, OnDestroy {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'InBoxDetailContentComponent';

  appName = Globals.thisApplication;
  orgName: string = null;
  orgUrl: string = null;
  accountLogoURL: string = null;

  displayCoOpAgent = false;

  onChangeReferral: Subject<WField> = null;
  onChangeReferralSubscription: Subscription = null;

  onChangeContractType: Subject<WField> = null;
  onChangeContractTypeSubscription: Subscription = null;

  onChangeClientRequired: Subject<WField> = null;
  onChangeClientRequiredSubscription: Subscription = null;

  onChangeCoOpAgent: Subject<WField> = null;
  onChangeCoOpAgentSubscription: Subscription = null;

  onChangeLender: Subject<WField> = null;
  onChangeLenderSubscription: Subscription = null;

  onChangeAttorney: Subject<WField> = null;
  onChangeAttorneySubscription: Subscription = null;

  screenType: ScreenType = null;
  screenTypeSubscription: Subscription = null;

  constructor(
    userInterfaceService: UserInterfaceService,
    eventServerService: EventServerService,
    public modalDialogService: ModalDialogService,
  ) {
    super(userInterfaceService, eventServerService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    // console.log('InBoxDetail.ngOnInit()', this.resource.asParms, JSON.stringify(this.resource.asParms));

    /////////////////////////////////////////////////////////////////////////////////
    // first, we set up the page, operationally speaking...
    /////////////////////////////////////////////////////////////////////////////////

    // let's force typeaheads instead of dropdowns...
    // (needed until we roll out v7.2.2 w/the new InputForm.xml)
    // this.resource.coOpAgentID.displayComponent = 'typeahead';
    // this.resource.attorneyID.displayComponent = 'typeahead';
    // this.resource.lenderID.displayComponent = 'typeahead';

    this.screenType = this.userInterfaceService.screenType.getValue();

    this.screenTypeSubscription = this.userInterfaceService.screenType.subscribe(
      (screenType: ScreenType) => {
        this.screenType = screenType;
      }
    );

    this.orgName = Globals.organizationName;
    this.orgUrl = Globals.organizationURL;

    this.mode = (this.user.isPublicOnly ? FieldMode.write : this.mode);
    this.accountLogoURL = this.userInterfaceService.accountLogoURL;

    // We force taskTemplateGroupID to include a "foreignType" lookup
    // but WITHOUT making it an actual "foreignKey"...
    if (this.resource.taskTemplateGroupID) {
      this.userInterfaceService.convertToFakeForeignKeyField(this.resource, 'TaskTemplateGroup', 'taskTemplateGroupID');
    }

    // console.log('InBoxDetail.ngOnInit()', this.resource, this.mode, this.user, JSON.stringify(this.resource.captchaQuery));

    if (!this.resource.captchaQuery && (!this.resource.inputFormID || !this.resource.inputFormID.value)) {
      this.populateCaptcha();
    }

    this.onChangeContractType = new Subject<WField>();

    this.onChangeContractTypeSubscription = this.onChangeContractType.subscribe(
      (f: WField) => {
        this.setFieldValuesPerContractType(f.value);
      }
    );

    this.onChangeReferral = new Subject<WField>();

    this.onChangeReferralSubscription = this.onChangeReferral.subscribe(
      (f: WField) => {
        try {
          if (f) {
            this.setFieldValuesPerReferral();
          }
        } catch (ex) {
          this.userInterfaceService.alertUserToException(ex, 'onChangeReferral');
        }
      }
    );

    this.onChangeClientRequired = new Subject<WField>();

    this.onChangeClientRequiredSubscription = this.onChangeClientRequired.subscribe(
      (f: WField) => {
        try {
          const required = f.value;

          // "clientXRequired"
          const clientNumber = Number(f.name.substring(6, 7));

          if (required) {
            this.setFieldsPerClient(clientNumber, required);
          } else {
            this.clearClient(clientNumber);
          }

        } catch (ex) {
          this.userInterfaceService.alertUserToException(ex, 'onChangeClientRequiredSubscription');
        }
      }
    );

    this.onChangeCoOpAgent = new Subject<WField>();

    this.onChangeCoOpAgentSubscription = this.onChangeCoOpAgent.subscribe(
      (f: WField) => {
        this.setFieldsPerCoOpAgent(f);
      }
    );

    this.onChangeLender = new Subject<WField>();

    this.onChangeLenderSubscription = this.onChangeLender.subscribe(
      (f: WField) => {
        this.setFieldsPerLender(f);
      }
    );

    this.onChangeAttorney = new Subject<WField>();

    this.onChangeAttorneySubscription = this.onChangeAttorney.subscribe(
      (f: WField) => {
        this.setFieldsPerAttorney(f);
      }
    );

    /////////////////////////////////////////////////////////////////////////////////
    // second, we adjust the display per the provided resource values...
    /////////////////////////////////////////////////////////////////////////////////

    if (this.resource.inputFormID.isNull) {

      // if the ID is null, we're doing an add, so we show Attorney, CoOpAgent, and Client2...

      // then we properly initialize client roles, as necessary...

      if (this.resource.contractType.isPopulated)  {
        if ((this.resource.contractType.value === 'Dual Agency') || (this.resource.contractType.value === 'Seller')) {

          this.resource.client1Role.value = 'Seller';
          this.resource.client2Role.value = 'Seller';
          this.resource.client3Role.value = 'Seller';
          this.resource.client4Role.value = 'Buyer';
          this.resource.client5Role.value = 'Buyer';
          this.resource.client6Role.value = 'Buyer';
        }
      }

      const extraClientNumbers: number [] = [2, 3, 4, 5, 6];

      for (const n of extraClientNumbers) {
        if (n !== 4) {
          this.resource['client' + n + 'Required'].value = this.clientIsPopulated(n);
          this.resource['client' + n + 'Required'].changed  = true;
        }
        this.setFieldsPerClient(n, this.clientIsPopulated(n));
      }

    }

    // configure fields appropriately for the current contractType
    if (!this.resource.contractType.value) {
      this.resource.contractType.value = 'Buyer';
      this.resource.contractType.changed = true;
    }

    // console.log('InBoxDetail.ngOnInit() - about to set fields per contractType: ', this.resource.contractType.value, JSON.stringify(this.resource));

    this.setFieldValuesPerContractType(this.resource.contractType.value);

    this.setFieldValuesPerReferral();

  }

  ngOnDestroy(): void {

    if (this.screenTypeSubscription) {
      this.screenTypeSubscription.unsubscribe();
    }

    if (this.onChangeReferralSubscription) {
      this.onChangeReferralSubscription.unsubscribe();
    }

    if (this.onChangeClientRequiredSubscription) {
      this.onChangeClientRequiredSubscription.unsubscribe();
    }
    if (this.onChangeContractTypeSubscription) {
      this.onChangeContractTypeSubscription.unsubscribe();
    }

    if (this.onChangeCoOpAgentSubscription) {
      this.onChangeCoOpAgentSubscription.unsubscribe();
    }

    if (this.onChangeLenderSubscription) {
      this.onChangeLenderSubscription.unsubscribe();
    }

    if (this.onChangeAttorneySubscription) {
      this.onChangeAttorneySubscription.unsubscribe();
    }

    super.ngOnDestroy();
  }

  populateCaptcha(): void {
    this.eventServerService.fireEvent('InBox', 'generate', {}).subscribe(
      (result: WEvent) => {
        // console.log(this.name+'.preDetailLoad() - result:', result);

        if (result.status === 'OK') {
          const captchaQuery = result.parameters.captchaQuery;
          const captchaKey = result.parameters.captchaKey;

          let f = new WString('captchaQuery', captchaQuery);
          this.resource.addExtraField(f);

          f = new WInteger('captchaAnswer', null);
          this.resource.addExtraField(f);

          f = new WString('captchaKey', captchaKey);
          f.changed = true;
          this.resource.addExtraField(f);

          // console.log(this.name+'.preDetailLoad() - captchaQuery: ' + captchaQuery + ', captchaKey: ' + captchaKey);

        } else {
          this.modalDialogService.showAlert('Unable to generate CAPTCHA!\n' + result.message, 'Error!');
        }
      }
    );
  }

  refreshCaptcha(): void {
    // console.log('InBox.refreshCaptcha()');
    this.eventServerService.fireEvent('InBox', 'generate', null).subscribe(
      (result: WEvent) => {
        // console.log('InBox.refresh() - result: ' + JSON.stringify(result));

        if (result.status === 'OK') {
          const captchaQuery = result.getParameter('captchaQuery');
          const captchaKey = result.getParameter('captchaKey');

          this.resource.captchaQuery.value = captchaQuery;

          this.resource.captchaKey.value = captchaKey;
          this.resource.captchaKey.changed = true;

          this.resource.captchaAnswer.value = null;

        } else {
          this.modalDialogService.showAlert('Unable to generate CAPTCHA!\n' + result.message, 'Error!');
        }
      }
    );
  }

  clientIsPopulated(n: number): boolean {
    return (
              this.resource['client' + n + 'FirstName'].isPopulated
              || this.resource['client' + n + 'LastName'].isPopulated
              || this.resource['client' + n + 'Email'].isPopulated
              || this.resource['client' + n + 'Phone'].isPopulated
            );
  }

  clearClient(n: number): void {
    try {
      // console.log('about to clear client ' + n);

      this.resource['client' + n + 'FirstName'].value = null;
      this.resource['client' + n + 'LastName'].value  = null;
      this.resource['client' + n + 'Email'].value     = null;
      this.resource['client' + n + 'Phone'].value     = null;
      this.resource['client' + n + 'Married'].value   = null;
      this.resource['client' + n + 'Role'].value   = null;

      this.resource['client' + n + 'FirstName'].changed  = true;
      this.resource['client' + n + 'LastName'].changed   = true;
      this.resource['client' + n + 'Email'].changed      = true;
      this.resource['client' + n + 'Phone'].changed      = true;
      this.resource['client' + n + 'Married'].changed    = true;
      this.resource['client' + n + 'Role'].changed    = true;

      if ((n !== 1) && (n !== 4)) {
        this.resource['client' + n + 'Required'].value    = false;
        this.resource['client' + n + 'Required'].changed  = true;
      }

    } catch (ex) {
      const msg = 'InBox.clearClient()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  copyClient(fromN: number, toN: number): void {
    try {
      console.log('copyClient(): ' + fromN + ' to ' + toN);

      this.resource['client' + toN + 'FirstName'].value = this.resource['client' + fromN + 'FirstName'].value;
      this.resource['client' + toN + 'LastName'].value  = this.resource['client' + fromN + 'LastName'].value;
      this.resource['client' + toN + 'Email'].value     = this.resource['client' + fromN + 'Email'].value;
      this.resource['client' + toN + 'Phone'].value     = this.resource['client' + fromN + 'Phone'].value;
      this.resource['client' + toN + 'Married'].value   = this.resource['client' + fromN + 'Married'].value;

      this.resource['client' + toN + 'FirstName'].changed  = true;
      this.resource['client' + toN + 'LastName'].changed   = true;
      this.resource['client' + toN + 'Email'].changed      = true;
      this.resource['client' + toN + 'Phone'].changed      = true;
      this.resource['client' + toN + 'Married'].changed    = true;

    } catch (ex) {
      const msg = 'InBox.copyClient()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  setFieldValuesPerContractType(contractType: string): void {

    // console.log('InBoxDetail.setFieldValuesPerContractType() - BEFORE:', contractType, this.resource.asParms);

    if (!contractType || (contractType === '')) {
      return;
    }

    // reset these dates for the contract when you change contractType...

    if (contractType === 'Listing') {

      this.resource.listingDate.noTimestamp = true;

      this.resource.contractDate.value = null;
      this.resource.contractDate.changed = false;

      this.resource.ddDate.value = null;
      this.resource.ddDate.changed = false;

      this.resource.closingDate.value = null;
      this.resource.closingDate.changed = false;

      this.resource.client1Role.value = 'Seller';
      this.resource.client1Role.changed = false;
      this.resource.client2Role.value = 'Seller';
      this.resource.client2Role.changed = false;
      this.resource.client3Role.value = 'Seller';
      this.resource.client3Role.changed = false;

      // CoOpAgent and Lender fields are NOT required...

      this.displayCoOpAgent = false;
      this.resource.cashPurchase.value = false;

      // showingsBegin MUST be provided for listings...

      this.resource.showingsBegin.required = true;

    } else {

      this.resource.listingDate.value = null;
      this.resource.listingDate.changed = false;

      // showingsBegin do NOT need to be provided for non-listings...

      this.resource.showingsBegin.required = false;

    }

    if (contractType === 'Buyer') {

      if (this.clientIsPopulated(4) && (this.resource.client4Role.value === 'Buyer')) {
        this.copyClient(4, 1);
        this.setFieldsPerClient(1, true);
      }

      if (this.clientIsPopulated(5) && (this.resource.client5Role.value === 'Buyer')) {
        this.copyClient(5, 2);
        this.setFieldsPerClient(2, true);
      }

      if (this.clientIsPopulated(6) && (this.resource.client6Role.value === 'Buyer')) {
        this.copyClient(6, 3);
        this.setFieldsPerClient(3, true);
      }

      this.clearClient(4);
      this.clearClient(5);
      this.clearClient(6);

      this.resource.client1Role.value = 'Buyer';
      this.resource.client2Role.value = 'Buyer';
      this.resource.client3Role.value = 'Buyer';

      this.resource.client1Role.changed = true;
      this.resource.client2Role.changed = true;
      this.resource.client3Role.changed = true;

      this.displayCoOpAgent = this.coOpIsPopulated();

    } else if (contractType === 'Seller') {

      if (this.clientIsPopulated(4) && (this.resource.client4Role.value === 'Seller')) {
        this.copyClient(4, 1);
        this.setFieldsPerClient(1, true);
      }

      if (this.clientIsPopulated(5) && (this.resource.client5Role.value === 'Seller')) {
        this.copyClient(5, 2);
        this.setFieldsPerClient(2, true);
      }

      if (this.clientIsPopulated(6) && (this.resource.client6Role.value === 'Seller')) {
        this.copyClient(6, 3);
        this.setFieldsPerClient(3, true);
      }

      this.clearClient(4);
      this.clearClient(5);
      this.clearClient(6);

      this.resource.client1Role.value = 'Seller';
      this.resource.client2Role.value = 'Seller';
      this.resource.client3Role.value = 'Seller';

      this.resource.client1Role.changed = true;
      this.resource.client2Role.changed = true;
      this.resource.client3Role.changed = true;

      this.displayCoOpAgent = this.coOpIsPopulated();

    } else if (contractType === 'Dual Agency') {

      if (this.clientIsPopulated(1) && (this.resource.client1Role.value === 'Buyer')) {
        this.copyClient(1, 4);
        this.setFieldsPerClient(4, true);
        this.clearClient(1);
      }

      if (this.clientIsPopulated(2) && (this.resource.client1Role.value === 'Buyer')) {
        this.copyClient(2, 5);
        this.setFieldsPerClient(5, true);
        this.clearClient(2);
      }

      if (this.clientIsPopulated(3) && (this.resource.client1Role.value === 'Buyer')) {
        this.copyClient(3, 6);
        this.setFieldsPerClient(6, true);
        this.clearClient(3);
      }

      this.resource.client1Role.value = 'Seller';
      this.resource.client2Role.value = 'Seller';
      this.resource.client3Role.value = 'Seller';
      this.resource.client4Role.value = 'Buyer';
      this.resource.client5Role.value = 'Buyer';
      this.resource.client6Role.value = 'Buyer';

      this.resource.client1Role.changed = true;
      this.resource.client2Role.changed = true;
      this.resource.client3Role.changed = true;
      this.resource.client4Role.changed = true;
      this.resource.client5Role.changed = true;
      this.resource.client6Role.changed = true;

      this.displayCoOpAgent = false;
    }

    // now go mark the proper fields as required, per the resource content...

    this.setRequiredFieldsForCoOpAgent();
    this.setRequiredFieldsForLender();
    this.setRequiredFieldsForAttorney();

    // console.log('InBoxDetail.setFieldValuesPerContractType() - AFTER:', contractType, this.resource.asParms);
  }

  setFieldValuesPerReferral(): void {
    if (!this.resource.referral.value) {
      this.resource.referralName.value = null;
      this.resource.referralCompany.value = null;
      this.resource.referralEmail.value = null;
      this.resource.referralPhone.value = null;

      this.resource.referralName.changed = true;
      this.resource.referralCompany.changed = true;
      this.resource.referralEmail.changed = true;
      this.resource.referralPhone.changed = true;
    }

    this.resource.referralName.required    = this.resource.referral.value;
    this.resource.referralCompany.required = this.resource.referral.value;
    this.resource.referralEmail.required   = this.resource.referral.value;
    this.resource.referralPhone.required   = this.resource.referral.value;

  }

  setFieldsPerClient(clientNumber: number, required: boolean): void {

    // console.log('InBoxDetail.setFieldsPerClient() - BEFORE:', clientNumber, required, JSON.stringify(this.resource.asParms), new Error().stack);

    if ((clientNumber === 2) || (clientNumber === 5)) {
      this.resource['client' + (clientNumber + 1) + 'Required'].value  = false;
      this.resource['client' + (clientNumber + 1) + 'Required'].changed  = true;
    }

    this.resource['client' + clientNumber + 'Role'].changed  = true;
    this.resource['client' + clientNumber + 'Role'].required = required;

    // this.resource['client' + clientNumber + 'FirstName'].value = null;
    // this.resource['client' + clientNumber + 'LastName'].value  = null;
    // this.resource['client' + clientNumber + 'Email'].value     = null;
    // this.resource['client' + clientNumber + 'Phone'].value     = null;
    // this.resource['client' + clientNumber + 'Married'].value   = false;

    this.resource['client' + clientNumber + 'FirstName'].changed  = this.resource['client' + clientNumber + 'FirstName'].isPopulated;
    this.resource['client' + clientNumber + 'LastName'].changed   = this.resource['client' + clientNumber + 'LastName'].isPopulated;
    this.resource['client' + clientNumber + 'Email'].changed      = this.resource['client' + clientNumber + 'Email'].isPopulated;
    this.resource['client' + clientNumber + 'Phone'].changed      = this.resource['client' + clientNumber + 'Phone'].isPopulated;
    this.resource['client' + clientNumber + 'Married'].changed    = this.resource['client' + clientNumber + 'Married'].isPopulated;

    this.resource['client' + clientNumber + 'FirstName'].required = required;
    this.resource['client' + clientNumber + 'LastName'].required  = required;
    this.resource['client' + clientNumber + 'Email'].required     = required;
    this.resource['client' + clientNumber + 'Phone'].required     = required;

    // console.log('InBoxDetail.setFieldsPerClient() - AFTER:', JSON.stringify(this.resource.asParms));

  }

  toggleCoOpAgentDisplay(): void {
    this.displayCoOpAgent = !this.displayCoOpAgent;

    if (!this.displayCoOpAgent) {
      this.resource.coOpAgentID.value = Globals.MAGIC_NULL_FKEY_VALUE;

      this.resource.coOpAgentFirstName.value = null;
      this.resource.coOpAgentLastName.value = null;
      this.resource.coOpAgentCompany.value = null;
      this.resource.coOpAgentEmail.value = null;
      this.resource.coOpAgentPhone.value = null;
      this.resource.coOpCoord.value = null;
      this.resource.coOpCoordEmail.value = null;
      this.resource.coOpCoordPhone.value = null;

      this.resource.coOpAgentID.changed = true;

      this.resource.coOpAgentFirstName.changed = true;
      this.resource.coOpAgentLastName.changed = true;
      this.resource.coOpAgentCompany.changed = true;
      this.resource.coOpAgentEmail.changed = true;
      this.resource.coOpAgentPhone.changed = true;
      this.resource.coOpCoord.changed = true;
      this.resource.coOpCoordEmail.changed = true;
      this.resource.coOpCoordPhone.changed = true;
    }

    this.setRequiredFieldsForCoOpAgent();

    // console.log('InBox.toggleCoOpAgentDisplay()', this.displayCoOpAgent, this.resource);
  }

  setFieldsPerCoOpAgent(f: WField): void {

    if (f.name === 'coOpAgentID') {
      this.resource.coOpAgentFirstName.value = null;
      this.resource.coOpAgentLastName.value = null;
      this.resource.coOpAgentCompany.value = null;
      this.resource.coOpAgentEmail.value = null;
      this.resource.coOpAgentPhone.value = null;
      this.resource.coOpCoord.value = null;
      this.resource.coOpCoordEmail.value = null;
      this.resource.coOpCoordPhone.value = null;

      this.resource.coOpAgentFirstName.changed = true;
      this.resource.coOpAgentLastName.changed = true;
      this.resource.coOpAgentCompany.changed = true;
      this.resource.coOpAgentEmail.changed = true;
      this.resource.coOpAgentPhone.changed = true;
      this.resource.coOpCoord.changed = true;
      this.resource.coOpCoordEmail.changed = true;
      this.resource.coOpCoordPhone.changed = true;

    } else {
      this.resource.coOpAgentID.value = Globals.MAGIC_NULL_FKEY_VALUE;
      this.resource.coOpAgentID.changed = true;
    }

    this.setRequiredFieldsForCoOpAgent();

  }

  setFieldsPerLender(f: WField): void {

    if (f.name === 'cashPurchase') {

      this.resource.lenderID.value = Globals.MAGIC_NULL_FKEY_VALUE;
      this.resource.lenderFirstName.value = null;
      this.resource.lenderLastName.value = null;
      this.resource.lenderCompany.value = null;
      this.resource.lenderEmail.value = null;
      this.resource.lenderPhone.value = null;

      this.resource.lenderID.changed = true;
      this.resource.lenderFirstName.changed = true;
      this.resource.lenderLastName.changed = true;
      this.resource.lenderCompany.changed = true;
      this.resource.lenderEmail.changed = true;
      this.resource.lenderPhone.changed = true;

    } else if (f.name === 'lenderID') {

      this.resource.lenderFirstName.value = null;
      this.resource.lenderLastName.value = null;
      this.resource.lenderCompany.value = null;
      this.resource.lenderEmail.value = null;
      this.resource.lenderPhone.value = null;

      this.resource.lenderFirstName.changed = true;
      this.resource.lenderLastName.changed = true;
      this.resource.lenderCompany.changed = true;
      this.resource.lenderEmail.changed = true;
      this.resource.lenderPhone.changed = true;

    } else {

      this.resource.lenderID.value = Globals.MAGIC_NULL_FKEY_VALUE;
      this.resource.lenderID.changed = true;
    }

    this.setRequiredFieldsForLender();

  }

  setFieldsPerAttorney(f: WField): void {

    const idChanged = (f.name === 'attorneyID');

    if (idChanged) {
      this.resource.attorneyFirstName.value = null;
      this.resource.attorneyLastName.value = null;
      this.resource.attorneyCompany.value = null;
      this.resource.attorneyEmail.value = null;
      this.resource.attorneyPhone.value = null;

      this.resource.attorneyFirstName.changed = true;
      this.resource.attorneyLastName.changed = true;
      this.resource.attorneyCompany.changed = true;
      this.resource.attorneyEmail.changed = true;
      this.resource.attorneyPhone.changed = true;

    } else {
      this.resource.attorneyID.value = Globals.MAGIC_NULL_FKEY_VALUE;
      this.resource.attorneyID.changed = true;
    }

    this.setRequiredFieldsForAttorney();

  }

  coOpIsPopulated(): boolean {
    return (this.resource.coOpAgentID.isPopulated
      || this.resource.coOpAgentCompany.isPopulated
      || this.resource.coOpAgentFirstName.isPopulated
      || this.resource.coOpAgentLastName.isPopulated
      || this.resource.coOpAgentEmail.isPopulated
      || this.resource.coOpAgentPhone.isPopulated);
  }

  setRequiredFieldsForCoOpAgent(): void {

    this.resource.coOpAgentID.required        = (!this.resource.coOpAgentCompany.isPopulated
                                                && !this.resource.coOpAgentFirstName.isPopulated
                                                && !this.resource.coOpAgentLastName.isPopulated
                                                && !this.resource.coOpAgentEmail.isPopulated
                                                && !this.resource.coOpAgentPhone.isPopulated
                                                && this.displayCoOpAgent);

    this.resource.coOpAgentCompany.required   = this.resource.coOpAgentID.isNull && this.displayCoOpAgent;
    this.resource.coOpAgentFirstName.required = this.resource.coOpAgentID.isNull && this.displayCoOpAgent;
    this.resource.coOpAgentLastName.required  = this.resource.coOpAgentID.isNull && this.displayCoOpAgent;
    this.resource.coOpAgentEmail.required     = this.resource.coOpAgentID.isNull && this.displayCoOpAgent;
    this.resource.coOpAgentPhone.required     = this.resource.coOpAgentID.isNull && this.displayCoOpAgent;
  }

  setRequiredFieldsForLender(): void {

    const needsLender = !this.resource.cashPurchase.value
                        &&
                        ((this.resource.contractType.value === 'Buyer')
                         ||
                         (this.resource.contractType.value === 'Dual Agency'));

    this.resource.lenderID.required        = (!this.resource.lenderCompany.isPopulated
                                              && !this.resource.lenderFirstName.isPopulated
                                              && !this.resource.lenderLastName.isPopulated
                                              && !this.resource.lenderEmail.isPopulated
                                              && !this.resource.lenderPhone.isPopulated
                                              && needsLender
                                            );
    this.resource.lenderCompany.required   = this.resource.lenderID.isNull && needsLender;
    this.resource.lenderFirstName.required = this.resource.lenderID.isNull && needsLender;
    this.resource.lenderLastName.required  = this.resource.lenderID.isNull && needsLender;
    this.resource.lenderEmail.required     = this.resource.lenderID.isNull && needsLender;
    this.resource.lenderPhone.required     = this.resource.lenderID.isNull && needsLender;
  }

  setRequiredFieldsForAttorney(): void {

    const needsAttorney = ((this.resource.contractType.value === 'Buyer')
                           ||
                           (this.resource.contractType.value === 'Dual Agency'));

    this.resource.attorneyID.required        = (!this.resource.attorneyCompany.isPopulated
                                                && !this.resource.attorneyFirstName.isPopulated
                                                && !this.resource.attorneyLastName.isPopulated
                                                && !this.resource.attorneyEmail.isPopulated
                                                && !this.resource.attorneyPhone.isPopulated
                                                && needsAttorney);
    this.resource.attorneyCompany.required   = this.resource.attorneyID.isNull && needsAttorney;
    this.resource.attorneyFirstName.required = this.resource.attorneyID.isNull && needsAttorney;
    this.resource.attorneyLastName.required  = this.resource.attorneyID.isNull && needsAttorney;
    this.resource.attorneyEmail.required     = this.resource.attorneyID.isNull && needsAttorney;
    this.resource.attorneyPhone.required     = this.resource.attorneyID.isNull && needsAttorney;

  }

}
