<td class="italic right white-bg">
  {{ idx + 1 }}
</td>
<td class="border-left link" [title]="resource.agency.value">
  {{ getInitials(resource.agency.value) }}
</td>
<td class="border-left">
  <span *ngIf="resource.agentID.value != -1">
    <span class="link" [title]="resource.agentFirstName.value + ' ' + resource.agentLastName.value" wackadooPageLoad eventHandler="Agents" action="search" [parms]="{agentID: resource.agentID.value}">
      {{ (resource.agentLastName.value === null ? '' : resource.agentLastName.value) }}
    </span> 
  </span> 
</td>
<td class="border-left">
  {{ resource.contractName.value.replace(resource.contractAddress.value, '').replace( ' - ', '') }}
</td>
<td class="border-left link contractName" title="Open TaskList" (click)="openContractTaskList()">
  {{ resource.contractAddress.value }}
</td>
<td class="border-left">
  {{formatDateTime(resource.closingDate.value)}}
</td>
<td class="border-left">
  <span *ngIf="resource.attorneyID.value != -1">
    <span class="link" [title]="resource.attorneyCompany.value" wackadooPageLoad eventHandler="Attorneys" action="search" [parms]="{attorneyID: resource.attorneyID.value}">
      {{ (resource.attorneyCompany.value === null ? '' : getInitials(resource.attorneyCompany.value)) }}
    </span>
  </span>
</td>
<td class="border-left">
  <span *ngIf="resource.lenderID.value != -1">
    <span class="link" [title]="resource.lenderCompany.value" wackadooPageLoad eventHandler="Lenders" action="search" [parms]="{lenderID: resource.lenderID.value}">
      {{ (resource.lenderCompany.value === null ? '' : getInitials(resource.lenderCompany.value)) }}
    </span>
  </span>
</td>
<td class="border-left">
  {{formatDate(resource.ddDate.value)}}
</td>
<td class="border-left center" [title]="getQueryName(0)">
  <div *wackadooResourceBlock="let t of []; let idx = index; eventHandler: 'Tasks'; action: 'search'; parms: { query: getQuery(0), sortBy: 'sequence' }; defaultText: '&#10003;'; debug: false;">
    <wackadoo-quick-view-task [resource]="t"></wackadoo-quick-view-task>
  </div>
</td>
<td class="border-left center" [title]="getQueryName(1)">
  <div *wackadooResourceBlock="let t of []; let idx = index; eventHandler: 'Tasks'; action: 'search'; parms: { query: getQuery(1), sortBy: 'sequence' }; defaultText: '&#10003;'; debug: false;">
    <wackadoo-closing-compliance-task [resource]="t"></wackadoo-closing-compliance-task>
  </div>
</td>
<td class="border-left center" [title]="getQueryName(2)">
  <div *wackadooResourceBlock="let t of []; let idx = index; eventHandler: 'Tasks'; action: 'search'; parms: { query: getQuery(2), sortBy: 'sequence' }; defaultText: '&#10003;'; debug: false;">
    <wackadoo-quick-view-task [resource]="t"></wackadoo-quick-view-task>
  </div>
</td>
<td class="border-left center" [title]="getQueryName(3)">
  <div *wackadooResourceBlock="let t of []; let idx = index; eventHandler: 'Tasks'; action: 'search'; parms: { query: getQuery(3), sortBy: 'sequence' }; defaultText: '&#10003;'; debug: false;">
    <wackadoo-quick-view-task [resource]="t"></wackadoo-quick-view-task>
  </div>
</td>
<td class="border-left center" [title]="getQueryName(4)">
  <div *wackadooResourceBlock="let t of []; let idx = index; eventHandler: 'Tasks'; action: 'search'; parms: { query: getQuery(4), sortBy: 'sequence' }; defaultText: '&#10003;'; debug: false;">
    <wackadoo-quick-view-task [resource]="t"></wackadoo-quick-view-task>
  </div>
</td>
<td class="border-left center" [title]="getQueryName(5)">
  <div *wackadooResourceBlock="let t of []; let idx = index; eventHandler: 'Tasks'; action: 'search'; parms: { query: getQuery(5), sortBy: 'sequence' }; defaultText: '&#10003;'; debug: false;">
    <wackadoo-quick-view-task [resource]="t"></wackadoo-quick-view-task>
  </div>
</td>
<td class="border-left center">
  <wackadoo-closing-note [f]="resource.closingNotes" [contractID]="resource.contractID.value"></wackadoo-closing-note>
</td>
<td class="border-left center" [title]="getQueryName(6)">
  <div *wackadooResourceBlock="let t of []; let idx = index; eventHandler: 'Tasks'; action: 'search'; parms: { query: getQuery(6), sortBy: 'sequence' }; defaultText: '&#10003;'; debug: false;">
    <wackadoo-quick-view-task [resource]="t"></wackadoo-quick-view-task>
  </div>
</td>
