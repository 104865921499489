import { Component } from '@angular/core';
import { TaskListComponent } from '../task-list/task-list.component';

@Component({
  selector: 'wackadoo-my-task-list',
  templateUrl: './my-task-list.component.html',
})
export class MyTaskListComponent extends TaskListComponent {

}
