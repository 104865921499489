<td class="italic right white-bg">
  {{ idx + 1 }}
</td>
<td class="border-left link">
  {{ formatDate(resource.contractDate.value) }}
</td>
<td class="border-left">
  {{ resource.contractName.value.replace(resource.contractAddress.value, '').replace( ' - ', '') }}
</td>
<td class="border-left link contractName" title="Open TaskList" (click)="openContractTaskList()">
  {{ resource.contractAddress.value }}
</td>
<td class="border-left">
  <span *ngIf="resource.agentID.value != -1">
    <span *wackadooResourceBlock="let a of []; let aIdx = index; eventHandler: 'Agents'; action: 'search'; parms: { agentID: resource.agentID.value }; defaultText: ''; debug: false;">
     <span class="link" [title]="a.agentFirstName.value + ' ' + a.agentLastName.value" wackadooPageLoad eventHandler="Agents" action="search" [parms]="{agentID: a.agentID.value}">
        {{(a.agentFirstName.value === null ? '' : a.agentFirstName.value)}} {{(a.agentLastName.value === null ? '' : a.agentLastName.value)}}
      </span> 
    </span>
  </span> 
</td>
<td class="border-left">
  {{ resource.contractType.value }}
</td>
<td class="border-left center" [title]="getQueryName(0)">
  <div *wackadooResourceBlock="let t of []; let idx = index; eventHandler: 'Tasks'; action: 'search'; parms: { query: getQuery(0), sortBy: 'sequence' }; defaultText: '&#10003;'; debug: false;">
    <wackadoo-quick-view-task [resource]="t"></wackadoo-quick-view-task>
  </div>
</td>
<td class="border-left center" [title]="getQueryName(1)">
  <div *wackadooResourceBlock="let t of []; let idx = index; eventHandler: 'Tasks'; action: 'search'; parms: { query: getQuery(1), sortBy: 'sequence' }; defaultText: '&#10003;'; debug: false;">
    <wackadoo-quick-view-task [resource]="t"></wackadoo-quick-view-task>
  </div>
</td>
<td class="border-left center" [title]="getQueryName(2)">
  <div *wackadooResourceBlock="let t of []; let idx = index; eventHandler: 'Tasks'; action: 'search'; parms: { query: getQuery(2), sortBy: 'sequence' }; defaultText: '&#10003;'; debug: false;">
    <wackadoo-quick-view-task [resource]="t"></wackadoo-quick-view-task>
  </div>
</td>
<td class="border-left center" [title]="getQueryName(3)">
  <div *wackadooResourceBlock="let t of []; let idx = index; eventHandler: 'Tasks'; action: 'search'; parms: { query: getQuery(3), sortBy: 'sequence' }; defaultText: '&#10003;'; debug: false;">
    <wackadoo-quick-view-task [resource]="t"></wackadoo-quick-view-task>
  </div>
</td>
