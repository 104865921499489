import { Component, OnInit, OnDestroy } from '@angular/core';
import { ResourceComponent } from 'src/app/client-core/data/resource/resource.component';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { WResource } from 'src/app/client-core/data/resource.model';
import { Subject, Subscription } from 'rxjs';
import { WField } from 'src/app/client-core/data/field.model';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { ClosingProService } from '../../../closing-pro.service';
import { WEvent } from 'src/app/client-core/data/event.model';

@Component({
  selector: 'wackadoo-task-template-modal',
  templateUrl: './task-template-modal.component.html',
})
export class TaskTemplateModalComponent extends ResourceComponent implements OnInit, OnDestroy {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'TaskTemplateModalComponent';

  onTaskTypeChange: Subject<WField> = null;
  onTaskTypeChangeSubscription: Subscription = null;

  constructor(
    public modalDialogService: ModalDialogService,
    public closingProService: ClosingProService,
    userInterfaceService: UserInterfaceService,
    eventServerService: EventServerService,
  ) {
    super(userInterfaceService, eventServerService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.onTaskTypeChange = new Subject<WField>();

    this.onTaskTypeChangeSubscription = this.onTaskTypeChange.subscribe(
      (f: WField) => {
        if (f) {
          // console.log('field changed:', f);
          if (f.value === 'email') {
            // We load up taskEMailTemplate with select options from EMailTemplates
            if (f.value === 'email') {
              this.userInterfaceService.convertToSelectFieldFromOtherResourceType(this.resource, 'taskEMailTemplate', 'templateName', 'EMailTemplate');
            }
          }
        }
      }
    );

    // We load up taskEMailTemplate with select options from EMailTemplates
    if (this.resource.taskType.value === 'email') {
      this.userInterfaceService.convertToSelectFieldFromOtherResourceType(this.resource, 'taskEMailTemplate', 'templateName', 'EMailTemplate');
    }
  }

  ngOnDestroy(): void {
    if (this.onTaskTypeChangeSubscription) {
      this.onTaskTypeChangeSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  get isVendorActivity(): boolean {
    return this.closingProService.isVendorActivity(this.resource.taskType.value);
  }

  relativeTaskMessage(r: WResource): string {
    const n = Math.abs(r.subsequentTaskLeadTime.value);
    let msg = '';
    msg += n;
    msg += ' ';
    msg += (n !== 1 ? r.subsequentTaskLeadTimeUnit.value : (r.subsequentTaskLeadTimeUnit.value.replace('s', '')));
    msg += ' ';
    msg += (r.subsequentTaskLeadTime.value < -0.5) ? 'BEFORE' : 'AFTER';
    return msg;
  }

  openTaskTemplateModal(taskTemplate: WResource): void {
    this.modalDialogService.modifyResourceModal('TaskTemplateModalComponent', 'TaskTemplates', taskTemplate).subscribe(
      (event: WEvent) => {
        // if we cancel or properly saved the page...
        if (event && (event.status !== 'OK')) {
          // console.log('modifyResourceModal.closed()', event);
          this.modalDialogService.showAlert('Unable to modify taskTemplate...\n' + event.message, 'WARNING!');
        }
      }
    );

  }

  // Need to insert this logic in the proper location to take out the .footer email templates,
  // but convertToSelectFieldFromOtherResourceType() does NOT have any kind of call-back Subject at this point.
  _scrubOutFooters(): void {
    const templateNameSelectOptions = this.resource.taskEMailTemplate.select;
    let s = '';
    const templateNames = templateNameSelectOptions.split('|');
    for (const templateName of templateNames) {
        if (!templateName.endsWith('.footer')) {
          s += '|' + templateName;
        }
    }
    this.resource.taskEMailTemplate.select = s;
  }

  editEMailTemplate(): void {
    if ((this.resource.taskEMailTemplate.value !== null) && (this.resource.taskEMailTemplate.value !== '')) {
      const parms: any = {};
      parms.templateName = this.resource.taskEMailTemplate.value;
      this.eventServerService.loadResourceFromServer('EMailTemplates', parms).subscribe(
        (r: WResource) => {
          if (r) {
            // in case we fired this from a modal dialog...
            this.modalDialogService.closeModals();

            this.userInterfaceService.selectResource('EMailTemplates', r);
            this.userInterfaceService.loadPage('EMailTemplates');
          } else {
            this.modalDialogService.showAlert('Bad template name: "' + this.resource.taskEMailTemplate.value + '"', 'Error');
          }
        }
      );
    }
  }

}
