import { Component, OnInit, OnDestroy } from '@angular/core';
import { WResource } from 'src/app/client-core/data/resource.model';
import { Subject, Subscription } from 'rxjs';
import { WField } from 'src/app/client-core/data/field.model';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { ClosingProService } from '../../../closing-pro.service';
import { DetailContentComponent } from 'src/app/client-core/pages/repository-page/detail-content/detail-content.component';
import { WEvent } from 'src/app/client-core/data/event.model';

@Component({
  selector: 'wackadoo-contract-detail-content',
  templateUrl: './contract-detail-content.component.html',
})
export class ContractDetailContentComponent extends DetailContentComponent implements OnInit, OnDestroy {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'ContractDetailContentComponent';

  showReInitWarningSubject: Subject<void> = null;
  showReInitWarningSubscription: Subscription = null;

  onReferralChangeSubject: Subject<WField> = null;
  onReferralChangeSubscription: Subscription = null;

  constructor(
    userInterfaceService: UserInterfaceService,
    eventServerService: EventServerService,
    public closingProService: ClosingProService,
    public modalDialogService: ModalDialogService,
  ) {
    super(userInterfaceService, eventServerService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    // console.log('ContractDetailContentComponent.ngOnInit()'); // , this.resource, new Error().stack);

    // We force taskTemplateGroupID to include a "foreignType" lookup
    // but WITHOUT making it an actual "foreignKey"...
    // console.log('Contracts.ngOnInit() - taskTemplateGroupID', this.resource.taskTemplateGroupID);
    if (this.resource.taskTemplateGroupID) {
      this.userInterfaceService.convertToFakeForeignKeyField(this.resource, 'TaskTemplateGroup', 'taskTemplateGroupID');
    }

    // at this point, we SHOULD do the following... (Except that this fires multiple times...)
    // 1. check to see if there is an escrowAgentID
    // 2. if not,
    //       a. we check to see if the current attorney is already an escrowAgent also
    //       b. if so, we get that escrowAgent and assign that escrowAgentID to this Contract
    //       c. if not, we dupe the attorney as an escrowAgent and assign that escrowAgentID to this Contract

    // const escrowAgentIDField = this.resource.escrowAgentID;
    // if (!escrowAgentIDField || !escrowAgentIDField.value) {
    //   const attorneyIDField = this.resource.attorneyID;
    //   if (attorneyIDField && attorneyIDField.value) {
    //     const attorneyID = attorneyIDField.value;
    //     this.closingProService.assignEscrowAgentToMatchTheAttorney(this.resource.contractID.value, attorneyID);
    //   }
    // }

    // now tag the contract with the current timestamp to show when it was last accessed...
    if (this.action === 'copy') {
      // console.log(this.name+'.preDetailLoad() - about to set lastAccess in copy of of resource');
      this.resource.lastAccess.value = (new Date()).toJSON();
      this.resource.lastAccess.changed = true;
    } else {
      const parms = this.resource.keyField.asParm;
      // console.log(this.name+'.preDetailLoad() - about to update lastAccess: ' + JSON.stringify(parms));
      parms.lastAccess = (new Date()).toJSON();
      // we modify Contracts so that we do not inadvertently re-set contractStatus to 'active' in the ActiveContracts ResourceRepositoryWrapper EH...
      this.eventServerService.fireEvent('Contracts', 'modify', parms).subscribe();
    }

    this.showReInitWarningSubject = new Subject<void>();
    this.showReInitWarningSubscription = this.showReInitWarningSubject.subscribe(
      () => {
        const title = 'WARNING!';

        let msg = 'Changing the TaskList for the "' + this.resource.contractName.value + '" contract is a pretty big deal, because when you click "Save", you will <strong>COMPLETELY ERASE</strong> your existing TaskList.';
        msg += ' (The new TaskList will be initialized with all Tasks set as incomplete. All your "ad hoc" Tasks will be lost.)';
        msg += '\n\nIf you DO NOT mean to do that, DO NOT click "Save"! (Instead, click "Cancel" immediately!)';

        this.modalDialogService.showAlert(msg, title);
      }
    );

    this.onReferralChangeSubject = new Subject<WField>();

    this.onReferralChangeSubscription = this.onReferralChangeSubject.subscribe(
      (f: WField) => {
        // console.log('referralChange()', f);
        if (!f.value) {
          this.resource.referralName.value = '';
          this.resource.referralCompany.value = '';
          this.resource.referralEmail.value = '';
          this.resource.referralPhone.value = '';

          this.resource.referralName.changed = true;
          this.resource.referralCompany.changed = true;
          this.resource.referralEmail.changed = true;
          this.resource.referralPhone.changed = true;
        }
      }
    );

  }

  ngOnDestroy(): void {
    if (this.showReInitWarningSubscription) {
      this.showReInitWarningSubscription.unsubscribe();
    }
    if (this.onReferralChangeSubscription) {
      this.onReferralChangeSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  newNote(): void {
    const tempSubject = new Subject<WEvent>();
    const tempSubscription = tempSubject.subscribe(
      (event: WEvent) => {
        tempSubscription.unsubscribe();
        if (event.status === 'OK') {
          this.userInterfaceService.loadPage(this.eventHandler, null, this.resource.keyField.asParm);
        } else {
          this.modalDialogService.showAlert('Unable to update contract note: ' + event.message, 'Warning!');
        }
      }
    );
    this.closingProService.updateContractNote(this.resource.contractID.value, this.resource.contractNote.value, tempSubject);
  }

  reInitTaskList(): void {
    this.closingProService.reInitializeContractTasks(this.resource);
  }

  newTask(): void {
    this.closingProService.addNewAdHocTask(this.resource).subscribe(
      () => {
        this.userInterfaceService.reloadCurrentPage();
      }
    );
  }

  addNewContractParticipant(): void {
    const contractID = this.resource.contractID.value;
    const contractType = (this.resource.contractType.value === 'Buyer' ? 'Buyer' : 'Seller');
    this.closingProService.addNewClientAndContractParticipant(contractID, contractType);
  }

  addNewCoOpAgent(): void {
    this.closingProService.addNewCoOpAgent(this.resource.contractID.value);
  }

  addNewLender(): void {
    this.closingProService.addNewLender(this.resource.contractID.value);
  }

  addNewAttorney(): void {
    this.closingProService.addNewAttorney(this.resource.contractID.value);
  }

  addNewEscrowAgent(): void {
    this.closingProService.addNewEscrowAgent(this.resource.contractID.value);
  }

  copyAttorneyToEscrowAgent(): void {
    this.closingProService.assignEscrowAgentToMatchTheAttorney(this.resource.contractID.value, this.resource.attorneyID.value);
  }

  openTaskListToProperPanel(task: WResource): void {
    this.closingProService.openTaskListToProperPanel(task);
  }

  displayModalResourceDialog(resource: WResource): void {
    this.modalDialogService.modifyResourceModal('ClientModalComponent', 'Clients', resource);
  }

  toggleClientRole(cp: WResource): void {
    try {
      const cpRes = cp.asParms;

      const parms = cp.keyField.asParm;
      parms.clientRole = (cpRes.clientRole === 'Seller' ? 'Buyer' : 'Seller');
      parms.reasonForChange = 'Changed ' + (cpRes.clientCompany ? cpRes.clientCompany : cpRes.clientFirstName + ' ' + cpRes.clientLastName) + ' client role to ' + parms.clientRole;

      // console.log('toggleClientRole() - ContractParticipants, modify, parms:', parms);
      this.eventServerService.fireEvent('ContractParticipants', 'modify', parms).subscribe(
        (responseEvent: WEvent) => {
          // console.log('toggleClientRole() - responseEvent:', responseEvent);
          if (responseEvent.status !== 'OK') {
            this.modalDialogService.showAlert(responseEvent.message, 'Error Changing Client Role');
          }
          this.userInterfaceService.reloadCurrentPage();
        }
      );

    } catch (ex) {
      const msg = 'toggleClientRole()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  deleteContractParticipant(): void {
    try {
      const parms = this.resource.keyField.asParm;

      // console.log(parms);

      this.eventServerService.fireEvent('ContractParticipants', 'delete', parms).subscribe(
        (responseEvent: WEvent) => {
          if (responseEvent.status !== 'OK') {
            this.modalDialogService.showAlert(responseEvent.message, 'Error Deleting ContractParticipant');
          }
          this.userInterfaceService.reloadCurrentPage();
        }
      );

    } catch (ex) {
      const msg = 'deleteContractParticipant()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

}
