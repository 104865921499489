import { Component, OnInit, OnDestroy } from '@angular/core';
import { RepositoryPageComponent } from 'src/app/client-core/pages/repository-page/repository-page.component';

@Component({
  selector: 'wackadoo-todays-hot-list',
  templateUrl: '../../../../../../src/app/client-core/pages/repository-page/repository-page.component.html',
})
export class TodaysHotListComponent extends RepositoryPageComponent implements OnInit, OnDestroy {

  ngOnInit(): void {
    // console.log('TodaysHotListComponent.ngOnInit()'); // , new Error().stack);
    this.userInterfaceService.selectResourceOnRepositoryGridClick = false;
    this.gridResourceComponentClassName = 'RelatedTaskDetailComponent';

    super.ngOnInit();
  }

  ngOnDestroy(): void {
    // console.log('TodaysHotListComponent.ngOnDestroy()'); // , new Error().stack);
    this.userInterfaceService.selectResourceOnRepositoryGridClick = true;
    super.ngOnDestroy();
  }

}
