import { Component, OnInit } from '@angular/core';
import { RepositoryPageComponent } from 'src/app/client-core/pages/repository-page/repository-page.component';

@Component({
  selector: 'wackadoo-account-meta-data',
  templateUrl: '../../../../../../src/app/client-core/pages/repository-page/repository-page.component.html',
})
export class AccountMetaDataComponent extends RepositoryPageComponent implements OnInit {

  ngOnInit(): void {

    this.detailContentComponentClassName = 'AccountMetaDataDetailComponent';
    super.ngOnInit();
  }

  postPageEvent(): void {
    // console.log('CampaignComponent.postPageEvent() - ' + this.eventHandler, this.event, this.resources, this.parameters, this.mode, new Date().toJSON());

    // First thing we do is grab the currently selected resource for this eventHandler.

    this.selectedResource = this.userInterfaceService.getSelectedResource(this.eventHandler);

    // console.log('CampaignComponent.postPageEvent()', this.action, 'this.selectedResource: ', this.selectedResource, this.resources);

    // Note: If we are doing an add or modify, then we have a selected
    // resource that contains some new values that we need to keep.

    // console.log('CampaignComponent.postPageEvent()', this.parameters);

    /////////////////////////////////////////////////////////////////////////////////
    // The second thing we do is replace it with the "singleton" resource in the event,
    // but only for "list" and "search" singletons that match the search parameters!
    // Remember: As a singleton resource repository, we ALWAYS get exactly one
    // resource back for both list and search events!
    /////////////////////////////////////////////////////////////////////////////////

    if (
        ((this.action === 'search') || (this.action === 'list'))
        && this.resources && (this.resources.length === 1)
      ) {
      // console.log('CampaignComponent.postPageEvent() -\nold selectedResource', this.selectedResource, '\nabout to select', this.resources[0]);
      this.userInterfaceService.selectResource(this.eventHandler, this.resources[0]);
      this.selectedResource = this.resources[0];
    }

    // and finally drop in the new Components...
    this.loadComponents();
  }

}
