import { Component, OnInit, Input } from '@angular/core';
import { WField } from 'src/app/client-core/data/field.model';
import { WResource } from 'src/app/client-core/data/resource.model';
import { ResourceComponent } from 'src/app/client-core/data/resource/resource.component';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { UtilityLibService } from 'src/app/client-core/services/utility-lib.service';
import { ClosingProService } from '../../../../closing-pro.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';

@Component({
  selector: '[wackadoo-closing]',
  templateUrl: './closing.component.html',
})
export class ClosingComponent extends ResourceComponent implements OnInit {

  @Input() idx = -1;

  constructor(
      userInterfaceService: UserInterfaceService,
      eventServerService: EventServerService,
      public utilLibService: UtilityLibService,
      public closingProService: ClosingProService,
      public modalDialogService: ModalDialogService,
  ) {
    super(userInterfaceService, eventServerService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    // console.log(this.resource);
  }

  getQuery(i: number): string {
    const query = this.closingProService.getQueryForUpcomingClosings(i)
                + (this.resource.contractID.value != null ? ' && contractID:' + this.resource.contractID.value : '')
                + ' && taskComplete:false'
              ;
    return query;
  }

  getQueryName(i: number): string {
    return this.closingProService.getQueryDescriptionForUpcomingClosings(i);
  }

  getInitials(phrase: string): string {
    return this.utilLibService.getInitials(phrase);
  }

  formatDate(dateString: string): string {
    if (dateString === null) {
      return '';
    }
    const d = new Date(dateString);
    return this.utilLibService.formatHumanReadableDate(d, false);
  }

  formatDateTime(dateString: string): string {
    if (dateString === null) {
      return '';
    }
    const d = new Date(dateString);
    return this.utilLibService.formatHumanReadableDateTime(d, false);
  }

  scrubTitle(f: WField): string {
    return (f.value && (f.value !== 'null') ? f.value : '');
  }

  openContractTaskList(): void {
    const parms: any = {};
    parms.contractID = this.resource.contractID.value;
    this.eventServerService.loadResourceFromServer('ActiveContracts', parms).subscribe(
      (resource: WResource) => {
        this.userInterfaceService.selectResource('ActiveContracts', resource);
        this.userInterfaceService.loadPage('TaskList');
      }
    );
  }

}
