import { Component, OnInit } from '@angular/core';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { WResource } from 'src/app/client-core/data/resource.model';
import { PageComponent } from 'src/app/client-core/pages/page/page.component';
import { UserAuthService } from 'src/app/client-core/services/user-auth.service';
import { UtilityLibService } from 'src/app/client-core/services/utility-lib.service';
import { ClosingProService } from '../../../closing-pro.service';

@Component({
  selector: 'wackadoo-upcoming-closings',
  templateUrl: './upcoming-closings.component.html',
})
export class UpcomingClosingsComponent extends PageComponent implements OnInit {

  constructor(
    userAuthService: UserAuthService,
    userInterfaceService: UserInterfaceService,
    public eventServerService: EventServerService,
    public utilLibService: UtilityLibService,
    public closingProService: ClosingProService,
  ) {
    super(userAuthService, userInterfaceService);
  }

  // scrubTitle(f: WField): string {
  //   return (f.value && (f.value !== 'null') ? f.value : '');
  // }

  openContractTaskList(contractID: number): void {
    const parms: any = {};
    parms.contractID = contractID;
    this.eventServerService.loadResourceFromServer('ActiveContracts', parms).subscribe(
      (resource: WResource) => {
        this.userInterfaceService.selectResource('ActiveContracts', resource);
        this.userInterfaceService.loadPage('TaskList');
      }
    );
  }

  getQueryName(i: number): string {
    return this.closingProService.getQueryNameForUpcomingClosings(i);
  }

  getQueryDescription(i: number): string {
    return this.closingProService.getQueryDescriptionForUpcomingClosings(i);
  }

  buildQueryForThisWeek(): string {
    const d = new Date();
    return this.buildQueryForWeek(d);
  }

  buildQueryForNextWeek(): string {
    const d = new Date();
    const oneWeekFromToday = new Date(d.setDate(d.getDate() + 7));
    return this.buildQueryForWeek(oneWeekFromToday);
  }

  buildQueryForWeek(d: Date): string {

    const startOfWeek = this.utilLibService.getStartOfWeek(d);
    const endOfWeek = this.utilLibService.getEndOfWeek(d);

    const qt = 'closingDate:[' + this.utilLibService.formatDateForRange(startOfWeek) + ' TO ' + this.utilLibService.formatDateForRange(endOfWeek) + ']';

    // console.log('\n\n', d, '\n', startOfWeek, '\n', endOfWeek, '\n', qt);

    return qt;
  }

  getWeekOfThisWeek(): string {
    const d = new Date();
    return this.utilLibService.formatHumanReadableDate(this.utilLibService.getStartOfWeek(d));
  }

  getWeekOfNextWeek(): string {
    const d = new Date();
    const oneWeekFromToday = new Date(d.setDate(d.getDate() + 7));
    return this.utilLibService.formatHumanReadableDate(this.utilLibService.getStartOfWeek(oneWeekFromToday));
  }

}
