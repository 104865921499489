<div class="btn-group marginTopQuarterEm d-flex" role="group">
    <button type="button" title="Save"   data-abbrev="" class="btn btn-secondary actionButton" (click)="save()"   *ngIf="editMode && (methods.includes('add') || methods.includes('modify'))"><span class="fas fa-check"></span></button>
    <button type="button" title="Edit"   data-abbrev="" class="btn btn-secondary actionButton" (click)="modify()" *ngIf="!editMode && methods.includes('modify')"><span class="fas fa-pencil-alt"></span></button>

	<button type="button" title="Copy To Seller Contract"       data-abbrev="To Seller"  class="btn btn-secondary actionButton" (click)="copyToSellerContract()" *ngIf="showCopyToSellerContractButton"><span class="fas fa-copy" aria-hidden="true"></span></button>
    <button type="button" title="Copy To Buyer Contract"        data-abbrev="To Buyer"   class="btn btn-secondary actionButton" (click)="copyToBuyerContract()" *ngIf="showCopyToBuyerContractButton"><span class="fas fa-copy" aria-hidden="true"></span></button>
	<button type="button" title="EMail Status Link To Agent"    data-abbrev="EMail Link" class="btn btn-secondary actionButton" (click)="emailLinkToAgent()"><span class="fas fa-envelope" aria-hidden="true"></span></button>

    <button type="button" title="Delete" data-abbrev="" class="btn btn-secondary actionButton" (click)="remove()" *ngIf="!editMode && methods.includes('delete')"><span class="fas fa-trash"></span></button>
    <button type="button" title="Cancel" data-abbrev="" class="btn btn-secondary actionButton" (click)="cancel()"><span class="fas fa-times"></span></button>
</div>
