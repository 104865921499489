import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wackadoo-closing-pro-application-header',
  templateUrl: './closing-pro-application-header.component.html',

})
export class ClosingProApplicationHeaderComponent implements OnInit {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'ClosingProApplicationHeaderComponent';

  constructor() { }

  ngOnInit(): void {
  }

}
