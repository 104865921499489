import { Component } from '@angular/core';
import { TaskRelativeModalComponent } from '../../task-list/task-relative-modal/task-relative-modal.component';

@Component({
  selector: 'wackadoo-task-templates-relative-modal',
  templateUrl: './task-templates-relative-modal.component.html',
})
export class TaskTemplatesRelativeModalComponent extends TaskRelativeModalComponent {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'TaskTemplatesRelativeModalComponent';

}
