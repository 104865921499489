<div class="btn-group marginTopQuarterEm d-flex" role="group">
    <button type="button" title="Save"   data-abbrev="" class="btn btn-secondary actionButton" (click)="save()"   *ngIf="editMode && (methods.includes('add') || methods.includes('modify'))"><span class="fas fa-check"></span></button>
    <button type="button" title="Edit"   data-abbrev="" class="btn btn-secondary actionButton" (click)="modify()" *ngIf="!editMode && methods.includes('modify')"><span class="fas fa-pencil-alt"></span></button>
    <button type="button" title="Copy"   data-abbrev="" class="btn btn-secondary actionButton" (click)="copy()"   *ngIf="!editMode && methods.includes('add')"><span class="fas fa-copy"></span></button>
    <button type="button" title="Delete" data-abbrev="" class="btn btn-secondary actionButton" (click)="remove()" *ngIf="!editMode && methods.includes('delete')"><span class="fas fa-trash"></span></button>
    
    <button type="button" title="Copy To EscrowAgent"     data-abbrev=""       class="btn btn-secondary actionButton" (click)="createEscrowAgent()"><span class="fas fa-copy"></span></button>
    <button type="button" title="Download Calendar"       data-abbrev="Calendar"    class="btn btn-secondary actionButton" (click)="downloadICS()"><span class="fas fa-calendar-alt"></span></button>

    <div class="btn noborder paddingTop0 paddingBottom0 paddingLeft0 paddingRightHalfEm">
        <div ngbDropdown placement="top-right" class="d-inline-block fullWidth">
            <button type="button" class="btn btn-secondary fullWidth" id="vcf-menu" ngbDropdownToggle><span class="fas fa-address-card"></span> {{((screenType === 'phone') ? '' : ((screenType === 'tablet') ? 'Contact' : 'Contact Card'))}}</button>
            <div ngbDropdownMenu aria-labelledby="vcf-menu">
                <li ngbDropdownItem class="downloadVCF" (click)="downloadVCF()"><a>Attorney</a></li>
				<li role="separator" class="divider"></li>
                <li ngbDropdownItem class="downloadVCF" (click)="downloadVCF('attorneyContact1')" *ngIf="resource.attorneyContact1.value && (resource.attorneyContact1.value !== '')"><a>{{ resource.attorneyContact1Role.value }}</a></li>
                <li ngbDropdownItem class="downloadVCF" (click)="downloadVCF('attorneyContact2')" *ngIf="resource.attorneyContact2.value && (resource.attorneyContact2.value !== '')"><a>{{ resource.attorneyContact2Role.value }}</a></li>
                <li ngbDropdownItem class="downloadVCF" (click)="downloadVCF('attorneyContact3')" *ngIf="resource.attorneyContact3.value && (resource.attorneyContact3.value !== '')"><a>{{ resource.attorneyContact3Role.value }}</a></li>
                <li ngbDropdownItem class="downloadVCF" (click)="downloadVCF('attorneyContact4')" *ngIf="resource.attorneyContact4.value && (resource.attorneyContact4.value !== '')"><a>{{ resource.attorneyContact4Role.value }}</a></li>
            </div>
        </div>
    </div>

    <button type="button" title="Tag As Duplicate"     *ngIf="(screenType !== 'phone') && !isTagged()"     data-abbrev="Tag"    class="btn btn-secondary actionButton" (click)="tagAsDuplicate()"><span class="fas fa-user-tag"></span></button>
    <button type="button" title="Un-Tag As Duplicate"  *ngIf="(screenType !== 'phone') && isTagged()"      data-abbrev="Un-Tag" class="btn btn-secondary actionButton" (click)="unTagAsDuplicate()"><span class="fas fa-user-tag"></span></button>

    <button type="button" title="Cancel" data-abbrev="" class="btn btn-secondary actionButton" (click)="cancel()"><span class="fas fa-times"></span></button>
</div>
