<div class="marginTop6 row">
    <div class="col-sm-6">
        <div class="card">
            <div class="card-header floatGroup">
                <div class="floatLeft">
                    Client
                </div>
                <div class="floatRight">
                    {{ resource.clientID.value }}
                </div>
            </div>
            <div class="card-body">
                <div><wackadoo-field [f]="resource.clientFirstName" [mode]="mode"></wackadoo-field><wackadoo-field [f]="resource.clientLastName" [mode]="mode"></wackadoo-field></div>
                <div class="marginTopQuarterEm"><wackadoo-field [f]="resource.clientCompany" [mode]="mode"></wackadoo-field></div>
                <div class="marginTopQuarterEm"><wackadoo-field [f]="resource.clientAddress" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.clientCity" [mode]="mode"></wackadoo-field><wackadoo-field [f]="resource.clientState" [mode]="mode" [size]="2"></wackadoo-field><wackadoo-field [f]="resource.clientPostalCode" [mode]="mode"></wackadoo-field></div>
                <div class="marginTopQuarterEm"><wackadoo-field [f]="resource.clientEmail" [mode]="mode"></wackadoo-field></div>
                <div><wackadoo-field [f]="resource.clientPhone" [mode]="mode"></wackadoo-field></div>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                Note
            </div>
            <div class="card-body">
                <wackadoo-field [f]="resource.clientNote" [mode]="mode"></wackadoo-field>
            </div>
        </div>
    </div>
    <div class="col-sm-6" *ngIf="resource.clientID.value">
        <ng-container *ngIf="resource.clientID.value">
            <wackadoo-contract-history
                title="Contract History"
                cardHeightClassName="maxHeightViewPort80"
                eventHandler="Reports"
                action="list"
                [parms]="{ reportName: 'Contract History for Client', clientID: resource.clientID.value }"
                defaultText="No contract history..."
                [debug]="false"
            ></wackadoo-contract-history>
        </ng-container>
<!--
        <div class="card">
            <div class="card-header">
                Open, Related Tasks (Top 25)
            </div>
            <div class="card-body smallish maxHeightViewPort50 scrollable-y">
                <div *wackadooResourceBlock="let r of []; let idx = index; eventHandler: 'CustomGenerator'; action: 'listRelatedOpenTasksForClient'; parms: {clientID: resource.clientID.value, sortBy:'sequence', sortDirection: 1, pageSize: 25 }; defaultText: 'No open tasks...'; debug: false;">
                    <wackadoo-related-task-detail [resource]="r" [mode]="mode"></wackadoo-related-task-detail>
                </div>
            </div>
        </div>
-->
    </div>
</div>
